export const data = [
  {
    testname: 'Test Name 1',
    passed: 150,
    failed: 30,
    didnotfinished: 20,
    total: 200
  },
  {
    testname: 'Test Name 2',
    passed: 150,
    failed: 30,
    didnotfinished: 10,
    total: 200
  },
  {
    testname: 'Test Name 3',
    passed: 250,
    failed: 30,
    didnotfinished: 2,
    total: 200
  },
  {
    testname: 'Test Name 4',
    passed: 30,

    failed: 50,
    didnotfinished: 100,
    total: 180
  },
  {
    testname: 'Test Name 5',
    passed: 250,
    failed: 30,
    didnotfinished: 2,
    total: 200
  },
  {
    testname: 'Test Name 6',
    passed: 250,
    failed: 30,
    didnotfinished: 2,
    total: 200
  },
  {
    testname: 'Test Name 7',
    passed: 250,
    failed: 30,
    didnotfinished: 2,
    total: 200
  },
  {
    testname: 'Test Name 8',
    passed: 250,
    failed: 30,
    didnotfinished: 2,
    total: 200
  },
  {
    testname: 'Test Name 9',
    passed: 250,
    failed: 30,
    didnotfinished: 2,
    total: 200
  },
  {
    testname: 'Test Name 10',
    passed: 250,
    failed: 30,
    didnotfinished: 2,
    total: 200
  }
]
