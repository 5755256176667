import React from 'react';

import CancleIcon  from "../../assets/cancelled_icon.svg";
import FailedIcon  from "../../assets/failed_icon.svg";
import PassIcon  from "../../assets/pass_icon.svg";
import SkipIcon  from "../../assets/skipped_icon.svg";


const GetExecutionStatusIcon = (result)=>{

const iconStyle = {style:{height:"1rem" ,width : "1rem" ,marginRight:".1rem",marginTop:".1rem"}, className:"ml-auto mx-0", alt:result}
  const icons = {
    Passed: <img src={PassIcon} {...iconStyle}/>,
    Failed: <img src={FailedIcon} {...iconStyle}/>,
    Skipped: <img src={SkipIcon} {...iconStyle}/>,
    Cancelled: <img src={CancleIcon} {...iconStyle}/>,
  }

  return icons[result]
}

export default GetExecutionStatusIcon;


