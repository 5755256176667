import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import Breadcrumb from '../../components/Navigation/Breadcrumb'
import ProjectNavigation from '../../components/Navigation/ProjectNavigation'
import { httpGet } from '../../store/services/testServiceClient'
import Alert from '../../components/Alerts/Alert'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button, Empty, Input, Space, Spin, Table, Tag, Select, Radio, Checkbox } from 'antd'
import { formatDate, getTimezoneOffset } from '../../utils/dateTime'
import { getTagColor } from '../../utils/util'
import { classNames } from '../../utils/styles'
import qs from 'query-string'
import validateEmail from '../../utils/email-validator'
import { TEST_STATUSES } from '../../statics/constants'

let pageInfo = null
let columnWidths = {
  name: 350,
  assignee: 190,
  status: 120,
  tags: 280,
  lastExecution: 180,
  lastModified: 190
}

const Tests = (props) => {
  const alertBox = useRef()
  const focusRef = useRef()
  const assigneeDetails = useMemo(() => new Map(), [])
  const [tests, setTests] = useState([])
  const [showProgress, setShowProgress] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [tempText, setTempText] = useState('')
  const [hasMore, setHasMore] = useState(false)
  const [status, setStatus] = useState('')
  const [filterTags, setFilterTags] = useState([])
  const [assignee, setAssignee] = useState('Anybody')
  const [lastResult, setLastResult] = useState('')
  const [nameFilterVisible, setNameFilterVisible] = useState(false)
  const [statusFilterVisible, setStatusFilterVisible] = useState(false)
  const [tagFilterVisible, setTagFilterVisible] = useState(false)
  const [assigneeFilterVisible, setAssigneeFilterVisible] = useState(false)
  const [resultFilterVisible, setResultFilterVisible] = useState(false)
  const [projectTags, setProjectTags] = useState([])
  const [tags, setTags] = useState([])
  const [filtersUpdated, setFiltersUpdated] = useState(false)
  const [orderBy, setOrderBy] = useState('Name')
  const queryParameters = useRef()
  const resizingColumnRef = useRef(undefined)
  const [actualColumnWidths, setActualColumnWidths] = useState(columnWidths)
  const minColumnWidth = 80
  const testTableContainerRef = useRef()
  const [showInfiniteScrollLoader, setShowInfiniteScrollLoader] = useState(false)
  const resizeHandleBorder = useRef()

  const extractQueryParameterFromUrl = useCallback((paramName) => {
    const uri = window.location.search.substring(1)
    const params = new URLSearchParams(uri)
    if (params.has(paramName)) {
      updateQueryParameters(paramName, params.get(paramName))
      return params.get(paramName)
    } else {
      // throw new Error(`Query parameter '${paramName}' is missing.`)
      return ''
    }
  }, [])

  const updateQueryParameters = (key, value) => {
    queryParameters.current = { ...queryParameters.current, [key]: value }
  }

  const updateQueryParametersAndPush = useCallback(
    (key, value) => {
      queryParameters.current = { ...queryParameters.current, [key]: value }
      const searchString = qs.stringify(queryParameters.current)
      props.history.push({
        search: searchString
      })
    },
    [props.history]
  )

  const getAssigneeIdFromEmail = (email) => {
    const id = [...assigneeDetails].find(([key, val]) => val === email)
    return id ? id[0] : email
  }

  const getAssigneeName = useCallback(
    (id) => {
      return assigneeDetails.has(id) ? assigneeDetails.get(id) : 'Nobody'
    },
    [assigneeDetails]
  )

  const setInitialTags = useCallback(() => {
    const statusFromUrl = extractQueryParameterFromUrl('status')
    const assigneeFromUrl = extractQueryParameterFromUrl('assignee')
    const tagsFromUrl = extractQueryParameterFromUrl('tags')
    const testNameFromUrl = extractQueryParameterFromUrl('filter')
    const lastResultFromUrl = extractQueryParameterFromUrl('lastResult')
    if (statusFromUrl) {
      setStatus(statusFromUrl)
      setFilterTags([{ id: 'status', value: statusFromUrl, label: 'Status' }])
    }
    if (assigneeFromUrl) {
      validateEmail.isEmail(assigneeFromUrl)
        ? setAssignee(() => getAssigneeIdFromEmail(assigneeFromUrl))
        : setAssignee(assigneeFromUrl)
      assigneeFromUrl !== 'Anybody' &&
        setFilterTags((prev) => [
          ...prev,
          {
            id: 'assignee',
            value: !validateEmail.isEmail(assigneeFromUrl) ? getAssigneeName(assigneeFromUrl) : assigneeFromUrl,
            label: 'Assignee'
          }
        ])
    }
    if (tagsFromUrl) {
      setTags(tagsFromUrl.split(','))
      setFilterTags((prev) => [...prev, { id: 'tags', value: tagsFromUrl.split(',').join('; '), label: 'Tags' }])
    }
    if (testNameFromUrl) {
      setSearchText(testNameFromUrl)
      setFilterTags((prev) => [...prev, { id: 'filter', value: testNameFromUrl, label: 'Test Name' }])
    }
    if (lastResultFromUrl) {
      setLastResult(lastResultFromUrl)
      setFilterTags((prev) => [...prev, { id: 'lastResult', value: lastResultFromUrl, label: 'Result' }])
    }
    setFiltersUpdated(true)
  }, [extractQueryParameterFromUrl, getAssigneeName])

  const getProjectTags = useCallback(async () => {
    try {
      const res = await httpGet(`projects/${props.match.params.project}/testtags`)
      setProjectTags(res.data)
    } catch (error) {
      updateUIOnError('Error retrieving project tags.', error)
    }
  }, [props.match.params.project])

  const loadProjectUsers = useCallback(async () => {
    try {
      const response = await httpGet(`projects/${props.match.params.project}/users`)
      response.data.forEach((user) => {
        assigneeDetails.set(user.Id, user.Email)
      })
    } catch (e) {
      updateUIOnError('Error loading project users.', e)
    }
  }, [assigneeDetails, props.match.params.project])

  const loadTests = useCallback(
    async (loadMore = false) => {
      try {
        const queryStringParameters = new URLSearchParams({
          filter: searchText.trim(),
          orderBy,
          cursor: !pageInfo ? '' : pageInfo.Next,
          status,
          assignee: assignee === 'Nobody' ? '' : assignee,
          tags: tags.length > 0 ? tags.join(',') : '',
          lastResult
        }).toString()
        const response = await httpGet(`projects/${props.match.params.project}/testindex?${queryStringParameters}`)
        if (loadMore) {
          setTests((prev) => {
            return [...prev, ...response.data.Items]
          })
        } else {
          setTests(response.data.Items)
        }
        pageInfo = response.data.Info
        setHasMore(pageInfo?.HasNext)
        setShowProgress(false)
      } catch (e) {
        updateUIOnError('Error retrieving tests.', e)
      }
    },
    [searchText, status, tags, assignee, orderBy, lastResult, props.match.params.project]
  )

  const loadFirstPage = useCallback(async () => {
    showProgressUI()
    setTests([])
    pageInfo = null
    setHasMore(false)
    await loadTests()
  }, [loadTests])

  useEffect(() => {
    const showTests = async () => {
      showProgressUI()
      await loadProjectUsers()
      getProjectTags()
      setInitialTags()
    }
    showTests()
  }, [loadProjectUsers, getProjectTags, setInitialTags])

  const loadMore = async () => {
    // LoadMore() can be called by the infiniteScroll anytime after the mounted state.
    // Allow Loading the next page only when first page is loaded.
    if (pageInfo != null && pageInfo.HasNext) {
      setShowInfiniteScrollLoader(true)
      await loadTests(true)
      setShowInfiniteScrollLoader(false)
    }
  }

  const showTestDetails = (testid) => {
    props.history.push(`/p/${props.match.params.project}/tests/${testid}`)
  }

  const showProgressUI = () => {
    setShowProgress(true)
    alertBox.current?.hideAlert()
  }

  const updateUIOnError = (message, e) => {
    setShowProgress(false)
    alertBox.current?.showServerError(message, e)
  }

  const searchString = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab' || e.type === 'click') {
      setSearchText(tempText)
      setNameFilterVisible(false)
      updateFilterTags({ id: 'filter', value: tempText, label: 'Test Name' })
      updateQueryParametersAndPush('filter', tempText)
      setTempText('')
      setFiltersUpdated(true)
    }
  }

  useEffect(() => {
    if (nameFilterVisible) {
      setTimeout(() => focusRef.current.focus(), 100)
    }
  }, [nameFilterVisible])

  const applyStatusFilter = (value) => {
    setStatus(value)
    setStatusFilterVisible(false)
    updateFilterTags({ id: 'status', value: value, label: 'Status' })
    updateQueryParametersAndPush('status', value)
    setFiltersUpdated(true)
  }

  const applyAssigneeFilter = (value) => {
    setAssignee(value)
    setAssigneeFilterVisible(false)
    if (value !== 'Anybody') {
      updateFilterTags({ id: 'assignee', value: getAssigneeName(value), label: 'Assignee' })
    } else {
      setFilterTags(filterTags.filter((tag) => tag.id !== 'assignee'))
    }
    updateQueryParametersAndPush('assignee', value)
    setFiltersUpdated(true)
  }

  const applyTagsFilter = () => {
    setTagFilterVisible(false)
    updateFilterTags({ id: 'tags', value: tags.join(','), label: 'Tags' })
    updateQueryParametersAndPush('tags', tags.join(','))
    setFiltersUpdated(true)
  }

  const updateFilterTags = (newTag) => {
    if (filterTags.filter((tag) => tag.id === newTag.id).length > 0) {
      setFilterTags(filterTags.map((tag) => (tag.id === newTag.id ? newTag : tag)))
    } else {
      setFilterTags((prev) => [...prev, newTag])
    }
  }

  const applyResultFilter = (value) => {
    setLastResult(value)
    setResultFilterVisible(false)
    if (value !== '') {
      updateFilterTags({ id: 'lastResult', value: value, label: 'Result' })
    } else {
      setFilterTags(filterTags.filter((tag) => tag.id !== 'lastResult'))
    }
    updateQueryParametersAndPush('lastResult', value)
    setFiltersUpdated(true)
  }

  const removeTag = (tag) => {
    setFilterTags(filterTags.filter((item) => item.id !== tag.id))
    switch (tag.id) {
      case 'status':
        setStatus('')
        updateQueryParametersAndPush('status', undefined)
        break
      case 'tags':
        setTags([])
        updateQueryParametersAndPush('tags', undefined)
        break
      case 'assignee':
        setAssignee('Anybody')
        updateQueryParametersAndPush('assignee', undefined)
        break
      case 'filter':
        setSearchText('')
        updateQueryParametersAndPush('filter', undefined)
        break
      case 'lastResult':
        setLastResult('')
        updateQueryParametersAndPush('lastResult', undefined)
        break
      default:
        break
    }
    setFiltersUpdated(true)
  }

  const clearFilters = () => {
    setStatus('')
    setFilterTags([])
    setTags([])
    setAssignee('Anybody')
    setSearchText('')
    setLastResult('')
    props.history.push({ search: undefined })
    queryParameters.current = {}
    setFiltersUpdated(true)
  }

  const updateOrderBy = (value) => {
    setOrderBy(value)
    setFiltersUpdated(true)
  }

  useEffect(() => {
    if (filtersUpdated) {
      loadFirstPage()
      setFiltersUpdated(false)
    }
  }, [filtersUpdated, loadFirstPage])

  const nameFilter = () => ({
    filterDropdown: () => (
      <div className='p-2'>
        <Space>
          <Input
            ref={focusRef}
            value={tempText}
            onChange={(e) => setTempText(e.target.value)}
            placeholder='Filter by test name'
            onKeyDown={(e) => searchString(e)}
            style={{ width: '220px', fontSize: '13px' }}
          />
          <Button type='primary' icon={<i className='fa fa-search' />} className='-ml-2.5' onClick={searchString} />
        </Space>
      </div>
    ),
    filterIcon: () => <i className='fa fa-search' />
  })

  const assigneeFilter = () => ({
    filterDropdown: () => (
      <div className='py-2 pl-2 pr-1'>
        <Radio.Group
          onChange={(e) => applyAssigneeFilter(e.target.value)}
          value={assignee}
        >
          <Space direction='vertical' size={4}>
            {Array.from(assigneeDetails).map(([key, value]) => (
              <Radio value={key} checked={assignee === key} className='filterDropdownItem'>
                {value}
              </Radio>
            ))}

            <Radio value='Nobody' checked={assignee === ''} className='filterDropdownItem'>
              Nobody
            </Radio>
            <Radio value='Anybody' checked={assignee === 'Anybody'} className='filterDropdownItem'>
              Anybody
            </Radio>
          </Space>
        </Radio.Group>
      </div>
    )
  })

  const statusFilter = () => ({
    filterDropdown: () => (
      <div className='py-2 pl-2 pr-1'>
        <Radio.Group onChange={(e) => applyStatusFilter(e.target.value)} value={status}>
          <Space direction='vertical' size={4}>
            {TEST_STATUSES.map((testStatus) => (
              <Radio value={testStatus} checked={status === testStatus} className='filterDropdownItem'>
                {testStatus}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
    )
  })

  const tagsFilter = () => ({
    filterDropdown: () => (
      <div className='py-2 pl-2 pr-1 '>
        {projectTags.length > 0 ? (
          <>
            <Checkbox.Group onChange={(checkedValues) => setTags(checkedValues)} value={tags}>
              <Space direction='vertical' size={4}>
                {projectTags?.map((tag) => (
                  <Checkbox value={tag} className='filterDropdownItem'>
                    {tag}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
            <div className='flex justify-end mt-2'>
              <Button type='primary' disabled={tags.length === 0} size='small' onClick={applyTagsFilter}>
                Apply
              </Button>
            </div>
          </>
        ) : (
          <p className='m-0 p-0 text-gray-400'>No Tags</p>
        )}
      </div>
    )
  })

  const resultFilter = () => ({
    filterDropdown: () => (
      <div className='py-2 pl-2 pr-1'>
        <Radio.Group onChange={(e) => applyResultFilter(e.target.value)} value={lastResult}>
          <Space direction='vertical' size={4}>
            <Radio value='Passed' checked={lastResult === 'Passed'} className='filterDropdownItem'>
              Passed
            </Radio>
            <Radio value='DidNotFinish' checked={lastResult === 'DidNotFinish'} className='filterDropdownItem'>
              Not Finished
            </Radio>
            <Radio value='Failed' checked={lastResult === 'Failed'} className='filterDropdownItem'>
              Failed
            </Radio>
            <Radio value='Cancelled' checked={lastResult === 'Cancelled'} className='filterDropdownItem'>
              Cancelled
            </Radio>
            <Radio value='NotRun' checked={lastResult === 'NotRun'} className='filterDropdownItem'>
              Not Run
            </Radio>
            <Radio value='' checked={lastResult === ''} className='filterDropdownItem'>
              All
            </Radio>
          </Space>
        </Radio.Group>
      </div>
    )
  })

  useEffect(() => {
    const allSelects = document.getElementsByClassName('ant-select-selection-search-input')
    for (const el of allSelects) {
      el.setAttribute('readonly', true)
    }
  })

  const setResizingColumn = (index) => {
    resizingColumnRef.current = index
  }

  const showResizeHandleBorder = (e) => {
    testTableContainerRef.current.style.cursor = 'col-resize'
    resizeHandleBorder.current.style.left = e.pageX + 'px'
    resizeHandleBorder.current.style.height = document.querySelector('#scrollableTestsDiv').clientHeight + 'px'
    resizeHandleBorder.current.style.display = 'block'
  }

  const hideResizeHandleBorder = () => {
    testTableContainerRef.current && (testTableContainerRef.current.style.cursor = 'default')
    resizeHandleBorder.current && (resizeHandleBorder.current.style.display = 'none')
  }

  const resizeListener = (e) => {
    if (resizingColumnRef.current !== undefined) {
      handleResize(resizingColumnRef.current, e.movementX)
      resizeHandleBorder.current.style.left = e.pageX + 'px'
    }
    window.onmouseup = (e) => {
      window.removeEventListener('mousemove', resizeListener)
      setResizingColumn(undefined)
      setActualColumnWidths(columnWidths)
      localStorage.setItem('testsTableColumnWidths', JSON.stringify(columnWidths))
      hideResizeHandleBorder()
    }
  }

  const handleResize = (columnName, delta) => {
    let updatedWidth = columnWidths[columnName] + delta
    updatedWidth = updatedWidth >= minColumnWidth ? updatedWidth : minColumnWidth
    columnWidths = { ...columnWidths, [`${columnName}`]: updatedWidth }
  }

  useEffect(() => {
    if (localStorage.getItem('testsTableColumnWidths')) {
      columnWidths = JSON.parse(localStorage.getItem('testsTableColumnWidths'))
      setActualColumnWidths(columnWidths)
    } else {
      if (testTableContainerRef.current.offsetWidth > Object.values(columnWidths).reduce((prev, curr) => prev + curr)) {
        columnWidths = {
          name: testTableContainerRef.current.offsetWidth * 0.35,
          assignee: testTableContainerRef.current.offsetWidth * 0.15,
          status: testTableContainerRef.current.offsetWidth * 0.08,
          tags: testTableContainerRef.current.offsetWidth * 0.2,
          lastExecution: testTableContainerRef.current.offsetWidth * 0.1,
          lastModified: testTableContainerRef.current.offsetWidth * 0.115
        }
        setActualColumnWidths(columnWidths)
      }
    }
  }, [])

  const columns = [
    {
      title: (
        <div className='flex justify-between items-center h-5 select-none'>
          <span>Name</span>
          <i
            className={classNames(
              'fas fa-sort-down text-xl px-0.5 pb-2',
              orderBy === 'Name' ? 'cursor-default' : 'cursor-pointer sortButton'
            )}
            style={{ color: orderBy === 'Name' ? '#3b82f6' : '#bfbfbf' }}
            onClick={() => orderBy !== 'Name' && updateOrderBy('Name')}
            title={orderBy !== 'Name' ? 'Click to sort by name' : ''}
          />
          <div
            className='resizeHandle'
            style={{ right: '-28px' }}
            onMouseDown={(e) => {
              setResizingColumn('name')
              showResizeHandleBorder(e)
              window.addEventListener('mousemove', resizeListener, { passive: true })
            }}
          />
        </div>
      ),
      dataIndex: 'Name',
      width: actualColumnWidths.name,
      render: (Name, test) => (
        <div className='text-blue-500 hover:underline cursor-pointer ellipsis' onClick={() => showTestDetails(test.Id)}>
          {Name}
        </div>
      ),
      ...nameFilter(),
      filterDropdownVisible: nameFilterVisible,
      onFilterDropdownVisibleChange: (visible) => setNameFilterVisible(visible)
    },
    {
      title: (
        <div className='select-none'>
          Assignee
          <div
            className='resizeHandle'
            style={{ right: '-28px' }}
            onMouseDown={(e) => {
              setResizingColumn('assignee')
              showResizeHandleBorder(e)
              window.addEventListener('mousemove', resizeListener, { passive: true })
            }}
          />
        </div>
      ),
      dataIndex: 'Assignee',
      width: actualColumnWidths.assignee,
      render: (Assignee) => <div className='ellipsis'>{getAssigneeName(Assignee)}</div>,
      ...assigneeFilter(),
      filterDropdownVisible: assigneeFilterVisible,
      onFilterDropdownVisibleChange: (visible) => setAssigneeFilterVisible(visible)
    },
    {
      title: (
        <div className='select-none'>
          Status
          <div
            className='resizeHandle'
            style={{ right: '-28px' }}
            onMouseDown={(e) => {
              setResizingColumn('status')
              showResizeHandleBorder(e)
              window.addEventListener('mousemove', resizeListener, { passive: true })
            }}
          />
        </div>
      ),
      dataIndex: 'Status',
      width: actualColumnWidths.status,
      ...statusFilter(),
      filterDropdownVisible: statusFilterVisible,
      onFilterDropdownVisibleChange: (visible) => setStatusFilterVisible(visible)
    },
    {
      title: (
        <div className='select-none'>
          Tags
          <div
            className='resizeHandle'
            style={{ right: '-28px' }}
            onMouseDown={(e) => {
              setResizingColumn('tags')
              showResizeHandleBorder(e)
              window.addEventListener('mousemove', resizeListener, { passive: true })
            }}
          />
        </div>
      ),
      dataIndex: 'Tags',
      width: actualColumnWidths.tags,
      render: (Tags, record) =>
        Tags?.length > 0 && (
          <Select
            id={record.Id}
            key={record.Id}
            value={Tags}
            bordered={false}
            mode='tags'
            maxTagCount='responsive'
            style={{ width: '100%', cursor: 'pointer' }}
            menuItemSelectedIcon={false}
            removeIcon={false}
            dropdownClassName='testTags'
          />
        ),
      ...tagsFilter(),
      filterDropdownVisible: tagFilterVisible,
      onFilterDropdownVisibleChange: (visible) => setTagFilterVisible(visible)
    },
    {
      title: (
        <div className='select-none ellipsis' style={{ width: actualColumnWidths.lastExecution - 32 }}>
          Last Execution
          <div
            className='resizeHandle'
            style={{ right: '-28px' }}
            onMouseDown={(e) => {
              setResizingColumn('lastExecution')
              showResizeHandleBorder(e)
              window.addEventListener('mousemove', resizeListener, { passive: true })
            }}
          />
        </div>
      ),
      width: actualColumnWidths.lastExecution,
      render: (text, record) =>
        record.RecentExecutions && (
          <>
            <Tag color={getTagColor(record.RecentExecutions.at(-1).Result)}>
              {record.RecentExecutions.at(-1).Result === 'DidNotFinish'
                ? 'Not Finished'
                : record.RecentExecutions.at(-1).Result}
            </Tag>
          </>
        ),
      ...resultFilter(),
      filterDropdownVisible: resultFilterVisible,
      onFilterDropdownVisibleChange: (visible) => setResultFilterVisible(visible)
    },
    {
      title: (
        <div className='flex justify-between items-center h-5 select-none '>
          <div
            className='ellipsis'
            title={`Last Modified (${getTimezoneOffset()})`}
          >{`Last Modified (${getTimezoneOffset()})`}
          </div>
          <i
            className={classNames(
              'fas fa-sort-up px-0.5 text-xl pt-2',
              orderBy === '' ? 'cursor-default' : 'cursor-pointer sortButton'
            )}
            style={{ color: orderBy === '' ? '#3b82f6' : '#bfbfbf' }}
            onClick={() => orderBy !== '' && updateOrderBy('')}
            title={orderBy !== '' ? 'Click to sort by last modified date' : ''}
          />
          <div
            className='resizeHandle'
            style={{ right: '1px' }}
            onMouseDown={(e) => {
              setResizingColumn('lastModified')
              showResizeHandleBorder(e)
              window.addEventListener('mousemove', resizeListener, { passive: true })
            }}
          />
        </div>
      ),
      dataIndex: 'LastModified',
      width: actualColumnWidths.lastModified,
      render: (LastModified) => <div className='ellipsis'>{formatDate(LastModified, false)}</div>
    }
  ]

  return (
    <div className=' mt-3'>
      <Breadcrumb />
      <ProjectNavigation />
      <div id='testsUIPage' ref={testTableContainerRef}>
        <Alert ref={alertBox} showCloseButton />
        <div
          className={classNames(filterTags.length > 0 ? 'justify-between' : 'justify-end', 'flex pl-0')}
          style={{ minHeight: '35px' }}
        >
          {filterTags.length > 0 && (
            <div>
              {filterTags.map((tag) => (
                <Tag closable color='blue' className='filterTag' key={tag.id} onClose={() => removeTag(tag)}>
                  {tag.label}: "{tag.value}"
                </Tag>
              ))}
              <Button onClick={clearFilters} size='small' className='filterClearButton'>
                Clear
              </Button>
            </div>
          )}
          <p className='float-right my-auto'>
            Showing {pageInfo?.Next ? '' : 'all'} {tests.length} items
          </p>
        </div>
        <div id='scrollableTestsDiv'>
          <InfiniteScroll
            dataLength={tests.length}
            next={loadMore}
            hasMore={hasMore}
            loader={
              showInfiniteScrollLoader && (
                <center>
                  <Spin className='mt-2' />
                </center>
              )
            }
            style={{ overflowX: 'hidden' }}
            scrollableTarget='scrollableTestsDiv'
          >
            <div id='resizeHandleBorderTests' ref={resizeHandleBorder} />
            <Table
              size='small'
              columns={columns}
              dataSource={tests}
              pagination={false}
              scroll={{ x: 'min-content' }}
              tableLayout='fixed'
              locale={{
                emptyText: showProgress ? (
                  <Spin tip='Loading tests...' />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<b>No tests found.</b>} />
                )
              }}
              className='testsTable'
              style={{ minHeight: hasMore && 'calc(100vh - 291px) ' }}
            />
          </InfiniteScroll>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Tests)
