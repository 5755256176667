import { Auth } from 'aws-amplify'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'

export const awsAppSyncClient = new AWSAppSyncClient({
  url: process.env.REACT_APP_ANALYSIS_SERVICE_URL,
  region: process.env.REACT_APP_AWS_REGION,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
  }
})
