import { Card, Empty, Skeleton, Tooltip } from 'antd'
import React from 'react'
import { TagCloud } from 'react-tagcloud'
import { withRouter } from 'react-router'

const TagsUsageCloud = ({ loading, tagsUsage, ...props }) => {
  const customRenderer = (tag, size, color) => {
    return (
      <Tooltip title={`Used in ${tag.count} ${tag.count === 1 ? 'test' : 'tests'}`}>
        <span
          className='m-2 cursor-pointer'
          key={tag.value}
          style={{ color, fontSize: size }}
          onClick={() => props.history.push(`/p/${props.match.params.project}/tests?tags=${tag.value}`)}
        >
          {tag.value}
        </span>
      </Tooltip>
    )
  }
  return (
    <Card
      className='col-span-1 lg:col-span-1 shadow-sm rounded-sm'
      size='small'
      style={{ height: 'fit-content' }}
      title={<strong className='text-xs'>Tags Usage</strong>}
      bodyStyle={{ paddingTop: 0, paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}
    >
      {loading ? (
        <Skeleton className='px-4' loading={loading} active />
      ) : tagsUsage?.length > 0 ? (
        <TagCloud
          className='flex items-center flex-wrap 2xl:overflow-hidden overflow-x-auto overflow-y-hidden'
          minSize={15}
          maxSize={40}
          tags={tagsUsage.map((obj) => (obj = { ...obj, value: obj.tag }))}
          renderer={customRenderer}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<b>There are no tags in this project.</b>} />
      )}
    </Card>
  )
}

export default withRouter(TagsUsageCloud)
