import React, { useState } from 'react'
import { ResponsiveHeatMap } from '@nivo/heatmap'
import { data } from './dataReport2'
import { Button, Dropdown, Menu, Divider, Spin } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import UMDatePicker from './components/UMDatePicker'

export default function Report2 () {
  const [refresh, setRefresh] = useState(false)

  const colors = []
  for (let i = 0; i < data.length; i++) {
    colors.push('hsl(119, 67%, 65%)')
    colors.push('hsl(0, 78%, 60%)')
    colors.push('hsl(51, 100%, 50%)')
  }

  function getColor () {}
  getColor.domain = () => {
    return () => {
      return colors.shift()
    }
  }

  return (
    <div style={{ border: '1px solid lightgrey' }}>
      <div className='px-3 my-3  flex items-center justify-between'>
        {/* <Dropdown
          overlay={
            <Menu>
              <UMDatePicker />
            </Menu>
          }
          trigger={['click']}
          className='mr-3'
        >
          <span
            style={{
              border: '1px solid lightgrey',
              padding: 5,
              width: 130
            }}
            className='rounded-sm'
          >
            Last 6 Months <DownOutlined style={{ color: 'grey', float: 'right' }} className='mt-1.5' />
          </span>
        </Dropdown> */}
        <Button
          shape='circle'
          icon={<i className='fas fa-redo-alt' style={{ color: 'black' }} />}
          onClick={() => {
            setRefresh(true)
            setTimeout(() => {
              setRefresh(false)
            }, 1000)
          }}
        />
      </div>
      <Divider className='mt-0 pt-0' />
      <Spin tip='Loading...' spinning={refresh}>
        <div style={{ height: 600 }}>
          <ResponsiveHeatMap
            data={data}
            keys={['passed', 'failed', 'didnotfinished']}
            indexBy='testname'
            margin={{ top: 85, right: 180, bottom: 100, left: 220 }}
            forceSquare={false}
            padding={1}
            colors={getColor}
            axisTop={{
              orient: 'center',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendOffset: 0
            }}
            axisLeft={{
              orient: 'right',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Test Name',
              legendPosition: 'middle',
              legendOffset: -140
            }}
            axisRight={null}
            axisBottom={null}
            enableGridX={false}
            enableGridY
            cellShape='rect'
            cellOpacity={1}
            cellBorderWidth={0}
            cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
            enableLabels
            labelTextColor={{ from: 'color', modifiers: [['darker', '2.1']] }}
            animate
            motionStiffness={120}
            motionDamping={9}
            isInteractive
            hoverTarget='cell'
            cellHoverOpacity={1}
            cellHoverOthersOpacity={0.8}
          />
        </div>
      </Spin>
    </div>
  )
}
