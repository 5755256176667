import React, { useEffect, useState, useCallback } from 'react'
import { classNames } from '../../../utils/styles'
import { formatDate, toApproximateDuration } from '../../../utils/dateTime'
import { BugOutlined, InfoCircleFilled } from '@ant-design/icons'
import { Spin } from 'antd'
import { getAttachmentUrl } from '../../../utils/reports'
import GetExecutionStatusIcon from '../../../components/Report/GetExecutionStatusIcons'
import { allowedSequences } from '../../../utils/util'

const DetailReportNode = ({ node, root, runId }) => {
  const [inputs, setInputs] = useState([])
  const [outputs, setOutputs] = useState([])
  const [result, setResult] = useState('Passed')
  const [started, setStarted] = useState()
  const [isBranch, setIsBranch] = useState()
  const [isComposite, setIsComposite] = useState()
  const [isLeftBranchSelected, setIsLeftBranchSelected] = useState()
  const [isRightBranchSelected, setIsRightBranchSelected] = useState()
  const [canDisplayLogMessages, setCanDisplayLogMessages] = useState()
  const [isSeq, setIsSeq] = useState()
  const [message, setMessage] = useState('')
  const [duration, setDuration] = useState(0)
  const [imgIdentifiers, setImgIdentifiers] = useState({})
  const [isLoop, setIsLoop] = useState()
  const [isIteration, setIsIteration] = useState()

  const summaryResult = useCallback(() => {
    return node.Result
  }, [node])

  useEffect(() => {
    if (node) {
      setStarted(formatDate(node.StartTime, {
        year: undefined,
        day: '2-digit',
        second: 'numeric'
      }))
      setIsBranch(node.Type === 'Branch')
      setIsComposite(node.Type === 'CompositeStep')
      setIsLeftBranchSelected(node.Type === 'Branch' && inputs[0]?.Argument.value === inputs[1]?.Argument.value)
      setIsRightBranchSelected(
        node.Type === 'Branch' && !(node.Type === 'Branch' && inputs[0]?.Argument.value === inputs[1]?.Argument.value) && result === 'Passed'
      )
      setCanDisplayLogMessages(
        ((node.Type === 'Step' && node.Lines.length > 0) || node.Result === 'Failed') && (node.Type !== 'Iteration')
      )
      setIsSeq(allowedSequences.includes(node.Type))
      setMessage(node.Message || '')
      setDuration(toApproximateDuration(node.Duration ? node.Duration : 0))
      setIsLoop(node.Type === 'Loop')
      setIsIteration(node.Type === 'Iteration')
    }
  }, [node, inputs, result])

  useEffect(() => {
    async function getImages () {
      const imgIdsLoaded = Object.values(imgIdentifiers).some(id => Boolean(id))
      if (!imgIdsLoaded) {
        await setUrl(imgIdentifiers)
      }
    }
    getImages()
  }, [imgIdentifiers])

  async function setUrl (imgIds) {
    for (const imgId in imgIds) {
      const url = await getAttachmentUrl(runId, imgId)
      setImgIdentifiers((prevImgIdentifiers) => ({
        ...prevImgIdentifiers,
        [imgId]: url
      }))
    }
  }

  const getArgumentValue = (input, imgIdentifiers) => {
    if (input.Argument?.type === 'ImageIdentifier' || input.Argument?.isImageIdentifer || input.type) {
      return (
        <div className='w-20'>
          <Spin spinning={!imgIdentifiers[input.Argument.id] && !input.Argument?.isImageIdentifer} size='small'>
            <img
              className='text-xs w-20'
              src={imgIdentifiers[input.Argument.id]}
              alt={`${
                input.Argument?.isImageIdentifer
                  ? 'Image cannot be displayed'
                  : `${imgIdentifiers[input.Argument.id] ? input.Argument?.type : ''}`
              }`}
            />
          </Spin>
        </div>
      )
    } else {
      return input.Argument?.value
    }
  }

  const paramArgToViewModel = (paramArg) => {
    // older report contains keyvalue pair for parameters {{key,value}}
    // new report contains list of parameters {{Key,name,value }}
    let arg = {}
    if (paramArg.Value && typeof paramArg.Value === 'object') {
      const type = paramArg.Value.ArgType ? paramArg.Value.ArgType : paramArg.Value.$type
      switch (type) {
        case 'PlainText':
        case 'ArgPlainText':
          const regex = /^<datatypes.ImageIdentifier object at (0x[\dA-Fa-f]+)>$/
          const isImageIdentifer = regex.test(paramArg.Value.Text)
          arg = {
            value: paramArg.Value.Text,
            type,
            isImageIdentifer
          }
          break
        case 'ArgIdentifier':
        case 'Identifier':
        case 'SimpleIdentifier':
        case 'ArgSimpleIdentifier':
          arg = {
            value: paramArg.Value.Method + '=' + paramArg.Value.Specification,
            type
          }
          break
        case 'ImageIdentifier':
        case 'ArgImageIdentifier':
          setImgIdentifiers((prevImgIdentifiers) => ({
            ...prevImgIdentifiers,
            [paramArg.Value.Id]: ''
          }))
          arg = {
            id: paramArg.Value.Id,
            type
          }
          break
        case 'Secret':
        case 'ArgObscureSecret':
          // Reports generated with V1 don't have the Text field
          arg = {
            value: paramArg.Value?.Text || '******',
            type: 'Secret'
          }
          break
        default:
          arg = {
            value: paramArg?.Value?.Text || 'No value found',
            type
          }
          break
      }
    } else if (typeof paramArg.Value === 'string') {
      arg = {
        value: paramArg.Value,
        type: typeof paramArg.Value
      }
    }
    return { Id: paramArg.Key, Name: paramArg.Name, Argument: arg }
  }

  useEffect(() => {
    setResult(summaryResult())
    if (node.Parameters) {
      setInputs(node.Parameters.map(paramArgToViewModel))
    }
    if (node.Outputs) {
      setOutputs(node.Outputs.map(paramArgToViewModel))
    }
  }, [node.Outputs, node.Parameters, summaryResult])

  return (
    <>
      {!root && (
        <>
          <div className='flex flex-row checkPageBreak' style={{ border: '1px solid #ddd', fontSize: '13px' }}>
            <div
              className='flex items-start p-1 pl-2 text-sm'
              style={{ width: '110px', borderRight: '1px solid #ddd' }}
            >
              <p className='font-bold mb-0 font-mono text-base'>{isIteration ? node.CurrentIteration : node.StepNum}</p>
              {(() => {
                return GetExecutionStatusIcon(result)
              })()}
            </div>
            <div>
              <div className='flex'>
                <div style={{ width: '270px', borderRight: '1px solid #ddd' }} className='p-1 '>
                  <p className='m-0 font-bold ml-1 mb-1'>
                    {isIteration
                      ? `${node.Type}  ${node.CurrentIteration.replaceAll('I', '-').split('-').pop()}`
                      : node.StepName}
                  </p>
                  {result === 'Skipped' ? (
                    <p className='py-1 m-0 text-gray-400 italic ml-1'> Step skipped </p>
                  ) : result === 'Cancelled' && node.Nodes?.length == 0 ? (
                    <p>
                      <span className='ml-1 italic py-1 ' style={{ color: 'rgb(253 115 197 / 62%)', fontWeight: 600 }}>
                        Step Cancelled By User{' '}
                      </span>
                    </p>
                  ) : isBranch ? (
                    <p className='py-1 m-0 italic ml-1'>
                      <i className='fas fa-cube text-blue-300 mr-1' />
                      Junction
                    </p>
                  ) : isComposite ? (
                    <p className='py-1 m-0 italic ml-1'>
                      <i className='fas fa-cubes text-blue-300 mr-1' />
                      {node.ComponentName}
                    </p>
                  ) : isLoop ? (
                    <p className='py-1 m-0 italic ml-1'>
                      <i className='fas fa-cube text-blue-300 mr-1' />
                      Loop
                    </p>
                  ) : isIteration ? (
                    <p className='py-1 m-0 italic ml-1'>
                      <i className='fas fa-undo mr-1' />
                      Iteration
                    </p>
                  ) : (
                    node.ComponentName && (
                      <p className='py-1 m-0 italic ml-1'>
                        <i className='fas fa-cube text-blue-300 mr-1' />
                        {node.ComponentName}
                      </p>
                    )
                  )}
                </div>

                <div style={{ width: '110px', borderRight: '1px solid #ddd' }} className='p-1'>
                  <p className='m-0 py-1 ml-1'>
                    <i className='fas fa-clock mr-1 text-gray-400' />
                    {started}
                  </p>
                  <p className='m-0 py-1 ml-1'>
                    <i className='fas fa-stopwatch mr-1 text-gray-400' style={{ fontSize: '15px' }} />
                    {duration}
                  </p>
                </div>

                <div style={{ width: '560px' }}>
                  {isBranch === true ? (
                    <>
                      <div className='grid grid-cols-7 py-1'>
                        <p
                          className='mb-0 pb-0 col-span-2'
                          style={{
                            borderRight: '1px solid #dddddd'
                          }}
                        >
                          <div className='inline-block' style={{ width: '20px' }}>
                            <i className='fas fa-code-branch text-purple-600 mx-1' />
                          </div>
                          decisionVariable
                        </p>
                        <p className='mb-0 pb-0 mx-1 col-span-2' style={{ borderRight: '1px solid #dddddd' }}>
                          Decision Variable
                        </p>
                        <p className='mb-0 pb-0 mx-1 col-span-3' style={{ wordBreak: 'break-all' }}>
                          {getArgumentValue(inputs[0], imgIdentifiers)}
                        </p>
                      </div>
                      <div className='grid grid-cols-7 py-1'>
                        <p
                          className={classNames(isLeftBranchSelected && 'text-green-600', 'mb-0 pb-0 mr-1 col-span-2')}
                          style={{
                            borderRight: '1px solid #dddddd'
                          }}
                        >
                          <div className='inline-block' style={{ width: '20px' }} />
                          branchLeft
                        </p>
                        <p
                          className={classNames(isLeftBranchSelected && 'text-green-600', 'mb-0 pb-0 mx-1 col-span-2')}
                          style={{ borderRight: '1px solid #dddddd' }}
                        >
                          Branch Left
                        </p>
                        <p
                          className={classNames(isLeftBranchSelected && 'text-green-600', 'mb-0 pb-0 mx-1 col-span-3')}
                          style={{ wordBreak: 'break-all' }}
                        >
                          {getArgumentValue(inputs[1], imgIdentifiers)}
                        </p>
                      </div>
                      <div className='grid grid-cols-7 py-1'>
                        <p
                          className={classNames(isRightBranchSelected && 'text-green-600', 'mb-0 mr-1 pb-0 col-span-2')}
                          style={{
                            borderRight: '1px solid #dddddd'
                          }}
                        >
                          <div className='inline-block' style={{ width: '20px' }} />
                          branchRight
                        </p>
                        <p
                          className={classNames(isRightBranchSelected && 'text-green-600', 'mb-0 pb-0 mx-1 col-span-2')}
                          style={{ wordBreak: 'break-all', borderRight: '1px solid #dddddd' }}
                        >
                          Branch Right
                        </p>
                        <p
                          className={classNames(isRightBranchSelected && 'text-green-600', 'mb-0 pb-0 mx-1 col-span-3')}
                          style={{ wordBreak: 'break-all' }}
                        >
                          {inputs[2].Argument.value ? getArgumentValue(inputs[2], imgIdentifiers) : '-'}
                        </p>
                      </div>
                    </>
                  ) : inputs.length > 0 ? (
                    <>
                      {inputs.map((param, i) => (
                        <div className='grid grid-cols-7 py-1' key={param.Key}>
                          <p
                            className='mb-0 mx-1  pb-0 col-span-2'
                            style={{ wordBreak: 'break-all', borderRight: '1px solid #dddddd' }}
                          >
                            <div className='inline-block' style={{ width: '20px' }}>
                              {i === 0 && <i className='transform rotate-45 fas fa-arrow-down text-green-600' />}
                            </div>
                            {param.Id}
                          </p>
                          <p
                            className='mb-0 pb-0 mx-1 col-span-2'
                            style={{ wordBreak: 'break-all', borderRight: '1px solid #dddddd' }}
                          >
                            {param.Name}
                          </p>
                          <p
                            className={`${
                              param.Argument?.type === 'Secret' && 'text-gray-400 select-none'
                            } text-xs mb-1 text-left  break-all`}
                          >
                            {getArgumentValue(param, imgIdentifiers)}
                          </p>
                        </div>
                      ))}
                      {outputs.length > 0 && (
                        <>
                          {outputs.map((param, i) => (
                            <div className='grid grid-cols-7 py-1' key={param.Key}>
                              <p
                                className='mb-0 pb-0 col-span-2 mx-1'
                                style={{
                                  wordWrap: 'break-all',
                                  borderRight: '1px solid #dddddd'
                                }}
                              >
                                <div className='inline-block' style={{ width: '20px' }}>
                                  {i === 0 && <i className='transform rotate-45 fas fa-arrow-up text-red-600' />}
                                </div>
                                {param.Id}
                              </p>
                              <p
                                className='mb-0 pb-0 mx-1 col-span-2'
                                style={{ wordBreak: 'break-all', borderRight: '1px solid #dddddd' }}
                              >
                                {param.Name}
                              </p>
                              <p className='mb-0 pb-0 mx-1 col-span-3' style={{ wordBreak: 'break-all' }}>
                                {param.Argument.value}
                              </p>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  ) : isLoop ? (
                    <div className='grid grid-cols-7 py-1'>
                      <p
                        className='mb-0 mx-1  pb-0 col-span-2'
                        style={{ wordBreak: 'break-all', borderRight: '1px solid #dddddd' }}
                      >
                        <div className='inline-block' style={{ width: '20px' }}>
                          <i className='fas fa-undo' />
                        </div>
                        LoopIteration
                      </p>
                      <p
                        className='mb-0 pb-0 mx-1 col-span-2'
                        style={{ wordBreak: 'break-all', borderRight: '1px solid #dddddd' }}
                      >
                        Iteration Count
                      </p>
                      <p className='text-xs mb-1 text-left  break-all'>{node.Iterations}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {canDisplayLogMessages && (
                <div style={{ borderTop: '1px solid #ddd', width: '940px' }} className='text-gray-400 p-1 m-0'>
                  <div className='checkPageBreak m-0 p-0 ml-1' style={{ wordBreak: 'break-all' }}>
                    <p>Log Messages:</p>
                    {node.Lines.map((line) => (
                      <p className='m-0'>
                        {line.Type === 'Debug' ? (
                          <BugOutlined className='text-yellow-500 mr-2' />
                        ) : (
                          <InfoCircleFilled className='text-blue-300 mr-2' />
                        )}
                        {line.Message}
                      </p>
                    ))}
                    {result === 'Failed' && (
                      <p>
                        <i className='fas fa-exclamation-circle text-red-600 mr-2' />
                        <span className='mr-1 text-red-600'>Error: </span> {message}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {isSeq && node.Nodes.map((item) => <DetailReportNode node={item} runId={runId} key={item.NodeId} />)}
    </>
  )
}

export default DetailReportNode
