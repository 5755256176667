import { ResponsivePie } from '@nivo/pie'
import { Card, Empty, Skeleton } from 'antd'
import { withRouter } from 'react-router-dom'
import React from 'react'

const TestsByAssignee = ({ loading, testsByAssignee, filter, setFilter, ...props }) => {
  return (
    <Card
      className='shadow-sm rounded-sm col-span-1 relative w-full'
      style={{ height: 'fit-content' }}
      size='small'
      title={<strong className='text-xs'>Tests by Assignee</strong>}
      bodyStyle={{ paddingTop: 0, paddingLeft: 10, paddingRight: 10, paddingBottom: '200px', height: 200 }}
    >
      {loading ? (
        <center>
          <Skeleton.Image className='my-2' style={{ width: 150, height: 150 }} />
        </center>
      ) : testsByAssignee?.length > 0 ? (
        <div className='absolute w-full h-full'>
          <ResponsivePie
            data={testsByAssignee
              .filter((obj) => obj.assignee.email !== null || obj.assignee.email !== '')
              .map((obj) => (obj = { ...obj, id: obj.assignee.email, label: obj.assignee.email, value: obj.count }))}
            margin={{ top: 10, right: 60, bottom: 47, left: 60 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={5}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            arcLabelsSkipAngle={10}
            enableArcLinkLabels={false}
            arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
            colors={{ scheme: 'spectral' }}
            onClick={({ id }) => {
              props.history.push(`/p/${props.match.params.project}/tests?assignee=${id}`)
            }}
            onMouseEnter={(node, event) => {
              event.target.style.cursor = 'pointer'
            }}
          />
        </div>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<b>There are no assigned tests in this project.</b>} />
      )}
    </Card>
  )
}

export default withRouter(TestsByAssignee)
