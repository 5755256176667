import axios from 'axios'
import amplify from './amplify'
export const getHttpClient = async (url, requiresAuthentication = true) => {
  try {
    let headers = { 'Content-Type': 'application/json' }
    if (requiresAuthentication) {
      headers = {
        Authorization: 'Bearer ' + (await amplify.getIdToken()),
        'Content-Type': 'application/json'
      }
    }
    return axios.create({
      baseURL: `${url}`,
      headers: headers
    })
  } catch (e) {
    console.log(e)
  }
}
