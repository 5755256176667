import { colorForTestResult } from './colors'
import { getAttachmentUrl } from './reports'
import appRoutes from '../statics/appRoutes'

const REDIRECT_MARKER = 'redirect-'

const getRedirectPath = () => {
  const url = window.location.href
  const qMarkPosn = url.indexOf('?')
  if (qMarkPosn < 0) {
    return ''
  }
  const qParams = url.indexOf('&') > -1 ? url.substring(qMarkPosn + 1).split('&') : url.split('?').slice(1)
  for (const qParam of qParams) {
    if (qParam.startsWith(REDIRECT_MARKER) && qParam.length > REDIRECT_MARKER.length) {
      return qParam.substring(REDIRECT_MARKER.length)
    }
  }
  return ''
}

const getValidRegexForAppRoute = (route) => {
  return new RegExp('^' + route.replace(/:[^\s/]+/g, '[^/]+') + '$')
}

const validAppRoutesRegexes = Object.values(appRoutes).map(getValidRegexForAppRoute)

const isValidRoute = (path) => {
  return validAppRoutesRegexes.some((regex) => regex.test(path))
}

const encodeUrl = (url) => {
  return encodeURIComponent(`${REDIRECT_MARKER}${url}`)
}

const decodeUrl = (url) => {
  return decodeURIComponent(url)
}

const getTagColor = (testResult) => {
  testResult = testResult.toLowerCase()
  switch (testResult) {
    case 'passed':
      return 'green'
    case 'failed':
      return 'red'
    case 'didnotfinish':
      return 'gold'
    case 'cancelled':
      return 'pink'
    default:
      return 'default'
  }
}

const getTestResultColor = (result) => {
  switch (result) {
    case 'Passed':
      return colorForTestResult.passed
    case 'Failed':
      return colorForTestResult.failed
    case 'DidNotFinish':
      return colorForTestResult.didnotfinish
    default:
      return colorForTestResult.cancelled
  }
}

const getImageAsBase64 = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl)

    if (!response.ok) {
      throw new Error(`Failed to fetch image. Status code: ${response.status}`)
    }

    const blob = await response.blob()
    return await new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    throw new Error('Failed to fetch image.')
  }
}

const transformEnvVariables = async (variable, runId, fetchBase64 = false) => {
  let arg = {}
  if (variable.Value && typeof variable.Value === 'object') {
    const type = variable.Value.ArgType ? variable.Value.ArgType : variable.Value.$type
    switch (type) {
      case 'PlainText':
      case 'ArgPlainText':
        arg = {
          value: variable.Value.Text,
          type
        }
        break
      case 'ArgIdentifier':
      case 'Identifier':
      case 'SimpleIdentifier':
      case 'ArgSimpleIdentifier':
        arg = {
          value: variable.Value.Method + '=' + variable.Value.Specification,
          type
        }
        break
      case 'ImageIdentifier':
      case 'ArgImageIdentifier':
        const imageUrl = await getAttachmentUrl(runId, variable.Value.Id)
        if (fetchBase64) {
          var imageBase64 = await getImageAsBase64(imageUrl)
        }
        arg = {
          id: variable.Value.Id,
          value: fetchBase64 ? imageBase64 : imageUrl,
          type
        }
        break
      case 'Secret':
      case 'ArgObscureSecret':
        // Reports generated with V1 don't have the Text field
        arg = {
          value: variable.Value?.Text || '******',
          type: 'Secret'
        }
        break
      default:
        arg = {
          value: variable?.Value?.Text || 'No value found',
          type
        }
        break
    }
  } else if (typeof variable.Value === 'string') {
    arg = {
      value: variable.Value,
      type: typeof variable.Value
    }
  }
  return { Id: variable.Key, Name: variable.Name, Argument: arg }
}

const scrollToSection = (ref) => {
  const headerHeight = 64
  if (ref.current) {
    const yOffset = ref.current.getBoundingClientRect().top - headerHeight
    window.scrollTo({
      top: yOffset,
      behavior: 'smooth'
    })
  }
}

const allowedSequences = ['TestSequence', 'CompositeStep', 'Loop', 'Iteration'];

export {
  getRedirectPath,
  encodeUrl,
  decodeUrl,
  getTagColor,
  getTestResultColor,
  transformEnvVariables,
  scrollToSection,
  allowedSequences,
  isValidRoute
}
