import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { formatDate, toApproximateDuration } from '../../utils/dateTime'
import AtomicReportNode from './components/AtomicReportNode'
import { Modal, Card, Divider, Spin } from 'antd'
import { getAttachmentUrl } from '../../utils/reports'
import { DownloadOutlined } from '@ant-design/icons'
import { getTestResultColor, scrollToSection, transformEnvVariables } from '../../utils/util'
import PdfDownloadDropdown from './components/PdfDownloadDropdown'
import VariableDisplay from './components/VariableDisplay'
import { useTransformEnvVariables } from '../../custom-hooks/useTransformEnvVariables'

const AtomicReport = ({ run, setErrorMessage }) => {
  const [attachmentUrl, setAttachmentUrl] = useState('')
  const [attachmentModal, setAttachmentModal] = useState(false)
  const { transformedEnvVariables, transformingEnvVariable } = useTransformEnvVariables(run)

  const envSectionRef = useRef()
  const datasetSectionRef = useRef()

  const showAttachment = async (ref) => {
    let url = ''
    try {
      url = await getAttachmentUrl(run.RunId, ref)
      setAttachmentUrl(url)
      setAttachmentModal(true)
    } catch (e) {
      setErrorMessage(e.message)
    }
  }

  const scrollToEnvSection = () => scrollToSection(envSectionRef)

  const scrollToDatasetSection = () => scrollToSection(datasetSectionRef)

  return (
    <Card className='rounded-sm'>
      <div className='grid grid-cols-1 gap-y-2 sm:flex gap-x-6 items-center justify-between'>
        <div className='flex items-center gap-x-2 testName'>
          <span className='text-lg font-bold'>Test:</span>
          <h2 className='ellipsis text-blue-500 italic m-0'>
            <strong title={run.TestName}>{run.TestName}</strong>
          </h2>
          <h2 className='m-0'>
            {run.Project && (
              <Link to={`/p/${run.Project}/tests/${run.TestId}`}>
                <i className='fa fa-link fa-sm' />
              </Link>
            )}
          </h2>
        </div>
        <div className='flex items-center gap-x-3'>
          <span className='text-lg font-bold'>Result:</span>
          <strong className='text-lg' style={{ color: getTestResultColor(run.Result) }}>
            {run.Result}
          </strong>
          <div
            className='bg-white rounded-md shadow-md cursor-pointer flex items-center justify-center'
            style={{ height: '26px', width: '30px', border: '1px solid lightgrey' }}
          >
            <PdfDownloadDropdown runId={run.RunId} downloadIcon={<DownloadOutlined className='text-lg mt-1' />} />
          </div>
        </div>
      </div>
      <div className='grid grid-cols-1 sm:flex sm:justify-between ml-0 mt-3'>
        <ul className='sm:w-5/12 px-0'>
          <div className='flex justify-between items-baseline'>
            <span className='text-md font-bold'>Started At:</span>
            <span>{formatDate(run.StartTime)}</span>
          </div>
          <div className='flex justify-between items-baseline'>
            <span className='text-md font-bold'>Duration:</span>
            <span>{toApproximateDuration(run.Duration)}</span>
          </div>
          <div className='flex justify-between items-baseline mt-3'>
            <span className='text-md font-bold mr-2'>Dataset:</span>
            <span
              className='cursor-pointer'
              title={run.Scenario && 'Click to scroll to the Dataset Parameters section'}
              onClick={run.Scenario && scrollToDatasetSection}
            >
              {run.Scenario} {run.Scenario && <i className='fa fa-link fa-sm text-blue-500 ml-1' />}
            </span>
          </div>
          <div className='flex justify-between align-items-baseline'>
            <span className='text-md font-bold'>Environment</span>
            <span
              className={`text-md ${!run?.TestEnvironment?.Name ? 'italic text-gray-400' : 'cursor-pointer'}`}
              title={run?.TestEnvironment?.Name && 'Click to scroll to the Environment Variables section'}
              onClick={run?.TestEnvironment?.Name && scrollToEnvSection}
            >
              {run?.TestEnvironment?.Name ? (
                <>
                  {run.TestEnvironment.Name}
                  <i className='fa fa-link fa-sm text-blue-500 ml-1' />{' '}
                </>
              ) : (
                'Not Specified'
              )}
            </span>
          </div>
        </ul>
        <ul className='sm:w-5/12 px-0'>
          <li className='flex justify-between items-baseline'>
            <span className='text-md font-bold mr-2'>Run Id:</span>
            <span>{run.RunId}</span>
          </li>
          <li className='flex justify-between items-baseline'>
            <span className='text-md font-bold'>User:</span>
            <span>{run.User.Name}</span>
          </li>
          <li className='flex justify-between items-baseline'>
            <span className='text-md font-bold'>Machine:</span>
            <span>{run.Environment.Machine}</span>
          </li>
          <li className='flex justify-between items-baseline'>
            <span className='text-md font-bold'>Operating System:</span>
            <span>{run.Environment.OSVersion}</span>
          </li>
          <li className='flex justify-between items-baseline'>
            <span className='text-md font-bold'>useMango Version:</span>
            <span>{run.Environment.UMVersion}</span>
          </li>
        </ul>
      </div>
      <ul className='px-0 mt-4'>
        <AtomicReportNode
          key={run.Root.NodeId}
          node={run.Root}
          runId={run.RunId}
          root
          showAttachment={showAttachment}
        />
      </ul>
      <div className='mt-8 w-full md:w-3/5' ref={datasetSectionRef}>
        <VariableDisplay title={'Dataset Parameters'} loading={false} data={run.Parameters} />
      </div>
      <div className='mt-8 w-full md:w-3/5' ref={envSectionRef}>
        <VariableDisplay
          title={'Environment Variables'}
          loading={transformingEnvVariable}
          data={transformedEnvVariables}
        />
      </div>
      <Modal
        visible={attachmentModal}
        width='90vw'
        height='90vh'
        onCancel={() => setAttachmentModal(false)}
        title='Screenshot'
        footer={<div />}
      >
        {attachmentUrl.length === 0 ? (
          <p>Attachment url is missing</p>
        ) : (
          <img src={attachmentUrl} className='w-full' alt='Attachment is missing' />
        )}
      </Modal>

      {run.Errors.length > 0 && (
        <div>
          <h3 className='text-red-500'>
            <strong>Errors</strong>
          </h3>
          <ul className='p-0 list-none'>
            {run.Errors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      )}
    </Card>
  )
}

export default AtomicReport
