import Auth from '@aws-amplify/auth'
import { notification, Spin } from 'antd'
import React, { useEffect } from 'react'
import appRoutes from '../../statics/appRoutes'

const VerifyEmail = (props) => {
  const verifyCode = async (code) => {
    const user = await Auth.currentAuthenticatedUser()
    Auth.verifyUserAttributeSubmit(user, 'email', code)
      .then((res) => {
        notification.success({ message: 'Email verified successfully.', placement: 'topRight' })
      })
      .catch((err) => {
        console.log(err)
        notification.error({ message: 'Something went wrong. Please try again later.', placement: 'topRight' })
      })
      .finally(() =>
        setTimeout(() => {
          props.history.push(appRoutes.homePage)
        }, 2000)
      )
  }

  const extractQueryParametersFromUrl = () => {
    const uri = window.location.search.substring(1)
    const params = new URLSearchParams(uri)
    if (params.has('code')) {
      verifyCode(params.get('code'))
    } else {
      props.history.push(appRoutes.homePage)
    }
  }

  useEffect(() => {
    if (window.location.search) extractQueryParametersFromUrl()
  }, [window.location.search])

  return (
    <div className='h-24 my-auto flex justify-center items-center'>
      <Spin tip='Verifying email...' />
    </div>
  )
}

export default VerifyEmail
