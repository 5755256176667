import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Auth } from 'aws-amplify'
import {
  generateApiKey,
  getAccount,
  getApiKey,
  revokeApiKey,
  updateAccountName
} from '../../store/services/adminServiceClient'
import { getFormattedDate } from '../../utils/dateTime'
import { notification, Skeleton, Button, Input, Tooltip } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone, CopyOutlined, InfoCircleFilled } from '@ant-design/icons'

const Account = () => {
  const newNameRef = useRef()
  const [editAccountName, setEditAccountName] = useState(false)
  const [loading, setLoading] = useState(true)
  const [newName, setNewName] = useState('')
  const [accountID, setAccountID] = useState('')
  const [accountInfo, setAccountInfo] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [showLoadingIcon, setShowLoadingIcon] = useState(false)
  const apiKeyInputBox = useRef(null)
  const [apiKeyVisible, setApiKeyVisible] = useState(false)
  const [showToolTip, setShowToolTip] = useState(false)

  const handleNameChange = async (e) => {
    e.preventDefault()
    if (isNameValid()) {
      try {
        setLoading(true)
        await updateAccountName(accountID, newName)
        sendSuccess(`Account name changed to ${newName}`)
        localStorage.setItem('AccountName', newName)
        getUserAccount()
      } catch (e) {
        sendError(e.response.data)
        setLoading(false)
      } finally {
        setNewName('')
        setEditAccountName(false)
      }
    }
  }

  const getUserAccount = useCallback(async () => {
    const response = await getAccount(accountID)
    setAccountInfo(response.data)
    setNewName(accountInfo.name)
  }, [accountID, accountInfo.name])

  const isNameValid = () => {
    const letters = /^[A-Za-z-_0-9]+$/
    if (newName === accountInfo.name) {
      sendError('Validation Error', "New account name can't be same as old account name")
      return false
    } else if (!newName?.match(letters)) {
      sendError('Validation Error', 'Account Name can only have letters, numbers, hyphen and underscore')
      return false
    } else return true
  }

  const sendError = (message, error) => {
    notification.error({
      message: message,
      description: error,
      placement: 'topRight'
    })
  }

  const sendSuccess = (message) => {
    notification.success({
      message,
      placement: 'topRight'
    })
  }

  useEffect(() => {
    const getAccountID = async () => {
      const session = await Auth.currentSession()
      setAccountID(session.idToken.payload['custom:accountid'])
    }
    getAccountID()
  }, [])

  useEffect(() => {
    if (editAccountName) newNameRef.current.focus()
  }, [editAccountName])

  const getAccountApiKey = useCallback(async () => {
    try {
      const response = await getApiKey(accountID)
      setApiKey(response.data)
    } catch (err) {
      console.log(err)
    }
  }, [accountID])

  useEffect(() => {
    const getAccountInfo = async () => {
      await getUserAccount()
      if (localStorage.getItem('Role') === 'Administrator') await getAccountApiKey()
      setLoading(false)
    }
    if (accountID) {
      getAccountInfo()
    }
  }, [accountID, getUserAccount, getAccountApiKey])

  const generateAccountApiKey = async () => {
    try {
      setShowLoadingIcon(true)
      const response = await generateApiKey(accountID)
      setApiKey(response.data)
      sendSuccess('API key generated successfully.')
    } catch (err) {
      sendError('Generate API Key error', err.message)
    }
    setShowLoadingIcon(false)
  }

  const revokeAccountApiKey = async () => {
    try {
      setShowLoadingIcon(true)
      const response = await revokeApiKey(accountID)
      setApiKey(response.data)
      sendSuccess('API key revoked successfully.')
    } catch (err) {
      sendError('Revoke API Key error', err.message)
    }
    setShowLoadingIcon(false)
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiKey)
    setShowToolTip(true)
  }

  useEffect(() => {
    if (apiKeyVisible) {
      apiKeyInputBox.current.focus({
        cursor: 'all'
      })
    } else {
      apiKeyInputBox.current?.blur()
    }
  }, [apiKeyVisible])

  useEffect(() => {
    if (showToolTip) {
      setTimeout(() => {
        setShowToolTip(false)
      }, 800)
    }
  }, [showToolTip])

  const getValue = () => (apiKeyVisible ? apiKey : apiKey.substr(0, 3) + '**********')

  const toggleButton = (
    <span onClick={() => setApiKeyVisible(!apiKeyVisible)}>
      {apiKeyVisible ? (
        <EyeTwoTone className='cursor-pointer' />
      ) : (
        <EyeInvisibleOutlined className='text-gray-500 cursor-pointer' />
      )}
    </span>
  )

  const copyButton = (
    <Tooltip
      color='green'
      title={
        <span className='text-xs'>
          Copied to clipboard <i className='fas fa-check-circle' />
        </span>
      }
      visible={showToolTip}
      placement='bottom'
    >
      <Button
        type='ghost'
        style={{ border: 'none' }}
        icon={<CopyOutlined title='Copy to clipboard' className='cursor-pointer' onClick={copyToClipboard} />}
        size='small'
      />
    </Tooltip>
  )

  return (
    <div className='mt-4 w-full flex justify-center'>
      <div className='my-4 w-full'>
        {loading ? (
          <div className='w-full mx-auto shadow-sm rounded p-5 '>
            <Skeleton active />
          </div>
        ) : (
          <div className='w-full mx-auto shadow-sm rounded pb-2'>
            <div className='p-4 bg-transparentColor font-bold text-lg w-full'>Account: {accountInfo.name}</div>
            <table className='mx-4 grid grid-cols-1'>
              <tr className='grid grid-cols-1 lg:grid-cols-2 py-3'>
                <th className='col-span-1 text-left'>Account Status:</th>
                <td className='font-semibold'>{accountInfo.status}</td>
              </tr>
              <tr className='grid grid-cols-1 lg:grid-cols-2 py-3'>
                <th className='col-span-1 text-left'>The maximum number of users in your account is:</th>
                <td className='font-semibold'>{accountInfo.maxUsers}</td>
              </tr>
              <tr className='grid grid-cols-1 lg:grid-cols-2 py-3'>
                <th className='col-span-1 text-left'>Your account is licensed for use until:</th>
                <td className='font-semibold'>{getFormattedDate(accountInfo.validUntilDate)}</td>
              </tr>
            </table>
            {localStorage.getItem('Role') === 'Administrator' && (
              <div>
                {!editAccountName ? (
                  <div className='p-1 mx-3 my-2'>
                    <Button
                      type='primary'
                      onClick={() => {
                        setNewName(accountInfo.name)
                        setEditAccountName(true)
                      }}
                    >
                      Change Name
                    </Button>
                  </div>
                ) : (
                  <form onSubmit={handleNameChange} className='flex items-center p-1 mx-3 my-2 w-full md:w-2/5'>
                    <Input
                      ref={newNameRef}
                      required
                      id='newAccountName'
                      value={newName}
                      onChange={(e) => setNewName(e.target.value)}
                      placeholder='New Account name'
                      className='mr-2'
                    />
                    {!loading && (
                      <>
                        <Button type='default' onClick={() => setEditAccountName(false)} className='mr-1'>
                          Cancel
                        </Button>
                        <Button disabled={!newName} id='changeAccountName' type='primary' onClick={handleNameChange}>
                          Submit
                        </Button>
                      </>
                    )}
                  </form>
                )}
                <div className='mx-4 my-4'>
                  <div className='text-lg font-bold my-2'>
                    <span>API Key</span>
                    <Tooltip
                      title='API Key is used to access account artifacts or run tests in Jenkins.'
                      color='#e6f7ff'
                      overlayInnerStyle={{ color: 'black', minWidth: '310px' }}
                      placement='right'
                    >
                      <InfoCircleFilled style={{ color: '#1a64ed' }} className='ml-1' />
                    </Tooltip>
                  </div>
                  <div className='flex items-center my-2'>
                    {apiKey ? (
                      <>
                        <Input
                          className='w-60'
                          ref={apiKeyInputBox}
                          addonBefore={copyButton}
                          suffix={toggleButton}
                          value={getValue()}
                          readOnly
                          style={{ textColor: apiKeyVisible ? 'black' : 'lightgray' }}
                        />
                        <Button className='mx-8' danger onClick={revokeAccountApiKey} loading={showLoadingIcon}>
                          Revoke Key
                        </Button>
                      </>
                    ) : (
                      <Button onClick={generateAccountApiKey} type='primary' loading={showLoadingIcon}>
                        Generate Key
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Account
