import { httpGet } from '../store/services/testServiceClient'
import { setProjectProperties } from '../store/actions'
import { useDispatch } from 'react-redux'

const useFetchProjectName = () => {
  const dispatch = useDispatch()

  const fetchProjectName = async ({ projectId }) => {
    try {
      const response = await httpGet(`projects/${projectId}`)
      dispatch(setProjectProperties({ projectName: response.data.Name }))
    } catch (_) {
      dispatch(setProjectProperties({ projectName: '' }))
    }
  }

  return [fetchProjectName]
}

export default useFetchProjectName
