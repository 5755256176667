import { getAttachment } from '../store/services/reportServiceClient'

const getAttachmentUrl = async (runId, ref) => {
  const response = await getAttachment(runId, ref)
  if (response.status === 200) {
    return response.data.AttachmentUrl
  } else {
    throw new Error(`Url for attachment ${ref} could not be obtained : ${response.status}`)
  }
}

export {
  getAttachmentUrl
}
