import { getHttpClient } from './httpClient'

const getZeroBounceHttpClient = async () => {
  return getHttpClient(process.env.REACT_APP_ZERO_BOUNCE_URL, false)
}

const httpGet = async (url) => {
  const testServiceHttpClient = await getZeroBounceHttpClient()
  return testServiceHttpClient.get(`${url}&api_key=${process.env.REACT_APP_ZERO_BOUNCE_API_KEY}`)
}

export { httpGet }
