import { Button, Card, Divider, Empty, Skeleton } from 'antd'
import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import amplify from '../../../store/services/amplify'

const UserTestsUI = ({ loading, userTests, ...rest }) => {
  const [username, setUsername] = useState()

  const getUsername = async () => {
    const username = await amplify.getUserName()
    setUsername(username)
  }

  useEffect(() => {
    getUsername()
  }, [])

  return (
    <>
      <Card
        className='shadow-sm rounded-sm col-span-1 lg:col-span-3 lg:overflow-x-hidden'
        size='small'
        title={
          <div className='flex justify-between items-center'>
            <strong className='text-xs'>My Recent Tests</strong>
            <Link className='text-xs' to={`/p/${rest.match.params.project}/tests?assignee=${username}`}>
              See more tests
            </Link>
          </div>
        }
        bodyStyle={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 10 }}
      >
        {loading ? (
          <Skeleton className='px-4' loading={loading} active paragraph={{ rows: 4 }} />
        ) : userTests?.length > 0 ? (
          <div className='flex flex-col overflow-x-auto'>
            <div className='align-middle inline-block min-w-full'>
              <div className='border-b border-gray-200 sm:rounded-sm'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead>
                    <tr>
                      <th scope='col' className='px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider'>
                        Test
                        <Divider className='mt-1 mb-0' />
                      </th>
                      <th
                        style={{ width: 100 }}
                        scope='col'
                        className='px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider'
                      >
                        Status
                        <Divider className='mt-1 mb-0' />
                      </th>
                      <th
                        scope='col'
                        style={{ width: 200 }}
                        className='px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider'
                      >
                        Recent Runs
                        <Divider className='mt-1 mb-0' />
                      </th>
                      <th
                        style={{ width: 110 }}
                        scope='col'
                        className='px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider'
                      >
                        Last Modified
                        <Divider className='mt-1 mb-0' />
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {userTests.map((test) => (
                      <tr key={test._id} name={test.name} className='hover:bg-transparentColor h-6'>
                        <td className='px-3 whitespace-nowrap'>
                          <a
                            href={`/p/${rest.match.params.project}/tests/${test._id}`}
                            title='Click to open the test'
                            className='text-xs text-gray-900'
                          >
                            {test.name}
                          </a>
                        </td>
                        <td className='px-3 text-xs whitespace-nowrap'>{test.status}</td>
                        <td className='px-3 text-xs whitespace-nowrap'>
                          {test.recentExecutions &&
                            test.recentExecutions.length &&
                            test.recentExecutions.map((execution) =>
                              execution.Result === 'Passed' ? (
                                <Button
                                  className='m-0 p-0'
                                  title='Click to open the execution report'
                                  type='text'
                                  shape='circle'
                                  onClick={() =>
                                    rest.history.push(`/p/${rest.match.params.project}/executions/${execution._id}`)}
                                >
                                  <i className='fa fa-check text-green-500' />
                                </Button>
                              ) : (
                                <Button
                                  className='m-0 p-0'
                                  title='Click to open the execution report'
                                  type='text'
                                  shape='circle'
                                  onClick={() =>
                                    rest.history.push(`/p/${rest.match.params.project}/executions/${execution._id}`)}
                                >
                                  <i className='fa fa-times text-red-500' />
                                </Button>
                              )
                            )}
                        </td>
                        <td className='px-3 text-xs whitespace-nowrap'>
                          {new Date(test.lastModified).toLocaleDateString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<b>There are no tests assigned to you.</b>} />
        )}
      </Card>
    </>
  )
}

export default withRouter(UserTestsUI)
