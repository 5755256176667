import { getHttpClient } from './httpClient'

const getReportServiceHttpClient = async () => {
  return getHttpClient(process.env.REACT_APP_REPORT_SERVICE_URL)
}

const getReport = async (runId) => {
  const reportServiceHttpClient = await getReportServiceHttpClient()
  return reportServiceHttpClient.get(`/reports/${runId}?format=json`)
}

const getAttachment = async (runId, ref) => {
  const reportServiceHttpClient = await getReportServiceHttpClient()
  return reportServiceHttpClient.get(`/reports/${runId}/attachments/${ref}`)
}

export { getReport, getAttachment }
