import actions from '../../statics/actions'

export const login = () => {
  return {
    type: actions.LOGIN
  }
}
export const loginSuccess = () => {
  return {
    type: actions.LOGIN_SUCCESS
  }
}
export const getProjects = () => {
  return {
    type: actions.GET_PROJECTS
  }
}
export const getProjectsSuccess = (payload) => {
  return {
    type: actions.GET_PROJECTS_SUCCESS,
    payload
  }
}
export const getTest = () => {
  return {
    type: actions.GET_TEST
  }
}
export const getTestSuccess = () => {
  return {
    type: actions.GET_TEST_SUCCESS
  }
}
export const setError = (payload) => {
  return {
    type: actions.SET_ERROR,
    payload
  }
}

export const logout = () => {
  return {
    type: actions.LOGOUT
  }
}
export const logoutSuccess = () => {
  return {
    type: actions.LOGOUT_SUCCESS
  }
}

export const getAccount = ({ type, callback }) => {
  return {
    type: actions.GET_ACCOUNT,
    payload: { type, callback }
  }
}

export const getAccountSuccess = (payload) => {
  return {
    type: actions.GET_ACCOUNT_SUCCESS,
    payload
  }
}

export const updateProjectProperties = (payload) => {
  return {
    type: actions.UPDATE_PROJECT_PROPERTIES,
    payload
  }
}

export const clearReportFilters = () => {
  return {
    type: actions.CLEAR_REPORT_FILTERS
  }
}

export const setProjectProperties = (payload) => {
  return {
    type: actions.SET_PROJECT,
    payload
  }
}

export const setAccountError = (payload) => {
  return {
    type: actions.SET_ACCOUNT_ERROR,
    payload
  }
}

export const setProjectsRequireRefresh = (payload) => {
  return {
    type: actions.SET_PROJECTS_REQUIRE_REFRESH,
    payload
  }
}
