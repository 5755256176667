import { Button, Card, Checkbox, Empty, Space, Table, Tag, notification } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { getTestResultColor, getTagColor } from '../../utils/util'
import { formatDate, getTodaysDate, toApproximateDuration } from '../../utils/dateTime'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import PdfDownloadDropdown from './components/PdfDownloadDropdown'
import { getSummaryExecutionReportPdf } from '../../store/services/printReportClient'
import MultiDatasetPieChart from './components/MultiDatasetPieChart'

const MultiDatasetReport = ({ report, match }) => {
  const [datasetExecutionResults, setDatasetExecutionResults] = useState([])
  const [resetButtonDisabled, setResetButtonDisabled] = useState(true)
  const [notificationApi, notificationContextHolder] = notification.useNotification()
  const [initiateSummaryPdfDownload, setInitiateSummaryPdfDownload] = useState(false)
  const executions = report?.Root?.Datasets
  const key = 'downloading-multidataset-report'

  const countExecutionsByResult = () => {
    const resultCountMap = new Map()
    executions.forEach((execution) => {
      const result = execution.Result
      resultCountMap.set(result, (resultCountMap.get(result) || 0) + 1)
    })
    return resultCountMap
  }

  const formatPieChartData = () => {
    const resultCountMap = countExecutionsByResult()
    return Array.from(resultCountMap).map(([result, count]) => ({
      id: result,
      label: result,
      value: count,
      color: getTestResultColor(result)
    }))
  }

  const extractDownloadQueryParameterFromUrl = () => {
    const uri = window.location.search.substring(1)
    const params = new URLSearchParams(uri)
    return params.get('download') === 'true'
  }

  const downloadSummaryReportPdf = async () => {
    notificationApi.open({
      message: 'Downloading Report',
      key,
      icon: <LoadingOutlined style={{ color: '#108ee9' }} />,
      duration: 0
    })
    try {
      const response = await getSummaryExecutionReportPdf(match.params.runId)
      const attachmentUrl = await response.data
      const element = document.createElement('a')
      element.href = attachmentUrl
      element.setAttribute('download', `SummaryReport_${getTodaysDate()}.pdf`)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      notificationApi.success({
        key,
        message: 'Downloaded Successfully!'
      })
    } catch (e) {
      notificationApi.error({
        key,
        message: e.message || 'Some error occured'
      })
    }
  }

  useEffect(() => {
    if (executions) {
      const pieChartData = formatPieChartData()
      setDatasetExecutionResults(pieChartData)
      setInitiateSummaryPdfDownload(extractDownloadQueryParameterFromUrl())
    }
  }, [])

  useEffect(() => {
    if (initiateSummaryPdfDownload) {
      downloadSummaryReportPdf()
    }
  }, [initiateSummaryPdfDownload])

  const openDatasetReport = async (runId) => {
    window.open(`/p/${match.params.project}/executions/${runId}`, '_blank')
  }

  const datasetTableColumns = [
    {
      title: 'Dataset Name',
      dataIndex: 'Scenario',
      key: 'Scenario',
      render: (scenarioName, record) => (
        <div className='text-blue-500 ellipsis cursor-pointer' onClick={() => openDatasetReport(record.RunId)}>
          <span title={scenarioName}>{scenarioName}</span>
        </div>
      ),
      width: 500
    },
    {
      title: 'Execution Time',
      dataIndex: 'Duration',
      key: 'Duration',
      width: 150,
      render: (duration) => <>{toApproximateDuration(duration)}</>
    },
    {
      title: 'Result',
      dataIndex: 'Result',
      key: 'Result',
      width: 200,
      render: (result) => <Tag color={getTagColor(result)}> {result === 'DidNotFinish' ? 'Not Finished' : result}</Tag>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className='py-2 pl-2 pr-1'>
          <Checkbox.Group onChange={(checkedValues) => setSelectedKeys(checkedValues)} value={selectedKeys}>
            <Space direction='vertical' size={4}>
              {[
                { name: 'Passed', value: 'Passed' },
                { name: 'Failed', value: 'Failed' },
                { name: 'Cancelled', value: 'Cancelled' },
                { name: 'Not Finished', value: 'DidNotFinish' }
              ].map((resultType) => (
                <Checkbox value={resultType.value} className='filterDropdownItem' key={resultType.value}>
                  {resultType.name}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
          <div className='flex justify-between mt-2'>
            <Button
              onClick={() => {
                clearFilters()
                setResetButtonDisabled(true)
              }}
              size='small'
              style={{ width: 50, fontSize: '13px' }}
              disabled={resetButtonDisabled}
            >
              Reset
            </Button>
            <Button
              onClick={() => {
                confirm()
                setResetButtonDisabled(false)
              }}
              type='primary'
              disabled={selectedKeys.length === 0}
              size='small'
              style={{ width: 50, fontSize: '13px' }}
            >
              Apply
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.Result.indexOf(value) === 0
    },
    {
      title: 'Report',
      key: 'RunId',
      dataIndex: 'RunId',
      width: 150,
      render: (Id) => <PdfDownloadDropdown runId={Id} />
    }
  ]

  return (
    <Card className='rounded-sm'>
      {notificationContextHolder}
      <div className='grid grid-cols-1 gap-y-2 sm:flex gap-x-6 items-center justify-between'>
        <div className='flex items-center gap-x-2 testName'>
          <span className='text-lg font-bold'>Test:</span>
          <h2 className='ellipsis text-blue-500 italic m-0'>
            <strong title={report.TestName}>{report.TestName}</strong>
          </h2>
          <h2 className='m-0'>
            {report.Project && (
              <Link to={`/p/${report.Project}/tests/${report.TestId}`}>
                <i className='fa fa-link fa-sm' />
              </Link>
            )}
          </h2>
        </div>
        <div className='flex items-center gap-x-3'>
          <span className='text-lg font-bold'>Result:</span>
          <strong className='text-lg' style={{ color: getTestResultColor(report.Result) }}>
            {report.Result}
          </strong>
          <div
            className='bg-white rounded-md shadow-md cursor-pointer flex items-center justify-center'
            style={{ height: '26px', width: '30px', border: '1px solid lightgrey' }}
          >
            <PdfDownloadDropdown
              runId={report.RunId}
              downloadIcon={<DownloadOutlined className='text-lg mt-1' />}
              executionType={report.Root.Type}
              downloadSummaryReport={downloadSummaryReportPdf}
            />
          </div>
        </div>
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-y-8 gap-x-20 mt-3'>
        <Card className='rounded-sm shadow-sm h-min' bodyStyle={{ padding: 0, paddingLeft: 24, paddingTop: 24 }}>
          <div className='grid grid-cols-3'>
            <div className='flex flex-col gap-y-6 col-span-1'>
              <p className='m-0 text-base'>Total Datasets</p>
              <p className='text-4xl font-bold m-0'>{executions.length}</p>
              <div>
                {datasetExecutionResults.map((data) => (
                  <div className='flex items-center gap-x-3' key={data.label}>
                    <div
                      style={{
                        borderRadius: '50%',
                        width: '10px',
                        height: '10px',
                        backgroundColor: data.color,
                        minWidth: '10px'
                      }}
                    />
                    <div className='text-gray-400 '>
                      {`${data.value} ${data.label === 'DidNotFinish' ? 'NotFinshed' : data.label}`}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='flex flex-col items-center justify-start col-span-2'>
              <MultiDatasetPieChart data={datasetExecutionResults} />
            </div>
          </div>
        </Card>
        <Card className='rounded-sm shadow-sm'>
          <div className='grid grid-cols-3 gap-y-4'>
            <p className='text-left col-span-1'>Environment</p>
            <p
              className={`text-right col-span-2 ${
                report?.TestEnvironment ? ' font-semibold' : 'italic text-grey-300 font-normal'
              }`}
            >
              {report?.TestEnvironment ?? 'Not Specified'}
            </p>
            <p className='text-left col-span-1'>Started At</p>
            <p className='text-right font-semibold col-span-2'>{formatDate(report.StartTime)}</p>
            <p className='text-left col-span-1'>Total Duration</p>
            <p className='text-right font-semibold col-span-2'> {toApproximateDuration(report.Duration)}</p>
            <p className='text-left col-span-1'>User</p>
            <p className='text-right font-semibold col-span-2'>{report.User.Email} </p>
          </div>
        </Card>
        <Card className='rounded-sm shadow-sm'>
          <div className='grid grid-cols-2 gap-y-10'>
            <p className='text-left'>Machine</p>
            <p className='text-right font-semibold'> {report.Environment.Machine}</p>
            <p className='text-left'>Operating System</p>
            <p className='text-right font-semibold'> {report.Environment.OSVersion}</p>
            <p className='text-left'>useMango Version</p>
            <p className='text-right font-semibold'> {report.Environment.UMVersion}</p>
          </div>
        </Card>
      </div>
      <div style={{ overflowX: 'auto' }}>
        <Table
          columns={datasetTableColumns}
          dataSource={report.Root.Datasets}
          pagination={false}
          size='small'
          className='mt-6 multidataset-report'
          tableLayout='fixed'
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<b>No Executions</b>} /> }}
        />
      </div>
    </Card>
  )
}

export default withRouter(MultiDatasetReport)
