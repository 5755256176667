import { useEffect, useState } from 'react'
import { transformEnvVariables } from '../utils/util'

export function useTransformEnvVariables(report) {
  const [transformedEnvVariables, setTransformedEnvVariables] = useState([])
  const [transformingEnvVariable, setTransformingEnvVariable] = useState(false)

  useEffect(() => {
    if (report?.TestEnvironment) {
      setTransformingEnvVariable(true)
      const transformedTestEnvVars = report.TestEnvironment.Variables.map((variable) =>
        transformEnvVariables(variable, report.RunId)
      )

      Promise.all(transformedTestEnvVars).then((transformed) => {
        setTransformedEnvVariables(transformed)
        setTransformingEnvVariable(false)
      })
    }
  }, [report])

  return { transformedEnvVariables, transformingEnvVariable }
}
