import React from 'react'
import { withRouter } from 'react-router-dom'
import appRoutes from '../../statics/appRoutes'
import { classNames } from '../../utils/styles'

const TestNavigation = (props) => {
  const selectOverviewTab = () => {
    props.history.push(`/p/${props.match.params.project}/tests/${props.match.params.testId}`)
  }
  const selectExecutionsTab = () => {
    props.history.push(`/p/${props.match.params.project}/tests/${props.match.params.testId}/executions`)
  }
  const selectScenariosTab = () => {
    props.history.push(`/p/${props.match.params.project}/tests/${props.match.params.testId}/datasets`)
  }
  return (
    <div className='flex justify-start mb-4 h-12 shadow-sm rounded'>
      <p
        onClick={selectOverviewTab}
        className={classNames(props.match.path === appRoutes.testPage && 'selectedTab', 'tab w-24')}
      >
        Overview
      </p>
      <p
        onClick={selectExecutionsTab}
        className={classNames(props.match.path === appRoutes.testExecutionsPage && 'selectedTab', 'tab w-26')}
      >
        Executions
      </p>
      <p
        onClick={selectScenariosTab}
        className={classNames(props.match.path === appRoutes.testScenariosPage && 'selectedTab', 'tab w-24')}
      >
        Datasets
      </p>
    </div>
  )
}

export default withRouter(TestNavigation)
