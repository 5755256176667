import React, { useState, useEffect, useCallback } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { Select, Button, Typography, Divider, Spin, notification, Popover } from 'antd'
import UMDatePicker from './components/UMDatePicker'
import TimeBucketSelector from './components/TimeBucketSelector'
import TestsSelector from '../../components/TestsSelector'
import ReportPrintingControls from './components/ReportPrintingControls'
import { classNames } from '../../utils/styles'
import ClosableTag from '../../components/ClosableTag'
import {
  colorsForLegends,
  colorForBasicExecutionGraph,
  colorForTestResult,
  colorForTestStatus,
  getNewRandomLightColor
} from '../../utils/colors'
import { useSelector, useDispatch } from 'react-redux'
import { clearReportFilters, updateProjectProperties } from '../../store/actions'
import { getExecutionsAnalysis, getExecutionsTestAssigneeList } from '../../store/services/analysisServiceClient'
import { getISOWeekNumber, getWeekRange, formatPeriodLabel, formatFromDateUTC, formatToDateUTC, getDayDifference } from '../../utils/dateTime'
import { EXECUTION_TYPE_MAP } from '../../statics/constants'

const { Option } = Select
const assigneeMap = new Map()

function ExecutionHistory (props) {
  const dispatch = useDispatch()
  const { fromDate, toDate, graphType, groupBy, resultFilter, assigneeFilter, testIdFilter, statusFilter, executionTypeFilter, timeSegment } =
    useSelector((state) => state.selectedProject)
  const testResults = ['Passed', 'Failed', 'DidNotFinish', 'Cancelled']
  const testStatuses = ['Ready', 'Revalidate', 'Design', 'Review', 'Repair']
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState(assigneeMap)
  const selectedProject = props.selectedProject
  const [executionData, setExecutionData] = useState([])
  const [yTickValues, setYTickValues] = useState(undefined)
  const [popoverVisible, setPopoverVisible] = useState(false)
  const nobodyUserId = '00000000-0000-0000-0000-000000000000'
  const [colorsForGraph, setColorsForGraph] = useState(new Map())

  const groupBylabels = {
    Status: 'Status',
    TestResult: 'Result',
    Email: 'User',
    Machine: 'Machine',
    Assignee: 'Assignee'
  }

  const getEndDateForServer = (endDate) => {
    const tempEndDate = new Date(endDate)
    tempEndDate.setDate(tempEndDate.getDate() + 1)
    tempEndDate.setMinutes(tempEndDate.getMinutes() - 1)
    tempEndDate.setSeconds(59)
    return tempEndDate
  }

  const getFilterAndGroupParams = (clickedProperty) => {
    const searchParams = {}
    resultFilter.length && (searchParams.result = resultFilter.join(' '))
    statusFilter.length && (searchParams.status = statusFilter.join(' '))
    assigneeFilter.length && (searchParams.assignedUserId = assigneeFilter.join(' '))
    testIdFilter.length && (searchParams.testId = testIdFilter.join(' '))
    executionTypeFilter.length && (searchParams.executionType = executionTypeFilter)

    switch (groupBy) {
      case 'TestResult':
        if (resultFilter.length === 0 || resultFilter.includes(clickedProperty)) { searchParams.result = clickedProperty }
        break
      case 'Status':
        if (statusFilter.length === 0 || statusFilter.includes(clickedProperty)) { searchParams.status = clickedProperty }
        break
      case 'Assignee':
        const assigneeUserId = [...users].find(([_, value]) => clickedProperty === value)?.[0]
        if (assigneeFilter.length === 0 || assigneeFilter.includes(assigneeUserId)) { searchParams.assignedUserId = assigneeUserId }
        break
      case 'Machine':
        searchParams.machine = clickedProperty
        break
      case 'Email':
        searchParams.user = clickedProperty
        break
      default:
    }
    return searchParams
  }

  const goToExecutions = (bar) => {
    let startDate, endDate
    const selectedBarDate = timeSegment === 'hour' ? new Date(bar.data.Date + ' UTC') : new Date(bar.data.Date)
    const year = selectedBarDate.getFullYear()
    const month = selectedBarDate.getMonth()
    switch (timeSegment) {
      case 'month': {
        const rangeInSameMonth = fromDate.getMonth() === toDate.getMonth()
        startDate = rangeInSameMonth || fromDate.getMonth() === month ? fromDate : new Date(year, month, 1)
        endDate = rangeInSameMonth || toDate.getMonth() === month ? toDate : new Date(year, month + 1, 0)
        endDate = getEndDateForServer(endDate)
        break
      }
      case 'week': {
        const selectedWeek = getISOWeekNumber(selectedBarDate)
        ;[startDate, endDate] = getWeekRange(selectedWeek, year)
        if (getISOWeekNumber(fromDate) === selectedWeek && startDate < fromDate) startDate = fromDate
        if (getISOWeekNumber(toDate) === selectedWeek && endDate > toDate) endDate = toDate
        endDate = getEndDateForServer(endDate)
        break
      }
      case 'day': {
        startDate = selectedBarDate
        endDate = getEndDateForServer(selectedBarDate)
        break
      }
      case 'hour': {
        const startTimestamp = new Date(selectedBarDate)
        const endTimestamp = new Date(selectedBarDate)
        startTimestamp.setUTCHours(startTimestamp.getUTCHours(), 0)
        endTimestamp.setUTCHours(endTimestamp.getUTCHours() + 1, 0)
        endTimestamp.setUTCSeconds(endTimestamp.getUTCSeconds() - 1)
        startDate = startTimestamp
        endDate = endTimestamp
        break
      }
      default: {
        startDate = fromDate
        endDate = toDate
      }
    }
    startDate = startDate.toUTCString()
    endDate = endDate.toUTCString()

    const params = new URLSearchParams({
      startDate,
      endDate,
      ...getFilterAndGroupParams(bar.id)
    }).toString()

    window.open(`/p/${props.selectedProject}/executions?${params}`, '_blank')
  }

  const getTickValues = useCallback(() => {
    const numberOfExecutions = executionData
      .map((dataPoint) =>
        Object.values(dataPoint)
          .filter((value) => typeof value === 'number')
          .reduce((acc, curr) => acc + curr, 0)
      )
      .filter((val) => val !== 0)
    const maxTickValue = numberOfExecutions.length > 0 ? Math.max(...numberOfExecutions) : undefined
    if (maxTickValue < 8) return maxTickValue
  }, [executionData])

  const formatFromDateLocal = (date) => {
    if (date) {
      const d = new Date(date)
      return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} 00:00:00`
    }
    return ''
  }

  const formatToDateLocal = (date) => {
    if (date) {
      const d = new Date(date)
      return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} 23:59:59`
    }
    return ''
  }

  const dayDifference = useCallback(() => {
    return getDayDifference(fromDate, toDate)
  }, [fromDate, toDate])

  const fetchData = useCallback(async () => {
    try {
      setLoading(true)
      const requestObject = {
        FromDateUTC: formatFromDateUTC(fromDate),
        ToDateUTC: formatToDateUTC(toDate),
        TimeBucket: timeSegment,
        ProjectId: selectedProject,
        GroupBy: groupBy,
        Filters: {
          TestResult: resultFilter,
          Status: statusFilter,
          Assignee: assigneeFilter,
          TestId: testIdFilter,
          ExecutionType: executionTypeFilter
        }
      }
      if (timeSegment !== 'hour') {
        requestObject.FromDateLocal = formatFromDateLocal(fromDate)
        requestObject.ToDateLocal = formatToDateLocal(toDate)
      }
      const response = await getExecutionsAnalysis(requestObject)
      const translatedData = translateDataForGraph(response.data)
      getColorsForGraph(translatedData)
      setExecutionData(translatedData)
    } catch (error) {
      notification.error({
        message: 'Reports Error',
        description: error.message || JSON.stringify(error),
        placement: 'topRight'
      })
    }
    setLoading(false)
  }, [groupBy, fromDate, selectedProject, resultFilter, statusFilter, assigneeFilter, testIdFilter, executionTypeFilter, timeSegment, toDate])

  useEffect(() => {
    if (fromDate && toDate && timeSegment) {
      timeSegment === 'hour' && dayDifference() > 6
        ? notification.warning({
            message: 'Selected date range is very large for hourly time segment',
            placement: 'topRight',
            duration: 2
          })
        : fetchData()
    }
  }, [fromDate, toDate, timeSegment, groupBy, resultFilter, statusFilter, assigneeFilter, testIdFilter, executionTypeFilter, fetchData, dayDifference])

  useEffect(() => {
    setYTickValues(getTickValues())
  }, [getTickValues])

  const getAssigneeList = useCallback(async () => {
    try {
      const response = await getExecutionsTestAssigneeList(props.selectedProject)
      response.data.forEach(([email, id]) => {
        assigneeMap.set(id, email)
      })
      setUsers(new Map(assigneeMap))
    } catch (error) {
      notification.error({
        message: 'Something went wrong',
        description: error.message,
        placement: 'topRight'
      })
    }
  }, [])

  useEffect(() => {
    getAssigneeList()
  }, [getAssigneeList])

  const getEmailFromId = (periodData) => {
    let translated = {}
    let nobodyCount = 0
    Object.keys(periodData)
      .filter((key) => key !== 'PeriodLabel' && key !== 'Date')
      .forEach((key) => {
        if (key === nobodyUserId || key === '' || !assigneeMap.has(key)) {
          nobodyCount = nobodyCount + periodData[key]
          translated = { ...translated, Nobody: nobodyCount }
        } else if (assigneeMap.has(key)) {
          translated = { ...translated, [assigneeMap.get(key)]: periodData[key] }
        }
      })
    return translated
  }

  const translateDataForGraph = (executionData) => {
    if (groupBy === 'Assignee') {
      return executionData.map((periodData) => ({
        PeriodLabel: formatPeriodLabel(periodData.PeriodLabel, timeSegment),
        Date: periodData.PeriodLabel,
        ...getEmailFromId(periodData)
      }))
    } else {
      return executionData.map((periodData) => ({
        ...periodData,
        PeriodLabel: formatPeriodLabel(periodData.PeriodLabel, timeSegment),
        Date: periodData.PeriodLabel
      }))
    }
  }

  const getKeys = () => {
    switch (groupBy) {
      case '':
        return ['Count']
      case 'TestResult':
        return ['Passed', 'Failed', 'DidNotFinish', 'Cancelled']
      case 'Status':
        return ['Design', 'Ready', 'Review', 'Repair', 'Revalidate']
      default:
        return getUniqueKeys(executionData)
    }
  }

  const refresh = () => {
    timeSegment === 'hour' && dayDifference() > 6
      ? notification.warning({
          message: 'Selected date range is very large for hourly time segment',
          placement: 'topRight',
          duration: 2
        })
      : fetchData()
  }

  const getLabels = () => {
    if (executionData.length > 60) {
      const labels = []
      const interval = Math.floor(executionData.length / 10)
      for (let i = 0; i <= 9; i++) {
        labels.push(executionData[interval * i].PeriodLabel)
      }
      labels.push(executionData.at(-1).PeriodLabel)
      return labels
    }
    return null
  }

  const getColor = ({ id }) => {
    return colorsForGraph.get(id)
  }

  const getUniqueKeys = (executionData) => {
    const keys = new Set()
    executionData.forEach((record) => {
      Object.keys(record)
        .filter((key) => key !== 'PeriodLabel' && key !== 'Date')
        .forEach(keys.add, keys)
    })
    return Array.from(keys)
  }

  const getColorForBasicExecutionGraph = () => {
    const colorsMap = new Map()
    colorsMap.set('Count', colorForBasicExecutionGraph)
    return colorsMap
  }

  const getColorForGroupByResultOrStatus = (executionData) => {
    const colorsMap = new Map()
    const uniqueKeys = getUniqueKeys(executionData)
    const colorForGroupByField = groupBy === 'TestResult' ? colorForTestResult : colorForTestStatus
    uniqueKeys.forEach((key) => {
      if (!colorsMap.has(key)) {
        colorsMap.set(key, colorForGroupByField[key.toLowerCase()])
      }
    })
    return colorsMap
  }

  const getColorsForGroupByFields = (executionData) => {
    const colorsMap = new Map()
    let colorIndex = 0
    const uniqueKeys = getUniqueKeys(executionData)
    const colorsArray = Object.values(colorsForLegends)
    uniqueKeys.forEach((key) => {
      if (!colorsMap.has(key)) {
        if (colorIndex === colorsArray.size) {
          colorIndex = 0
        }
        let color
        if (colorsArray[colorIndex]) {
          color = colorsArray[colorIndex]
        } else {
          color = getNewRandomLightColor(colorsArray)
          colorsArray.push(color)
        }
        colorsMap.set(key, color)
        colorIndex += 1
      }
    })
    return colorsMap
  }

  const getColorsForGraph = (executionData) => {
    let colorsMap = new Map()
    switch (groupBy) {
      case '':
        colorsMap = getColorForBasicExecutionGraph()
        break
      case 'TestResult':
      case 'Status':
        colorsMap = getColorForGroupByResultOrStatus(executionData)
        break
      default:
        colorsMap = getColorsForGroupByFields(executionData)
        break
    }
    setColorsForGraph(colorsMap)
  }

  const getLabelForTooltip = (id) => {
    if (groupBy === '') return 'Executions'
    else if (groupBy === 'TestResult') return testResultDisplayName(id)
    else return id
  }

  const testResultDisplayName = (result) => {
    return result === 'DidNotFinish' ? 'Not Finished' : result
  }

  const handleVisibleChange = (newVisible) => {
    loading
      ? setPopoverVisible(false)
      : setPopoverVisible(newVisible)
  }

  return (
    <div className='grid grid-cols-6 lg:grid-cols-9'>
      <div className='col-span-6 lg:col-span-7' style={{ border: '1px solid lightgrey' }}>
        <div className='grid  grid-cols-9 px-2 my-3 items-center gap-x-1'>
          <div className='col-span-9 xl:col-span-4 flex items-center mb-2 xl:mb-0'>
            <UMDatePicker
              setFromDate={(fromDate) => dispatch(updateProjectProperties({ fromDate }))}
              setToDate={(toDate) => dispatch(updateProjectProperties({ toDate }))}
              setDropdownDateRangeValue={(periodLabel) =>
                dispatch(updateProjectProperties({ periodLabel }))}
            />
            <TimeBucketSelector buckets={['month', 'week', 'day', 'hour']} />
          </div>
          <div className='col-span-9 xl:col-span-5'>
            <div className='flex items-center justify-start gap-x-4 xl:gap-x-2 2xl:gap-x-4'>
              <Typography className='font-semibold'>Group By :</Typography>
              {Object.keys(groupBylabels).map((group) => (
                <button
                  className={classNames(
                    !fromDate || !toDate
                      ? 'bg-gray-200 text-gray-600'
                      : groupBy === group
                        ? 'bg-blue-600 text-white'
                        : 'text-blue-600 bg-white',
                    'cursor-pointer px-2 py-1 rounded-lg  border-0 border-white'
                  )}
                  onClick={() => {
                    if (!fromDate || !toDate) {
                      return notification.warning({
                        message: 'Please select a time period',
                        placement: 'topRight',
                        duration: 2
                      })
                    }
                    dispatch(updateProjectProperties({ groupBy: groupBy === group ? '' : group }))
                  }}
                >
                  {groupBylabels[group]}
                </button>
              ))}
            </div>
          </div>
          <div className='col-start-10 flex justify-evenly w-20'>
            <Button
              shape='circle'
              icon={<i className='fas fa-redo-alt' style={{ color: 'black' }} />}
              onClick={() => refresh()}
              disabled={!fromDate || !toDate}
            />
            <Popover
              placement='bottomRight'
              content={(<ReportPrintingControls selectedProject={props.selectedProject} users={users} />)}
              visible={popoverVisible}
              onVisibleChange={handleVisibleChange}
              trigger='click'
            >
              <Button
                shape='circle'
                icon={<i className='fas fa-ellipsis-v' style={{ color: 'black' }} />}
                disabled={loading || !fromDate || !toDate}
              />
            </Popover>
          </div>
        </div>
        <Divider className='mt-0 pt-0' />
        <Spin tip='Loading...' spinning={loading}>
          <div style={{ height: 600 }} id='executionReport'>
            <ResponsiveBar
              data={executionData}
              keys={getKeys()}
              groupMode={graphType}
              indexBy='PeriodLabel'
              margin={{ top: 10, right: 20, left: 60, bottom: 100 }}
              onClick={(bar) => goToExecutions(bar)}
              onMouseEnter={(_, event) => (event.target.style.cursor = 'pointer')}
              onMouseLeave={(_, event) => (event.target.style.cursor = '')}
              padding={0.3}
              colors={getColor}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              gridYValues={yTickValues}
              axisBottom={{
                tickSize: 6,
                tickPadding: 5,
                tickRotation: -90,
                tickValues: getLabels()
              }}
              axisLeft={{
                format: (tick) => Number.isInteger(tick) && tick,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                tickValues: yTickValues,
                legend: 'Number of executions',
                legendPosition: 'middle',
                legendOffset: -50
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor='#5a5858'
              tooltip={({ id, value, color, indexValue }) => (
                <div
                  style={{
                    padding: 8,
                    color,
                    background: '#222222'
                  }}>
                  <div className='flex flex-row gap-x-2'>
                    <div className='flex flex-col'>
                      <span className='text-xs'>{getLabelForTooltip(id)}:</span>
                      <span className='text-xs'>Period:</span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='text-xs italic font-semibold'>{value}</span>
                      <span className='text-xs italic font-semibold'> {indexValue}</span>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        </Spin>
      </div>
      <div className='col-span-3 lg:col-span-2  lg:ml-5 mt-5 lg:mt-0'>
        <div className='flex justify-between items-center'>
          <h4 className='font-semibold m-0'>Filters</h4>
          <button
            className=' text-blue-600 border-none bg-white cursor-pointer'
            onClick={() => dispatch(clearReportFilters())}
          >
            Clear All
          </button>
        </div>
        <Typography className='mt-4 mb-1'> Execution Type</Typography>
        <Select
          mode='single'
          key='executionType'
          id='executionType'
          maxTagCount='responsive'
          showArrow
          className='executionTypeSelect'
          style={{ width: '99%', cursor: 'pointer' }}
          render={(props) => {
            props.closable = false
            return ClosableTag(props)
          }}
          onChange={(value) => dispatch(updateProjectProperties({ executionTypeFilter: value }))}
          value={executionTypeFilter}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          disabled={!fromDate || !toDate}>
          {Object.entries(EXECUTION_TYPE_MAP).map(([ name, value ]) => (
            <Option key={name}>{value}</Option>
          ))}
        </Select>
        <Typography className='mt-4 mb-1'> Result</Typography>
        <Select
          key='result'
          id='result'
          mode='multiple'
          placeholder={<Typography>Select Results</Typography>}
          style={{ width: '99%' }}
          maxTagCount='responsive'
          showArrow
          tagRender={ClosableTag}
          className='resultSelect'
          onChange={(value) => dispatch(updateProjectProperties({ resultFilter: value }))}
          value={resultFilter}
          disabled={!fromDate || !toDate}
        >
          {testResults.map((result) => (
            <Option key={result}>{testResultDisplayName(result)}</Option>
          ))}
        </Select>
        <Typography className='mt-4 mb-1'> Status</Typography>
        <Select
          mode='multiple'
          key='status'
          id='status'
          placeholder={<Typography>Select Statuses</Typography>}
          maxTagCount='responsive'
          showArrow
          className='statusSelect'
          style={{ width: '99%', cursor: 'pointer' }}
          tagRender={ClosableTag}
          onChange={(value) => dispatch(updateProjectProperties({ statusFilter: value }))}
          value={statusFilter}
          disabled={!fromDate || !toDate}
        >
          {testStatuses.map((status) => (
            <Option key={status}>{status}</Option>
          ))}
        </Select>
        <Typography className='mt-4 mb-1'> Assignee</Typography>
        <Select
          mode='multiple'
          key='assignee'
          id='assignee'
          placeholder={<Typography>Select Assignees</Typography>}
          maxTagCount='responsive'
          showArrow
          className='assigneeSelect'
          style={{ width: '99%', cursor: 'pointer' }}
          tagRender={ClosableTag}
          onChange={(value) => dispatch(updateProjectProperties({ assigneeFilter: value }))}
          value={assigneeFilter}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          disabled={!fromDate || !toDate}
        >
          {Array.from(users.entries()).map(([id, email]) => (
            <Option key={id}>{email}</Option>
          ))}
        </Select>

        <Typography className='mt-4 mb-1'>Test Name</Typography>
        <TestsSelector selectedProject={props.selectedProject} />

        <Divider />
        <h4 className='font-semibold'>Graph type</h4>
        <Select
          defaultValue={graphType}
          value={graphType}
          className='monthFilter'
          onChange={(value) => dispatch(updateProjectProperties({ graphType: value }))}
          style={{ width: 130 }}
        >
          <Option value='stacked'>
            <i className='far fa-chart-bar mr-2' />
            Stacked
          </Option>
          <Option value='grouped'>
            <i className='far fa-chart-bar mr-2' />
            Grouped
          </Option>
        </Select>
        <Divider />
        <h4 className='font-semibold'>Legends</h4>
        <div style={{ maxHeight: '335px', overflowY: 'auto' }} id='graphLegends'>
          {Array.from(colorsForGraph.entries()).map(([key, color]) => (
            <div className='flex items-center gap-x-3 my-2'>
              <div
                style={{
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  backgroundColor: color,
                  minWidth: '20px'
                }}
              />
              <div className='truncate' title={key}>
                {groupBy === 'TestResult' ? testResultDisplayName(key) : key}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ExecutionHistory
