import React, { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateProjectProperties } from '../store/actions'
import { httpGet } from '../store/services/testServiceClient'
import { Select, Typography, Empty, Spin } from 'antd'
import tagRender from './ClosableTag'
const { Option } = Select

const testsMap = new Map()

const TestsSelector = ({ selectedProject }) => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [pageInfo, setPageInfo] = useState('')
  const [fetching, setFetching] = useState(false)
  const [tests, setTests] = useState(testsMap)
  const { fromDate, toDate, testIdFilter } = useSelector((state) => state.selectedProject)

  const getTests = async () => {
    setFetching(true)
    const response = await httpGet(
      `projects/${
        selectedProject
      }/testindex?filter=${searchText.trim()}&cursor=${
        !pageInfo ? '' : pageInfo.Next
      }`
    )
    const testsData = await response.data
    testsData.Items.forEach(({ Id, Name }) => {
      testsMap.set(Id, Name)
    })
    setTests(testsMap)
    dispatch(updateProjectProperties({ tests: testsMap }))
    setPageInfo(testsData.Info)
    setFetching(false)
  }

  useEffect(() => {
    [...testsMap.keys()].forEach((key) => {
      if (!testIdFilter.includes(key)) {
        testsMap.delete(key)
      }
    })
    setTests(testsMap)
    dispatch(updateProjectProperties({ tests: testsMap }))
    getTests()
  }, [selectedProject, searchText, testIdFilter])

  const loadMore = (event) => {
    if (event.target.scrollTop + event.target.offsetHeight + 1 >= event.target.scrollHeight && pageInfo?.HasNext) {
      getTests()
    }
  }

  const debounce = (func, delay) => {
    let debounceTimer
    return function () {
      const context = this
      const args = arguments
      clearTimeout(debounceTimer)
      debounceTimer = setTimeout(() => func.apply(context, args), delay)
    }
  }
  const searchHandler = useCallback(
    debounce((value) => {
      setPageInfo(null)
      setSearchText(value)
    }, 500),
    []
  )

  return (
    <Select
      mode='multiple'
      key='test'
      id='test'
      placeholder={<Typography>Select Tests</Typography>}
      maxTagCount='responsive'
      showArrow
      className='testIdSelect'
      style={{ width: '100%', cursor: 'pointer' }}
      tagRender={tagRender}
      onChange={(value) => dispatch(updateProjectProperties({ testIdFilter: value }))}
      value={testIdFilter}
      autoClearSearchValue={false}
      onSearch={searchHandler}
      onFocus={() => {
        if (searchText) {
          setSearchText('')
          setPageInfo(null)
        }
      }}
      filterOption={(input, option) => option.props.children.indexOf(input) >= 0}
      disabled={!fromDate || !toDate}
      notFoundContent={
                fetching ? (
                  <Spin className='grid m-2 justify-center' />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description='No Tests found'
                  />
                )
              }
      onPopupScroll={loadMore}
    >
      {Array.from(tests.entries()).map(([id, name]) => (
        <Option
          key={id}
        >
          {name}
        </Option>
      ))}
    </Select>
  )
}

export default TestsSelector
