const appRoutes = {
  homePage: '/',
  loginPage: '/login',
  trialSetup: '/trialSetup',
  accountPage: '/account',
  profilePage: '/profile',
  projectPage: '/p/:project',
  testsPage: '/p/:project/tests',
  executionsPage: '/p/:project/executions',
  projectReport: '/p/:project/reports',
  testPage: '/p/:project/tests/:testId',
  testExecutionsPage: '/p/:project/tests/:testId/executions',
  testScenariosPage: '/p/:project/tests/:testId/datasets',
  projectSettingsPage: '/settings/projects',
  userSettingsPage: '/settings/users',
  reportPage: '/p/:project/executions/:runId',
  reportPageWithoutProject: '/execution/:runId', // for backward compatibility
  summaryReport: '/p/:project/executions/summaryreport/:runId',
  detailReport: '/p/:project/executions/detailedreport/:runId',
  administrationPage: '/settings/administration',

  signupPage: '/signup',
  cfsignupForm: '/cfsignup',
  verifyEmail: '/verifyEmail',
  unauthorized: '/unauthorized'
}

export default appRoutes
