import { Card, Divider, Empty, Skeleton, Tag } from 'antd'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { formatDate, getDuration, getTimezoneOffset } from '../../../utils/dateTime'
import { getTagColor } from '../../../utils/util'
import MultiDatasetIcon from '../../../assets/multiDataset.svg'

const RecentExecutionsUI = ({ loading, recentExecutions, ...rest }) => {
  const isMultiDatasetExecution = (executionType) => executionType === 'MultiDataset'

  return (
    <>
      <Card
        className='shadow-sm rounded-sm col-span-1 lg:col-span-3'
        size='small'
        title={
          <div className='flex justify-between items-center'>
            <strong className='text-xs'>Recent Executions</strong>
            <Link className='text-xs' to={`/p/${rest.match.params.project}/executions`}>
              See more executions
            </Link>
          </div>
        }
        bodyStyle={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 10 }}
      >
        {loading ? (
          <Skeleton className='px-4' loading={loading} active paragraph={{ rows: 4 }} />
        ) : recentExecutions?.length > 0 ? (
          <div className='flex flex-col overflow-x-auto'>
            <div className='align-middle inline-block min-w-full'>
              <div className='border-b border-gray-200 sm:rounded-sm'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead>
                    <tr>
                      <th scope='col' className='px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider '>
                        <span className='ml-5'>Test</span>
                        <Divider className='mt-1 mb-0' />
                      </th>
                      <th
                        style={{ width: 100 }}
                        scope='col'
                        className='px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider'
                      >
                        Duration
                        <Divider className='mt-1 mb-0' />
                      </th>
                      <th
                        style={{ width: 200 }}
                        scope='col'
                        title={`Run At (${getTimezoneOffset()})`}
                        className='px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider'
                      >
                        {`Run At (${getTimezoneOffset()})`}
                        <Divider className='mt-1 mb-0' />
                      </th>
                      <th
                        style={{ width: 110 }}
                        scope='col'
                        className='px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider'
                      >
                        Result
                        <Divider className='mt-1 mb-0' />
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {recentExecutions.map((execution) => (
                      <tr key={execution._id} name={execution.name} className='hover:bg-transparentColor h-6'>
                        <td className='px-3 py-2 whitespace-nowrap'>
                          <div className='flex items-center gap-x-2'>
                            <div style={{ width: '12px' }}>
                              {isMultiDatasetExecution(execution.executionType) && (
                                <img src={MultiDatasetIcon} alt='Multi Dataset Icon' title='Test with datasets' />
                              )}
                            </div>
                            <a
                              title='Click to open the execution'
                              href={`/p/${rest.match.params.project}/executions/${execution._id}`}
                              className='text-xs text-gray-900 ellipsis'
                              style={{ maxWidth: '700px' }}
                            >
                              {execution.testName}
                            </a>
                          </div>
                        </td>
                        <td className='px-3 py-2 text-xs whitespace-nowrap'>
                          {getDuration(execution.startedAt, execution.endedAt)}
                        </td>
                        <td className='px-3 py-2 text-xs whitespace-nowrap'>{formatDate(execution.startedAt, false)}</td>
                        <td className='px-3 py-2 text-xs whitespace-nowrap'>
                          <Tag color={getTagColor(execution.testResult)}>
                            {execution.testResult === 'DidNotFinish' ? 'Not Finished' : execution.testResult}
                          </Tag>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<b>There are no test executions in this project.</b>}
          />
        )}
      </Card>
    </>
  )
}

export default withRouter(RecentExecutionsUI)
