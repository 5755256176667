import React, { useState, useEffect, useRef } from 'react'
import { classNames } from '../utils/styles'
import { Input, Spin } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'

const EditableCell = ({ value, updateValue, id, selected, setSelected }) => {
  const inputCell = useRef()
  const [editedValue, setEditedValue] = useState('')
  const [editing, setEditing] = useState(false)
  const [unsaved, setUnsaved] = useState(false)
  const [saving, setSaving] = useState(false)

  const editCell = () => {
    setEditing(true)
    setUnsaved(true)
  }

  useEffect(() => {
    if (editing) inputCell.current.focus()
  }, [editing])

  const selectCell = () => {
    setSelected(id)
  }

  const commitEdit = async (event) => {
    setEditing(false)
    setUnsaved(false)
    setSaving(true)
    if (editedValue !== value) {
      const response = await updateValue(editedValue)
      if (!response) {
        setEditedValue(value)
      }
    }
    setSaving(false)
  }

  const cancelEdit = () => {
    setEditing(false)
    if (unsaved) {
      setEditedValue(value)
    }
  }

  useEffect(() => {
    setEditedValue(value)
  }, [value])

  return (
    <div
      tabIndex='0'
      className={classNames(saving && 'saving', editing && selected && 'editing', 'cell-container')}
      onFocus={selectCell}
      onClick={editCell}
      onKeyDown={(e) => {
        if (e.key.toLowerCase() === 'f2') {
          editCell()
        }
      }}
    >
      <div className='view p-2'>
        <Paragraph title={value} className='mt-1 view truncate'>{value}</Paragraph>
      </div>
      <Input
        className='edit'
        type='text'
        tabIndex='-1'
        ref={inputCell}
        value={editedValue}
        onChange={(e) => setEditedValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key.toLowerCase() === 'tab') commitEdit()
        }}
        onKeyUp={(e) => {
          if (e.key.toLowerCase() === 'enter') {
            commitEdit()
          } else if (e.key.toLowerCase() === 'escape') {
            cancelEdit()
          }
        }}
        onBlur={() => cancelEdit()}
      />
      <div className='flex justify-center mt-2'>{saving && <Spin />}</div>
    </div>
  )
}

export default EditableCell
