export const signupTestEmailAddress = 'usemango@mailinator.com'

export const TEST_STATUSES = ['Design', 'Ready', 'Review', 'Repair', 'Revalidate']

export const ADMINISTRATION_PAGE_UI_STATES = {
  INITIAL: 'initial',
  SUMMARY: 'summary',
  SUCCESS: 'success',
  FAIL: 'fail'
}

export const EXECUTION_TYPE_MAP = {
  Atomic: 'Single Dataset',
  MultiDataset: 'Multiple Datasets'
}

export const ENTITY = {
  TEST: 'Test',
  COMPONENT: 'Component',
  PROJECT: 'Project'
}
