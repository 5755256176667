import React, { useState, useEffect } from 'react'
import Breadcrumb from '../../components/Navigation/Breadcrumb'
import ProjectNavigation from '../../components/Navigation/ProjectNavigation'
import gql from 'graphql-tag'
import UserTestsUI from './components/UserTestsUI'
import { notification } from 'antd'
import RecentExecutionsUI from './components/RecentExecutionsUI'
import TestsByStatusPie from './components/TestsByStatusPie'
import ExecutionsByResultPie from './components/ExecutionsByResultPie'
import TestsByAssigneePie from './components/TestsByAssigneePie'
import TagsUsageCloud from './components/TagsUsageCloud'
import ExecutionsByWeekChart from './components/ExecutionsByWeekChart'
import { awsAppSyncClient } from '../../store/services/appSync'

const Project = (props) => {
  const [selectedProject, setSelectedProject] = useState('')
  const [userTests, setUserTests] = useState([])
  const [recentExecutions, setRecentExecutions] = useState([])
  const [testsByStatus, setTestsByStatus] = useState([])
  const [testsByAssignee, setTestsByAssignee] = useState([])
  const [lastTestExecutionsByResult, setLastTestExecutionsByResult] = useState([])
  const [tagsUsage, setTagsUsage] = useState([])
  const [recentExecutionsByResult, setRecentExecutionsByResult] = useState([])
  const [userTestsByStatus, setUserTestsByStatus] = useState([])
  const [executionsByWeek, setExecutionsByWeek] = useState([])
  const [loading, setLoading] = useState(true)

  const getProjectOverviewQuery = `
  {
    getProjectOverview (projectId: "${selectedProject}") {
      userTests {
        _id
        name
        status
        recentExecutions{
          _id
          Result
        }
        lastModified
      }
      recentExecutions {
        _id
        testName
        startedAt
        endedAt
        testResult
        executionType
      }
      testsByStatus {
        status
        count
      }
      testsByAssignee {
        assignee {
          email
        }
        count
      }
      lastTestExecutionsByResult {
        result
        count
      }
      tagsUsage {
        tag
        count
      }
      executionsByWeek {
        week
        range
        outcome {
          result
          count
        }
      }
      userTestsByStatus {
        count
        status
      }
      recentExecutionsByResult {
        count
        result
      }
    }
  }`
  useEffect(() => {
    setSelectedProject(props.match.params.project)
  }, [props.match.params.project])

  useEffect(() => {
    const getProjectOverview = async () => {
      try {
        const response = await awsAppSyncClient.query({
          query: gql(getProjectOverviewQuery)
        })
        if (response.data.getProjectOverview) {
          setUserTests(response.data.getProjectOverview.userTests)
          setRecentExecutions(response.data.getProjectOverview.recentExecutions)
          setTestsByStatus(response.data.getProjectOverview.testsByStatus)
          setTestsByAssignee(response.data.getProjectOverview.testsByAssignee)
          setLastTestExecutionsByResult(response.data.getProjectOverview.lastTestExecutionsByResult)
          setTagsUsage(response.data.getProjectOverview.tagsUsage)
          setExecutionsByWeek(response.data.getProjectOverview.executionsByWeek)
          setUserTestsByStatus(response.data.getProjectOverview.userTestsByStatus)
          setRecentExecutionsByResult(response.data.getProjectOverview.recentExecutionsByResult)
          setLoading(false)
        }
      } catch (err) {
        notification.error({
          message: 'Project Overview error',
          description: err.message || JSON.stringify(err),
          placement: 'topRight'
        })
      }
    }
    if (selectedProject && getProjectOverviewQuery) getProjectOverview()
  }, [getProjectOverviewQuery, selectedProject])

  return (
    <div>
      <Breadcrumb />
      <ProjectNavigation />
      <div className='mb-3 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-3'>
        <TestsByStatusPie
          loading={loading}
          title='Tests by Status'
          testsByStatus={testsByStatus}
          emptyDiscription='There are no tests in this project.'
        />
        <TestsByAssigneePie loading={loading} testsByAssignee={testsByAssignee} />
        <ExecutionsByResultPie
          title='Executions by Last Execution results'
          loading={loading}
          executionsByResult={lastTestExecutionsByResult}
        />
        <ExecutionsByWeekChart loading={loading} executionsByWeek={executionsByWeek} />
      </div>
      <div className='mb-3 grid grid-cols-1 lg:grid-cols-4 gap-3'>
        <RecentExecutionsUI loading={loading} recentExecutions={recentExecutions} />
        <ExecutionsByResultPie
          title='Recent Executions by result'
          loading={loading}
          executionsByResult={recentExecutionsByResult}
        />
      </div>
      <div className='mb-3 grid grid-cols-1 lg:grid-cols-4 gap-3'>
        <UserTestsUI loading={loading} userTests={userTests} />
        <TestsByStatusPie
          loading={loading}
          title='My Recent Tests by Status'
          testsByStatus={userTestsByStatus}
          emptyDiscription='There are no tests assigned to you.'
        />
      </div>
      <div className='mb-3 grid grid-cols-1'>
        <TagsUsageCloud loading={loading} tagsUsage={tagsUsage} />
      </div>
    </div>
  )
}

export default Project
