import { Divider, Spin } from 'antd'
import React from 'react'

const VariableDisplay = ({ title, data, loading }) => {
  return (
    <>
      <p className='text-md font-bold'>{title}</p>
      <div className='flex flex-col gap-y-3'>
        <Divider className='my-0' />
        <Spin spinning={loading} size='small'>
          {data.length > 0 ? (
            <div className='flex flex-col gap-y-3'>
              {data.map((param) => (
                <div key={param.Key} className='grid grid-cols-2 md:grid-cols-3 p-0 gap-x-10'>
                  <span className='font-semibold break-all md:col-span-1'>
                    {title === 'Dataset Parameters' ? param.Key : param.Name}
                  </span>
                  {title === 'Dataset Parameters' ? (
                    <span className='break-all md:col-span-2'>{param.Value.Text}</span>
                  ) : (
                    <span
                      className={`${
                        param.Argument?.type === 'Secret' && 'text-gray-400 select-none'
                      } break-all md:col-span-2`}
                    >
                      {param.Argument?.type === 'ImageIdentifier' ? (
                        <img className='w-20' src={param.Argument?.value} alt={param.Name} />
                      ) : (
                        param.Argument?.value
                      )}
                    </span>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p className='italic text-gray-300'>{title === 'Dataset Parameters' ? 'No Parameters' : 'No Variables'}</p>
          )}
        </Spin>
      </div>
    </>
  )
}

export default VariableDisplay