import React, { useState, useEffect, useRef, forwardRef, useCallback, useMemo } from 'react'
import Breadcrumb from '../../components/Navigation/Breadcrumb'
import ProjectNavigation from '../../components/Navigation/ProjectNavigation'
import { httpGet } from '../../store/services/testServiceClient'
import Alert from '../../components/Alerts/Alert'
import InfiniteScroll from 'react-infinite-scroll-component'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import qs from 'query-string'
import 'antd/dist/antd.css'
import './ExecutionPage.css'
import { Table, Input, Button, Space, Radio, Spin, Tag, Empty, Select, Checkbox } from 'antd'
import { classNames } from '../../utils/styles'
import { getTagColor } from '../../utils/util'
import { formatDate, getDuration, appendZero, getTimezoneOffset } from '../../utils/dateTime'
import { getExecutions, getExecutionsUserList } from '../../store/services/analysisServiceClient'
import MultiDatasetIcon from '../../assets/multiDataset.svg'
import DatasetExecutionIcon from '../../assets/multiDatasetChild.svg'
import PdfDownloadDropdown from '../Report/components/PdfDownloadDropdown'
import { EXECUTION_TYPE_MAP } from '../../statics/constants'

let pageInfo = null
let columnWidths = {
  name: 380,
  runAt: 180,
  user: 200,
  duration: 90,
  tags: 255,
  result: 100,
  report: 80
}

function Executions (props) {
  let queryParameters = {}
  const alertBox = useRef()
  const focusRef = useRef()
  const [allHistory, setAllHistory] = useState([])
  const [infoMessage, setInfoMessage] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [startDateSelected, setStartDateSelected] = useState('')
  const [endDateSelected, setEndDateSelected] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [testName, setTestName] = useState('')
  const [results, setResults] = useState([])
  const [statuses, setStatuses] = useState([])
  const [executionType, setExecutionType] = useState('')
  const [assignedUserIds, setAssignedUserIds] = useState([])
  const [machines, setMachines] = useState([])
  const [testIds, setTestIds] = useState([])
  const [filterTags, setFilterTags] = useState([])
  const [instantDateFilter, setInstantDateFilter] = useState('')
  const [hasMore, setHasMore] = useState(false)
  const [filterCleared, setFilterCleared] = useState(false)
  const [nameFilterVisible, setNameFilterVisible] = useState(false)
  const [dateFilterVisible, setDateFilterVisible] = useState(false)
  const [resultFilterVisible, setResultFilterVisible] = useState(false)
  const [tagFilterVisible, setTagFilterVisible] = useState(false)
  const [userFilterVisible, setUserFilterVisible] = useState(false)
  const [projectTags, setProjectTags] = useState([])
  const executionsUserMap = useMemo(() => new Map(), [])
  const [tags, setTags] = useState([])
  const [users, setUsers] = useState([])
  const resizingColumnRef = useRef(undefined)
  const [actualColumnWidths, setActualColumnWidths] = useState(columnWidths)
  const minColumnWidth = 90
  const executionsTableContainerRef = useRef()
  const [showInfiniteScrollLoader, setShowInfiniteScrollLoader] = useState(false)
  const nobodyUserId = '00000000-0000-0000-0000-000000000000'
  const resizeHandleBorder = useRef()

  const extractQueryParameterFromUrl = useCallback((paramName) => {
    const uri = window.location.search.substring(1)
    const params = new URLSearchParams(uri)
    if (params.has(paramName)) {
      updateQueryParameters(paramName, params.get(paramName))
      return params.get(paramName)
    } else {
      // throw new Error(`Query parameter '${paramName}' is missing.`)
      return ''
    }
  }, [])

  const getTestNames = async (testIds) => {
    const responses = await Promise.all([
      ...testIds.map((testId) => httpGet(`projects/${props.match.params.project}/tests/${testId}`))
    ])
    const testNames = responses.map((testData) => testData?.data?.Name)
    return testNames
  }

  const setInitialTags = useCallback(async () => {
    const tempTags = []
    let extractedStartDate = extractQueryParameterFromUrl('startDate')
    let extractedEndDate = extractQueryParameterFromUrl('endDate')
    const extractedResults = extractQueryParameterFromUrl('result')
    const extractedTestName = extractQueryParameterFromUrl('testName')
    const extractedExecutionType = extractQueryParameterFromUrl('executionType')
    const tags = extractQueryParameterFromUrl('tag')
    const extractedStatuses = extractQueryParameterFromUrl('status')
    const extractedMachines = extractQueryParameterFromUrl('machine')
    const extractedAssignedUserIds = extractQueryParameterFromUrl('assignedUserId')
    const extractedTestIds = extractQueryParameterFromUrl('testId')
    const users = extractQueryParameterFromUrl('user')
    if (extractedStartDate) {
      extractedStartDate = new Date(extractedStartDate + ' UTC')
      tempTags.push({ filterField: 'startDate', filterValue: extractedStartDate.toDateString(), text: 'From' })
    }
    setStartDate(extractedStartDate)
    setStartDateSelected(extractedStartDate)

    if (extractedEndDate) {
      extractedEndDate = new Date(extractedEndDate + ' UTC')
      tempTags.push({ filterField: 'endDate', filterValue: extractedEndDate.toDateString(), text: 'To' })
    }
    setEndDate(extractedEndDate)
    setEndDateSelected(extractedEndDate)
    if (extractedResults) {
      setResults(extractedResults.split(' '))
      tempTags.push({ filterField: 'result', filterValue: extractedResults.split(' ').join('; '), text: 'Results' })
    }
    if (extractedStatuses) {
      setStatuses(extractedStatuses.split(' '))
      tempTags.push({ filterField: 'status', filterValue: extractedStatuses.split(' ').join('; '), text: 'Statuses' })
    }
    if (extractedExecutionType) {
      setExecutionType(extractedExecutionType)
      tempTags.push({
        filterField: 'executionType',
        filterValue: EXECUTION_TYPE_MAP[extractedExecutionType],
        text: 'Execution Type'
      })
    }
    if (extractedAssignedUserIds) {
      setAssignedUserIds(extractedAssignedUserIds.split(' '))
      const assigneeEmails = extractedAssignedUserIds
        .split(' ')
        .map((assigneeId) => [...executionsUserMap].find(([id, _email]) => id === assigneeId))
        .map((assigneeDetails) => assigneeDetails?.[1])
      tempTags.push({ filterField: 'assignee', filterValue: assigneeEmails.join('; '), text: 'Assignees' })
    }
    if (extractedMachines) {
      setMachines(extractedMachines.split(' '))
      tempTags.push({ filterField: 'machine', filterValue: extractedMachines.split(' ').join('; '), text: 'Machines' })
    }
    if (extractedTestName) {
      tempTags.push({ filterField: 'testName', filterValue: extractedTestName, text: 'Test Name' })
    }
    setTestName(extractedTestName)
    if (extractedTestIds) {
      setTestIds(extractedTestIds.split(' '))
      const testNames = await getTestNames(extractedTestIds.split(' '))
      tempTags.push({ filterField: 'testName', filterValue: testNames.join('; '), text: 'Test Names' })
    }
    if (tags) {
      setTags(tags.split(' '))
      tempTags.push({ filterField: 'tag', filterValue: tags.split(' ').join('; '), text: 'Tags' })
    }
    if (users) {
      setUsers(users.split(' '))
      tempTags.push({ filterField: 'user', filterValue: users.split(' ').join('; '), text: 'Users' })
    }
    if (tempTags.length > 0) setFilterTags(tempTags)
  }, [extractQueryParameterFromUrl, executionsUserMap])

  const updateQueryParameters = (key, value) => {
    queryParameters = { ...queryParameters, [key]: value }
  }

  const updateQueryParametersAndPush = useCallback(
    (key, value) => {
      queryParameters = { ...queryParameters, [key]: value }
      const searchString = qs.stringify(queryParameters)
      props.history.push({
        search: searchString
      })
    },
    [props.history]
  )

  const toServerEndDateFormat = (endDateToUTCFormat) => {
    if (endDateToUTCFormat) {
      const tempEndDateToUTCFormat = new Date(endDateToUTCFormat)
      return (
        tempEndDateToUTCFormat.getUTCMonth() +
        1 +
        '/' +
        tempEndDateToUTCFormat.getUTCDate() +
        '/' +
        tempEndDateToUTCFormat.getUTCFullYear() +
        ` ${appendZero(tempEndDateToUTCFormat.getUTCHours())}:${appendZero(tempEndDateToUTCFormat.getUTCMinutes())}:${appendZero(tempEndDateToUTCFormat.getUTCSeconds())}`
      )
    } else return endDateToUTCFormat
  }

  const getProjectTags = useCallback(async () => {
    try {
      const res = await httpGet(`projects/${props.match.params.project}/testtags`)
      setProjectTags(res.data)
    } catch (error) {
      updateUIOnError('Error retrieving project tags.', error)
    }
  }, [props.match.params.project])

  const getExecutionsUsers = useCallback(async () => {
    try {
      const response = await getExecutionsUserList(props.match.params.project)
      response.data.forEach(([ Email, Id ]) => executionsUserMap.set(Id, Email))
      executionsUserMap.set(nobodyUserId, 'Nobody')
    } catch (error) {
      updateUIOnError('Error retrieving users.', error)
    }
  }, [executionsUserMap])

  const loadExecutionHistory = useCallback(
    async (loadMore = false) => {
      try {
        const filters = {
          Since: toServerStartDateFormat(startDateSelected),
          Until: toServerEndDateFormat(endDateSelected),
          TestName: testName,
          ExecutionType: executionType,
          TestResult: results.join(' '),
          Email: users.join(' '),
          TestId: testIds.join(' '),
          Status: statuses.join(' '),
          Machine: machines.join(' '),
          Assignee: assignedUserIds.join(' '),
          Tags: tags.join(' ')
        }
        const response = await getExecutions({
          ProjectId: props.match.params.project,
          OrderBy: 'StartedAt',
          SortOrder: 'Desc',
          Offset: !pageInfo ? 0 : pageInfo.Next,
          Filters: filters
        })
        if (loadMore) {
          setAllHistory((prev) => {
            return [...prev, ...response.data.Items]
          })
        } else {
          setAllHistory(response.data.Items)
        }
        pageInfo = {
          Next: response.data?.Info?.Offset,
          HasNext: response.data?.Info?.HasNext
        }
        setHasMore(pageInfo?.HasNext)
        setInfoMessage('No execution history found.')
      } catch (e) {
        updateUIOnError('Error retrieving execution history.', e)
      }
    },
    [
      endDateSelected,
      props.match.params.project,
      results,
      assignedUserIds,
      statuses,
      machines,
      startDateSelected,
      tags,
      testName,
      executionType,
      testIds,
      users
    ]
  )

  const loadFirstPage = useCallback(async () => {
    showProgressUI()
    setAllHistory([])
    pageInfo = null
    setHasMore(false)
    await loadExecutionHistory()
    updateUIOnSuccess()
  }, [loadExecutionHistory])

  const loadMore = async () => {
    if (pageInfo != null && pageInfo.HasNext) {
      setShowInfiniteScrollLoader(true)
      try {
        await loadExecutionHistory(true)
      } catch (e) {
        updateUIOnError('Error retrieving execution history.', e)
      } finally {
        setShowInfiniteScrollLoader(true)
      }
    }
  }

  const showProgressUI = () => {
    setInfoMessage('Loading...')
    alertBox.current?.hideAlert()
  }

  const updateUIOnSuccess = (message = '') => {
    alertBox.current?.showSuccess(message)
  }

  const updateUIOnError = (message, e) => {
    alertBox.current?.showServerError(message, e)
    setInfoMessage('')
  }

  const launchReport = async (runId) => {
    props.history.push(`/p/${props.match.params.project}/executions/${runId}`)
  }

  const toServerStartDateFormat = (startDate) => {
    if (startDate) {
      return (
        startDate.getUTCMonth() +
        1 +
        '/' +
        startDate.getUTCDate() +
        '/' +
        startDate.getUTCFullYear() +
        ` ${appendZero(startDate.getUTCHours())}:${appendZero(startDate.getUTCMinutes())}:00`
      )
    } else return startDate
  }

  const selectDateRange = async (range) => {
    const newDate = new Date()
    const year = newDate.getFullYear()
    const month = newDate.getMonth()
    const date = newDate.getDate()
    const day = newDate.getDay()
    switch (range) {
      case 'Today':
        setStartDate(new Date(year, month, date))
        setEndDate(new Date(year, month, date))
        break
      case 'Yesterday':
        setStartDate(new Date(year, month, date - 1))
        setEndDate(new Date(year, month, date - 1))
        break
      case 'This Week':
        setStartDate(new Date(year, month, date - day + 1))
        setEndDate(new Date(year, month, date))
        break
      case 'This Month':
        setStartDate(new Date(year, month, 1))
        setEndDate(new Date(year, month, date))
        break
      case 'Last Week':
        setStartDate(new Date(year, month, date - day - 6))
        setEndDate(new Date(year, month, date - day))
        break
      case 'Last Month':
        setStartDate(new Date(year, month - 1, 1))
        setEndDate(new Date(year, month, 0))
        break
      default:
        break
    }
  }

  const selectStartDate = (date) => {
    setStartDate(date)
    setInstantDateFilter('')
  }

  const selectEndDate = (date) => {
    setEndDate(date)
    setInstantDateFilter('')
  }

  const selectEndDateForServer = (date) => {
    if (date) {
      const newEndDate = new Date(date)
      newEndDate.setDate(newEndDate.getDate() + 1)
      newEndDate.setSeconds(newEndDate.getSeconds() - 1)
      return newEndDate
    }
    return ''
  }

  const CustomFromDateInput = forwardRef(({ value, onClick }, ref) => (
    <span className='form-control dateSpan filterDropdownItem' onClick={onClick} ref={ref} style={{ width: '150px' }}>
      {value || 'Select From Date'}
    </span>
  ))

  const CustomToDateInput = forwardRef(({ value, onClick }, ref) => (
    <span className='form-control dateSpan filterDropdownItem' onClick={onClick} ref={ref} style={{ width: '150px' }}>
      {value || 'Select To Date'}
    </span>
  ))

  const applyDateFilter = () => {
    setDateFilterVisible(false)
    startDate !== '' && setStartDateSelected(startDate)
    let newEndDate = new Date(endDate)
    if (endDate !== '') {
      newEndDate = selectEndDateForServer(endDate)
      setEndDateSelected(newEndDate)
    }
    startDate !== '' && updateQueryParametersAndPush('startDate', new Date(startDate).toUTCString())
    endDate !== '' && updateQueryParametersAndPush('endDate', newEndDate.toUTCString())
    const temp = filterTags.filter((tag) => tag.filterField !== 'startDate' && tag.filterField !== 'endDate')
    startDate !== '' && temp.push({ filterField: 'startDate', filterValue: startDate.toDateString(), text: 'From' })
    endDate !== '' && temp.push({ filterField: 'endDate', filterValue: endDate.toDateString(), text: 'To' })
    setFilterTags(temp)
  }

  const removeTag = async (tag) => {
    setFilterTags(filterTags.filter((item) => item.filterField !== tag.filterField))
    setFilterCleared(true)
    switch (tag.filterField) {
      case 'startDate':
        setStartDate('')
        setStartDateSelected('')
        setInstantDateFilter('')
        updateQueryParametersAndPush('startDate', undefined)
        break
      case 'endDate':
        setEndDate('')
        setEndDateSelected(() => selectEndDateForServer(''))
        setInstantDateFilter('')
        updateQueryParametersAndPush('endDate', undefined)
        break
      case 'result':
        setResults([])
        updateQueryParametersAndPush('result', undefined)
        break
      case 'testName':
        setTestName('')
        setTestIds([])
        updateQueryParametersAndPush('testName', undefined)
        updateQueryParametersAndPush('testId', undefined)
        break
      case 'tag':
        setTags([])
        updateQueryParametersAndPush('tag', undefined)
        break
      case 'user':
        setUsers([])
        updateQueryParametersAndPush('user', undefined)
        break
      case 'status':
        setStatuses([])
        updateQueryParametersAndPush('status', undefined)
        break
      case 'machine':
        setMachines([])
        updateQueryParametersAndPush('machine', undefined)
        break
      case 'assignee':
        setAssignedUserIds([])
        updateQueryParametersAndPush('assignedUserId', undefined)
        break
      case 'executionType':
        setExecutionType('')
        updateQueryParametersAndPush('executionType', undefined)
        break
      default:
        break
    }
  }

  const clearFilters = () => {
    setStartDate('')
    setEndDate('')
    setStartDateSelected('')
    setEndDateSelected(() => selectEndDateForServer(''))
    setResults([])
    setStatuses([])
    setAssignedUserIds([])
    setMachines([])
    setTestName('')
    setFilterTags([])
    setInstantDateFilter('')
    props.history.push({ search: undefined })
    queryParameters = {}
    setTags([])
    setUsers([])
    setFilterCleared(true)
  }

  const handleForm = () => {
    if (searchValue) {
      setNameFilterVisible(false)
      setTestName(searchValue)
      updateQueryParametersAndPush('testName', searchValue)
      const temp = filterTags.filter((tag) => tag.filterField !== 'testName')
      setFilterTags([...temp, { filterField: 'testName', filterValue: searchValue, text: 'Test Name' }])
      setSearchValue('')
    }
  }

  const applyResultFilter = () => {
    setResultFilterVisible(false)
    let tempTags = filterTags
    if (filterTags.filter((tag) => tag.filterField === 'result').length > 0) {
      tempTags = filterTags.filter((tag) => tag.filterField !== 'result')
      setFilterTags(tempTags)
      updateQueryParametersAndPush('result', undefined)
    }
    if (results.length) {
      updateQueryParametersAndPush('result', results.join(' '))
      setFilterTags([...tempTags, { filterField: 'result', filterValue: results.join('; '), text: 'Results' }])
    } else {
      setFilterCleared(true)
    }
  }

  const applyTags = () => {
    let tempTags = filterTags
    if (filterTags.filter((tag) => tag.filterField === 'tag').length > 0) {
      tempTags = filterTags.filter((tag) => tag.filterField !== 'tag')
      setFilterTags(tempTags)
      updateQueryParametersAndPush('tag', undefined)
    }
    if (tags.length > 0) {
      updateQueryParametersAndPush('tag', tags.join(' '))
      setFilterTags([...tempTags, { filterField: 'tag', filterValue: tags.join('; '), text: 'Tags' }])
    } else {
      setFilterCleared(true)
    }
    setTagFilterVisible(false)
  }

  const applyUserFilter = () => {
    let tempTags = filterTags
    if (filterTags.filter((tag) => tag.filterField === 'user').length > 0) {
      tempTags = filterTags.filter((tag) => tag.filterField !== 'user')
      setFilterTags(tempTags)
      updateQueryParametersAndPush('user', undefined)
    }
    if (users.length > 0) {
      updateQueryParametersAndPush('user', users.join(' '))
      setFilterTags([...tempTags, { filterField: 'user', filterValue: users.join('; '), text: 'Users' }])
    } else {
      setFilterCleared(true)
    }
    setUserFilterVisible(false)
  }

  const nameFilter = () => ({
    filterDropdown: () => (
      <div className='p-2'>
        <Space>
          <Input
            placeholder='Search by test name'
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={() => handleForm()}
            value={searchValue}
            style={{ width: '220px' }}
            ref={focusRef}
            className='filterDropdownItem'
          />
          <Button
            type='primary'
            icon={<i className='fa fa-search' />}
            className='-ml-2.5'
            onClick={() => handleForm()}
          />
        </Space>
      </div>
    ),
    filterIcon: () => <i className='fa fa-search' />
  })

  useEffect(() => {
    if (nameFilterVisible) {
      setTimeout(() => focusRef.current.focus(), 100)
    }
  }, [nameFilterVisible])

  const dateFilter = () => ({
    filterDropdown: () => (
      <div className='py-2.5 pl-3'>
        <div className='flex items-center justify-start mt-1 filterDropdownItem'>
          <span className='mr-2 font-bold'>From: </span>
          <DatePicker
            selected={startDate}
            onChange={(date) => selectStartDate(date)}
            customInput={<CustomFromDateInput />}
            dateFormat='dd MMM yyyy'
            maxDate={endDate || new Date()}
            showPopperArrow={false}
          />
        </div>
        <div className='flex items-center justify-start mt-1'>
          <span className='font-bold filterDropdownItem' style={{ marginLeft: '20px', marginRight: '6px' }}>
            To:
          </span>
          <DatePicker
            selected={endDate}
            onChange={(date) => selectEndDate(date)}
            customInput={<CustomToDateInput />}
            dateFormat='dd MMM yyyy'
            maxDate={new Date()}
            minDate={startDate}
            showPopperArrow={false}
          />
        </div>
        <div className='mt-2'>
          <Radio.Group onChange={(e) => setInstantDateFilter(e.target.value)} value={instantDateFilter}>
            <div className='grid grid-cols-2'>
              <Radio value='Today' checked={instantDateFilter === 'Today'} className='filterDropdownItem'>
                Today
              </Radio>
              <Radio value='Yesterday' checked={instantDateFilter === 'Yesterday'} className='filterDropdownItem'>
                Yesterday
              </Radio>
              <Radio value='This Week' checked={instantDateFilter === 'This Week'} className='filterDropdownItem'>
                This Week
              </Radio>
              <Radio value='This Month' checked={instantDateFilter === 'This Month'} className='filterDropdownItem'>
                This Month
              </Radio>
              <Radio value='Last Week' checked={instantDateFilter === 'Last Week'} className='filterDropdownItem'>
                Last Week
              </Radio>
              <Radio value='Last Month' checked={instantDateFilter === 'Last Month'} className='filterDropdownItem'>
                Last Month
              </Radio>
            </div>
          </Radio.Group>
        </div>
        <div className='flex justify-end'>
          <Button
            onClick={() => applyDateFilter()}
            className='mt-2 mr-3'
            type='primary'
            disabled={startDate === '' && endDate === '' && instantDateFilter === ''}
            size='small'
          >
            Apply
          </Button>
        </div>
      </div>
    )
  })

  const resultFilter = () => ({
    filterDropdown: () => (
      <div className='py-2 pl-2 pr-1'>
        <Checkbox.Group onChange={(checkedValues) => setResults(checkedValues)} value={results}>
          <Space direction='vertical' size={4}>
            {[
              { name: 'Passed', value: 'Passed' },
              { name: 'Failed', value: 'Failed' },
              { name: 'Cancelled', value: 'Cancelled' },
              { name: 'Not Finished', value: 'DidNotFinish' }
            ].map((tag) => (
              <Checkbox value={tag.value} className='filterDropdownItem'>
                {tag.name}
              </Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
        <div className='flex justify-end mt-2'>
          <Button onClick={() => applyResultFilter()} type='primary' disabled={results.length === 0} size='small'>
            Apply
          </Button>
        </div>
      </div>
    )
  })

  const tagsFilter = () => ({
    filterDropdown: () => (
      <div className='py-2 pl-2 pr-1'>
        {projectTags.length > 0 ? (
          <>
            <Checkbox.Group
              onChange={(checkedValues) => {
                setTags(checkedValues)
              }}
              value={tags}
            >
              <Space direction='vertical' size={4}>
                {projectTags?.map((tag) => (
                  <Checkbox value={tag} className='filterDropdownItem'>
                    {tag}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
            <div className='flex justify-end mt-2'>
              <Button onClick={() => applyTags()} type='primary' disabled={tags.length === 0} size='small'>
                Apply
              </Button>
            </div>
          </>
        ) : (
          <p className='m-0 p-0 text-gray-400'>No Tags</p>
        )}
      </div>
    )
  })

  const userFilter = () => ({
    filterDropdown: () => (
      <div className='py-2 pl-2 pr-1'>
        {executionsUserMap.size > 0 ? (
          <>
            <Checkbox.Group
              onChange={(checkedValues) => {
                setUsers(checkedValues)
              }}
              value={users}
            >
              <Space direction='vertical' size={4}>
                {[...new Set([...executionsUserMap.values()])]?.map((email) => (
                  <Checkbox value={email} key={email} className='filterDropdownItem'>
                    {email}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
            <div className='flex justify-end mt-2'>
              <Button onClick={() => applyUserFilter()} type='primary' disabled={users.length === 0} size='small'>
                Apply
              </Button>
            </div>
          </>
        ) : (
          <p className='m-0 p-0 text-gray-400'>No Users</p>
        )}
      </div>
    )
  })

  const isMultiDatasetExecution = (executionType) => executionType.Type === 'MultiDataset'

  const isMultiDatasetChild = (executionType) => executionType.Type === 'Atomic' && executionType.IsMultiDatasetChild

  const columns = [
    {
      key: 'TestName',
      title: (
        <div className='select-none ellipsis' style={{ width: actualColumnWidths.name - 32 }}>
          <span className='ml-6'>Test Name</span>
          <div
            className='resizeHandle z-10'
            style={{ right: '-28px' }}
            onMouseDown={(e) => {
              setResizingColumn('name')
              showResizeHandleBorder(e)
              window.addEventListener('mousemove', resizeListener, { passive: true })
            }}
          />
        </div>
      ),
      dataIndex: 'TestName',
      width: actualColumnWidths.name,
      render: (ID, record) => (
        <div
          className='text-blue-500 cursor-pointer ellipsis gap-x-3'
          onClick={() => launchReport(record.Id)}
          title={record.TestName}
        >
          <span style={{ width: '21px' }} className='inline-block'>
            {isMultiDatasetExecution(record.ExecutionType) && (
              <span>
                <img src={MultiDatasetIcon} alt='Multi Dataset Icon' title='Execution with Multiple Datasets' />
              </span>
            )}
            {isMultiDatasetChild(record.ExecutionType) && (
              <span>
                <img src={DatasetExecutionIcon} alt='Multi Dataset Icon' title='Single Dataset execution run within a Multiple Dataset execution' />
              </span>
            )}
          </span>
          {record.TestName}
        </div>
      ),
      ...nameFilter(),
      filterDropdownVisible: nameFilterVisible,
      onFilterDropdownVisibleChange: (visible) => setNameFilterVisible(visible)
    },
    {
      title: (
        <div className='select-none ellipsis' style={{ width: actualColumnWidths.runAt - 32 }} title={`Run At (${getTimezoneOffset()})`}>
          Run At {`(${getTimezoneOffset()})`}
          <div
            className='resizeHandle z-10'
            style={{ right: '-28px' }}
            onMouseDown={(e) => {
              setResizingColumn('runAt')
              showResizeHandleBorder(e)
              window.addEventListener('mousemove', resizeListener, { passive: true })
            }}
          />
        </div>
      ),
      dataIndex: 'StartedAt',
      key: 'StartedAt',
      width: actualColumnWidths.runAt,
      render: (StartedAt) => <div className='ellipsis'>{formatDate(StartedAt, false)}</div>,
      ...dateFilter(),
      filterDropdownVisible: dateFilterVisible,
      onFilterDropdownVisibleChange: (visible) => setDateFilterVisible(visible)
    },
    {
      title: (
        <div className='select-none'>
          User
          <div
            className='resizeHandle z-10'
            style={{ right: '-28px' }}
            onMouseDown={(e) => {
              setResizingColumn('user')
              showResizeHandleBorder(e)
              window.addEventListener('mousemove', resizeListener, { passive: true })
            }}
          />
        </div>
      ),
      key: 'User',
      width: actualColumnWidths.user,
      render: (text, record) => {
        return <div className='ellipsis'>{record.UserDetails.Email}</div>
      },
      ...userFilter(),
      filterDropdownVisible: userFilterVisible,
      onFilterDropdownVisibleChange: (visible) => setUserFilterVisible(visible)
    },
    {
      title: (
        <div className='select-none'>
          Duration
          <div
            className='resizeHandle z-10'
            style={{ right: '1px' }}
            onMouseDown={(e) => {
              setResizingColumn('duration')
              showResizeHandleBorder(e)
              window.addEventListener('mousemove', resizeListener, { passive: true })
            }}
          />
        </div>
      ),
      key: 'duration',
      width: actualColumnWidths.duration,
      render: (text, item) => {
        return <div className='ellipsis'>{getDuration(item.StartedAt, item.EndedAt)}</div>
      }
    },
    {
      title: (
        <div className='select-none'>
          Tags
          <div
            className='resizeHandle z-10'
            style={{ right: '-28px' }}
            onMouseDown={(e) => {
              setResizingColumn('tags')
              showResizeHandleBorder(e)
              window.addEventListener('mousemove', resizeListener, { passive: true })
            }}
          />
        </div>
      ),
      dataIndex: 'Tags',
      width: actualColumnWidths.tags,
      render: (Tags, record) =>
        Tags?.length > 0 && (
          <Select
            id={record.Id}
            key={record.Id}
            value={Tags}
            bordered={false}
            mode='tags'
            maxTagCount='responsive'
            style={{ width: '100%', cursor: 'pointer' }}
            menuItemSelectedIcon={false}
            removeIcon={false}
            dropdownClassName='executionsPage'
          />
        ),
      ...tagsFilter(),
      filterDropdownVisible: tagFilterVisible,
      onFilterDropdownVisibleChange: (visible) => setTagFilterVisible(visible)
    },
    {
      title: (
        <div className='select-none'>
          Result
          <div
            className='resizeHandle z-10'
            style={{ right: '-28px' }}
            onMouseDown={(e) => {
              setResizingColumn('result')
              showResizeHandleBorder(e)
              window.addEventListener('mousemove', resizeListener, { passive: true })
            }}
          />
        </div>
      ),
      dataIndex: 'TestResult',
      key: 'TestResult',
      width: actualColumnWidths.result,
      render: (TestResult) =>
        TestResult && (
          <Tag color={getTagColor(TestResult)}> {TestResult === 'DidNotFinish' ? 'Not Finished' : TestResult}</Tag>
        ),
      ...resultFilter(),
      filterDropdownVisible: resultFilterVisible,
      onFilterDropdownVisibleChange: (visible) => setResultFilterVisible(visible)
    },
    {
      title: (
        <div className='select-none'>
          Report
          <div
            className='resizeHandle z-10'
            style={{ right: '1px' }}
            onMouseDown={(e) => {
              setResizingColumn('report')
              showResizeHandleBorder(e)
              window.addEventListener('mousemove', resizeListener, { passive: true })
            }}
          />
        </div>
      ),
      dataIndex: 'Id',
      key: 'report',
      width: actualColumnWidths.report,
      render: (Id, record) => <PdfDownloadDropdown executionType={record.ExecutionType.Type} runId={Id} />
    }
  ]

  useEffect(() => {
    const showExecutions = async () => {
      try {
        showProgressUI()
        await getExecutionsUsers()
        setInitialTags()
        if (Object.keys(queryParameters).length === 0) {
          loadFirstPage()
        }
        getProjectTags()
        updateUIOnSuccess()
      } catch (e) {
        updateUIOnError('Error retrieving execution history.', e)
      }
    }
    showExecutions()
  }, [getProjectTags, getExecutionsUsers, setInitialTags])

  useEffect(() => {
    const allSelects = document.getElementsByClassName('ant-select-selection-search-input')
    for (const el of allSelects) {
      el.setAttribute('readonly', true)
    }
  })

  useEffect(() => {
    if (filterTags.length > 0) loadFirstPage()
  }, [filterTags])

  useEffect(() => {
    if (filterCleared) {
      setFilterCleared(false)
      loadFirstPage()
    }
  }, [filterCleared, loadFirstPage])

  useEffect(() => {
    if (instantDateFilter) {
      selectDateRange(instantDateFilter)
    }
  }, [instantDateFilter])

  const setResizingColumn = (index) => {
    resizingColumnRef.current = index
  }

  const showResizeHandleBorder = (e) => {
    executionsTableContainerRef.current.style.cursor = 'col-resize'
    resizeHandleBorder.current.style.left = e.pageX + 'px'
    resizeHandleBorder.current.style.height = document.querySelector('#scrollableExecutionsDiv').clientHeight + 'px'
    resizeHandleBorder.current.style.display = 'block'
  }

  const hideResizeHandleBorder = () => {
    executionsTableContainerRef.current && (executionsTableContainerRef.current.style.cursor = 'default')
    resizeHandleBorder.current && (resizeHandleBorder.current.style.display = 'none')
  }

  const resizeListener = (e) => {
    if (resizingColumnRef.current !== undefined) {
      handleResize(resizingColumnRef.current, e.movementX)
      resizeHandleBorder.current.style.left = e.pageX + 'px'
    }
    window.onmouseup = (e) => {
      window.removeEventListener('mousemove', resizeListener)
      setResizingColumn(undefined)
      setActualColumnWidths(columnWidths)
      localStorage.setItem('executionsTableColumnWidths', JSON.stringify(columnWidths))
      hideResizeHandleBorder()
    }
  }

  const handleResize = (columnName, delta) => {
    let updatedWidth = columnWidths[columnName] + delta
    updatedWidth = updatedWidth >= minColumnWidth ? updatedWidth : minColumnWidth
    columnWidths = { ...columnWidths, [`${columnName}`]: updatedWidth }
  }

  useEffect(() => {
    if (localStorage.getItem('executionsTableColumnWidths')) {
      columnWidths = JSON.parse(localStorage.getItem('executionsTableColumnWidths'))
      setActualColumnWidths(columnWidths)
    } else {
      if (
        executionsTableContainerRef.current.offsetWidth >
        Object.values(columnWidths).reduce((prev, curr) => prev + curr)
      ) {
        columnWidths = {
          name: executionsTableContainerRef.current.offsetWidth * 0.33,
          runAt: executionsTableContainerRef.current.offsetWidth * 0.13,
          user: executionsTableContainerRef.current.offsetWidth * 0.15,
          duration: executionsTableContainerRef.current.offsetWidth * 0.07,
          tags: executionsTableContainerRef.current.offsetWidth * 0.2,
          result: executionsTableContainerRef.current.offsetWidth * 0.07,
          report: executionsTableContainerRef.current.offsetWidth * 0.05
        }
        setActualColumnWidths(columnWidths)
      }
    }
  }, [])

  return (
    <>
      <div className='mt-3'>
        <Breadcrumb />
        <ProjectNavigation />
        <div id='historyUIPage' className='' ref={executionsTableContainerRef}>
          <Alert ref={alertBox} showCloseButton />
          <div
            className={classNames(filterTags.length > 0 ? 'justify-between' : 'justify-end', 'flex pl-0')}
            style={{ minHeight: '35px' }}
          >
            {filterTags.length > 0 && (
              <div>
                {filterTags.map((tag) => (
                  <Tag closable color='blue' onClose={() => removeTag(tag)} className='filterTag' key={tag.filterField}>
                    {tag.text}: "{tag.filterValue === 'DidNotFinish' ? 'Not Finished' : tag.filterValue}"
                  </Tag>
                ))}
                <Button onClick={() => clearFilters()} size='small' className='filterClearButton'>
                  Clear
                </Button>
              </div>
            )}
            <p className='float-right my-auto'>
              Showing {pageInfo?.Next ? '' : 'all'} {allHistory.length} items
            </p>
          </div>
          <div id='scrollableExecutionsDiv'>
            <InfiniteScroll
              dataLength={allHistory.length}
              next={loadMore}
              hasMore={hasMore}
              loader={
                showInfiniteScrollLoader && (
                  <center>
                    <Spin className='mt-2' />
                  </center>
                )
              }
              style={{ overflowX: 'hidden' }}
              scrollableTarget='scrollableExecutionsDiv'
            >
              <div id='resizeHandleBorderExecutions' ref={resizeHandleBorder} />
              <Table
                dataSource={allHistory}
                columns={columns}
                pagination={false}
                scroll={{ x: 'min-content' }}
                tableLayout='fixed'
                locale={{
                  emptyText:
                    infoMessage === 'Loading...' ? (
                      <Spin tip='Loading executions...' />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<b>{infoMessage}</b>} />
                    )
                }}
                size='small'
                rowKey={(record) => record.Id}
                className='executionsTable'
                style={{ minHeight: hasMore && 'calc(100vh - 291px) ' }}
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </>
  )
}

export default Executions
