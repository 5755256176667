import { getHttpClient } from './httpClient'

const getTestServiceHttpClient = async () => {
  return getHttpClient(process.env.REACT_APP_TEST_SERVICE_URL)
}
const httpGet = async (url) => {
  const testServiceHttpClient = await getTestServiceHttpClient()
  return testServiceHttpClient.get(url)
}

const httpPost = async (url, data, headers = {}) => {
  const testServiceHttpClient = await getTestServiceHttpClient()
  return testServiceHttpClient.post(url, data, headers)
}

const httpPut = async (url, data, headers = {}) => {
  const testServiceHttpClient = await getTestServiceHttpClient()
  return testServiceHttpClient.put(url, data, headers)
}

const httpDelete = async (url) => {
  const testServiceHttpClient = await getTestServiceHttpClient()
  return testServiceHttpClient.delete(url)
}

export { httpGet, httpPost, httpPut, httpDelete }
