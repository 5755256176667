import React, { useRef, useState } from 'react'
import Breadcrumb from '../../components/Navigation/Breadcrumb'
import SettingsNavigation from '../../components/Navigation/SettingsNavigation'
import Alert from '../../components/Alerts/Alert'
import ActionPanel from './components/ActionPanel'
import { ADMINISTRATION_PAGE_UI_STATES, ENTITY } from '../../statics/constants'
import TestAdministration from './components/TestAdministration'
import ComponentAdministration from './components/ComponentAdministration'
import { ProjectAdministration } from './components/ProjectAdministration'

const Administration = () => {
  const alertBox = useRef()
  const [action, setAction] = useState('Clone')
  const [entity, setEntity] = useState('')
  const [componentType, setComponentType] = useState('')
  const [sourceProjectId, setSourceProjectId] = useState('')
  const [destinationProjectId, setDestinationProjectId] = useState('')
  const [sourceProjectName, setSourceProjectName] = useState('')
  const [destinationProjectName, setDestinationProjectName] = useState('')
  const [disableSourceProjectSelect, setDisableSourceProjectSelect] = useState(false)
  const [reuseUnchangedArtefactsWhileCloning, setReuseUnchangedArtefactsWhileCloning] = useState(false)
  const [currentUIState, setCurrentUIState] = useState(ADMINISTRATION_PAGE_UI_STATES.INITIAL)

  const updateUIOnError = (message, e) => {
    alertBox.current?.showServerError(message, e)
  }

  return (
    <div style={{ minHeight: '85vh' }}>
      <Breadcrumb />
      <SettingsNavigation />
      <div className='mt-3 px-6'>
        <Alert ref={alertBox} showCloseButton />

        {currentUIState === ADMINISTRATION_PAGE_UI_STATES.INITIAL && (
          <>
            <p className='mt-3'>Choose the project assets you wish to transfer to another project.</p>
            <ActionPanel
              action={action}
              setAction={setAction}
              entity={entity}
              setEntity={setEntity}
              componentType={componentType}
              setComponentType={setComponentType}
              sourceProjectId={sourceProjectId}
              setSourceProjectId={setSourceProjectId}
              destinationProjectId={destinationProjectId}
              setDestinationProjectId={setDestinationProjectId}
              setSourceProjectName={setSourceProjectName}
              setDestinationProjectName={setDestinationProjectName}
              disableSourceProjectSelect={disableSourceProjectSelect}
              setCurrentUIState={setCurrentUIState}
              updateUIOnError={updateUIOnError}
            />
          </>
        )}

        {(() => {
          switch (entity) {
            case ENTITY.TEST:
              return (
                <TestAdministration
                  action={action}
                  entity={entity}
                  setEntity={setEntity}
                  currentUIState={currentUIState}
                  setCurrentUIState={setCurrentUIState}
                  sourceProjectId={sourceProjectId}
                  setSourceProjectId={setSourceProjectId}
                  destinationProjectId={destinationProjectId}
                  setDestinationProjectId={setDestinationProjectId}
                  reuseUnchangedArtefactsWhileCloning={reuseUnchangedArtefactsWhileCloning}
                  setReuseUnchangedArtefactsWhileCloning={setReuseUnchangedArtefactsWhileCloning}
                  updateUIOnError={updateUIOnError}
                  setDisableSourceProjectSelect={setDisableSourceProjectSelect}
                  disableSourceProjectSelect={disableSourceProjectSelect}
                  sourceProjectName={sourceProjectName}
                  destinationProjectName={destinationProjectName}
                />
              )
            case ENTITY.COMPONENT:
              return (
                <ComponentAdministration
                  action={action}
                  entity={entity}
                  componentType={componentType}
                  setEntity={setEntity}
                  setComponentType={setComponentType}
                  currentUIState={currentUIState}
                  setCurrentUIState={setCurrentUIState}
                  sourceProjectId={sourceProjectId}
                  setSourceProjectId={setSourceProjectId}
                  destinationProjectId={destinationProjectId}
                  setDestinationProjectId={setDestinationProjectId}
                  reuseUnchangedArtefactsWhileCloning={reuseUnchangedArtefactsWhileCloning}
                  setReuseUnchangedArtefactsWhileCloning={setReuseUnchangedArtefactsWhileCloning}
                  updateUIOnError={updateUIOnError}
                  setDisableSourceProjectSelect={setDisableSourceProjectSelect}
                  disableSourceProjectSelect={disableSourceProjectSelect}
                  sourceProjectName={sourceProjectName}
                  destinationProjectName={destinationProjectName}
                />
              )
            case ENTITY.PROJECT:
              return (
                <ProjectAdministration
                  action={action}
                  entity={entity}
                  setEntity={setEntity}
                  currentUIState={currentUIState}
                  setCurrentUIState={setCurrentUIState}
                  updateUIOnError={updateUIOnError}
                />
              )
            default:
              return <></>
          }
        })()}
      </div>
    </div>
  )
}

export default Administration
