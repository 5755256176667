import React, { useCallback, useEffect, useState } from 'react'
import { httpGet, httpPost, httpDelete, httpPut } from '../../store/services/testServiceClient.js'
import SettingsNavigation from '../../components/Navigation/SettingsNavigation'
import Breadcrumb from '../../components/Navigation/Breadcrumb'
import { Button, Input, Table, Modal, Checkbox, Alert, Spin, Tooltip } from 'antd'

const UserSettings = () => {
  const [allUsers, setAllUsers] = useState([])
  const [currentRecord, setCurrentRecord] = useState({
    Id: '',
    Name: '',
    Email: '',
    Title: '',
    IsAdministrator: false
  })
  const [dialogAction, setDialogAction] = useState('')
  const [searchText, setSearchText] = useState('')
  const [alertText, setAlertText] = useState('')
  const [isSuccess, setIsSuccess] = useState(true)
  const [showProgress, setShowProgress] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isnewUserModalVisible, setIsNewUserModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const cloneRecord = (Email) => {
    // Deep clone the selected record so that editing is non-destructive until committed explicitly.
    const selected = allUsers.find(function (r) {
      return r.Email === Email
    })
    setCurrentRecord({
      Id: selected.Id,
      Name: selected.Name,
      Email: selected.Email,
      Title: selected.Title,
      IsAdministrator: selected.IsAdministrator
    })
  }

  const loadAllUsers = useCallback(async () => {
    showProgressUI('Loading')
    try {
      const response = await httpGet('users')
      setAllUsers(response.data)
      updateUIOnSuccess()
      setInfoMessage('No users exist, create a new user or modify your search criteria...')
    } catch (e) {
      updateUIOnError('Error retrieving users.', e)
    }
  }, [])

  const findUser = async () => {
    try {
      if (searchText.trim() === '') {
        await loadAllUsers()
      } else {
        showProgressUI('Loading')
        const response = await httpGet(`users/${searchText}`)
        setAllUsers([response.data])
        updateUIOnSuccess()
      }
    } catch (e) {
      updateUIOnError(`User with email '${searchText}' not found.`, e)
    }
  }

  const showModalDialogForAdd = () => {
    setAlertText('')
    setCurrentRecord({ Id: '', Name: '', Email: '', Title: '', IsAdministrator: false })
    setDialogAction('Add')
    setIsNewUserModalVisible(true)
  }

  const showModalDialogForUpdate = async (userId) => {
    showProgressUI('Getting user details')
    try {
      const response = await httpGet(`users/${userId}`)
      setCurrentRecord({
        ...response.data
      })
      setAlertText('')
      setDialogAction('Update')
      setIsModalVisible(true)
      updateUIOnSuccess()
    } catch (err) {
      updateUIOnError('Error retrieving user.', err)
    }
  }

  const showModalDialogForDelete = (Email) => {
    setAlertText('')
    cloneRecord(Email)
    setIsDeleteModalVisible(true)
  }

  const performActionOnUser = () => {
    setIsNewUserModalVisible(false)
    switch (dialogAction) {
      case 'Add':
        addUser()
        break
      case 'Update':
        updateUser()
        break
      default:
        break
    }
  }

  const addUser = async () => {
    showProgressUI(`Adding user ${currentRecord.Email}`)
    try {
      await httpPost('users', currentRecord)
      await loadAllUsers()
      updateUIOnSuccess(`User '${currentRecord.Email}' added successfully`)
    } catch (e) {
      updateUIOnError(`Error adding user '${currentRecord.Email}'`, e)
    }
  }

  const updateUser = async () => {
    setIsModalVisible(false)
    showProgressUI(`Updating user ${currentRecord.Email}`)
    try {
      await httpPut(`users/${currentRecord.Id}`, currentRecord)
      await loadAllUsers()
      updateUIOnSuccess(`User '${currentRecord.Email}' updated successfully`)
    } catch (e) {
      updateUIOnError(`Error updating user '${currentRecord.Email}'`, e)
    }
  }

  const deleteUser = async () => {
    setIsDeleteModalVisible(false)
    showProgressUI(`Deleting user ${currentRecord.Email}`)
    try {
      await httpDelete(`users/${currentRecord.Id}`)
      await loadAllUsers()
      updateUIOnSuccess(`User '${currentRecord.Email}' deleted successfully`)
    } catch (e) {
      updateUIOnError(`Error deleting user '${currentRecord.Email}'`, e)
    }
  }

  const showProgressUI = (message) => {
    setAlertText('')
    setShowProgress(true)
    setInfoMessage(`${message}...`)
  }

  const updateUIOnSuccess = (message = '') => {
    setShowProgress(false)
    setIsSuccess(true)
    setAlertText(message)
  }

  const updateUIOnError = (message, e) => {
    setShowProgress(false)
    setIsSuccess(false)
    const hasErrorMessage = e.response != null && e.response.data != null && e.response.data.trim().length > 0
    const responseString = hasErrorMessage ? `: ${e.response.data}` : ''
    setAlertText(`${message} ${responseString}`)
    setInfoMessage('')
    console.log(e)
  }

  const resendInvitation = async () => {
    setIsModalVisible(false)
    showProgressUI(`Sending an invitation email to ${currentRecord.Email}`)
    try {
      await httpPost('users/resendInvitation', { email: currentRecord.Email })
      updateUIOnSuccess(`A new invitation email has been sent to ${currentRecord.Email}`)
    } catch (err) {
      updateUIOnError(`Error sending invitation to ${currentRecord.Email}.`, err)
    }
  }

  useEffect(() => {
    loadAllUsers()
  }, [loadAllUsers])

  const columns = [
    {
      title: 'Email',
      dataIndex: 'Email',
      width: 400,
      render: (Email, User) => (
        <>
          <Button type='link' onClick={() => showModalDialogForUpdate(User.Id)}>
            {Email}
          </Button>
        </>
      )
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      width: 200
    },
    {
      title: 'Job title',
      dataIndex: 'Title',
      width: 150
    },
    {
      title: 'Admin',
      dataIndex: 'IsAdministrator',
      width: 50,
      render: (IsAdministrator) => <>{IsAdministrator ? <span className='fa fa-check' /> : <span />}</>
    },
    {
      title: '',
      dataIndex: 'Email',
      width: 50,
      render: (Email) => (
        <>
          <Button type='link' onClick={() => showModalDialogForDelete(Email)}>
            <span className='fa fa-trash' />
          </Button>
        </>
      )
    }
  ]

  return (
    <>
      {localStorage.getItem('Role') === 'Administrator'
        ? (
          <>
            <Modal
              title='Add New User'
              visible={isnewUserModalVisible}
              onCancel={() => setIsNewUserModalVisible(false)}
              width={600}
              footer={[
                <Button type='primary' disabled={!currentRecord.Email} onClick={performActionOnUser} key='1'>
                  {dialogAction}
                </Button>,
                <Button type='ghost' onClick={() => setIsNewUserModalVisible(false)} key='2'>
                  Cancel
                </Button>
              ]}
            >
              <label className='text-lg mt-1 mb-1'>Email</label>
              <Input
                id='email'
                type='email'
                required
                name='email'
                className='mt-1 mb-2'
                placeholder='Email@domain.com'
                value={currentRecord.Email}
                onChange={(e) =>
                  setCurrentRecord((prev) => {
                    return { ...prev, Email: e.target.value }
                  })}
              />
              <label className='text-lg mt-3 mb-1'>Name</label>
              <br />
              <Input
                type='text'
                id='name'
                className='mt-1 mb-2'
                placeholder='FirstName LastName'
                value={currentRecord.Name}
                onChange={(e) =>
                  setCurrentRecord((prev) => {
                    return { ...prev, Name: e.target.value }
                  })}
              />
              <label className='text-lg mt-3 mb-1'>Title</label>
              <Input
                type='text'
                className='mt-1 mb-2'
                id='title'
                placeholder='Title'
                value={currentRecord.Title}
                onChange={(e) =>
                  setCurrentRecord((prev) => {
                    return { ...prev, Title: e.target.value }
                  })}
              />
              <Checkbox
                id='IsAdministrator'
                checked={currentRecord.IsAdministrator}
                className='mt-1 mb-2'
                onChange={(e) => {
                  if (e.target.checked) {
                    setCurrentRecord((prev) => {
                      return { ...prev, IsAdministrator: true }
                    })
                  } else {
                    setCurrentRecord((prev) => {
                      return { ...prev, IsAdministrator: false }
                    })
                  }
                }}
              >
                Account Administrator
              </Checkbox>
            </Modal>
            <Modal
              title='Update User'
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              width={600}
              footer={[
                <Button type='primary' disabled={!currentRecord.Email} onClick={performActionOnUser} key='1'>
                  {dialogAction}
                </Button>,
                <Button type='ghost' onClick={() => setIsModalVisible(false)} key='2'>
                  Cancel
                </Button>
              ]}
            >
              <div className='mb-2'>
                {currentRecord.AccountStatus !== 'CONFIRMED' && (
                  <Alert
                    message={
                      <Tooltip placement='bottom' title='User has not completed registration' className='cursor-default'>
                        Not Registered
                      </Tooltip>
                    }
                    description={
                      <Tooltip placement='bottom' title='User will receive a welcome email with a temporary password'>
                        <Button
                          size='middle'
                          type='primary'
                          className='w-min my-2'
                          disabled={currentRecord.AccountStatus === 'CONFIRMED'}
                          onClick={resendInvitation}
                        >
                          Resend Invitation
                        </Button>
                      </Tooltip>
                    }
                    type='warning'
                    showIcon
                  />
                )}
              </div>
              <label className='text-lg mt-1 mb-1'>Email</label>
              <Input
                id='email'
                type='email'
                required
                name='email'
                className='mt-1 mb-2'
                placeholder='Email@domain.com'
                value={currentRecord.Email}
                onChange={(e) =>
                  setCurrentRecord((prev) => {
                    return { ...prev, Email: e.target.value }
                  })}
              />
              <label className='text-lg mt-3 mb-1'>Name</label>
              <br />
              <Input
                type='text'
                id='name'
                className='mt-1 mb-2'
                placeholder='FirstName LastName'
                value={currentRecord.Name}
                onChange={(e) =>
                  setCurrentRecord((prev) => {
                    return { ...prev, Name: e.target.value }
                  })}
              />
              <label className='text-lg mt-3 mb-1'>Title</label>
              <Input
                type='text'
                className='mt-1 mb-2'
                id='title'
                placeholder='Title'
                value={currentRecord.Title}
                onChange={(e) =>
                  setCurrentRecord((prev) => {
                    return { ...prev, Title: e.target.value }
                  })}
              />
              <div className='flex justify-between items-center'>
                <Checkbox
                  id='IsAdministrator'
                  checked={currentRecord.IsAdministrator}
                  className='mt-1 mb-2'
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCurrentRecord((prev) => {
                        return { ...prev, IsAdministrator: true }
                      })
                    } else {
                      setCurrentRecord((prev) => {
                        return { ...prev, IsAdministrator: false }
                      })
                    }
                  }}
                >
                  Account Administrator
                </Checkbox>
                {currentRecord.AccountStatus === 'CONFIRMED' && (
                  <span className='text-right'>
                    <i className='fas fa-check-circle text-green-600' /> Registered
                  </span>
                )}
              </div>
            </Modal>
            <Modal
              title='Delete User'
              visible={isDeleteModalVisible}
              onCancel={() => setIsDeleteModalVisible(false)}
              width={600}
              footer={[
                <Button type='primary' disabled={!currentRecord.Email} onClick={deleteUser} key='1'>
                  Delete
                </Button>,
                <Button type='ghost' onClick={() => setIsDeleteModalVisible(false)} key='2'>
                  Cancel
                </Button>
              ]}
            >
              <p>Confirm that you want to delete '{currentRecord.Email}'.</p>
            </Modal>
            <div>
              <Breadcrumb />
              <SettingsNavigation />
              <div id='usersUIPage'>
                {alertText.length > 0 && (
                  <Alert message={alertText} type={isSuccess ? 'success' : 'error'} closable showIcon className='mb-2' />
                )}
                <div className='mb-3 grid grid-cols-2 sm:grid-cols-5 gap-2 sm:gap-8'>
                  <Button
                    id='addNewUserButton'
                    type='primary'
                    className='text-sm sm:text-md col-span-1'
                    onClick={() => showModalDialogForAdd()}
                    disabled={showProgress}
                  >
                    New User
                  </Button>
                  <Input
                    placeholder='Filter by user email'
                    className='col-span-1 sm:col-span-4'
                    type='Input.TextArea'
                    id='search'
                    disabled={showProgress}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === 'Tab') findUser()
                    }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
                {showProgress
                  ? (
                    <div id='infoMessage'>
                      <Spin>
                        <Alert className='sm:w-96 mx-auto' message='Please wait' description={infoMessage} type='info' />
                      </Spin>
                    </div>
                    )
                  : (
                      allUsers.length > 0 && (
                        <Table
                          className='shadow-sm overflow-x-scroll sm:overflow-hidden'
                          columns={columns}
                          loading={showProgress}
                          dataSource={allUsers}
                          size='small'
                          pagination={false}
                        />
                      )
                    )}
              </div>
            </div>
          </>
          )
        : (
          <Alert message='Error' description='Unauthorized Access' showIcon type='warning' className='mt-3' />
          )}
    </>
  )
}

export default UserSettings
