import React, { useState, useRef } from 'react'
import { createAccount } from '../../store/services/adminServiceClient.js'
import { Auth } from 'aws-amplify'
import { classNames } from '../../utils/styles.jsx'
import { Input, Button, Divider } from 'antd'
import emailValidator from '../../utils/email-validator.js'
import appRoutes from '../../statics/appRoutes.jsx'

const Cfsignup = () => {
  const [pwError, setPwError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [showProgress, setShowProgress] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [inputType, setInputType] = useState('password')
  const [fullName, setFullName] = useState('')
  const [jobTitle, setjobTitle] = useState('')
  const [email, setEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const loadingRef = useRef(null)
  const marketingWebsiteDownloadPageURL = 'https://www.usemango.co.uk/thank-you'

  const validateAndAdd = async () => {
    clearErrorFields()
    if (!email) {
      setEmailError('Business email cannot be empty.')
      return
    } else if (!(await emailValidator.isValid(email))) {
      setEmailError('Please enter a valid email address.')
      return
    } else if (!emailValidator.isBuisnessEmail(email)) {
      setEmailError('Please enter your business email address.')
      return
    } else if (password.length < 16) {
      setPwError('Password must be greater than 16 characters')
      return
    }
    await addAccount()
  }

  const clearErrorFields = () => {
    setPwError('')
    setEmailError('')
    setAlertText('')
  }

  const addAccount = async () => {
    try {
      await signup(email, password)
      await createAccount({
        Name: fullName,
        Email: email,
        CompanyName: companyName,
        PhoneNumber: phoneNumber,
        JobTitle: jobTitle
      })
      const user = await Auth.signIn(email, password)
      await Auth.verifyUserAttribute(user, 'email') // doesn't work if we don't Auth.signIn first
      window.top.location.href = marketingWebsiteDownloadPageURL
    } catch (e) {
      if (e.message != null) {
        // Cognito Error
        showOnFailed(e.message)
      } else {
        // admin service error or any other error
        showOnFailed(GetErrorMessage(e.response))
      }
    }
  }

  const signup = async (username, password) => {
    return await Auth.signUp({
      username,
      password,
      attributes: {
        email: username,
        'custom:role': 'Administrator'
      }
    })
  }

  const GetErrorMessage = (response) => {
    let errorMsg = 'Failed to create account.'
    if (response != null && response.data != null && typeof response.data === 'string') {
      errorMsg = response.data.replace('Unhandled server error: ', '')
    }
    return errorMsg
  }

  const showOnFailed = (errorMsg) => {
    errorMsg = errorMsg + ' Please try again.'
    showAlertMessage(errorMsg, false)
    console.log(`Failed to create account due to the following error: ${errorMsg}`)
  }

  const showAlertMessage = (message) => {
    setAlertText(message)
    setShowProgress(false)
  }

  const showHidePassword = () => {
    if (inputType === 'text') {
      setInputType('password')
    } else {
      setInputType('text')
    }
  }

  const handleSubmit = async () => {
    if (loadingRef?.current === true) return
    loadingRef.current = true
    setShowProgress(true)
    await validateAndAdd()
    setShowProgress(false)
    loadingRef.current = false
  }

  return (
    <div className='absolute left-0 w-96'>
      <div className='p-4' key='signup'>
        <form
          onSubmit={async (e) => {
            e.preventDefault()
            handleSubmit()
          }}
        >
          <div className='my-3'>
            <Input
              id='Name'
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder='Full name'
              disabled={showProgress}
            />
          </div>
          <div className='my-3'>
            <Input
              id='JobTitle'
              value={jobTitle}
              onChange={(e) => setjobTitle(e.target.value)}
              placeholder='Job Title'
              disabled={showProgress}
            />
          </div>
          <div className='my-3'>
            <Input
              id='PhoneNumber'
              type='number'
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder='Mobile Phone'
              disabled={showProgress}
            />
          </div>
          <div className='my-3'>
            <Input
              id='CompanyName'
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder='Company'
              disabled={showProgress}
            />
          </div>
          <div className='my-3'>
            <Input
              id='Email'
              type='email'
              required
              placeholder='Business Email'
              value={email}
              onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
              disabled={showProgress}
            />
            <p className='p-1 text-xs text-gray-600'>No Gmail, Hotmail or other Personal Email Addresses please.</p>
            <span id='EmailHelpText' className='text-red-500'>
              {emailError}
            </span>
          </div>
          <div className='my-3'>
            <Input
              id='Input-pwd'
              required
              placeholder='Enter password'
              type={inputType}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              suffix={
                <i
                  onClick={() => showHidePassword()}
                  className={classNames(inputType === 'text' ? 'fa-eye' : 'fa-eye-slash', 'fa')}
                />
              }
              disabled={showProgress}
            />
            <span id='PasswordHelpText' className='text-red-500'>
              {pwError}
            </span>
          </div>
          {alertText.length > 0 && (
            <p id='signupAlert' className='break-words mt-2 mb-0 text-red-500'>
              {alertText}
            </p>
          )}
          <Divider />
          <Button
            type='primary'
            className='w-full mb-2'
            htmlType='submit'
            disabled={showProgress}
            onClick={handleSubmit}
            loading={showProgress}
          >
            Click here to signup
          </Button>
          <label className='text-center text-xs'>
            By signing up, you agree to useMango's{' '}
            <a href='https://www.infuse.it/privacy-policy/' target='_blank'>
              terms and conditions
            </a>{' '}
            and
            <a href='https://www.infuse.it/privacy-policy/' target='_blank'>
              {' '}
              privacy policy.
            </a>
          </label>
        </form>
      </div>
    </div>
  )
}

export default Cfsignup
