import { Alert } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import appRoutes from '../statics/appRoutes'

const AccountErrorAlert = (props) => {
  const errorMessage = useSelector((state) => state.accountError)

  useEffect(() => {
    if (!errorMessage) props.history.push(appRoutes.homePage)
  }, [errorMessage])

  return (
    <Alert
      className='my-4'
      message={`Account ${errorMessage?.includes('validity ended') ? 'Expired' : 'Error'}`}
      description={<span>{errorMessage}</span>}
      type='error'
      showIcon
    />
  )
}
export default AccountErrorAlert
