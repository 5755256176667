import axiosRetry from 'axios-retry'

const ANALYSIS_SERVICE_RETRY_COUNT_FOR_LAMBDA_WARMUP = 1

const retryRequest = (client) => {
  axiosRetry(client, {
    retries: ANALYSIS_SERVICE_RETRY_COUNT_FOR_LAMBDA_WARMUP,
    retryCondition: (error) => error.message === 'Network Error'
  })
}

export default retryRequest
