import React, { useState } from 'react'
import { Button, Input } from 'antd'

const ProjectFilters = ({ projectFilters, setProjectFilters, setFiltersUpdated }) => {
  const [searchText, setSearchText] = useState(projectFilters.projectName)

  const updateProjectFilters = () => {
    setProjectFilters({ projectName: searchText })
    setFiltersUpdated(true)
  }

  return (
    <div className='flex items-center justify-start gap-x-4'>
      <div className='flex items-center gap-x-2'>
        <Input
          placeholder='Filter by project name'
          className='rounded-sm projectNameFilter w-full'
          suffix={
            <Button
              size='small'
              type='primary'
              icon={<i className='fa fa-search align-middle' style={{ fontSize: '10px' }} />}
              onClick={updateProjectFilters}
            />
          }
          style={{ fontSize: '12px', width: 270, paddingRight: 0 }}
          size='small'
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'Tab') {
              updateProjectFilters()
            }
          }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
    </div>
  )
}

export default ProjectFilters
