import React from 'react'
import Breadcrumb from '../../components/Navigation/Breadcrumb'
import ProjectNavigation from '../../components/Navigation/ProjectNavigation'
import { Select } from 'antd'
import ExecutionHistory from './ExecutionHistory'
import ExecutionTime from './ExecutionTime'
import './ProjectReport.css'
import Report2 from './Report2'
import { useSelector, useDispatch } from 'react-redux'
import { updateProjectProperties } from '../../store/actions'

const { Option } = Select

const reports = {
  executionHistory: {
    name: 'Execution History',
    description: 'Execution history time series'
  },
  executionTime: {
    name: 'Execution Time',
    description: 'Execution time series'
  }
}

const ProjectReport = (props) => {
  const dispatch = useDispatch()
  const { reportType } = useSelector((state) => state.selectedProject)

  return (
    <>
      <div className='mt-3'>
        <Breadcrumb />
        <ProjectNavigation />
        <div id='projectReport' className='rounded p-0'>
          <div className='grid grid-cols-9 mb-4 '>
            <div className='col-span-9 lg:col-span-3 flex items-center mb-2 lg:mb-0'>
              <span className='font-semibold mr-2'>Select Report : </span>
              <Select
                defaultValue={reportType}
                value={reportType}
                showSearch
                style={{ width: 170 }}
                placeholder='Select Report'
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => dispatch(updateProjectProperties({ reportType: value }))}
              >
                {Object.entries(reports).map((report) => (
                  <Option value={report[0]}>{report[1].name}</Option>
                ))}
              </Select>
            </div>
            <div className='col-span-9 lg:col-span-6 flex items-center'>
              <span className='font-semibold'>
                <i>Description :</i>
                <span className='font-medium ml-2' style={{ fontSize: '14px' }}>
                  {reports[reportType]?.description}
                </span>
              </span>
            </div>
          </div>
          {reportType === 'executionHistory' && <ExecutionHistory selectedProject={props.match.params.project} />}
          {reportType === 'executionTime' && <ExecutionTime selectedProject={props.match.params.project} />}
          {reportType === 'report_2' && <Report2 />}
        </div>
      </div>
    </>
  )
}
export default ProjectReport
