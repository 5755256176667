import React, { useState, useImperativeHandle } from 'react'
import { Alert as AntAlert } from 'antd'

const Alert = ({ showCloseButton }, ref) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [alertText, setAlertText] = useState('')
  useImperativeHandle(ref, () => ({
    hideAlert () {
      setAlertText('')
    },
    showSuccess (message) {
      setIsSuccess(true)
      setAlertText(message)
    },
    showServerError (message, exception) {
      setIsSuccess(false)
      if (!exception) {
        if (!message) {
          setAlertText('')
        } else {
          setAlertText(message)
        }
      } else {
        setAlertText(`${message} ${getServerErrorMessage(exception)}`)
      }
    }
  }))
  const getServerErrorMessage = (exception) => {
    const response = exception.response
    if (!response) {
      return exception.message
    } else if (!response.data) {
      return `Request failed with status: ${!response.statusText ? response.status : response.statusText}.`
    } else if (typeof response.data === 'object') {
      return getModelStateErrors(response.data)
    } else if (typeof response.data === 'string') {
      return response.data
    } else {
      console.log(`Unable to resolve error message from the server response: ${response}`)
      return exception.message
    }
  }
  const getModelStateErrors = (modelState) => {
    const errors = []
    for (const value of Object.values(modelState)) {
      errors.push(value)
    }
    return errors.toString()
  }
  return (
    <>
      {alertText.length > 0 && (
        <AntAlert
          message={isSuccess ? 'Success' : 'Error'}
          description={alertText}
          type={isSuccess ? 'success' : 'error'}
          showIcon
          closable={showCloseButton}
          afterClose={() => setAlertText('')}
        />
      )}
    </>
  )
}

export default React.forwardRef(Alert)
