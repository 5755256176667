import { useEffect } from 'react'
import './App.css'
import Header from './components/Header/Header'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import appRoutes from './statics/appRoutes'
import Login from './pages/Login/Login'
import { getAccount, login, logout } from './store/actions'
import Signup from './pages/Signup/Signup'
import TrialSetup from './pages/TrialSetup/TrialSetup'
import { useDispatch, useSelector } from 'react-redux'
import Footer from './components/Footer'
import Projects from './pages/Projects/Projects'
import Executions from './pages/Executions/Executions'
import Tests from './pages/Tests/Tests'
import Project from './pages/Project/Project'
import Account from './pages/Account/Account'
import ProjectSettings from './pages/Settings/ProjectSettings'
import UserSettings from './pages/Settings/UserSettings'
import Cfsignup from './pages/Cfsignup/Cfsignup'
import Test from './pages/Test/Test'
import Report from './pages/Report/Report'
import ProjectReport from './pages/ProjectReport/ProjectReport'
import DetailReport from './pages/Report/DetailReport'
import SummaryReport from './pages/Report/SummaryReport'
import VerifyEmail from './pages/VerifyEmail/VerifyEmail'
import Profile from './pages/Profile/Profile'
import AccountErrorAlert from './components/AccountErrorAlert'
import Administration from './pages/Settings/Administration'

const App = (props) => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const isAdmin = localStorage.getItem('Role') === 'Administrator'

  useEffect(() => {
    dispatch(login())
  }, [dispatch])

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(
        getAccount({
          type: 'checkIfAccountExpired',
          callback: () => {
            dispatch(logout())
            props.history.push(appRoutes.unauthorized)
          }
        })
      )
    }
  }, [isLoggedIn])

  return (
    <div className='App'>
      {!window.location.pathname.includes(appRoutes.cfsignupForm) && <Header />}
      <div className='mx-5'>
        <Switch>
          <Route exact path={appRoutes.loginPage} component={Login} />
          <Route
            exact
            path={appRoutes.signupPage}
            render={() => (isLoggedIn ? <Redirect to={appRoutes.homePage} /> : <Signup />)}
          />
          <Route exact path={appRoutes.homePage} component={Projects} />
          <Route exact path={appRoutes.executionsPage} component={Executions} />
          <Route exact path={appRoutes.testsPage} component={Tests} />
          <Route exact path={appRoutes.projectPage} component={Project} />
          <Route exact path={appRoutes.accountPage} component={Account} />
          <Route exact path={appRoutes.profilePage} component={Profile} />
          <Route exact path={appRoutes.verifyEmail} component={VerifyEmail} />
          <Route exact path={appRoutes.projectSettingsPage} component={ProjectSettings} />
          <Route exact path={appRoutes.userSettingsPage} component={UserSettings} />
          <Route exact path={appRoutes.cfsignupForm} component={Cfsignup} />
          <Route exact path={appRoutes.testPage} component={Test} />
          <Route exact path={appRoutes.testScenariosPage} component={Test} />
          <Route exact path={appRoutes.testExecutionsPage} component={Test} />
          <Route exact path={appRoutes.reportPage} component={Report} />
          <Route exact path={appRoutes.trialSetup} component={TrialSetup} />
          <Route exact path={appRoutes.reportPageWithoutProject} component={Report} />
          <Route exact path={appRoutes.projectReport} component={ProjectReport} />
          <Route exact path={appRoutes.detailReport} component={DetailReport} />
          <Route exact path={appRoutes.summaryReport} component={SummaryReport} />
          <Route exact path={appRoutes.unauthorized} component={AccountErrorAlert} />
          <Route
            exact
            path={appRoutes.administrationPage}
            render={() => (isAdmin ? <Administration /> : <Redirect to={appRoutes.homePage} />)}
          />
          <Redirect to={getRedirectUrl()} />
        </Switch>
      </div>

      {!window.location.pathname.includes(appRoutes.cfsignupForm) && <Footer />}
    </div>
  )
}

const getRedirectUrl = () => {
  let path = window.location.pathname
  const queryString = window.location.search
  if (path.includes('.html')) {
    path = path.split('.html')[0]
    const params = new URLSearchParams(queryString.substring(1))
    if (params.has('projectId') && params.has('testId') && path === '/test') {
      return `/p/${params.get('projectId')}/tests/${params.get('testId')}/datasets`
    } else if (params.has('runId') && path === '/report') {
      return `/execution/${params.get('runId')}`
    } else if (!checkInAppRoutes(path)) {
      return '/'
    } else {
      return path + queryString
    }
  }
  return '/'
}
const checkInAppRoutes = (pathName) => {
  return Object.keys(appRoutes).some((key) => appRoutes[key].toLowerCase() === pathName.toLowerCase())
}
export default withRouter(App)
