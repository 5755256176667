import React from 'react'
import EntityTable from './EntityTable'
import { Button, Spin } from 'antd'
import { ADMINISTRATION_PAGE_UI_STATES, ENTITY } from '../../../statics/constants'
import { ArrowRightOutlined } from '@ant-design/icons'
import ActionSummary from './ActionSummary'
import ActionResult from './ActionResult'
import CheckboxWithTooltip from '../../../components/CheckboxWithTooltip'

const EntityAdministration = ({
  action,
  entity,
  entities,
  tableColumns,
  showTableLoader,
  showInfiniteScrollLoader,
  hasMore,
  sourceProjectId,
  destinationProjectId,
  loadMore,
  currentUIState,
  setCurrentUIState,
  sourceProjectName,
  destinationProjectName,
  actionOnSelectedEntity,
  resetAdministrationPage,
  isActionOnEntityInitiated,
  selectedTableEntities,
  setSelectedTableEntities,
  reuseUnchangedArtefactsWhileCloning,
  setReuseUnchangedArtefactsWhileCloning,
  customSummaryMessage,
  customFailActionMessage,
  customSuccessActionMessage
}) => {
  const displayAction = action === 'Clone' ? 'Cloning' : action

  const isNextButtonDisabled = () => {
    if (entity !== ENTITY.PROJECT) {
      return !sourceProjectId || !destinationProjectId || selectedTableEntities.length === 0
    } else {
      return selectedTableEntities.length === 0
    }
  }

  return (
    <>
      {currentUIState === ADMINISTRATION_PAGE_UI_STATES.INITIAL && (
        <>
          <EntityTable
            entity={entity}
            entities={entities}
            tableColumns={tableColumns}
            loadMore={loadMore}
            hasMore={hasMore}
            selectedTableEntities={selectedTableEntities}
            setSelectedTableEntities={setSelectedTableEntities}
            showTableLoader={showTableLoader}
            showInfiniteScrollLoader={showInfiniteScrollLoader}
            sourceProjectId={sourceProjectId}
          />
          {entity !== ENTITY.PROJECT && (
            <CheckboxWithTooltip
              isChecked={reuseUnchangedArtefactsWhileCloning}
              isDisabled={false}
              onChange={setReuseUnchangedArtefactsWhileCloning}
              label='Reuse unchanged artefacts in destination project'
              tooltipTitle={`Project artefacts include scripted, scanned, composite components, variables used in the ${entity.toLowerCase()}.`}
            />
          )}
          <div className='flex items-center gap-x-2 w-full justify-end'>
            <Button
              className='w-min my-6'
              type='primary'
              onClick={() => setCurrentUIState(ADMINISTRATION_PAGE_UI_STATES.SUMMARY)}
              disabled={isNextButtonDisabled()}
            >
              Next <ArrowRightOutlined />
            </Button>
          </div>
        </>
      )}

      {currentUIState === ADMINISTRATION_PAGE_UI_STATES.SUMMARY && (
        <Spin tip={`${displayAction} ${entity}(s)...`} spinning={isActionOnEntityInitiated}>
          <ActionSummary
            action={action}
            entity={entity}
            sourceProjectName={sourceProjectName}
            destinationProjectName={destinationProjectName}
            selectedEntities={selectedTableEntities}
            setSelectedEntities={setSelectedTableEntities}
            actionOnSelectedEntity={actionOnSelectedEntity}
            setCurrentUIState={setCurrentUIState}
            reuseUnchangedArtefactsWhileCloning={reuseUnchangedArtefactsWhileCloning}
            customSummaryMessage={customSummaryMessage}
          />
        </Spin>
      )}

      {(currentUIState === ADMINISTRATION_PAGE_UI_STATES.SUCCESS ||
        currentUIState === ADMINISTRATION_PAGE_UI_STATES.FAIL) && (
        <ActionResult
          type={currentUIState === ADMINISTRATION_PAGE_UI_STATES.SUCCESS ? 'success' : 'fail'}
          action={action}
          entity={entity}
          destinationProjectName={destinationProjectName}
          sourceProjectName={sourceProjectName}
          resetAdministrationPage={resetAdministrationPage}
          setCurrentUIState={currentUIState === ADMINISTRATION_PAGE_UI_STATES.FAIL && setCurrentUIState}
          customFailActionMessage={customFailActionMessage}
          customSuccessActionMessage={customSuccessActionMessage}
        />
      )}
    </>
  )
}

export default EntityAdministration
