import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import appRoutes from '../../statics/appRoutes'
import { classNames } from '../../utils/styles'

const ProjectNavigation = (props) => {
  const [selectedTab, setSelectedTab] = useState(appRoutes.projectPage)

  useEffect(() => {
    setSelectedTab(props.match.path)
  }, [props.match.path])

  return (
    <div className='flex justify-start mb-4 h-12 shadow-sm rounded bg-white'>
      <p
        onClick={() => props.history.push(`/p/${props.match.params.project}`)}
        className={classNames(selectedTab === appRoutes.projectPage && 'selectedTab', 'tab w-26')}
      >
        Overview
      </p>
      <p
        onClick={() => props.history.push(`/p/${props.match.params.project}/tests`)}
        className={classNames(selectedTab === appRoutes.testsPage && 'selectedTab', 'tab w-26')}
      >
        Tests
      </p>
      <p
        onClick={() => props.history.push(`/p/${props.match.params.project}/executions`)}
        className={classNames(selectedTab === appRoutes.executionsPage && 'selectedTab', 'tab w-26')}
      >
        Executions
      </p>
      <p
        onClick={() => props.history.push(`/p/${props.match.params.project}/reports`)}
        className={classNames(selectedTab === appRoutes.projectReport && 'selectedTab', 'tab w-26')}
      >
        Reports
      </p>
    </div>
  )
}

export default withRouter(ProjectNavigation)
