import React, { useState, useEffect } from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Card, Empty, Skeleton } from 'antd'
import { colorForTestStatus } from '../../../utils/colors'
import { withRouter } from 'react-router-dom'
import amplify from '../../../store/services/amplify'

const TestsByStatusPie = ({ loading, testsByStatus, title, emptyDiscription, ...props }) => {
  const [username, setUsername] = useState()

  const getUsername = async () => {
    const username = await amplify.getUserName()
    setUsername(username)
  }

  useEffect(() => {
    getUsername()
  }, [])

  return (
    <Card
      className='shadow-sm rounded-sm col-span-1 relative w-full overflow-hidden'
      style={{ height: 'fit-content' }}
      size='small'
      title={<strong className='text-xs'>{title}</strong>}
      bodyStyle={{ paddingTop: 0, paddingLeft: 10, paddingRight: 10, height: 200 }}
    >
      {loading ? (
        <center>
          <Skeleton.Image className='my-2' style={{ width: 150, height: 150 }} />
        </center>
      ) : testsByStatus?.length > 0 ? (
        <div className='absolute w-full h-full'>
          <ResponsivePie
            arcLinkLabelsDiagonalLength={5}
            arcLinkLabelsStraightLength={0}
            data={testsByStatus.map(
              (obj) =>
                (obj = {
                  ...obj,
                  id: obj.status,
                  label: obj.status,
                  value: obj.count,
                  [`${obj.status}Color`]:
                    obj.status === 'Design'
                      ? colorForTestStatus.design
                      : obj.status === 'Review'
                        ? colorForTestStatus.review
                        : obj.status === 'Revalidate'
                          ? colorForTestStatus.revalidate
                          : obj.status === 'Ready'
                            ? colorForTestStatus.ready
                            : obj.status === 'Repair' && colorForTestStatus.repair
                })
            )}
            colors={({ id, data }) => String(data[`${id}Color`])}
            margin={{ top: 10, right: 60, bottom: 47, left: 60 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={5}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor='#333333'
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
            onClick={({ id }) => {
              title.includes('My Recent Tests')
                ? props.history.push(`/p/${props.match.params.project}/tests?assignee=${username}&status=${id}`)
                : props.history.push(`/p/${props.match.params.project}/tests?status=${id}`)
            }}
            onMouseEnter={(node, event) => {
              event.target.style.cursor = 'pointer'
            }}
          />
        </div>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<b>{emptyDiscription}</b>} />
      )}
    </Card>
  )
}

export default withRouter(TestsByStatusPie)
