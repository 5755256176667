import React, { useState } from 'react'
import { classNames } from '../../utils/styles'
import { Auth } from 'aws-amplify'
import appRoutes from '../../statics/appRoutes'
import { createAccount } from '../../store/services/adminServiceClient'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Input, Button, notification } from 'antd'
import USEMANGO_LOGO from '../../assets/usemango_logo.png'
import { getAccount } from '../../store/actions'
import emailValidator from '../../utils/email-validator.js'
import { signupTestEmailAddress } from '../../statics/constants'

const Signup = (props) => {
  const dispatch = useDispatch()
  const [showProgress, setShowProgress] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [inputType, setInputType] = useState('password')

  const validateAndAdd = async () => {
    setShowProgress(true)
    if (!(await emailValidator.isValid(email))) {
      showAlertMessage('Please enter a valid email address.')
      return
    } else if (!emailValidator.isBuisnessEmail(email)) {
      showAlertMessage('Please enter your business email address.')
      return
    } else if (password.length < 16) {
      showAlertMessage('Password must be greater than 16 characters')
      return
    }
    await addAccount()
  }

  const sendAlertMessage = (error) => {
    notification.error({
      message: 'Validation error',
      description: error,
      placement: 'topRight'
    })
  }

  const addAccount = async () => {
    try {
      await signup(email, password)
      await createAccount({ Email: email })
      notification.success({ message: 'Signed up successfully', placement: 'topRight' })
      const user = await Auth.signIn(email, password)
      await Auth.verifyUserAttribute(user, 'email')
      email !== signupTestEmailAddress ? dispatch(getAccount('signup')) : props.history.push(appRoutes.trialSetup)
    } catch (e) {
      if (e.message != null) {
        // Cognito Error
        showOnFailed(e.message)
      } else {
        // admin service error or any other error
        showOnFailed(GetErrorMessage(e.response))
      }
    }
  }

  const signup = async (username, password) => {
    return await Auth.signUp({
      username,
      password,
      attributes: {
        email: username,
        'custom:role': 'Administrator'
      }
    })
  }

  const GetErrorMessage = (response) => {
    let errorMsg = 'Failed to create account.'
    if (response != null && response.data != null && typeof response.data === 'string') {
      errorMsg = response.data.replace('Unhandled server error: ', '')
    }
    return errorMsg
  }

  const showOnFailed = (errorMsg) => {
    errorMsg = errorMsg + ' Please try again.'
    showAlertMessage(errorMsg)
    console.log(`Failed to create account due to the following error: ${errorMsg}`)
  }

  const showAlertMessage = (message) => {
    sendAlertMessage(message)
    setShowProgress(false)
  }

  const showHidePassword = () => {
    if (inputType === 'text') {
      setInputType('password')
    } else {
      setInputType('text')
    }
  }

  return (
    <div className='my-12 flex justify-center items-center'>
      <div className='lg:w-96 xl:w-2/5 p-4 shadow-sm' key='signup'>
        <h1 className='mb-4'>
          Try <img src={USEMANGO_LOGO} alt='' width={170} />
        </h1>
        <p className='font-bold text-gray-500'>Automated application testing across the web and desktop</p>
        <p>Register for a free 30 day trial.</p>
        <label for='Email'>Email Address</label>
        <Input
          id='Email'
          required
          placeholder='example@email.com'
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          value={email}
          disabled={showProgress}
          className='my-2'
        />
        <label for='Input-pwd'>Password</label>
        <Input
          id='Input-pwd'
          required
          placeholder='Enter password'
          type={inputType}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          suffix={
            <i
              onClick={() => showHidePassword()}
              className={classNames(inputType === 'text' ? 'fa-eye' : 'fa-eye-slash', 'fa')}
            />
          }
          className='my-2'
          onKeyDown={(e) => {
            if (e.key.toLowerCase() === 'enter') validateAndAdd()
          }}
        />
        <Button
          loading={showProgress}
          onClick={() => validateAndAdd()}
          className='float-right mt-3'
          type='primary'
          disabled={showProgress || !email || !password}
        >
          Submit
        </Button>
      </div>
    </div>
  )
}

export default withRouter(Signup)
