import { Button, Card, Empty, List } from 'antd'
import React from 'react'
import { CloseOutlined, ArrowLeftOutlined, InfoCircleFilled } from '@ant-design/icons'
import { ADMINISTRATION_PAGE_UI_STATES, ENTITY } from '../../../statics/constants'
import CheckboxWithTooltip from '../../../components/CheckboxWithTooltip'

const ActionSummary = ({
  action,
  sourceProjectName,
  destinationProjectName,
  selectedEntities,
  setSelectedEntities,
  entity,
  setCurrentUIState,
  actionOnSelectedEntity,
  reuseUnchangedArtefactsWhileCloning,
  customSummaryMessage
}) => {
  const displayAction = action === 'Clone' ? 'cloning' : action

  return (
    <>
      <Card
        className='shadow-sm col-span-1'
        title='Summary'
        headStyle={{ backgroundColor: 'var(--transparent-color)', fontWeight: 'bold' }}
      >
        {customSummaryMessage ?? (
          <p className='italic text-gray-500'>
            You are {displayAction} the following {entity}(s) from{' '}
            <span className='font-bold'>{sourceProjectName}</span> to
            <span className='font-bold'> {destinationProjectName}</span>
          </p>
        )}
        <List
          size='small'
          itemLayout='horizontal'
          dataSource={selectedEntities}
          style={{ overflow: 'auto', height: '37vh' }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <b>
                    No {entity} to {action.toLowerCase()}.
                  </b>
                }
              />
            )
          }}
          renderItem={(entity) => (
            <List.Item
              actions={[
                <Button
                  type='text'
                  icon={<CloseOutlined />}
                  size='small'
                  danger
                  onClick={() => setSelectedEntities(selectedEntities?.filter((item) => item.Id !== entity.Id))}
                />
              ]}
            >
              <List.Item.Meta title={<span>{entity.Name}</span>} />
            </List.Item>
          )}
        />
        {entity !== ENTITY.PROJECT && (
          <CheckboxWithTooltip
            isChecked={reuseUnchangedArtefactsWhileCloning}
            isDisabled={true}
            label='Reuse unchanged artefacts in destination project'
            tooltipTitle={`Project artefacts include scripted, scanned, composite components, variables used in the ${entity.toLowerCase()}.`}
          />
        )}
      </Card>
      <div className='flex items-center gap-x-6 w-full justify-end'>
        <Button
          className='w-min my-6'
          type='primary'
          onClick={() => setCurrentUIState(ADMINISTRATION_PAGE_UI_STATES.INITIAL)}
        >
          <ArrowLeftOutlined /> Back
        </Button>
        <Button
          className='w-min my-6'
          type='primary'
          onClick={() => actionOnSelectedEntity()}
          disabled={selectedEntities.length === 0}
        >
          Submit
        </Button>
      </div>
    </>
  )
}

export default ActionSummary
