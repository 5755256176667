import React, { useEffect, useState, useCallback, useRef } from 'react'
import { getReport } from '../../store/services/reportServiceClient.js'
import { formatDate, getTodaysDate, toApproximateDuration } from '../../utils/dateTime'
import DetailReportNode from './components/DetailReportNode.jsx'
import { Alert, Divider, notification, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import mango from '../../../src/assets/footerLogo.png'
import { getDetailedExecutionReportPdf } from '../../store/services/printReportClient'
import { getTestResultColor, scrollToSection, transformEnvVariables } from '../../utils/util.js'
import VariableDisplay from './components/VariableDisplay.jsx'
import { useTransformEnvVariables } from '../../custom-hooks/useTransformEnvVariables.jsx'

const DetailReport = (props) => {
  const [notificationApi, contextHolder] = notification.useNotification()
  const key = 'downloading-execution-report'

  const [ErrorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [run, setRun] = useState('')
  const { transformedEnvVariables, transformingEnvVariable } = useTransformEnvVariables(run)

  const envSectionRef = useRef()
  const datasetSectionRef = useRef()

  const loadReport = useCallback(async () => {
    try {
      const response = await getReport(props.match.params.runId)
      if (response.status === 200) {
        setRun(response.data)
      } else {
        setErrorMessage('Error loading report')
      }
    } catch (e) {
      if (e.response) {
        switch (e.response.status) {
          case 404:
            setErrorMessage(e.response.data.detail)
            break
          case 500:
            setErrorMessage(e.response.data)
            break
          default:
            setErrorMessage(e.message)
            break
        }
      }
    } finally {
      setIsLoading(false)
    }
  }, [props.match.params.runId])

  useEffect(() => {
    loadReport()
    downloadPdf()
  }, [loadReport])

  const downloadPdf = async () => {
    notificationApi.open({
      message: 'Downloading Report',
      key,
      icon: <LoadingOutlined style={{ color: '#108ee9' }} />,
      duration: 0
    })
    try {
      const response = await getDetailedExecutionReportPdf(props.match.params.runId)
      const attachmentUrl = await response.data
      const element = document.createElement('a')
      element.href = attachmentUrl
      element.setAttribute('download', `DetailedReport_${getTodaysDate()}.pdf`)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      notificationApi.success({
        key,
        message: 'Downloaded Successfully!'
      })
    } catch (e) {
      notificationApi.error({
        key,
        message: e.message || 'Some error occured'
      })
    }
  }

  const scrollToEnvSection = () => scrollToSection(envSectionRef)

  const scrollToDatasetSection = () => scrollToSection(datasetSectionRef)

  return (
    <div className='mt-4 overflow-x-auto'>
      {contextHolder}
      {ErrorMessage && (
        <Alert
          showIcon
          closable
          type='error'
          description={ErrorMessage}
          message='Error'
          afterClose={() => setErrorMessage('')}
        />
      )}
      {isLoading && (
        <div className='flex justify-center items-start'>
          <Spin>
            <Alert
              className='sm:w-96'
              message='Please wait'
              description='Your detailed report is loading...'
              type='info'
            />
          </Spin>
        </div>
      )}
      {!isLoading && ErrorMessage.length === 0 && (
        <div id='detailReport' style={{ width: '1000px' }} className='mx-auto'>
          <div className='m-0 p-0'>
            <span className='mr-3 text-lg font-bold'>Test Name</span>
            <span className='text-lg ml-4 font-bold text-blue-500 italic'>{run.TestName}</span> <br />
            <span className='text-lg mr-16 font-bold'>Result</span>
            <span className='text-lg font-bold' style={{ color: getTestResultColor(run.Result) }}>
              {run.Result}
            </span>
          </div>
          <div className='grid grid-cols-7 gap-x-7 mt-4'>
            <div className='col-span-2'>
              <div className='flex justify-between items-baseline mt-1 gap-x-3'>
                <span className='text-md font-bold'>Started At</span>
                <span className='text-md'>
                  {formatDate(run.StartTime, true, {
                    weekday: 'short',
                    second: '2-digit'
                  })}
                </span>
              </div>
              <div className='flex justify-between items-baseline mt-1 gap-x-3'>
                <span className='text-md font-bold'>Duration</span>
                <span className='text-md'>{toApproximateDuration(run.Duration)}</span>
              </div>
            </div>
            <div className='col-span-2'>
              <div className='flex justify-between items-baseline mt-1 gap-x-3 '>
                <span className='text-md font-bold'>Dataset</span>
                <span
                  className={`text-md cursor-pointer`}
                  title={run.Scenario && 'Click to scroll to the Dataset Parameters section'}
                  onClick={run.Scenario && scrollToDatasetSection}
                >
                  {run.Scenario} {run.Scenario && <i className='fa fa-link fa-sm text-blue-500 ml-1' />}
                </span>
              </div>
              <div className='flex justify-between items-baseline mt-1 gap-x-3 '>
                <span className='text-md font-bold'>Environment</span>
                <span
                  className={`text-md ${!run?.TestEnvironment?.Name ? 'italic text-gray-400' : 'cursor-pointer'}`}
                  title={run?.TestEnvironment?.Name && 'Click to scroll to the Environment Variables section'}
                  onClick={run?.TestEnvironment?.Name && scrollToEnvSection}
                >
                  {run?.TestEnvironment?.Name ? (
                    <>
                      {run.TestEnvironment.Name}
                      <i className='fa fa-link fa-sm text-blue-500 ml-1' />{' '}
                    </>
                  ) : (
                    'Not Specified'
                  )}
                </span>
              </div>
            </div>

            <ul className='col-span-3 pl-0'>
              <li className='flex justify-between items-baseline '>
                <span className='text-md font-bold mt-1'>User</span>
                <span className='text-md'>{run.User.Name}</span>
              </li>
              <li className='flex justify-between items-baseline '>
                <span className='text-md font-bold mt-1'>Machine</span>
                <span className='text-md'>{run.Environment.Machine}</span>
              </li>
              <li className='flex justify-between items-baseline '>
                <span className='text-md font-bold mt-1'>Operating System</span>
                <span className='text-md'>{run.Environment.OSVersion}</span>
              </li>
              <li className='flex justify-between items-baseline '>
                <span className='text-md font-bold mt-1'>useMango Version</span>
                <span className='text-md'>{run.Environment.UMVersion}</span>
              </li>
            </ul>
          </div>
          <h6 className='text-xl font-bold'>Detailed Report</h6>
          <div className='flex flex-row' style={{ border: '1px solid #ddd', fontSize: '14px' }}>
            <div className='flex items-center p-1 pl-2' style={{ width: '58px', borderRight: '1px solid #ddd' }}>
              <p className='font-bold mb-0'>Step</p>
            </div>
            <div
              style={{ width: '270px', borderRight: '1px solid #ddd' }}
              className='flex items-center p-1 pl-2 font-bold'
            >
              Step &amp; Component Name
            </div>
            <div
              style={{ width: '110px', borderRight: '1px solid #ddd' }}
              className='flex items-center p-1 font-bold pl-2'
            >
              Timing
            </div>
            <div className='flex flex-col col-span-4 p-1 pb-0 font-bold' style={{ width: '560px' }}>
              Parameters
              <div className='grid grid-cols-7 py-1' style={{ borderTop: '1px solid #dddddd' }}>
                <p className='mb-0 mr-1 col-span-2 ml-5' style={{ borderRight: '1px solid #dddddd' }}>
                  Id
                </p>
                <p className='mb-0 pb-0 mx-1 col-span-2' style={{ borderRight: '1px solid #dddddd' }}>
                  Name
                </p>
                <p className='mb-0 pb-0 mx-1 col-span-3'>Value</p>
              </div>
            </div>
          </div>
          <DetailReportNode key={run.Root.NodeId} node={run.Root} runId={run.RunId} root />
          <div className='mt-8 w-full md:w-3/5' ref={datasetSectionRef}>
            <VariableDisplay title={'Dataset Parameters'} loading={false} data={run.Parameters} />
          </div>
          <div className='mt-8 w-full md:w-3/5' ref={envSectionRef}>
            <VariableDisplay
              title={'Environment Variables'}
              loading={transformingEnvVariable}
              data={transformedEnvVariables}
            />
          </div>
          <p className='text-right mt-10'>
            <img src={mango} alt='useMango Logo' />
            <i>Report generated by useMango.</i>
          </p>
        </div>
      )}
    </div>
  )
}

export default DetailReport
