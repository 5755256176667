import html2pdf from 'html2pdf.js'
import mango from '../../../../src/assets/footerLogo.png'
import { EXECUTION_TYPE_MAP } from '../../../statics/constants'

const getReportOptions = ({ filename, graphWidth }) => ({
  margin: 10,
  filename,
  image: { type: 'jpeg', quality: 1 },
  jsPDF: { unit: 'mm', orientation: 'l', format: [graphWidth > 900 ? graphWidth * 0.25 + 48 : 297, 224] },
  html2canvas: {
    dpi: 144,
    scale: 2,
    letterRendering: true
  }
})

const addGraph = (reportHtml) => {
  const clonedGraph = document.getElementById('executionReport').cloneNode(true)
  reportHtml.appendChild(clonedGraph)
}

const addLegends = (reportHtml) => {
  const clonedLegends = document.getElementById('graphLegends').cloneNode(true)
  clonedLegends.classList.add('flex', 'gap-x-2', 'flex-wrap', 'justify-center')
  clonedLegends.style.marginTop = '-1.75rem'
  reportHtml.appendChild(clonedLegends)
}

const addMetaDataHeader = (reportHtml, fromDate, toDate, timeSegment, resultFilter, statusFilter, assigneeFilter, testIdFilter, executionTypeFilter, users, tests) => {
  const headerContainer = document.createElement('div')
  headerContainer.classList.add('flex', 'mb-2', 'flex-wrap')

  addDateRangeInfo(headerContainer, fromDate, toDate, timeSegment)
  addFilterInfo(headerContainer, resultFilter, statusFilter, assigneeFilter, testIdFilter, executionTypeFilter, users, tests)

  reportHtml.appendChild(headerContainer)
}

const addDateRangeInfo = (headerContainer, fromDate, toDate, timeSegment) => {
  addMetaDataToContainer([
    {
      name: 'From',
      prop: fromDate.toDateString()
    },
    {
      name: 'To',
      prop: toDate.toDateString()
    },
    {
      name: 'Bucket',
      prop: timeSegment
    }
  ], headerContainer)
}

const addFilterInfo = (headerContainer, resultFilter, statusFilter, assigneeFilter, testIdFilter, executionTypeFilter, users, tests) => {
  addMetaDataToContainer([
    {
      name: 'Execution Type',
      prop: executionTypeFilter
    },
    {
      name: 'Results',
      prop: resultFilter
    },
    {
      name: 'Statuses',
      prop: statusFilter
    },
    {
      name: 'Assignees',
      prop: assigneeFilter,
      collection: users
    },
    {
      name: 'Test Names',
      prop: testIdFilter,
      collection: tests
    }
  ], headerContainer)
}

const addFooter = (reportHtml) => {
  const footer = document.createElement('div')
  footer.classList.add('flex', 'mt-4', 'justify-end', 'w-full')

  const endLine = document.createElement('span')
  endLine.innerHTML = 'Report generated by useMango.'

  const img = document.createElement('img')
  img.setAttribute('src', mango)

  footer.appendChild(img)
  footer.appendChild(endLine)
  reportHtml.appendChild(footer)
}

const addMetaDataToContainer = (metaData, headerContainer) => {
  metaData.forEach(({ name, prop, collection }) => {
    const isFilterArray = Array.isArray(prop)
    if (isFilterArray && !prop.length) { return }

    const container = document.createElement('div')
    container.classList.add('mr-2', 'flex', 'flex-wrap')

    const nameElement = document.createElement('span')
    nameElement.classList.toggle('font-semibold')
    nameElement.innerHTML = name

    const propertyElement = document.createElement('span')

    if (isFilterArray) {
      propertyElement.innerHTML = `: ${(name === 'Assignees' || name === 'Test Names') ? prop.map(id => collection.get(id)).join(', ') : prop.join(', ')}`
    } else {
      propertyElement.classList.add('capitalize')
      propertyElement.innerHTML = `: ${name === 'Execution Type' ? EXECUTION_TYPE_MAP[prop] : prop}`
    }

    container.appendChild(nameElement)
    container.appendChild(propertyElement)
    headerContainer.appendChild(container)
  })
}

const createReportHTMLAndOptions = (fromDate, toDate, timeSegment, resultFilter, statusFilter, assigneeFilter, testIdFilter, executionTypeFilter, users, tests) => {
  const reportHtml = document.createElement('div')
  reportHtml.classList.add('flex', 'flex-col')

  addMetaDataHeader(reportHtml, fromDate, toDate, timeSegment, resultFilter, statusFilter, assigneeFilter, testIdFilter, executionTypeFilter, users, tests)
  addGraph(reportHtml)
  addLegends(reportHtml)
  addFooter(reportHtml)

  const reportOptions = getReportOptions({
    filename: `useMango Executions Report ${fromDate.toLocaleDateString()} - ${toDate.toLocaleDateString()}`,
    graphWidth: document.getElementById('executionReport').clientWidth
  })

  return [reportHtml, reportOptions]
}

const exportReport = (exportType, fromDate, toDate, timeSegment, resultFilter, statusFilter, assigneeFilter, testIdFilter, executionTypeFilter, users = null, tests = null) => {
  const [reportHtml, reportOptions] = createReportHTMLAndOptions(fromDate, toDate, timeSegment, resultFilter, statusFilter, assigneeFilter, testIdFilter, executionTypeFilter, users, tests)

  switch (exportType) {
    case 'print': {
      html2pdf()
        .from(reportHtml)
        .set(reportOptions)
        .outputImg()
        .then((reportImgElement) => {
          const iframe = document.createElement('iframe')
          iframe.classList.add('h-0', 'invisible', 'w-0')
          iframe.setAttribute('srcdoc', '<html><body></body></html>')
          document.body.appendChild(iframe)

          iframe.addEventListener('load', () => {
            const image = reportImgElement
            image.style.maxWidth = '100%'

            const body = iframe.contentDocument.body
            body.style.textAlign = 'center'
            body.appendChild(image)

            image.addEventListener('load', () => {
              iframe.contentWindow.print()
            })
          })

          iframe.contentWindow.addEventListener('afterprint', () => {
            iframe.parentNode.removeChild(iframe)
          })
        })
      break
    }
    case 'JPG':
    case 'JPEG': {
      reportHtml.classList.add('m-8')
      html2pdf()
        .from(reportHtml)
        .set(reportOptions)
        .outputImg()
        .then((img) => {
          const link = document.createElement('a')
          link.download = `useMango Executions Report ${fromDate.toLocaleDateString()} - ${toDate.toLocaleDateString()}.jpg`
          link.href = img.src
          link.click()
        })
      break
    }
    case 'PDF': {
      html2pdf()
        .from(reportHtml)
        .set(reportOptions)
        .save()
      break
    }
    default: {}
  }
}

export { exportReport }
