import React, { useEffect, useState, useCallback } from 'react'
import { Divider, Spin, notification, Popover, Button } from 'antd'
import UMDatePicker from './components/UMDatePicker'
import TimeBucketSelector from './components/TimeBucketSelector'
import TestsSelector from '../../components/TestsSelector'
import GraphTooltipDetail from './components/GraphTooltipDetail'
import ReportPrintingControls from '../ProjectReport/components/ReportPrintingControls'
import { getExecutionDuration } from '../../store/services/analysisServiceClient'
import { ResponsiveLine } from '@nivo/line'
import { formatPeriodLabel, formatFromDateUTC, formatToDateUTC, getDayDifference } from '../../utils/dateTime'
import { useSelector, useDispatch } from 'react-redux'
import { updateProjectProperties } from '../../store/actions'
import { line } from 'd3-shape'
import { colorsForLegends } from '../../utils/colors'

const selectedTestsMap = new Map()

function ExecutionTime (props) {
  const dispatch = useDispatch()
  const [executionData, setExecutionData] = useState([])
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hoveredTest, setHoveredTest] = useState('')
  const [colorsForGraph, setColorsForGraph] = useState(new Map())
  const { fromDate, toDate, testIdFilter, timeSegment } = useSelector((state) => state.selectedProject)
  const reorderLines = (lines) => [...lines.filter(({ id }) => id !== hoveredTest), lines.find(({ id }) => id === hoveredTest)]

  const lineLayer = ({ series, xScale, yScale }) => {
    let lines = series.map(({ id, color, data }) => {
      const dataPoints = data
        .map(({ data }) => data)
        .filter(({ y }) => y !== null)

      const lineGenerator = line()
        .x((d) => xScale(d.x))
        .y((d) => yScale(d.y))

      return {
        id,
        pointPositions: data
          .map(({ position }) => position)
          .filter(({ y }) => y !== null),
        color,
        path: lineGenerator(dataPoints)
      }
    })

    if (hoveredTest) {
      lines = reorderLines(lines)
    }

    return (
      <>
        {lines.map(({ color = null, path, pointPositions }) => (
          <>
            <path
              d={path}
              stroke={color}
              strokeWidth={2}
              fill='none'
            />
            {pointPositions.map(({ x, y }) => (
              <circle
                cx={x}
                cy={y}
                r={4}
                fill='white'
                stroke={color}
                style={{ strokeWidth: 2 }}
              />
            ))}
          </>
        ))}
      </>
    )
  }

  const tooltip = ({ point }) => {
    const { data } = point
    const { meta, x, y } = data
    const DELTA = 0.03
    if (data.meta.count > 0) {
      const dataMatchesForX = executionData.map(({ data, testid }) => ({
        color: colorsForGraph.get(testid)?.color,
        coords: data.find((point) => point.x === x)
      }))
      const overlappingPoints = dataMatchesForX.filter(point =>
        point.coords.meta.count && point.coords.y < (y + DELTA) && point.coords.y > (y - DELTA)
      )
      return (
        <div
          style={{
            padding: 8,
            background: '#222222',
            borderRadius: '2px'
          }}
        >
          {
            overlappingPoints.length === 1 && (
              <div
                className='flex flex-row gap-x-2' style={{ color: point.serieColor }}
              >
                <GraphTooltipDetail meta={meta} y={y} />
              </div>)
          }
          {
            overlappingPoints.length > 1 && (
              overlappingPoints.map(overlappingPoint => (
                <div
                  className='flex flex-row gap-x-2' style={{ color: overlappingPoint.color }}
                >
                  <GraphTooltipDetail meta={overlappingPoint.coords.meta} y={overlappingPoint.coords.y} />
                </div>)
              ))
          }
        </div>
      )
    } else return <></>
  }

  const dayDifference = useCallback(() => {
    return getDayDifference(fromDate, toDate)
  }, [fromDate, toDate])

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      if (testIdFilter.length > 7) {
        return notification.warn({
          message: 'Test limit exceeded',
          description: '',
          placement: 'topRight'
        })
      }
      const response = await getExecutionDuration({
        ProjectId: props.selectedProject,
        FromDateUTC: formatFromDateUTC(fromDate),
        ToDateUTC: formatToDateUTC(toDate),
        TimeBucket: timeSegment,
        Filters: {
          TestId: testIdFilter.join(' '),
          ExecutionType: 'Atomic'
        },
        OrderBy: 'StartedAt',
        SortOrder: 'ASC'
      })

      const executionDataResponse = response.data

      selectedTestsMap.clear()

      setExecutionData(
        executionDataResponse.map(({ id, testid, data }) => {
          selectedTestsMap.set(testid, id)
          return {
            id,
            testid,
            data: data.map(({ x, y, meta }) => ({
              x,
              meta,
              y: meta.count > 0 ? y : null
            }))
          }
        })
      )

      getColorsForGraph(selectedTestsMap)
    } catch (error) {
      notification.error({
        message: 'Reports Error',
        description: error.message || JSON.stringify(error),
        placement: 'topRight'
      })
    }
    setLoading(false)
  }, [fromDate, testIdFilter, timeSegment, toDate, props.selectedProject])

  useEffect(() => {
    if (fromDate && toDate && timeSegment) {
      if (timeSegment === 'month') {
        dispatch(updateProjectProperties({ timeSegment: 'week' }))
        return notification.warning({
          message: 'Monthly bucket not available for time graph, defaulting to week',
          placement: 'topRight',
          duration: 4
        })
      }
      if (timeSegment === 'hour' && dayDifference() > 6) {
        return notification.warning({
          message: 'Selected date range is very large for hourly time segment',
          placement: 'topRight',
          duration: 2
        })
      }
      fetchData()
    }
  }, [timeSegment, fromDate, toDate, testIdFilter, dayDifference, fetchData])

  const getColor = ({ testid }) => {
    return (colorsForGraph.has(testid) && colorsForGraph.get(testid)?.color)
  }

  const handleVisibleChange = (newVisible) => {
    loading
      ? setPopoverVisible(false)
      : setPopoverVisible(newVisible)
  }

  const refresh = () => {
    if (timeSegment === 'month') {
      return notification.warning({
        message: 'Monthly bucket not available for time graph',
        placement: 'topRight',
        duration: 2
      })
    }
    if (timeSegment === 'hour' && dayDifference() > 6) {
      notification.warning({
        message: 'Selected date range is very large for hourly time segment',
        placement: 'topRight',
        duration: 2
      })
    }
    fetchData()
  }

  const getColorsForGraph = (tests) => {
    const colorsMap = new Map()
    let colorIndex = 0
    const colorsArray = Object.values(colorsForLegends);
    [...tests.entries()].forEach(([id, name]) => {
      if (!colorsMap.has(id)) {
        if (colorIndex === colorsArray.size) {
          colorIndex = 0
        }
        colorsMap.set(id, {
          name,
          color: colorsArray[colorIndex]
        })
        colorIndex += 1
      }
    })
    setColorsForGraph(colorsMap)
  }

  return (
    <div className='grid grid-cols-6 lg:grid-cols-9'>
      <div className='col-span-6 lg:col-span-7' style={{ border: '1px solid lightgrey' }}>
        <div className='grid grid-cols-9 px-2 my-3 items-center gap-x-1'>
          <div className='col-span-9 xl:col-span-5 flex items-center mb-2 xl:mb-0'>
            <UMDatePicker
              setFromDate={(fromDate) => dispatch(updateProjectProperties({ fromDate }))}
              setToDate={(toDate) => dispatch(updateProjectProperties({ toDate }))}
              setDropdownDateRangeValue={(periodLabel) => dispatch(updateProjectProperties({ periodLabel }))}
            />
            <TimeBucketSelector buckets={['week', 'day', 'hour']} />
            <div className='ml-4 w-80'>
              <TestsSelector selectedProject={props.selectedProject} />
            </div>
          </div>
          <div className='col-start-10 flex justify-evenly w-20'>
            <Button
              shape='circle'
              icon={<i className='fas fa-redo-alt' style={{ color: 'black' }} />}
              onClick={refresh}
              disabled={!fromDate || !toDate}
            />
            <Popover
              placement='bottomRight'
              content={(<ReportPrintingControls selectedProject={props.selectedProject} />)}
              visible={popoverVisible}
              onVisibleChange={handleVisibleChange}
              trigger='click'
            >
              <Button
                shape='circle'
                icon={<i className='fas fa-ellipsis-v' style={{ color: 'black' }} />}
                disabled={loading || !fromDate || !toDate}
              />
            </Popover>
          </div>
        </div>
        <Divider className='mt-0 pt-0' />
        <Spin tip='Loading...' spinning={loading}>
          <div style={{ height: 600 }} id='executionReport'>
            <ResponsiveLine
              data={executionData}
              curve='linear'
              colors={getColor}
              onMouseMove={({ serieId }) => {
                setHoveredTest(serieId)
              }}
              onMouseLeave={() => setHoveredTest('')}
              margin={{ top: 10, right: 20, left: 86, bottom: 100 }}
              xScale={{ type: 'point' }}
              layers={[
                'grid',
                'markers',
                'axes',
                lineLayer,
                'areas',
                'crosshair',
                'mesh'
              ]}
              yScale={{
                type: 'linear'
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -90,
                legendOffset: 80,
                legendPosition: 'middle',
                format: (date) => formatPeriodLabel(date, timeSegment)
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Execution Time',
                legendPosition: 'middle',
                legendOffset: -70,
                orient: 'left',
                format: function (seconds) {
                  return (
                    Number.isInteger(seconds) &&
                    `${('0' + Math.trunc(seconds / 60)).slice(-2)} m ${
                      Number.isInteger(seconds)
                        ? ('0' + (seconds % 60)).slice(-2)
                        : ' '
                    } s`
                  )
                }
              }}
              pointSize={10}
              pointBorderWidth={2}
              pointLabelYOffset={-12}
              useMesh
              tooltip={tooltip}
            />
          </div>
        </Spin>
      </div>
      <div className='col lg:col-span-2  lg:ml-5 mt-5 lg:mt-0'>
        <h4 className='font-semibold'>Legends</h4>
        <div
          style={{ maxHeight: '335px', overflowY: 'auto' }}
          id='graphLegends'
        >
          {Array.from(colorsForGraph.entries()).map(([id, key]) => (
            <div className='flex items-center gap-x-3 my-2 rounded-sm'>
              <div
                style={{
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  backgroundColor: key.color,
                  minWidth: '20px'
                }}
              />
              <div className='truncate' title={key.name}>
                {key.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ExecutionTime
