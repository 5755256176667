import actions from '../../statics/actions'
import { combineReducers } from 'redux'

const auth = (state = { isLoggedIn: false }, action) => {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return { isLoggedIn: true }
    case actions.LOGOUT_SUCCESS:
      localStorage.clear()
      return { isLoggedIn: false }
    default:
      return state
  }
}
const projects = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case actions.GET_PROJECTS:
      return { ...state, loading: true }
    case actions.GET_PROJECTS_SUCCESS:
      return { loading: false, data: action.payload }
    default:
      return state
  }
}
const error = (state = null, action) => {
  switch (action.type) {
    case actions.SET_ERROR:
      return action.payload
    default:
      return state
  }
}

const accountError = (state = null, action) => {
  switch (action.type) {
    case actions.SET_ACCOUNT_ERROR:
      return action.payload
    default:
      return state
  }
}

const test = (state = { data: {}, loading: false }, action) => {
  switch (action.type) {
    case actions.GET_TEST:
      return { ...state, loading: true }
    case actions.GET_TEST_SUCCESS:
      return { ...state, loading: false }
    default:
      return state
  }
}

const selectedProject = (
  state = {
    projectName: '',
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    toDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    periodLabel: 'This Month',
    timeSegment: 'week',
    groupBy: '',
    graphType: 'stacked',
    assigneeFilter: [],
    executionTypeFilter: 'Atomic',
    statusFilter: [],
    resultFilter: [],
    testIdFilter: [],
    tests: new Map(),
    reportType: 'executionHistory'
  },
  action
) => {
  switch (action.type) {
    case actions.UPDATE_PROJECT_PROPERTIES:
      return { ...state, ...action.payload }
    case actions.SET_PROJECT:
      return { ...state, ...action.payload, assigneeFilter: [], statusFilter: [], resultFilter: [], testIdFilter: [], executionTypeFilter: 'Atomic' }
    case actions.CLEAR_REPORT_FILTERS:
      return { ...state, assigneeFilter: [], statusFilter: [], resultFilter: [], testIdFilter: [], executionTypeFilter: 'Atomic' }
    default:
      return state
  }
}

const projectsRequireRefresh = (state = false, action) => {
  switch (action.type) {
    case actions.SET_PROJECTS_REQUIRE_REFRESH:
      return action.payload
    default:
      return state
  }
}

const allReducers = combineReducers({
  auth,
  projects,
  error,
  test,
  selectedProject,
  accountError,
  projectsRequireRefresh
})

export default allReducers
