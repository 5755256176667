import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import appRoutes from '../../statics/appRoutes'
import { AmplifySignIn, AmplifyAuthenticator } from '@aws-amplify/ui-react'
import { getAccount, logout } from '../../store/actions'

const Login = (props) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoggedIn) props.history.push(appRoutes.homePage)
  }, [isLoggedIn, props.history])

  const handleLogin = (status) => {
    if (status === 'signedin') {
      dispatch(
        getAccount({
          callback: () => {
            dispatch(logout())
            props.history.push(appRoutes.unauthorized)
          }
        })
      )
    }
  }

  return (
    <AmplifyAuthenticator id='authenticator' handleAuthStateChange={handleLogin}>
      <AmplifySignIn
        style={{ height: '600px' }}
        id='signin'
        headerText='Sign in to useMango'
        slot='sign-in'
        hideSignUp
      />
    </AmplifyAuthenticator>
  )
}

export default withRouter(Login)
