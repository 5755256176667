const actions = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',

  GET_PROJECTS: 'GET_PROJECTS',
  GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',

  GET_TEST: 'GET_TEST',
  GET_TEST_SUCCESS: 'GET_TEST_SUCCESS',

  SET_ERROR: 'SET_ERROR',
  SET_ACCOUNT_ERROR: 'SET_ACCOUNT_ERROR',

  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  GET_ACCOUNT: 'GET_ACCOUNT',
  GET_ACCOUNT_SUCCESS: 'GET_ACCOUNT_SUCCESS',

  UPDATE_PROJECT_PROPERTIES: 'UPDATE_PROJECT_PROPERTIES',
  CLEAR_REPORT_FILTERS: 'CLEAR_REPORT_FILTERS',
  SET_PROJECT: 'SET_PROJECT',

  SET_PROJECTS_REQUIRE_REFRESH: 'SET_PROJECTS_REQUIRE_REFRESH'
}

export default actions
