import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Card, Empty, Skeleton } from 'antd'
import { colorForTestResult } from '../../../utils/colors'
import { withRouter } from 'react-router'

const ExecutionsByResultPie = ({ loading, executionsByResult, title, ...props }) => {
  return (
    <Card
      className='shadow-sm rounded-sm col-span-1 relative w-full overflow-hidden'
      style={{ height: 'fit-content' }}
      size='small'
      title={
        <strong title={title} className='text-xs'>
          {title}
        </strong>
      }
      bodyStyle={{ paddingTop: 0, paddingLeft: 10, paddingRight: 10, paddingBottom: '200px', height: 200 }}
    >
      {loading ? (
        <center>
          <Skeleton.Image className='my-2' style={{ width: 150, height: 150 }} />
        </center>
      ) : executionsByResult.length > 0 ? (
        <div className='absolute w-full h-full'>
          <ResponsivePie
            arcLinkLabelsDiagonalLength={5}
            arcLinkLabelsStraightLength={0}
            data={executionsByResult
              .filter((obj) => obj.result !== null)
              .map(
                (obj) =>
                  (obj = {
                    ...obj,
                    id: obj.result,
                    label: obj.result,
                    value: obj.count,
                    [`${obj.result}Color`]:
                      obj.result === 'Passed'
                        ? colorForTestResult.passed
                        : obj.result === 'Failed'
                          ? colorForTestResult.failed
                          : obj.result === 'Cancelled'
                            ? colorForTestResult.cancelled
                            : obj.result === 'DidNotFinish'
                              ? colorForTestResult.didnotfinish
                              : 'lightgray'
                  })
              )}
            colors={({ id, data }) => String(data[`${id}Color`])}
            margin={{ top: 10, right: 60, bottom: 47, left: 60 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={5}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor='#333333'
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
            onClick={({ id }) => {
              title === 'Recent Executions by result'
                ? props.history.push(`/p/${props.match.params.project}/executions?result=${id}`)
                : props.history.push(`/p/${props.match.params.project}/tests?lastResult=${id}`)
            }}
            onMouseEnter={(node, event) => {
              event.target.style.cursor = 'pointer'
            }}
          />
        </div>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<b>There are no test executions in this project.</b>}
        />
      )}
    </Card>
  )
}

export default withRouter(ExecutionsByResultPie)
