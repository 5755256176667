import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Breadcrumb from '../../components/Navigation/Breadcrumb'
import { Card, Divider, Empty, Skeleton, List, notification, Select, Dropdown, Menu } from 'antd'
import gql from 'graphql-tag'
import './Projects.css'
import { httpGet } from '../../store/services/testServiceClient'
import { awsAppSyncClient } from '../../store/services/appSync'
import CountUp from 'react-countup'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import { classNames } from '../../utils/styles'
import { colorForTestStatus, colorForTestResult } from '../../utils/colors'
import { setProjectProperties, setProjectsRequireRefresh } from '../../store/actions'
import { LOCALE, sortOptions } from '../../utils/stringCompare'
const { Option } = Select

const AccountOverviewQuery = `query AccountOverview {
  getAccountOverview {
    projects {
      id
      name
      lastModified
      lastExecution {
        testResult
        _id
      }
      testCount
      testsByStatus {
        status
        count
      }
      lastTestExecutionsByResult {
        count
        result
      }
    }
    accountInfo {
      name
      noOfUsers
      status
    }
  }
}
`

const Projects = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const { projectName } = useSelector((state) => state.selectedProject)
  const [projects, setProjects] = useState([])
  const [users, setUsers] = useState([])
  const [accountInfo, setAccountInfo] = useState(null)
  const [errorMessage, setErrorMessge] = useState('')
  const [userError, setUserError] = useState('')
  const [loadingAccountOverview, setLoadingAccountOverview] = useState(false)
  const [loadingUsers, setLoadingUsers] = useState(false)
  const [loadingDocs, setLoadingDocs] = useState(true)
  const [filter, setFilter] = useState('lastModified')
  const projectsRequireRefresh = useSelector((state) => state?.projectsRequireRefresh)

  useEffect(() => {
    async function getAccountOverview () {
      try {
        setLoadingAccountOverview(true)
        const result = await awsAppSyncClient.query({
          query: gql(AccountOverviewQuery),
          fetchPolicy: projectsRequireRefresh ? 'network-only' : 'cache-first'
        })
        setAccountInfo(result.data.getAccountOverview.accountInfo)
        setProjects(result.data.getAccountOverview.projects)
      } catch (error) {
        setErrorMessge(error.message)
        notification.error({
          message: 'Account overview error',
          description: error.message,
          placement: 'topRight'
        })
      }
      setLoadingAccountOverview(false)
    }

    async function getAccountUsers () {
      try {
        setLoadingUsers(true)
        const accountUsers = await httpGet('/users')
        setUsers(accountUsers.data.map((user) => user.Email))
      } catch (error) {
        setUserError(error.message)
        notification.error({
          message: 'Something went wrong',
          description: error.message,
          placement: 'topRight'
        })
      }
      setLoadingUsers(false)
    }

    setTimeout(() => {
      setLoadingDocs(false)
    }, 1000)
    getAccountOverview()
    if (localStorage.getItem('Role') === 'Administrator') getAccountUsers()
    if (projectsRequireRefresh) dispatch(setProjectsRequireRefresh(false))
  }, [])

  const getTestStatus = (status) => {
    const temp = {
      status: 'Status',
      Design: 0,
      DesignColor: colorForTestStatus.design,
      Ready: 0,
      ReadyColor: colorForTestStatus.ready,
      Review: 0,
      ReviewColor: colorForTestStatus.review,
      Repair: 0,
      RepairColor: colorForTestStatus.repair,
      Revalidate: 0,
      RevalidateColor: colorForTestStatus.revalidate
    }
    status.forEach((obj) => {
      temp[`${obj.status}`] = obj.count
    })
    return temp
  }

  return (
    <div>
      <Breadcrumb />
      {auth.isLoggedIn && (
        <>
          <div className='grid grid-cols-3'>
            <div className='m-0 p-0 col-span-3 lg:col-span-2 border-none mb-4'>
              <div>
                <div className='p-0 m-0 flex justify-between items-center'>
                  <strong>All Projects</strong>
                  <div className='flex flex-row items-center m-0 p-0'>
                    <p className='text-sm m-0 p-0 mx-2 font-bold'>Sort By: </p>
                    <Select
                      className='col-span-2'
                      defaultValue={filter}
                      value={filter}
                      onChange={(val) => setFilter(val)}
                      style={{ width: '135px' }}
                      dropdownClassName='z-0'
                    >
                      <Option value='lastModified'>Last Modified</Option>
                      <Option value='name'>Name</Option>
                    </Select>
                  </div>
                </div>
                <Divider className='w-52 min-w-0 mt-2' />

                {!errorMessage && (
                  <div>
                    {!loadingAccountOverview ? (
                      <>
                        <div
                          className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6'
                          id='projectsList'
                        >
                          {projects
                            .slice()
                            .sort(
                              filter === 'lastModified'
                                ? (a, b) => new Date(b.lastModified) - new Date(a.lastModified)
                                : (a, b) => a.name.localeCompare(b.name, LOCALE, sortOptions)
                            )
                            .map((project) => (
                              <Card
                                id={project.id}
                                key={project.id}
                                title={
                                  <div className='flex items-center justify-between m-0 p-0'>
                                    <span
                                      title={project.name}
                                      style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontSize: '15px'
                                      }}
                                      className='font-semibold cursor-pointer hover:text-blue-500'
                                      onClick={() => {
                                        projectName !== project.name &&
                                          dispatch(setProjectProperties({ projectName: project.name }))
                                        props.history.push({
                                          pathname: `/p/${project.id}`
                                        })
                                      }}
                                    >
                                      {project.name}
                                    </span>
                                    <Dropdown
                                      trigger={['click']}
                                      overlay={
                                        <Menu>
                                          <Menu.Item
                                            onClick={() => {
                                              projectName !== project.name &&
                                                dispatch(setProjectProperties({ projectName: project.name }))
                                              props.history.push({
                                                pathname: `/p/${project.id}/tests`
                                              })
                                            }}
                                          >
                                            Tests
                                          </Menu.Item>
                                          <Menu.Item
                                            onClick={() => {
                                              projectName !== project.name &&
                                                dispatch(setProjectProperties({ projectName: project.name }))
                                              props.history.push({
                                                pathname: `/p/${project.id}/executions`
                                              })
                                            }}
                                          >
                                            Executions
                                          </Menu.Item>
                                        </Menu>
                                      }
                                      placement='bottomRight'
                                    >
                                      <span className='w-7 text-right pr-2'>
                                        <i className='fas fa-ellipsis-v text-gray-400 cursor-pointer hover:text-headerColor' />
                                      </span>
                                    </Dropdown>
                                  </div>
                                }
                                size='small'
                                headStyle={{ paddingRight: 0 }}
                                className='projectCard w-full shadow-sm rounded-sm'
                                style={{ minHeight: 218 }}
                              >
                                <>
                                  <div className='grid grid-cols-3'>
                                    <div className='flex flex-col items-center col-span-1 justify-center'>
                                      <div
                                        className={classNames(
                                          project.testCount > 0
                                            ? 'cursor-pointer hover:text-blue-600'
                                            : 'cursor-default',
                                          'm-0 p-0 flex flex-col items-center'
                                        )}
                                        onClick={() => {
                                          projectName !== project.name &&
                                            dispatch(setProjectProperties({ projectName: project.name }))
                                          project.testCount &&
                                            props.history.push({
                                              pathname: `/p/${project.id}/tests`
                                            })
                                        }}
                                      >
                                        <CountUp
                                          start={0}
                                          end={project.testCount || 0}
                                          duration={project?.testCount < 10 ? 1 : 2}
                                          className='text-4xl	font-black	text-indigo-300'
                                        />
                                        <p className='text-xs font-semibold p-0 m-0 my-1'>Tests</p>
                                      </div>
                                    </div>
                                    <div className='flex flex-col items-center col-span-2 mb-2'>
                                      {project.lastTestExecutionsByResult ? (
                                        <div className='m-0 p-0 flex flex-col items-center'>
                                          <div style={{ height: 90, width: 90 }}>
                                            <ResponsivePie
                                              data={project.lastTestExecutionsByResult
                                                .filter((obj) => obj.result !== null)
                                                .map(
                                                  (obj) =>
                                                    (obj = {
                                                      ...obj,
                                                      id: obj.result,
                                                      label: obj.result,
                                                      value: Math.round((obj.count / project.testCount) * 100),
                                                      [`${obj.result}Color`]:
                                                        obj.result === 'Passed'
                                                          ? colorForTestResult.passed
                                                          : obj.result === 'Failed'
                                                            ? colorForTestResult.failed
                                                            : obj.result === 'Cancelled'
                                                              ? colorForTestResult.cancelled
                                                              : obj.result === 'DidNotFinish'
                                                                ? colorForTestResult.didnotfinish
                                                                : 'lightgray'
                                                    })
                                                )}
                                              innerRadius={0.45}
                                              padAngle={0.7}
                                              cornerRadius={3}
                                              enableArcLinkLabels={false}
                                              arcLabel={(d) => `${d.value}%`}
                                              arcLabelsSkipAngle={20}
                                              activeOuterRadiusOffset={2}
                                              colors={({ id, data }) => String(data[`${id}Color`])}
                                              margin={{ top: 3, right: 3, bottom: 3, left: 3 }}
                                              tooltip={({ datum }) => (
                                                <div
                                                  style={{
                                                    padding: 6,
                                                    color: 'black',
                                                    background: 'white',
                                                    width: 'max-content',
                                                    borderRadius: '2px',
                                                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)'
                                                  }}
                                                >
                                                  <span className='flex justify-between items-center'>
                                                    <div
                                                      className='mx-1 w-3 h-3'
                                                      style={{
                                                        backgroundColor: datum.color
                                                      }}
                                                    />
                                                    <div className='mx-1'>
                                                      {datum.id === 'DidNotFinish' ? 'Not Finished' : datum.id}:
                                                    </div>
                                                    <b>{datum.value + '%'}</b>
                                                  </span>
                                                </div>
                                              )}
                                              onClick={({ id }) => {
                                                projectName !== project.name &&
                                                  dispatch(setProjectProperties({ projectName: project.name }))
                                                props.history.push(`/p/${project.id}/tests?lastResult=${id}`)
                                              }}
                                              onMouseEnter={(node, event) => {
                                                event.target.style.cursor = 'pointer'
                                              }}
                                            />
                                          </div>
                                          <p className='text-xs font-semibold my-1'>Result Ratio</p>
                                        </div>
                                      ) : (
                                        <>
                                          <div style={{ height: 90, width: 90 }}>
                                            <ResponsivePie
                                              data={[{ id: 'No Executions', label: '', value: true }]}
                                              innerRadius={0.45}
                                              padAngle={0.7}
                                              cornerRadius={3}
                                              enableArcLinkLabels={false}
                                              activeOuterRadiusOffset={0}
                                              isInteractive={false}
                                              enableArcLabels={false}
                                              colors='#E1DFDF'
                                            />
                                          </div>
                                          <p className='text-xs  my-1'>No Executions</p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  {project.testsByStatus && (
                                    <>
                                      <div style={{ height: 30 }} className='w-full '>
                                        <ResponsiveBar
                                          data={[getTestStatus(project.testsByStatus)]}
                                          keys={['Design', 'Ready', 'Review', 'Repair', 'Revalidate']}
                                          indexBy='status'
                                          padding={0}
                                          layout='horizontal'
                                          valueScale={{ type: 'linear' }}
                                          indexScale={{ type: 'band', round: true }}
                                          valueFormat={{ format: ' >-', enabled: false }}
                                          colors={({ id, data }) => String(data[`${id}Color`])}
                                          borderColor={{
                                            from: 'color',
                                            modifiers: [['darker', 1.6]]
                                          }}
                                          axisTop={null}
                                          axisRight={null}
                                          axisBottom={null}
                                          axisLeft={null}
                                          enableGridY={false}
                                          label={(d) => `${d.id}`}
                                          labelSkipWidth={33}
                                          labelSkipHeight={12}
                                          labelTextColor={{
                                            from: 'color',
                                            modifiers: [['darker', 1.6]]
                                          }}
                                          tooltip={({ id, value, color }) => (
                                            <div
                                              style={{
                                                padding: 6,
                                                color: 'black',
                                                background: 'white',
                                                width: 'max-content',
                                                borderRadius: '2px',
                                                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)'
                                              }}
                                            >
                                              <span className='flex justify-between items-center'>
                                                <div
                                                  className='mx-1 w-3 h-3'
                                                  style={{
                                                    backgroundColor: color
                                                  }}
                                                />
                                                <div className='mx-1'>{id}:</div>
                                                <b>{value}</b>
                                              </span>
                                            </div>
                                          )}
                                          defs={[
                                            {
                                              id: 'dotsReady',
                                              type: 'patternDots',
                                              background: 'inherit',
                                              color: '#D6EAF8',
                                              size: 4,
                                              padding: 1,
                                              stagger: true
                                            },
                                            {
                                              id: 'dotsDesign',
                                              type: 'patternLines',
                                              background: 'inherit',
                                              color: '#A9CCE3',
                                              rotation: -45,
                                              lineWidth: 6,
                                              spacing: 10
                                            }
                                          ]}
                                          fill={[
                                            {
                                              match: {
                                                id: 'Design'
                                              },
                                              id: 'dotsDesign'
                                            },
                                            {
                                              match: {
                                                id: 'Ready'
                                              },
                                              id: 'dotsReady'
                                            }
                                          ]}
                                          onClick={({ id }) => {
                                            projectName !== project.name &&
                                              dispatch(setProjectProperties({ projectName: project.name }))
                                            props.history.push(`/p/${project.id}/tests?status=${id}`)
                                          }}
                                          onMouseEnter={(node, event) => {
                                            event.target.style.cursor = 'pointer'
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                </>
                              </Card>
                            ))}
                        </div>
                        {projects.length === 0 && (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<b>No Projects available.</b>} />
                        )}
                      </>
                    ) : (
                      <>
                        <div className='grid grid-cols-1 md:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3 gap-6'>
                          {new Array(6).fill('').map(() => (
                            <Card
                              className='w-full projectCard'
                              title={<Skeleton.Input style={{ width: 140, height: 20 }} active />}
                              style={{ minHeight: 218 }}
                            >
                              {/* <Skeleton.Input
                                className='w-20 sm:w-48 xl:w-36 2xl:w-40'
                                style={{ height: 20, marginTop: 0 }}
                                active
                              /> */}
                              <div className='grid grid-cols-3'>
                                <div className='flex flex-col items-center col-span-1 justify-center'>
                                  <Skeleton.Input className='w-10' style={{ height: 50, marginTop: 0 }} active />
                                </div>
                                <div className='flex flex-col items-center justify-start col-span-2'>
                                  <Skeleton.Image className='my-2' style={{ width: 90, height: 90 }} />
                                </div>
                              </div>
                              <Skeleton.Input className='w-full' style={{ height: 25 }} active />
                            </Card>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className='col-span-3 lg:col-span-1 flex flex-col lg:items-end items-start'>
              <Card
                className='accountOverview w-11/12  2xl:w-4/5 mb-8'
                title={<strong className='text-sm'>Account Overview </strong>}
              >
                {!errorMessage && (
                  <div className='grid grid-cols-1'>
                    {!loadingAccountOverview ? (
                      <>
                        <p className='mb-1 italic text-base	'>
                          <span className='text-sm font-semibold not-italic text-gray-700'>Account Name: </span>
                          {localStorage.getItem('AccountName') || accountInfo?.name}
                        </p>
                        <p className='mb-0 italic  text-base	'>
                          <span className='text-sm font-semibold not-italic  text-gray-700'>Licence Status: </span>
                          {accountInfo?.status}
                        </p>
                      </>
                    ) : (
                      <>
                        <Skeleton.Input
                          className='w-20 sm:w-30 2xl:w-30 mb-2'
                          style={{ height: 20, marginTop: 0 }}
                          active
                        />
                        <Skeleton.Input
                          className='w-20 sm:w-30 2xl:w-30 mb-2'
                          style={{ height: 20, marginTop: 0 }}
                          active
                        />
                      </>
                    )}
                  </div>
                )}
              </Card>
              {localStorage.getItem('Role') === 'Administrator' && (
                <Card
                  className='accountOverview  w-11/12  2xl:w-4/5 mb-8'
                  title={<strong className='text-sm'>Account Users</strong>}
                >
                  {!userError &&
                    (!loadingUsers ? (
                      users.length > 0 ? (
                        <List
                          style={{ overflow: 'auto', minHeight: '50px', maxHeight: '170px' }}
                          dataSource={users}
                          renderItem={(item) => (
                            <List.Item className='ml-0 pl-0 py-1'>
                              <List.Item.Meta title={item} />
                            </List.Item>
                          )}
                        />
                      ) : (
                        <span>No users in account</span>
                      )
                    ) : (
                      <Skeleton active />
                    ))}
                </Card>
              )}
              <Card
                className='accountOverview w-11/12 2xl:w-4/5  mb-8'
                title={
                  <div className='flex flex-wrap items-center justify-between'>
                    <strong className='text-sm '>Useful Links</strong>
                    <a style={{ fontSize: '11px' }} href='https://docs.usemango.co.uk/' target='_blank' rel='noreferrer'>
                      <i class='fas fa-external-link-alt mr-1' />
                      https://docs.usemango.co.uk/
                    </a>
                  </div>
                }
              >
                {!loadingDocs ? (
                  <ul className='list-outside useMangoDocs underline'>
                    <li className='pb-1'>
                      <a href='https://docs.usemango.co.uk/working-with-um/projects/' target='_blank' rel='noreferrer'>
                        Working with projects
                      </a>
                    </li>
                    <li className='pb-1'>
                      <a
                        href='https://docs.usemango.co.uk/working-with-um/working-with-components/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Working with components
                      </a>
                    </li>
                    <li className='pb-1'>
                      <a
                        href='https://docs.usemango.co.uk/working-with-um/working-with-tests/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Working with tests
                      </a>
                    </li>
                    <li className='pb-1'>
                      <a
                        href='https://docs.usemango.co.uk/working-with-um/project-variables/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Project Variables
                      </a>
                    </li>
                    <li className='pb-1'>
                      <a
                        href='https://docs.usemango.co.uk/working-with-um/chrome-extension/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Chrome Extension
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://docs.usemango.co.uk/working-with-um/integration-with-jenkins/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Integration with Jenkins
                      </a>
                    </li>
                  </ul>
                ) : (
                  <div className='flex flex-col'>
                    <Skeleton.Input className='w-44 mb-1' size='small' active />
                    <Skeleton.Input className='w-48 mb-1' size='small' active />
                    <Skeleton.Input className='w-40 mb-1' size='small' active />
                    <Skeleton.Input className='w-40 mb-1' size='small' active />
                    <Skeleton.Input className='w-44 mb-1' size='small' active />
                    <Skeleton.Input className='w-48' size='small' active />
                  </div>
                )}
              </Card>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Projects
