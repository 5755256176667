import React, { useEffect, useState } from 'react'
import { Button, Input, Select } from 'antd'
import { TEST_STATUSES } from '../../../statics/constants'
import TagsIcon from '../../../assets/tagsIcon.svg'
import SortIcon from '../../../assets/sortIcon.svg'
import FilterIcon from '../../../assets/filterIcon.svg'

import { httpGet } from '../../../store/services/testServiceClient'

const ComponentFilters = ({
  componentFilters,
  setComponentFilters,
  sourceProject,
  setFiltersUpdated,
  updateUIOnError,
  componentType,
}) => {
  const { status, sortBy, tags, componentName } = componentFilters
  const [searchText, setSearchText] = useState(componentName)
  const [projectTags, setProjectTags] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadProjectTags = async () => {
    try {
      const response = await httpGet(`projects/${sourceProject}/testtags`)
      setProjectTags(response.data)
    } catch (error) {
      updateUIOnError('Error retrieving project tags.', error)
    }
  }

  useEffect(() => {
    const loadFiltersData = async () => {
      setIsLoading(true)
      await Promise.all([loadProjectTags()])
      setIsLoading(false)
    }
    if (sourceProject) loadFiltersData()
  }, [sourceProject])

  useEffect(() => {
    setSearchText(componentName)
  }, [componentName])

  return (
    <div className='flex items-center justify-start gap-x-4'>
      <div className='flex items-center gap-x-2'>
        <div className='flex items-center text-xs gap-x-2'>
          <img src={SortIcon} alt='Sort by Icon' />
          <span>Sort By:</span>
        </div>
        <Select
          value={sortBy}
          onChange={(value) => {
            setComponentFilters({ ...componentFilters, sortBy: value })
            setFiltersUpdated(true)
          }}
          options={[
            { value: 'Name', label: 'Name' },
            { value: '', label: 'Date' },
          ]}
          className='text-xs w-full'
          size='small'
          style={{ width: 130 }}
          disabled={!sourceProject}
        />
      </div>

      {componentType === 'Composite' && (
        <>
          <div className='flex items-center gap-x-2'>
            <div className='flex items-center text-xs gap-x-1'>
              <img src={FilterIcon} alt='Filter Icon' />
              <span>Status:</span>
            </div>
            <Select
              value={status}
              onChange={(value) => {
                setComponentFilters({ ...componentFilters, status: value })
                setFiltersUpdated(true)
              }}
              options={[
                { value: '', label: 'All Status' },
                ...TEST_STATUSES.map((status) => ({
                  value: status,
                  label: status,
                })),
              ]}
              className='text-xs w-full'
              size='small'
              style={{ width: 130 }}
              disabled={!sourceProject}
            />
          </div>
          <div className='flex items-center gap-x-2'>
            <div className='flex items-center text-xs gap-x-2'>
              <img src={TagsIcon} alt='Tags Icon' />
              <span>Tags:</span>
            </div>
            <Select
              mode='multiple'
              maxTagCount='responsive'
              placeholder='Select Tags'
              value={tags}
              onChange={(value) => {
                setComponentFilters({ ...componentFilters, tags: value })
                setFiltersUpdated(true)
              }}
              options={projectTags.map((tag) => ({
                value: tag,
                label: tag,
              }))}
              className='text-xs w-full'
              size='small'
              style={{ width: 230 }}
              disabled={!sourceProject}
              loading={isLoading}
            />
          </div>
        </>
      )}

      <div className='flex items-center gap-x-2'>
        <Input
          placeholder='Filter by Component name'
          className='rounded-sm testNameFilter w-full'
          suffix={
            <Button
              size='small'
              type='primary'
              icon={<i className='fa fa-search align-middle' style={{ fontSize: '10px' }} />}
              onClick={() => {
                setComponentFilters({
                  ...componentFilters,
                  componentName: searchText,
                })
                setFiltersUpdated(true)
              }}
            />
          }
          style={{ fontSize: '12px', width: 270, paddingRight: 0 }}
          size='small'
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'Tab') {
              setComponentFilters({
                ...componentFilters,
                componentName: e.target.value,
              })
              setFiltersUpdated(true)
            }
          }}
          disabled={!sourceProject}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
    </div>
  )
}

export default ComponentFilters
