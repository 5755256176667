const LOCALE = 'en'

const sortOptions = {
  numeric: true,
  sensitivity: 'case'
}

export {
    LOCALE,
    sortOptions
}