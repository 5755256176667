import React from 'react'
import { withRouter } from 'react-router-dom'
import appRoutes from '../../statics/appRoutes'
import { classNames } from '../../utils/styles'

const SettingsNavigation = (props) => {
  return (
    <div className='flex justify-start mb-4 h-12 shadow-sm rounded'>
      <p
        onClick={() => props.history.push(appRoutes.projectSettingsPage)}
        className={classNames(
          props.match.path === appRoutes.projectSettingsPage && 'selectedTab',
          'tab text-center w-24'
        )}
      >
        Projects
      </p>
      <p
        onClick={() => props.history.push(appRoutes.userSettingsPage)}
        className={classNames(props.match.path === appRoutes.userSettingsPage && 'selectedTab', 'tab text-center w-24')}
      >
        Users
      </p>
      <p
        onClick={() => props.history.push(appRoutes.administrationPage)}
        className={classNames(
          props.match.path === appRoutes.administrationPage && 'selectedTab',
          'tab text-center w-24'
        )}
      >
        Administration
      </p>
    </div>
  )
}

export default withRouter(SettingsNavigation)
