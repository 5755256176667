import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch } from 'react-redux'
import { setProjectProperties } from '../../../store/actions'
import { ADMINISTRATION_PAGE_UI_STATES } from '../../../statics/constants'
import EntityAdministration from './EntityAdministration'
import TestFilters from './TestFilters'
import { httpGet, httpPost } from '../../../store/services/testServiceClient'

const TestAdministration = ({
  action,
  entity,
  setEntity,
  setCurrentUIState,
  sourceProjectId,
  setSourceProjectId,
  destinationProjectId,
  setDestinationProjectId,
  updateUIOnError,
  setDisableSourceProjectSelect,
  currentUIState,
  sourceProjectName,
  destinationProjectName,
  reuseUnchangedArtefactsWhileCloning,
  setReuseUnchangedArtefactsWhileCloning
}) => {
  const [tests, setTests] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const pageInfoRef = useRef({})
  const [showInfiniteScrollLoader, setShowInfiniteScrollLoader] = useState(false)
  const [showTableLoader, setShowTableLoader] = useState(false)
  const [isActionOnEntityInitiated, setIsActionOnEntityInitiated] = useState(false)
  const [filtersUpdated, setFiltersUpdated] = useState(false)
  const [selectedTableEntities, setSelectedTableEntities] = useState([])
  const [testFilters, setTestFilters] = useState({
    status: '',
    assignee: 'Anybody',
    sortBy: 'Name',
    tags: [],
    testName: '',
    searchText: ''
  })

  const history = useHistory()
  const dispatch = useDispatch()

  const testTableColumns = [
    {
      title: (
        <TestFilters
          testFilters={testFilters}
          setTestFilters={setTestFilters}
          sourceProject={sourceProjectId}
          setFiltersUpdated={setFiltersUpdated}
          updateUIOnError={updateUIOnError}
        />
      ),
      dataIndex: 'Name',
      render: (testName) => <>{testName}</>
    }
  ]

  const resetFilters = () => {
    setTestFilters({
      status: '',
      assignee: 'Anybody',
      sortBy: 'Name',
      tags: [],
      testName: ''
    })
  }

  const resetEntityData = () => {
    setTests([])
    setSelectedTableEntities([])
    setHasMore(false)
    pageInfoRef.current = null
  }

  const loadTests = async (loadMore = false) => {
    try {
      loadMore ? setShowInfiniteScrollLoader(true) : setShowTableLoader(true)
      setDisableSourceProjectSelect(true)

      const queryStringParameters = new URLSearchParams({
        filter: testFilters.testName.trim(),
        orderBy: testFilters.sortBy,
        cursor: !pageInfoRef.current ? '' : pageInfoRef.current.Next,
        status: testFilters.status,
        assignee: testFilters.assignee,
        tags: testFilters.tags.length > 0 ? testFilters.tags.join(',') : ''
      }).toString()

      const response = await httpGet(`projects/${sourceProjectId}/testindex?${queryStringParameters}`)

      if (loadMore) {
        setTests((prev) => [...prev, ...response.data.Items])
      } else {
        setTests(response.data.Items)
      }

      pageInfoRef.current = response.data.Info
      setHasMore(pageInfoRef.current?.HasNext)
    } catch (e) {
      updateUIOnError('Error retrieving tests.', e)
    } finally {
      loadMore ? setShowInfiniteScrollLoader(false) : setShowTableLoader(false)
      setDisableSourceProjectSelect(false)
    }
  }

  const loadMoreTests = async () => {
    if (pageInfoRef.current != null && pageInfoRef.current?.HasNext) {
      try {
        await loadTests(true)
      } catch (e) {
        updateUIOnError('Error retrieving tests.', e)
      }
    }
  }

  const cloneTests = async () => {
    try {
      setIsActionOnEntityInitiated(true)
      await httpPost('accountadministration/clonetests', {
        TestIds: selectedTableEntities.map((entity) => entity.Id),
        SourceProjectId: sourceProjectId,
        DestinationProjectId: destinationProjectId,
        ReuseUnchangedArtefacts: reuseUnchangedArtefactsWhileCloning
      })
      setCurrentUIState(ADMINISTRATION_PAGE_UI_STATES.SUCCESS)
    } catch (error) {
      setCurrentUIState(ADMINISTRATION_PAGE_UI_STATES.FAIL)
    } finally {
      setIsActionOnEntityInitiated(false)
    }
  }

  const resetAdministrationPage = () => {
    resetEntityData()
    setSourceProjectId('')
    resetFilters()
    setDestinationProjectId('')
    setCurrentUIState(ADMINISTRATION_PAGE_UI_STATES.INITIAL)
    setReuseUnchangedArtefactsWhileCloning(false)
    setEntity(null)
  }

  useEffect(() => {
    resetFilters()
    setFiltersUpdated(true)
  }, [sourceProjectId])

  useEffect(() => {
    if (filtersUpdated) {
      resetEntityData()
      if (sourceProjectId) loadTests()
      setFiltersUpdated(false)
    }
  }, [filtersUpdated])

  const cloningSuccessMessage = (
    <>
      <p>The Test(s) has been successfully cloned to the designated project.</p>
      <p>
        <span
          to=''
          onClick={() => {
            dispatch(setProjectProperties({ projectName: destinationProjectName }))
            history.push(`/p/${destinationProjectId}/tests`)
          }}
          className='text-blue-500 cursor-pointer hover:text-blue-400'
        >
          Click here
        </span>{' '}
        to see the cloned Test(s).
      </p>
    </>
  )

  return (
    sourceProjectId !== '' && (
      <EntityAdministration
        action={action}
        entity={entity}
        entities={tests}
        tableColumns={testTableColumns}
        showTableLoader={showTableLoader}
        showInfiniteScrollLoader={showInfiniteScrollLoader}
        loadMore={loadMoreTests}
        hasMore={hasMore}
        selectedTableEntities={selectedTableEntities}
        setSelectedTableEntities={setSelectedTableEntities}
        sourceProjectId={sourceProjectId}
        destinationProjectId={destinationProjectId}
        sourceProjectName={sourceProjectName}
        destinationProjectName={destinationProjectName}
        reuseUnchangedArtefactsWhileCloning={reuseUnchangedArtefactsWhileCloning}
        setReuseUnchangedArtefactsWhileCloning={setReuseUnchangedArtefactsWhileCloning}
        actionOnSelectedEntity={cloneTests}
        resetAdministrationPage={resetAdministrationPage}
        isActionOnEntityInitiated={isActionOnEntityInitiated}
        currentUIState={currentUIState}
        setCurrentUIState={setCurrentUIState}
        customSuccessActionMessage={cloningSuccessMessage}
      />
    )
  )
}

export default TestAdministration
