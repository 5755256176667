import React from 'react'
import { colorForTestResult } from '../../../utils/colors'

function GraphTooltipDetail ({ meta, y }) {
  return (
    <>
      <div className='flex flex-col py-1'>
        {!!meta.count && (
          <span className='text-xs'>Executions Count:</span>
        )}
        {!!meta.max && (
          <span className='text-xs'>Highest Time:</span>
        )}
        {!!meta.min && (
          <span className='text-xs'>Lowest Time:</span>
        )}
        <span className='text-xs'>
          {meta.count === 1
            ? 'Execution Time:'
            : 'Average Time:'}
        </span>
        {!!(meta.count === 1) && (
          <span className='text-xs'>Result:</span>
        )}
      </div>
      <div className='flex flex-col py-1'>
        {!!meta.count && (
          <div className='text-xs'>
            {meta.count}
          </div>
        )}
        {!!meta.max && (
          <div className='text-xs'>
            {meta.max}s
          </div>
        )}
        {!!meta.min && (
          <div className='text-xs'>
            {meta.min}s
          </div>
        )}
        {!!meta.count && (
          <div className='text-xs'>
            {`${y ? `${y.toFixed(2)}s` : 'NA'}`}
          </div>
        )}
        {!!(meta.count === 1) && (
          <span
            className='text-xs italic'
            style={{
              color:
          colorForTestResult[
            meta.result
              .toLowerCase()
              .replaceAll(' ', '')
          ]
            }}
          >
            {meta.result}
          </span>
        )}
      </div>
    </>
  )
}

export default GraphTooltipDetail
