const MINUTE = 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24
const MILLISECONDS_IN_A_SECOND = 1000
const NUMBER_OF_DAYS_IN_A_WEEK = 7
const LOCALE = undefined // use the browsers default locale.
const TIME_OPTIONS = { hour: 'numeric', minute: 'numeric' }
const WEEKDAY_OPTIONS = { weekday: 'long' }
const CURRENT_YEAR_OPTIONS = { month: 'long', day: 'numeric' }
const PEVIOUS_YEAR_OPTIONS = { month: 'long', day: 'numeric', year: 'numeric' }

const toRelativeDateTime = (date) => {
  const dateToFormat = new Date(date)
  if (isNaN(dateToFormat)) {
    return ''
  }
  const now = new Date()
  const secondsAgo = Math.round((now - dateToFormat) / MILLISECONDS_IN_A_SECOND)

  if (secondsAgo < MINUTE) {
    return 'just now'
  } else if (secondsAgo < MINUTE * 2) {
    return '1 minute ago'
  } else if (secondsAgo < HOUR) {
    return `${Math.floor(secondsAgo / MINUTE)} minutes ago`
  } else if (secondsAgo < HOUR * 2) {
    return '1 hour ago'
  } else if (today()) {
    return `today at ${format(TIME_OPTIONS)}`
  } else if (yesterday()) {
    return `yesterday at ${format(TIME_OPTIONS)}`
  } else if (lastWeek()) {
    return `last ${format(WEEKDAY_OPTIONS)}`
  } else if (currentYear()) {
    return format(CURRENT_YEAR_OPTIONS)
  } else {
    return format(PEVIOUS_YEAR_OPTIONS)
  }

  function today () {
    return now.getDate() === dateToFormat.getDate() && secondsAgo < DAY
  }
  function yesterday () {
    return now.getDate() - 1 === dateToFormat.getDate() && secondsAgo < DAY * 2
  }
  function lastWeek () {
    return now.getDay() !== dateToFormat.getDay() && secondsAgo < DAY * 7
  }
  function currentYear () {
    return now.getFullYear() === dateToFormat.getFullYear()
  }
  function format (options) {
    return Intl.DateTimeFormat(LOCALE, options).format(dateToFormat)
  }
}

const toApproximateDuration = (milliseconds) => {
  const MINS_FACTOR = MILLISECONDS_IN_A_SECOND * MINUTE
  if (!Number.isInteger(milliseconds)) {
    return `Invalid duration: ${milliseconds}`
  } else {
    const mins = Math.floor(milliseconds / MINS_FACTOR)
    const excessSeconds = Math.floor((milliseconds / MILLISECONDS_IN_A_SECOND) % 60)
    const seconds = milliseconds / MILLISECONDS_IN_A_SECOND
    if (mins > 0) {
      return `${mins}m ${excessSeconds}s`
    } else if (excessSeconds > 0) {
      return `${new Intl.NumberFormat(undefined, { maximumSignificantDigits: 3 }).format(seconds)}s`
    } else {
      return `${milliseconds}ms`
    }
  }
}

const getDateOrdinal = (d) => {
  if (d > 3 && d < 21) return `${d}th`
  switch (d % 10) {
    case 1:
      return `${d}st`
    case 2:
      return `${d}nd`
    case 3:
      return `${d}rd`
    default:
      return `${d}th`
  }
}
const getFormattedDate = (d) => {
  const newDate = new Date(d)
  const date = getDateOrdinal(newDate.getDate())
  const month = newDate.toLocaleString('en-us', { month: 'long' })
  const year = newDate.getFullYear()
  return date + ' ' + month + ' ' + year
}

const getDuration = (startedAt, endedAt) => {
  if (!startedAt || !endedAt) return 'N/A'
  const milliseconds = new Date(endedAt) - new Date(startedAt)
  const minutes = Math.floor(milliseconds / 60000)
  const seconds = Number(((milliseconds % 60000) / 1000).toFixed(0))
  if (minutes <= 0 && seconds < 1) {
    return 'less than a second'
  } else {
    const delimiter = minutes > 0 && seconds > 0 ? '.' : ''
    const minutesSuffix = minutes === 1 && seconds === 0 ? 'min' : 'mins'
    const secondsSuffix = seconds === 1 ? 'sec' : 'secs'
    const secondsText = seconds > 0 ? `${seconds} ${secondsSuffix}` : ''
    if (minutes === 0) return `${secondsText}`
    else if (minutes > 0 && seconds === 0) return `${minutes} ${minutesSuffix}`
    else if (minutes > 60) return `${minutes} ${minutesSuffix}`
    else return `${minutes}${delimiter}${seconds} ${minutesSuffix}`
  }
}

const dateWithUTCSuffix = (date) => (/^.*(Z|\+00:00|-00:00)$/.test(date) ? date : date + 'Z')

const isValidDate = (date) => !isNaN(date)

const formatDate = (date, displayTimeZone = true, moreOptions = {}) => {
  const dateToFormat = new Date(dateWithUTCSuffix(date))

  const options = {
    hour12: true,
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    ...moreOptions
  }
  return isValidDate(dateToFormat)
    ? `${Intl.DateTimeFormat(LOCALE, options).format(dateToFormat)} ${displayTimeZone ? `${getTimezoneOffset()}` : ''}`
    : 'NA'
}

const getISOWeekNumber = (date) => {
  const MILLI_SECS_PER_WEEK = 7 * 24 * 60 * 60 * 1000
  const THURSDAY = 4
  const dateObj = new Date(date.valueOf())
  const dayOfTheWeekIndex = (date.getDay() + 6) % NUMBER_OF_DAYS_IN_A_WEEK
  dateObj.setDate(dateObj.getDate() - dayOfTheWeekIndex + (THURSDAY - 1))
  const thursdayOfTheWeek = dateObj.valueOf()
  dateObj.setMonth(0, 1)
  if (dateObj.getDay() !== THURSDAY) {
    dateObj.setMonth(0, 1 + ((THURSDAY - dateObj.getDay() + NUMBER_OF_DAYS_IN_A_WEEK) % NUMBER_OF_DAYS_IN_A_WEEK))
  }
  return 1 + Math.ceil((thursdayOfTheWeek - dateObj) / MILLI_SECS_PER_WEEK)
}

const getWeekRange = (week, year) => {
  const MILLI_SECS_PER_HOUR = 60 * 60 * 1000
  const MILLI_SECS_PER_DAY = 24 * MILLI_SECS_PER_HOUR
  const firstDay = new Date(year, 0, 1).setHours(0, 0, 0, 0)
  const startDateTimestamp =
    new Date(firstDay).getTime() -
    MILLI_SECS_PER_DAY * ((new Date(firstDay).getDay() - 1 + NUMBER_OF_DAYS_IN_A_WEEK) % NUMBER_OF_DAYS_IN_A_WEEK) +
    MILLI_SECS_PER_DAY * NUMBER_OF_DAYS_IN_A_WEEK * week
  const startDate = new Date(startDateTimestamp)
  const endDate = new Date(startDateTimestamp + MILLI_SECS_PER_DAY * 6)
  return [startDate, endDate]
}

const formatPeriodLabel = (periodLabel, timeSegment) => {
  const date = new Date(periodLabel + ' UTC')

  switch (timeSegment) {
    case 'hour':
      return `${date.getHours()}:00 ${date.getDate()} / ${date.getMonth() + 1}`
    case 'day':
      return `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`
    case 'week':
      return `${getISOWeekNumber(date)} / ${date.getFullYear()}`
    case 'month':
      return `${date.getMonth() + 1} / ${date.getFullYear()}`
    default:
      return ''
  }
}

const formatFromDateUTC = (date) => {
  if (date) {
    const d = new Date(date)
      return `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()} ${appendZero(d.getUTCHours()) }:${appendZero(d.getUTCMinutes())}:00`
  }
  return ''
}

const formatToDateUTC = (date) => {
  if (date) {
    const d = new Date(new Date(date).setHours(23, 59, 59, 999))
    return `${d.getUTCFullYear()}-${
      d.getUTCMonth() + 1
    }-${d.getUTCDate()} ${appendZero(d.getUTCHours())}:${appendZero(d.getUTCMinutes())}:${appendZero(d.getUTCSeconds())}`
  }
  return ''
}

const getDayDifference = (fromDate, toDate) => {
  const timeInMilisec = new Date(toDate).getTime() - new Date(fromDate).getTime()
  const daysBetweenDates = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24))
  return daysBetweenDates
}

const appendZero = (timeUnit) => ('0' + timeUnit).slice(-2)

const getTodaysDate = () => {
  const today = new Date()
  return today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
}

const getTimezoneOffset = () => {
  let offset = new Date().getTimezoneOffset()
  const sign = offset < 0 ? '+' : '-'
  offset = Math.abs(offset)
  return `GMT${sign}${appendZero((offset / 60) | 0)}:${appendZero(offset % 60)}`
}

export {
  toRelativeDateTime,
  toApproximateDuration,
  getFormattedDate,
  getDuration,
  formatDate,
  getISOWeekNumber,
  getWeekRange,
  formatPeriodLabel,
  formatFromDateUTC,
  formatToDateUTC,
  getDayDifference,
  appendZero,
  getTodaysDate,
  getTimezoneOffset
}
