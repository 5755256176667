import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import UserPanel from './UserPanel'
import appRoutes from '../../statics/appRoutes'
import USEMANGO_LOGO from '../../assets/usemango_logo.png'
import { Button } from 'antd'
import { publishInstallerDownloadedEvent } from '../../store/services/trialActivityEventPublisher'

const Header = () => {
  const auth = useSelector((state) => state.auth)
  const [isPublishingEvent, setIsPublishingEvent] = useState(false)

  const download = async () => {
    window.location.href = 'https://download.usemango.co.uk/Install-useMango.msi'
    try {
      setIsPublishingEvent(true)
      await publishInstallerDownloadedEvent()
    } finally {
      setIsPublishingEvent(false)
    }
  }

  return (
    <header class='sticky top-0 z-50 bg-headerColor h-16 py-1'>
      <div className='flex justify-between items-center mx-5'>
        <Link to={appRoutes.homePage}>
          <img src={USEMANGO_LOGO} alt='' className='w-32 md:w-36' />
        </Link>
        {auth.isLoggedIn ? (
          <div className='flex justify-center items-center'>
            <Button
              type='link'
              className='mx-1 md:mx-2 md:px-2 text-gray-300'
              download='Install-useMango.msi'
              onClick={download}
              disabled={isPublishingEvent}
            >
              Download App
            </Button>
            <UserPanel />
          </div>
        ) : (
          <div className='grid grid-cols-2 gap-x-2'>
            <Link to={appRoutes.signupPage}>
              <Button className='text-white' type='ghost'>
                Sign Up
              </Button>
            </Link>
            <Link to={appRoutes.loginPage}>
              <Button className='text-white' type='ghost'>
                Log in
              </Button>
            </Link>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
