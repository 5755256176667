import { publishTrialActivityEvent } from './adminServiceClient'

const publishInstallerDownloadedEvent = async () => {
  if (localStorage.downloadedInstaller) {
    return
  }
  await publishTrialActivityEvent({ UserActivity: 'DownloadedInstaller' })
  localStorage.setItem('downloadedInstaller', true)
}

export { publishInstallerDownloadedEvent }
