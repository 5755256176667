import { getHttpClient } from './httpClient'

const getAdminServiceHttpClient = async (requiresAuthentication = true) => {
  return getHttpClient(process.env.REACT_APP_ADMIN_SERVICE_URL, requiresAuthentication)
}
const createAccount = async (account) => {
  const adminClient = await getAdminServiceHttpClient(false)
  return adminClient.post('signup', account)
}
const getAccount = async (_id) => {
  const adminClient = await getAdminServiceHttpClient()
  return adminClient.get(`accounts/${_id}`)
}
const updateAccountName = async (_id, newName) => {
  const adminClient = await getAdminServiceHttpClient()
  return adminClient.put(`accounts/${_id}/name`, { NewName: newName })
}
const publishTrialActivityEvent = async (event) => {
  const adminClient = await getAdminServiceHttpClient()
  return adminClient.post('/users/trial-activity', event)
}

const getApiKey = async (accountID) => {
  const adminClient = await getAdminServiceHttpClient()
  return adminClient.get(`accounts/${accountID}/apikey`)
}

const generateApiKey = async (accountID) => {
  const adminClient = await getAdminServiceHttpClient()
  return adminClient.post(`accounts/${accountID}/apikey`)
}

const revokeApiKey = async (accountID) => {
  const adminClient = await getAdminServiceHttpClient()
  return adminClient.delete(`accounts/${accountID}/apikey`)
}

export {
  createAccount,
  getAccount,
  updateAccountName,
  publishTrialActivityEvent,
  getApiKey,
  revokeApiKey,
  generateApiKey
}
