import { withRouter } from 'react-router-dom'
import appRoutes from '../../statics/appRoutes'
import { classNames } from '../../utils/styles'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import useFetchProjectName from '../../custom-hooks/useFetchProjectName'

const Breadcrumb = ({ testName, ...props }) => {
  const { projectName } = useSelector((state) => state.selectedProject)
  const [fetchProjectName] = useFetchProjectName()

  useEffect(() => {
    if (props?.match?.params?.project && !projectName) {
      fetchProjectName({ projectId: props.match.params.project })
    }
  }, [props.match.params, projectName])

  const testRoutes = [
    appRoutes.testPage,
    appRoutes.testExecutionsPage,
    appRoutes.testScenariosPage,
    appRoutes.reportPage
  ]

  return (
    <ul
      style={{ minHeight: '30px', height: '35px' }}
      className={classNames(
        localStorage.getItem('AccountName') &&
          'flex flex-row justify-start items-start bg-transparentColor shadow-sm list-none pt-2 pl-2 my-3'
      )}
    >
      {localStorage.getItem('AccountName') && (
        <li
          onClick={() => props.history.push(appRoutes.homePage)}
          className={classNames(props.match.path !== '/' && 'cursor-pointer', 'text-xs sm:text-sm ')}
        >
          <span
            className={classNames(
              props.match.path !== '/' && 'hover:bg-sky-100 rounded-sm  hover:transition duration-300',
              'sm:px-2 py-1'
            )}
          >
            {localStorage.getItem('AccountName')}
          </span>
        </li>
      )}
      {props.match.params.project && (
        <li
          onClick={() => {
            if (testRoutes.includes(props.match.path)) {
              props.history.push(`/p/${props.match.params.project}`)
            }
          }}
          className={classNames(
            testRoutes.includes(props.match.path) && 'cursor-pointer',
            'text-xs sm:text-sm ellipsis'
          )}
        >
          <i className='fa fa-chevron-right px-2 text-xs text-gray-500' />
          <span
            className={classNames(
              testRoutes.includes(props.match.path) && 'hover:bg-sky-100 rounded-sm  hover:transition duration-300',
              'sm:px-2 py-1'
            )}
            title={projectName}
          >
            {projectName}
          </span>
        </li>
      )}
      {testName && (
        <li className='text-xs sm:text-sm ellipsis'>
          <i className='fa fa-chevron-right px-2 text-xs text-gray-500' />
          <span className='sm:px-2 py-1' title={testName}>
            {testName}
          </span>
        </li>
      )}
      {props.match.path === appRoutes.userSettingsPage && (
        <>
          <li className='text-xs sm:text-sm '>
            <i className='fa fa-chevron-right px-2 text-xs text-gray-500' />
            <span className='sm:px-2 py-1'>Settings</span>
          </li>
          <li className='text-xs sm:text-sm '>
            <i className='fa fa-chevron-right px-2 text-xs text-gray-500' />
            <span className='sm:px-2 py-1'>Users</span>
          </li>
        </>
      )}
      {props.match.path === appRoutes.projectSettingsPage && (
        <>
          <li className='text-xs sm:text-sm '>
            <i className='fa fa-chevron-right px-2 text-xs text-gray-500' />
            <span className='sm:px-2 py-1'>Settings</span>
          </li>
          <li className='text-xs sm:text-sm '>
            <i className='fa fa-chevron-right px-2 text-xs text-gray-500' />
            <span className='sm:px-2 py-1'>Projects</span>
          </li>
        </>
      )}
      {props.match.path === appRoutes.administrationPage && (
        <>
          <li className='text-xs sm:text-sm '>
            <i className='fa fa-chevron-right px-2 text-xs text-gray-500' />
            <span className='sm:px-2 py-1'>Settings</span>
          </li>
          <li className='text-xs sm:text-sm '>
            <i className='fa fa-chevron-right px-2 text-xs text-gray-500' />
            <span className='sm:px-2 py-1'>Administration</span>
          </li>
        </>
      )}
    </ul>
  )
}

export default withRouter(Breadcrumb)
