import React, { useCallback, useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Breadcrumb from '../../components/Navigation/Breadcrumb'
import TestNavigation from '../../components/Navigation/TestNavigation'
import appRoutes from '../../statics/appRoutes'
import { httpGet, httpPut } from '../../store/services/testServiceClient'
import { formatDate, toRelativeDateTime } from '../../utils/dateTime.js'
import Alert from '../../components/Alerts/Alert'
import { useDispatch, useSelector } from 'react-redux'
import { getTest, getTestSuccess } from '../../store/actions'
import EditableCell from '../../components/EditableCell'
import { Button, Empty, Skeleton, Modal, Tag } from 'antd'
import { getTagColor } from '../../utils/util'

const Test = (props) => {
  const testIndexPath = `projects/${props.match.params.project}/testindex/${props.match.params.testId}`
  const testPath = `projects/${props.match.params.project}/tests/${props.match.params.testId}`
  const selectAllCheckbox = useRef()
  const scenarioTableBody = useRef()
  const alertbox = useRef()
  const csvfile = useRef()
  const [test, setTest] = useState()
  const [assignee, setAssignee] = useState('')
  const [parameters, setParameters] = useState([])
  const [scenarios, setScenarios] = useState([])
  const [isLoadingScenarios, setIsLoadingScenarios] = useState(false)
  const [scenariosProgressText, setScenariosProgressText] = useState('')
  const [someScenariosSelected, setSomeScenariosSelected] = useState(false)
  const [canAddScenario, setCanAddScenario] = useState(true)
  const [selectedCellId, setSelectedCellId] = useState('')
  const [isImportModalVisible, setIsImportModalVisible] = useState(false)
  const isLoadingTest = useSelector((state) => state.test.loading)
  const dispatch = useDispatch()

  const showError = useCallback((message, e) => {
    alertbox.current?.showServerError(message, e)
    scrollToElement('alert_box')
  }, [])

  const populateScenarioArgumentVMs = useCallback(
    (_scenarios) => {
      _scenarios.forEach((s) => {
        s.isSelected = false
        s.argsVM = []
      })

      parameters.forEach((p) => {
        _scenarios.forEach((s) => {
          const arg = s.Arguments.filter((a) => a.ParameterId === p.Id)
          const argValue = !arg || !arg[0] || !arg[0].Value ? '' : arg[0].Value.Text
          s.argsVM.push({ ParameterId: p.Id, Value: argValue })
        })
      })
      setScenarios(_scenarios)
    },
    [parameters]
  )

  const showTestExecutions = () => {
    props.history.push(`/p/${props.match.params.project}/executions?testName=${test.Name}`)
  }

  const loadTest = useCallback(async () => {
    try {
      dispatch(getTest())
      setTest((await httpGet(testIndexPath)).data)
      setParameters((await httpGet(testPath)).data.Parameters)
    } catch (e) {
      showError('Error loading test details.', e)
    } finally {
      dispatch(getTestSuccess())
    }
  }, [dispatch, showError, testIndexPath, testPath])

  const loadScenarios = useCallback(async () => {
    showScenarioProgress('Loading datasets for your test...')
    try {
      const _scenarios = (await httpGet(`${testPath}/scenarios`)).data
      setScenarios(_scenarios)
      populateScenarioArgumentVMs(_scenarios)
      setTest((prev) => {
        return { ...prev, HasScenarios: _scenarios.length > 0 }
      })
    } catch (e) {
      showError('Error loading datasets.', e)
    } finally {
      hideScenarioProgress()
    }
  }, [populateScenarioArgumentVMs, showError, testPath])

  // Some scenarios may not have value for all the parameters
  // Populate the scenario arguments (argsVM) in the order of parameters array

  const getAssignee = useCallback(async () => {
    try {
      let assigneeName = 'Nobody'
      if (test.Assignee) {
        const response = await httpGet(`projects/${props.match.params.project}/users`)
        const found = response.data.find((user) => user.Id === test.Assignee)
        if (found) {
          assigneeName = !found.Name ? found.Email : found.Name
        }
      }
      return assigneeName
    } catch (e) {
      showError('Error loading project users.', e)
    }
  }, [props.match.params.project, showError, test])

  const showImportDialog = async () => {
    if (csvfile.current) csvfile.current.value = ''
    setIsImportModalVisible(true)
  }

  const importcsv = async () => {
    setIsImportModalVisible(false)
    showScenarioProgress('Importing datasets...')
    console.log(csvfile.current)
    const csv = csvfile.current.files[0]
    if (!csv.size > 0) {
      showError('The csv file should have valid content.')
      hideScenarioProgress()
      return
    } else if (csv.name.split('.').pop().toLowerCase() !== 'csv') {
      showError('Select a .csv file.')
      hideScenarioProgress()
      return
    }
    try {
      const headers = { headers: { 'Content-Type': 'text/csv' } }
      await httpPut(`${testPath}/importScenarios`, csv, headers)
      await loadScenarios()
    } catch (e) {
      showError(`Error importing datasets from file: '${csv.name}'.`, e)
    } finally {
      hideScenarioProgress()
    }
  }
  const deleteScenarios = async () => {
    try {
      if (areAllScenariosSelected()) {
        await deleteScenarioSet()
      } else {
        await deleteSelectedScenarios()
      }
      await loadScenarios()
      setSomeScenariosSelected(false)
    } catch (e) {
      showError('Error deleting datasets.', e)
    } finally {
      hideScenarioProgress()
    }
  }

  const deleteSelectedScenarios = async () => {
    for (let i = 0; i < scenarios.length; i++) {
      if (scenarios[i].isSelected) {
        showScenarioProgress(`Deleting dataset '${scenarios[i].Name}'...`)
        await httpPut(`${testPath}/deleteScenario`, { ScenarioId: scenarios[i].Id })
      }
    }
  }

  const deleteScenarioSet = async () => {
    showScenarioProgress('Deleting datasets...')
    await httpPut(`${testPath}/deleteScenarioSet`)
  }

  const addScenario = async () => {
    try {
      setCanAddScenario(false)
      const args = []
      parameters.forEach((p) => args.push({ ParameterId: p.Id, Value: p.DefaultValue }))
      const body = { Name: getUniqueName(), Arguments: args }
      showScenarioProgress(`Adding dataset '${body.Name}'...`)
      await httpPut(`${testPath}/addScenario`, body)
      await loadScenarios()
      await hideScenarioProgress() // hideScenarioProgress() not added to finally block as scrollToElement() needs the table to be visible
      scrollToElement('footer')
    } catch (e) {
      hideScenarioProgress()
      showError('Error adding new dataset.', e)
    } finally {
      setCanAddScenario(true)
    }
  }

  const scrollToElement = (elementId) => {
    const ele = document.getElementById(elementId)
    ele?.scrollIntoView()
  }

  const getUniqueName = () => {
    const namePrefix = 'Unnamed Dataset'
    let newName = `${namePrefix}`
    let index = 1
    // eslint-disable-next-line
    while (scenarios.some((s) => s.Name === newName)) {
      newName = `${namePrefix} ${index++}`
    }
    return newName
  }

  const showScenarioProgress = (text) => {
    alertbox.current?.hideAlert()
    setIsLoadingScenarios(true)
    setScenariosProgressText(text)
  }

  const hideScenarioProgress = () => {
    setIsLoadingScenarios(false)
  }

  const launchReport = (runId) => {
    props.history.push(`/p/${props.match.params.project}/executions/${runId}`)
    // window.open(`${window.location.origin}/report?runId=${runId}`, '_blank')
  }

  // const extractQueryParameterFromUrl = (paramName) => {
  //   const uri = window.location.search.substring(1);
  //   const params = new URLSearchParams(uri);
  //   if (params.has(paramName)) {
  //     return params.get(paramName);
  //   } else {
  //     throw new Error(`Query parameter '${paramName}' is missing.`);
  //   }
  // };

  const getCommaSeperatedTags = (tags) => {
    return !tags ? '' : tags.toString()
  }

  const formatRelativeDate = (date) => {
    return toRelativeDateTime(date)
  }

  const formatDuration = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000)
    const seconds = ((milliseconds % 60000) / 1000).toFixed(0)

    if (minutes <= 0 && seconds < 1) {
      return 'less than a second'
    } else {
      const delimiter = minutes > 0 && seconds > 0 ? ',' : ''
      const minutesSuffix = minutes === 1 ? 'min' : 'mins'
      const minutesText = minutes > 0 ? `${minutes} ${minutesSuffix}${delimiter}` : ''
      const secondsSuffix = seconds === 1 ? 'sec' : 'secs'
      const secondsText = seconds > 0 ? `${seconds} ${secondsSuffix}` : ''
      return `${minutesText} ${secondsText}`
    }
  }

  const selectAll = () => {
    const checkbox = selectAllCheckbox
    const _scenarios = scenarios.map((s) => ({ ...s, isSelected: checkbox.current.checked }))
    setScenarios(_scenarios)
    const scenarioChecks = scenarioTableBody.current.getElementsByTagName('input')
    for (const sc of scenarioChecks) {
      sc.checked = checkbox.current.checked
    }
    setSomeScenariosSelected(checkbox.current.checked)
  }

  const enableDisableDeleteScenariosButton = (e, id) => {
    const _scenarios = scenarios.map((scenario) =>
      scenario.Id === id ? (scenario = { ...scenario, isSelected: e.target.checked }) : scenario
    )
    setScenarios(_scenarios)
    setSomeScenariosSelected(_scenarios.some((s) => s.isSelected === true))
    selectAllCheckbox.current.checked = _scenarios.every((s) => s.isSelected === true)
  }

  const areAllScenariosSelected = () => {
    return scenarios.every((s) => s.isSelected === true)
  }

  const selectCell = (id) => {
    setSelectedCellId(id)
  }

  const getCellId = (scenarioId, columnId) => {
    return `${scenarioId}-${columnId}`
  }

  const getScenarioNameCellId = (scenarioId) => {
    return `${scenarioId}-name`
  }

  const getScenarioRowId = (scenarioId) => {
    return `${scenarioId}-row`
  }
  useEffect(() => {
    console.log(selectedCellId)
  }, [selectedCellId])

  const updateParameterValue = async (newValue) => {
    alertbox.current?.hideAlert()
    const index = parseInt(selectedCellId.split('-')[0])
    const paramId = selectedCellId.split('-')[1]
    const scenario = scenarios.find((s) => s.Id === index)
    try {
      await httpPut(`${testPath}/updateScenario`, {
        Name: null,
        ScenarioId: scenario.Id,
        Values: [{ ParameterId: selectedCellId.split('-')[1], Value: newValue }]
      })
      setScenarios(
        scenarios.map((s) =>
          s.Id === index
            ? (s = {
                ...s,
                argsVM: s.argsVM.map((a) => (a.ParameterId === paramId ? (a = { ...a, Value: newValue }) : a))
              })
            : s
        )
      )
      return newValue
    } catch (e) {
      showError(`Error saving argument value '${newValue}' for dataset: ${scenario.Name}.`, e)
      return undefined
    }
  }

  const updateScenarioName = async (newName) => {
    alertbox.current?.hideAlert()
    const index = parseInt(selectedCellId.split('-')[0])
    const scenario = scenarios.find((s) => s.Id === index)
    try {
      await httpPut(`${testPath}/updateScenario`, { Name: newName, ScenarioId: scenario.Id, Values: [] })
      setScenarios(scenarios.map((s) => (s.Name === scenario.Name ? (s = { ...s, Name: newName }) : s)))
      return newName
    } catch (e) {
      showError(`Error renaming dataset: ${scenario.Name} to '${newName}'.`, e)
      return undefined
    }
  }

  useEffect(() => {
    loadTest()
  }, [loadTest])

  useEffect(() => {
    const loadUsersAndSetAssignee = async () => {
      if (test) {
        setAssignee(await getAssignee())
      }
    }
    loadUsersAndSetAssignee()
  }, [test?.Assignee, getAssignee, test])

  useEffect(() => {
    if (parameters.length) {
      loadScenarios()
    }
  }, [parameters, loadScenarios])

  useEffect(() => {
    if (!scenarios.length > 0) {
      setTest((prev) => {
        return { ...prev, HasScenarios: false }
      })
    }
  }, [scenarios])
  return (
    <>
      <div className='mt-3'>
        <Breadcrumb testName={test?.Name} />
        <TestNavigation />
        <Alert showCloseButton ref={alertbox} />
        {isLoadingTest === true && (
          <div className='w-full 2xl:w-4/5 shadow-sm px-4 pt-2 pb-12 '>
            <Skeleton active />
          </div>
        )}
        {isLoadingTest === false && test != null && (
          <div className='mt-3'>
            {props.match.path === appRoutes.testPage && (
              <div id='testDetailsCard' className='w-full 2xl:w-4/5 shadow-sm pb-2'>
                <div className='px-4 flex justify-start items-center h-12 bg-transparentColor font-bold text-md w-full'>
                  <b className='pr-2'>Test: </b> {test.Name}
                </div>
                <div className='p-4 md:w-96 lg:w-1/2'>
                  <div className='grid grid-cols-2 gap-y-3'>
                    <label id='testStatus'>Status:</label>
                    <label id='testStatusValue' htmlFor='testStatus'>
                      {test.Status}
                    </label>
                    <label id='testAssignee'>Assignee:</label>
                    <label id='testAssigneeValue' htmlFor='testAssignee'>
                      {assignee}
                    </label>
                    {test.Tags?.length > 0 && (
                      <>
                        <label id='testTags'>Tags:</label>
                        <label id='testTagsValue' htmlFor='testTags'>
                          {getCommaSeperatedTags(test.Tags)}
                        </label>
                      </>
                    )}
                    <label id='testDate'>Last modified:</label>
                    <label id='testDateValue' htmlFor='testDate'>
                      {formatRelativeDate(test.LastModified)}
                    </label>
                    {test.Description?.trim().length > 0 && (
                      <>
                        <label id='testDescription'>Description:</label>
                        <label id='testDescriptionValue' htmlFor='testDescription'>
                          {test.Description}
                        </label>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {props.match.path === appRoutes.testExecutionsPage && (
              <div className='px-0 w-full 2xl:w-4/5 shadow-sm pb-2'>
                <div className='px-4 h-12 bg-transparentColor font-bold text-md w-full flex justify-between items-center'>
                  <b>Recent Executions </b>
                  {test.RecentExecutions?.length > 0 && (
                    <Button type='ghost' onClick={showTestExecutions}>
                      Show all test executions
                    </Button>
                  )}
                </div>
                <div className='p-4'>
                  {test.RecentExecutions?.length > 0 ? (
                    <div className='flex flex-col'>
                      <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-sm'>
                            <table className='min-w-full divide-y divide-gray-200'>
                              <thead className='bg-gray-50'>
                                <tr>
                                  <th
                                    scope='col'
                                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider'
                                  >
                                    Run At
                                  </th>
                                  <th
                                    scope='col'
                                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider'
                                  >
                                    Duration
                                  </th>
                                  <th
                                    scope='col'
                                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider'
                                  >
                                    Result
                                  </th>
                                </tr>
                              </thead>
                              <tbody className='bg-white divide-y divide-gray-200'>
                                {test.RecentExecutions.sort(
                                  (a, b) => new Date(b.StartedAt).getTime() - new Date(a.StartedAt).getTime()
                                ).map((execution) => (
                                  <tr
                                    onClick={() => launchReport(execution.Id)}
                                    key={execution.Id}
                                    name={execution.Name}
                                    className='cursor-pointer hover:bg-transparentColor'
                                    title='Click to open the report'
                                  >
                                    <td className='px-6 py-4 whitespace-nowrap'>
                                      <div className='text-sm text-gray-900'>{formatDate(execution.StartedAt)}</div>
                                    </td>
                                    <td className='px-6 py-4 whitespace-nowrap'>
                                      {formatDuration(execution.DurationInMilliseconds)}
                                    </td>
                                    <td className='px-6 py-4 whitespace-nowrap'>
                                      <Tag color={getTagColor(execution.Result)}>
                                        {execution.Result === 'DidNotFinish' ? 'Not Finished' : execution.Result}
                                      </Tag>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<b>No test executions.</b>} />
                  )}
                </div>
              </div>
            )}
            {props.match.path === appRoutes.testScenariosPage && (
              <div className='px-0 w-full 2xl:w-4/5 w- shadow-sm pb-2'>
                <div className='px-4 h-12 bg-transparentColor font-bold text-md w-full flex justify-between items-center'>
                  Test Datasets
                </div>
                <div className='p-4' id='scenariosPanel'>
                  <div>
                    <p className='pb-2'>
                      Use <em>datasets</em> to run your test with different sets of input data. These enable you to use
                      the same test logic for multiple test cases, or to perform the same test in different
                      environments.
                    </p>
                    <div
                      className='w-full grid grid-cols-1 gap-y-2 sm:grid-cols-4 sm:gap-x-2'
                      disabled={isLoadingScenarios}
                    >
                      <Button
                        id='addScenarioBtn'
                        type='primary'
                        disabled={!canAddScenario || isLoadingScenarios}
                        loading={!canAddScenario}
                        onClick={addScenario}
                      >
                        <span> Add Dataset </span>
                      </Button>
                      {test.HasScenarios ? (
                        <Button
                          id='deleteScenariosBtn'
                          type='primary'
                          disabled={!someScenariosSelected || isLoadingScenarios}
                          loading={isLoadingScenarios}
                          className='bg-red-500 border-red-500 hover:border-red-300 hover:bg-red-300 ml-1'
                          onClick={deleteScenarios}
                        >
                          Delete Datasets
                        </Button>
                      ) : (
                        <div className='lg:col-span-2' />
                      )}
                      <Button
                        id='importScenariosBtn'
                        type='primary'
                        disabled={isLoadingScenarios}
                        onClick={showImportDialog}
                        className='col-span-2 lg:col-span-1'
                      >
                        <i className='fa fa-upload mr-3' aria-hidden='true' />
                        Import Datasets
                      </Button>
                    </div>
                    {isLoadingScenarios ? (
                      <div className='mt-3'>
                        <p>{scenariosProgressText}</p>
                      </div>
                    ) : test.HasScenarios ? (
                      <div className='mt-3 flex flex-col'>
                        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                          <div className='py-2 align-middle inline-block sm:px-6 lg:px-8'>
                            <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-sm'>
                              <table className='dataset-table divide-y divide-gray-200 border-solid border-1 border-gray-100'>
                                <thead className='bg-gray-50'>
                                  <tr>
                                    <th
                                      scope='col'
                                      className='px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-solid border-1 border-gray-100'
                                    >
                                      <input
                                        id='selectAllScenariosCheckbox'
                                        type='checkbox'
                                        width='1%'
                                        onClick={selectAll}
                                        value={areAllScenariosSelected}
                                        ref={selectAllCheckbox}
                                      />
                                    </th>
                                    <th
                                      scope='col'
                                      className='px-6 py-0 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-solid border-1 border-gray-100'
                                    >
                                      Dataset Name
                                    </th>
                                    {parameters.map((param) => (
                                      <th
                                        key={param.Id}
                                        scope='col'
                                        title={param.Name}
                                        className='truncate px-6 py-0 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-solid border-1 border-gray-100'
                                      >
                                        {param.Name}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody
                                  ref={scenarioTableBody}
                                  className='bg-white divide-y divide-gray-200 border-solid border-1 border-gray-100'
                                >
                                  {scenarios.map((scenario) => (
                                    <tr key={scenario.Id} id={getScenarioRowId(scenario.Id)}>
                                      <td className='px-6 py-0 whitespace-nowrap border-solid border-1 border-gray-100'>
                                        <input
                                          type='checkbox'
                                          value={scenario.isSelected}
                                          onChange={(e) => enableDisableDeleteScenariosButton(e, scenario.Id)}
                                        />
                                      </td>
                                      <td className='whitespace-nowrap border-solid border-1 border-gray-100 hover:bg-gray-100'>
                                        <EditableCell
                                          value={scenario.Name}
                                          id={getScenarioNameCellId(scenario.Id)}
                                          updateValue={updateScenarioName}
                                          selected={getScenarioNameCellId(scenario.Id) === selectedCellId}
                                          setSelected={selectCell}
                                        />
                                      </td>
                                      {scenario.argsVM.map((arg) => (
                                        <td className='whitespace-nowrap border-solid border-1 border-gray-100 hover:bg-gray-100'>
                                          <EditableCell
                                            value={arg.Value}
                                            updateValue={updateParameterValue}
                                            id={getCellId(scenario.Id, arg.ParameterId)}
                                            selected={getCellId(scenario.Id, arg.ParameterId) === selectedCellId}
                                            setSelected={selectCell}
                                          />
                                        </td>
                                      ))}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<b>Your test doesn't have any datasets. You can add or import datasets.</b>}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        visible={isImportModalVisible}
        destroyOnClose
        onCancel={() => setIsImportModalVisible(false)}
        footer={
          <>
            <Button type='primary' id='importBtn' onClick={importcsv}>
              Import
            </Button>
            <Button type='default' id='cancelBtn' onClick={() => setIsImportModalVisible(false)}>
              Cancel
            </Button>
          </>
        }
        title='Import datasets from a csv file'
      >
        <input type='file' ref={csvfile} accept='.csv' id='csvfileinput' />
      </Modal>
    </>
  )
}

export default withRouter(Test)
