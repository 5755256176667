import { Card, Empty, Spin, Table } from 'antd'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ENTITY } from '../../../statics/constants'

const EntityTable = ({
  entities,
  selectedTableEntities,
  setSelectedTableEntities,
  tableColumns,
  showTableLoader,
  showInfiniteScrollLoader,
  entity,
  sourceProjectId,
  loadMore,
  hasMore
}) => {
  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedTableEntities(selectedRows)
    }
  }

  return (
    <div className='flex flex-col items-start gap-y-1 mt-6 '>
      <p className='font-semibold m-0'>{`${entity}s`}</p>
      <Card className='w-full rounded-sm' bodyStyle={{ padding: 0 }}>
        <InfiniteScroll
          dataLength={entities.length}
          next={loadMore}
          hasMore={hasMore}
          loader={
            showInfiniteScrollLoader && (
              <center>
                <Spin className='mt-2' />
              </center>
            )
          }
          style={{ overflowX: 'auto' }}
          height={450}
          className='px-1'
        >
          <Table
            rowSelection={{
              type: entity !== ENTITY.PROJECT ? 'checkbox' : 'radio',
              selectedRowKeys: selectedTableEntities.map((entity) => entity.Id),
              ...rowSelection
            }}
            columns={tableColumns}
            dataSource={entities}
            pagination={false}
            locale={{
              emptyText: showTableLoader ? (
                <Spin tip={`Loading ${entity}s...`} />
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<b>{!sourceProjectId ? 'No Source Project Selected' : `No ${entity}s found`}.</b>}
                />
              )
            }}
            size='small'
            tableLayout='fixed'
            className='entityTable'
            rowKey='Id'
            scroll={{ x: 'max-content' }}
          />
        </InfiniteScroll>
      </Card>
    </div>
  )
}

export default EntityTable
