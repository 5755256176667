export const colorForTestStatus = {
  design: '#AED6F1',
  ready: '#D5F5E3',
  review: '#ffaf34',
  repair: '#f47560',
  revalidate: '#be72ff'
}

export const colorForTestResult = {
  passed: '#82E0AA',
  failed: '#EC7063',
  didnotfinish: '#f1e51b',
  cancelled: '#ffb7c5'
}

export const colorForBasicExecutionGraph = 'lightblue'

export const colorsForLegends = {
  paleGoldenRod: '#EEE8AA',
  sandyBrown: '#F4A460',
  aquaMarine: '#61CDBB',
  lightSteelBlue: '#B0C4DE',
  lightBrown: '#CEAB93',
  antiqueWhite: '#FAEBD7',
  lightYellow: '#FFFF99',
  bluryWoood: '#DEB887',
  lightPurple: '#CAB8FF',
  thistle: '#D8BFD8',
  lavender: '#E6E6FA',
  lightCoral: '#F08080',
  lightSalmon: '#FFA07A',
  orange: '#FFA500',
  lightGreen: '#90EE90',
  green: '#C0F792',
  darkTurquoise: '#00CED1',
  paleTurquoise: '#AFEEEE',
  gainsBoro: '#DCDCDC',
  orchid: '#DA70D6',
  hotPink: '#FF69B4',
  lightPink: '#F4BBBB',
  mistyRose: '#FFE4E1',
  bisque: '#FFE4C4',
  indianRed: '#EABCBC',
  liberty: '#BCE5DD',
  tropicalBlue: '#8EC7F9',
  gardenPlum: '#C7C0CD',
  grape: '#C6ACBA',
  bubbleGum: '#FFCADE',
  seaUrchin: '#CFAAC7',
  blueVelvet: '#8798DF'
}

export function getNewRandomLightColor (colorsArray) {
  const letters = ['B', 'C', 'D', 'E', 'F']
  let color
  do {
    color = [1, 2, 3, 4, 5, 6].reduce((newColorHex, _) => newColorHex + letters[Math.floor(Math.random() * letters.length)], '#')
  } while (colorsArray.includes(color))
  return color
}

// colors obtained from https://colorhunt.co/palettes/light
