import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Divider } from 'antd'
import { FilePdfOutlined, FileJpgOutlined, PrinterOutlined } from '@ant-design/icons'
import { exportReport } from '../utils/exportReport'

function ReportPrintingControls ({ users = null }) {
  const { fromDate, toDate, resultFilter, assigneeFilter, testIdFilter, statusFilter, executionTypeFilter, timeSegment, tests = new Map() } =
    useSelector((state) => state.selectedProject)

  return (
    <><Button
      icon={<PrinterOutlined />} type='text' className='text-left' block
      onClick={() => {
        exportReport('print', fromDate, toDate, timeSegment, resultFilter, statusFilter, assigneeFilter, testIdFilter, executionTypeFilter, users, tests)
      }}
      >Print
    </Button>
      <Divider className='my-0.5' />
      <Button
        icon={<FileJpgOutlined />} type='text' className='text-left' block
        onClick={() => {
          exportReport('JPG', fromDate, toDate, timeSegment, resultFilter, statusFilter, assigneeFilter, testIdFilter, executionTypeFilter, users, tests)
        }}
      >Download JPG
      </Button>
      <br />
      <Button
        icon={<FilePdfOutlined />} type='text' className='text-left' block
        onClick={() => {
          exportReport('PDF', fromDate, toDate, timeSegment, resultFilter, statusFilter, assigneeFilter, testIdFilter, executionTypeFilter, users, tests)
        }}
      >Download PDF
      </Button>
    </>
  )
}

export default ReportPrintingControls
