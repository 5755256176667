import { ResponsiveBar } from '@nivo/bar'
import { Card, Skeleton } from 'antd'
import React from 'react'
import { colorForTestResult } from '../../../utils/colors'

const ExecutionsByWeekChart = ({ loading, executionsByWeek }) => {
  return (
    <Card
      className='shadow-sm rounded-sm col-span-1 relative w-full'
      style={{ height: 'fit-content' }}
      size='small'
      title={<strong className='text-xs'>Weekly Execution results</strong>}
      bodyStyle={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 10, height: 200 }}
    >
      {loading ? (
        <center>
          <Skeleton.Image className='my-2' style={{ width: 150, height: 150 }} />
        </center>
      ) : (
        <div className='absolute w-full h-14/16'>
          <ResponsiveBar
            enableGridY={false}
            data={executionsByWeek.map((obj) => ({
              ...obj,
              Passed: obj.outcome?.filter((outcome) => outcome.result === 'Passed')[0]?.count || 0,
              PassedColor: colorForTestResult.passed,
              Failed: obj.outcome?.filter((outcome) => outcome.result === 'Failed')[0]?.count || 0,
              FailedColor: colorForTestResult.failed,
              Cancelled: obj.outcome?.filter((outcome) => outcome.result === 'Cancelled')[0]?.count || 0,
              CancelledColor: colorForTestResult.cancelled,
              DidNotFinish: obj.outcome?.filter((outcome) => outcome.result === 'DidNotFinish')[0]?.count || 0,
              DidNotFinishColor: colorForTestResult.didnotfinish
            }))}
            keys={['Passed', 'Failed', 'Cancelled', 'DidNotFinish']}
            colorBy='id'
            indexBy='week'
            margin={{ top: 20, bottom: 47, left: 45, right: 10 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            valueFormat={{ format: '', enabled: false }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              legend: 'Week',
              legendPosition: 'middle',
              legendOffset: 25
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Result',
              legendPosition: 'middle',
              legendOffset: -35
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            tooltip={({ id, value, color, indexValue, data }) => (
              <div
                style={{
                  padding: 8,
                  color: 'black',
                  background: 'white',
                  borderRadius: '2px',
                  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)'
                }}
                className='grid'
              >
                <div className='flex items-center justify-start mb-1'>
                  <div
                    className='w-3 h-3'
                    style={{
                      backgroundColor: color
                    }}
                  />
                  <span className='mx-1 text-sm font-semibold'>Week {indexValue}</span>
                </div>
                <div className='grid grid-cols-2'>
                  <span className='text-sm'>Period:</span>
                  <span className='text-sm font-semibold'>{data.range}</span>
                </div>
                <div className='grid grid-cols-2'>
                  <span className='text-sm'>{id}:</span>
                  <span className='text-sm font-semibold'>{value}</span>
                </div>
              </div>
            )}
            colors={({ id, data }) => String(data[`${id}Color`])}
          />
        </div>
      )}
    </Card>
  )
}

export default ExecutionsByWeekChart
