import { notification } from 'antd'
import { call, put } from 'redux-saga/effects'
import appRoutes from '../../statics/appRoutes'
import { getProjectsSuccess, loginSuccess, logoutSuccess, setError, setAccountError } from '../actions'
import amplify, { redirectBackToRequestPage } from '../services/amplify'
import { httpGet } from '../services/testServiceClient'

export function * loginHandle (action) {
  try {
    const loggedIn = yield call(amplify.checkLoggedIn, true)
    if (loggedIn === true) {
      yield put(loginSuccess())
    }
  } catch (e) {
    console.log(e)
  }
}
export function * logoutHandle (action) {
  try {
    yield call(amplify.logOut)
    yield put(logoutSuccess())
  } catch (e) {
    console.log(e)
  }
}

export function * getProjectsHandle (action) {
  try {
    const response = yield call(httpGet, 'projects')
    yield put(getProjectsSuccess(response.data))
  } catch (e) {
    yield put(setError(e.message))
  }
}

export function * getAccountHandle (action) {
  try {
    const response = yield call(httpGet, 'account')
    if (action.payload.type === 'checkIfAccountExpired') return
    localStorage.setItem('AccountName', response.data.Name)
    notification.success({ message: `Logged in to ${response.data.Name}`, placement: 'topRight' })
    if (action.payload.type === 'signup') {
      setTimeout(() => (window.location.href = process.env.REACT_APP_SITE_URL + appRoutes.trialSetup), 1000)
    } else {
      setTimeout(() => {
        redirectBackToRequestPage()
      }, 1000)
    }
  } catch (e) {
    yield put(
      setAccountError(
        e.response.status === 403
          ? `Unauthorized: ${e.response?.data?.detail || e.message}`
          : `Error retrieving acccount: ${e.message}`
      )
    )
    action.payload.callback()
  }
}
