import React, { useState, useEffect } from 'react'
import SwapIcon from '../../../assets/swapIcon.svg'
import { Select, Button, Empty } from 'antd'

import { httpGet } from '../../../store/services/testServiceClient'
import { ENTITY } from '../../../statics/constants'

const ActionPanel = ({
  action,
  setAction,
  entity,
  setEntity,
  componentType,
  setComponentType,
  sourceProjectId,
  setSourceProjectId,
  destinationProjectId,
  setDestinationProjectId,
  disableSourceProjectSelect,
  setSourceProjectName,
  setDestinationProjectName,
  updateUIOnError
}) => {
  const { Option } = Select
  const [projects, setProjects] = useState([])
  const [showProjectsLoading, setShowProjectsLoading] = useState(false)

  const loadProjects = async () => {
    try {
      setShowProjectsLoading(true)
      const response = await httpGet('projects')
      setProjects(response.data)
    } catch (e) {
      updateUIOnError('Error retrieving projects.', e)
    } finally {
      setShowProjectsLoading(false)
    }
  }

  const getProjectNameFromId = (id) => {
    return projects?.find((project) => project.Id === id)?.Name
  }

  const swapProjects = () => {
    setSourceProjectId(destinationProjectId)
    setSourceProjectName(getProjectNameFromId(destinationProjectId))
    setDestinationProjectId(sourceProjectId)
    setDestinationProjectName(getProjectNameFromId(sourceProjectId))
  }

  const resetData = () => {
    setSourceProjectId('')
    setDestinationProjectId('')
    setComponentType('')
  }

  useEffect(() => {
    loadProjects()
  }, [])

  return (
    <>
      <div className='flex flex-col gap-y-6 md:flex-row md:items-center lg:gap-x-64 md:gap-x-32 '>
        <div className='flex flex-col items-start gap-y-1 col-span-2'>
          <p className='font-semibold m-0'>Action:</p>
          <Select
            defaultValue={action}
            placeholder='Select Action'
            style={{ width: 300 }}
            onChange={(value) => setAction(value)}
            dropdownClassName='z-10'
          >
            <Option value='Clone'>Clone</Option>
          </Select>
        </div>
        <div className='flex flex-col items-start gap-y-1 col-span-2 md:ml-2 lg:ml-14'>
          <p className='font-semibold m-0'>Entity:</p>
          <Select
            placeholder='Select Entity'
            style={{ width: 300 }}
            onChange={(value) => {
              setEntity(value)
              resetData()
            }}
            dropdownClassName='z-10'
            value={entity === '' ? undefined : entity}
          >
            {Object.values(ENTITY).map((entityType) => (
              <Option key={entityType} value={entityType}>
                {entityType}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      {entity === ENTITY.COMPONENT && (
        <div className='flex flex-col gap-y-6 md:flex-row md:items-center lg:gap-x-32 md:gap-x-10 mt-6'>
          <div className='flex flex-col items-start gap-y-1 col-span-2'>
            <p className='font-semibold m-0'>Component Type:</p>
            <Select
              placeholder='Select Component Type'
              style={{ width: 300 }}
              onChange={(value) => setComponentType(value)}
              dropdownClassName='z-10'
              value={componentType === '' ? undefined : componentType}
            >
              <Option key='Composite' value='Composite'>
                Composite
              </Option>
              <Option key='Scanned' value='Scanned'>
                Scanned
              </Option>
              <Option key='Scripted' value='Scripted'>
                Scripted
              </Option>
            </Select>
          </div>
        </div>
      )}

      {entity && entity !== ENTITY.PROJECT && (
        <div className='flex flex-col gap-y-6 md:flex-row md:items-center lg:gap-x-32 md:gap-x-10 mt-6'>
          <div className='flex flex-col items-start gap-y-1 col-span-2'>
            <p className='font-semibold m-0'>Source Project:</p>
            <Select
              placeholder='Select Source Project'
              style={{ width: 300 }}
              allowClear
              value={sourceProjectId !== '' ? sourceProjectId : undefined}
              onChange={(value) => {
                setSourceProjectId(value ? value : '')
                setSourceProjectName(value ? getProjectNameFromId(value) : '')
              }}
              showSearch
              optionFilterProp='children'
              filterOption={(searchText, project) => project.label.includes(searchText)}
              filterSort={(projectA, projectB) =>
                projectA.label.toLowerCase().localeCompare(projectB.label.toLowerCase())
              }
              options={projects.map((project) => ({
                value: project.Id,
                label: project.Name,
                disabled: project.Id === destinationProjectId
              }))}
              notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<b>No Projects</b>} />}
              loading={showProjectsLoading}
              dropdownClassName='z-10'
              disabled={entity === ENTITY.COMPONENT ? !componentType : false}
            />
          </div>
          <Button
            type='text'
            className='w-fit hidden md:block'
            style={{ marginTop: '15px' }}
            disabled={!sourceProjectId || !destinationProjectId || disableSourceProjectSelect}
            onClick={swapProjects}
            title='Swap Projects'
          >
            <img src={SwapIcon} alt='Swap Icon' style={{ width: '25px' }} />
          </Button>
          <div className='flex flex-col items-start gap-y-1 col-span-2'>
            <p className='font-semibold m-0'>Destination Project:</p>
            <Select
              placeholder='Select Destination Project'
              style={{ width: 300 }}
              allowClear
              value={destinationProjectId !== '' ? destinationProjectId : undefined}
              onChange={(value) => {
                setDestinationProjectId(value ? value : '')
                setDestinationProjectName(value ? getProjectNameFromId(value) : '')
              }}
              showSearch
              optionFilterProp='children'
              filterOption={(searchText, project) => project.label.includes(searchText)}
              filterSort={(projectA, projectB) =>
                projectA.label.toLowerCase().localeCompare(projectB.label.toLowerCase())
              }
              options={projects.map((project) => ({
                value: project.Id,
                label: project.Name,
                disabled: project.Id === sourceProjectId
              }))}
              notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<b>No Projects</b>} />}
              loading={showProjectsLoading}
              dropdownClassName='z-10'
              disabled={entity === ENTITY.COMPONENT ? !componentType : false}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ActionPanel
