import { getHttpClient } from './httpClient'
import retryRequest from './retryRequest'

const getAnalysisServiceRetryHttpClient = async () => {
  const analysisServiceRetryHttpClient = await getHttpClient(process.env.REACT_APP_EXECUTIONS_ANALYSIS_SERVICE_URL)
  retryRequest(analysisServiceRetryHttpClient)
  return analysisServiceRetryHttpClient
}

const getExecutionsAnalysis = async (data) => {
  const analysisClient = await getAnalysisServiceRetryHttpClient()
  return analysisClient.post('/getExecutionsAnalysis', data)
}

const getExecutions = async (data) => {
  const executionsClient = await getAnalysisServiceRetryHttpClient()
  return executionsClient.post('/getExecutions', data)
}

const getExecutionDuration = async (data) => {
  const executionsClient = await getAnalysisServiceRetryHttpClient()
  return executionsClient.post('/getExecutionsTime', data)
}

const getExecutionsUserList = async (projectId) => {
  const executionsClient = await getAnalysisServiceRetryHttpClient()
  return executionsClient.get('/getExecutionsUsersList', {
    params: {projectId: projectId}
  })
}

const getExecutionsTestAssigneeList = async (projectId) => {
  const executionsClient = await getAnalysisServiceRetryHttpClient()
  return executionsClient.get('/getExecutionTestAssigneeList', {
    params: {projectId: projectId}
  })
}

export { getExecutionsAnalysis, getExecutions, getExecutionDuration, getExecutionsUserList, getExecutionsTestAssigneeList }
