import React, { useState } from 'react'
import { publishInstallerDownloadedEvent } from '../../store/services/trialActivityEventPublisher'
import { Card, Button } from 'antd'

const TrialSetup = () => {
  const [isPublishingEvent, setIsPublishingEvent] = useState(false)
  const download = async () => {
    window.location.href = 'https://download.usemango.co.uk/Install-useMango.msi'
    try {
      setIsPublishingEvent(true)
      await publishInstallerDownloadedEvent()
    } finally {
      setIsPublishingEvent(false)
    }
  }
  return (
    <div className='h-screen'>
      <div className='topbar'>
        <p className='text-center text-4xl px-10 py-16 sm:py-24 font-extrabold text-headerColor'>
          Start testing with useMango.
        </p>
        <div className='content pb-20'>
          <div className='text-center'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
              <div className='sm:mx-10 md:mx-0 col-span-1'>
                <Card
                  title='1. Confirm Email'
                  className='h-full rounded-sm text-white bg-headerColor'
                  bordered={false}
                  headStyle={{ color: 'white' }}
                >
                  <ol style={{ listStyleType: 'none' }}>
                    <li className='text-left my-2'>Click the confirmation link in your email.</li>
                  </ol>
                </Card>
              </div>
              <div className='sm:mx-10 md:mx-0 col-span-1'>
                <Card
                  title='2. Download'
                  className='h-full rounded-sm text-white bg-headerColor'
                  bordered={false}
                  headStyle={{ color: 'white' }}
                >
                  <ol style={{ listStyleType: 'none' }}>
                    <li className='text-left my-2'>Download the useMango installer.</li>
                  </ol>
                  <Button type='ghost' onClick={download} disabled={isPublishingEvent} className='text-white'>
                    Download Here
                  </Button>
                </Card>
              </div>
              <div className='sm:mx-10 md:mx-0 col-span-1'>
                <Card
                  title='3. Install'
                  className='h-full rounded-sm text-white bg-headerColor'
                  bordered={false}
                  headStyle={{ color: 'white' }}
                >
                  <ol>
                    <li className='text-left my-2'>Run the downloaded file</li>
                    <li className='text-left my-2'>useMango will automatically open once installation has finished.</li>
                  </ol>
                </Card>
              </div>
              <div className='sm:mx-10 md:mx-0 col-span-1'>
                <Card
                  title='4. Login'
                  className='h-full rounded-sm text-white bg-headerColor'
                  bordered={false}
                  headStyle={{ color: 'white' }}
                >
                  <ol style={{ listStyleType: 'none' }}>
                    <li className='text-left my-2'>Enter your usemango credentials to login.</li>
                  </ol>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrialSetup
