import { ResponsivePie } from '@nivo/pie'
import React from 'react'

const MultiDatasetPieChart = ({ data }) => {
  return (
    <div style={{ height: 250, width: 250, marginTop: '-45px' }}>
      <ResponsivePie
        data={data}
        innerRadius={0.45}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={2}
        colors={({ id, data }) => data.color}
        margin={{ top: 0, right: 50, bottom: 0, left: 50 }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor='#333333'
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
        arcLinkLabelsDiagonalLength={5}
        arcLinkLabelsStraightLength={0}
        tooltip={({ datum }) => (
          <div
            style={{
              padding: 6,
              color: 'black',
              background: 'white',
              width: 'max-content',
              borderRadius: '2px',
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)'
            }}
          >
            <span className='flex justify-between items-center'>
              <div
                className='mx-1 w-3 h-3'
                style={{
                  backgroundColor: datum.color
                }}
              />
              <div className='mx-1'>{datum.id === 'DidNotFinish' ? 'Not Finished' : datum.id}:</div>
              <b>{datum.value}</b>
            </span>
          </div>
        )}
      />
    </div>
  )
}

export default MultiDatasetPieChart
