import { getHttpClient } from './httpClient'

const getPrintReportServiceClient = async () => {
  return getHttpClient(process.env.REACT_APP_PRINT_REPORT_SERVICE_URL)
}

const getDetailedExecutionReportPdf = async (runId) => {
  const reportServiceHttpClient = await getPrintReportServiceClient()
  return reportServiceHttpClient.get(`/reports/detailedreport?runId=${runId}`)
}

const getSummaryExecutionReportPdf = async (runId) => {
  const reportServiceHttpClient = await getPrintReportServiceClient()
  return reportServiceHttpClient.get(`/reports/summaryreport?runId=${runId}`)
}

export { getDetailedExecutionReportPdf, getSummaryExecutionReportPdf }
