import { getRedirectPath, encodeUrl, isValidRoute } from '../../utils/util'
import { Amplify, Auth } from 'aws-amplify'
import appRoutes from '../../statics/appRoutes.jsx'

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID
  }
})

const publicRoutes = [appRoutes.signupPage, appRoutes.loginPage]
export function redirectBackToRequestPage () {
  const redirectPath = getRedirectPath()
  const siteURL = process.env.REACT_APP_SITE_URL;

  if (redirectPath && isValidRoute(decodeURIComponent(redirectPath))) {
    const nextHref = siteURL + decodeURIComponent(redirectPath)
    window.location.href = nextHref
  } else {
    window.location.href = siteURL
  }
}

function checkLoggedIn (redirectToLogin = true) {
  return Auth.currentAuthenticatedUser()
    .then(() => {
      return true
    })
    .catch(() => {
      if (redirectToLogin) {
        if (window.location.pathname === appRoutes.cfsignupForm) {
          return false
        } else if (!publicRoutes.includes(window.location.pathname)) {
          const redirectPath = `${window.location.pathname}${window.location.search}`
          window.location.href = process.env.REACT_APP_SITE_URL + '/login?' + encodeUrl(redirectPath)
        }
      }
      return false
    })
}

function getUserName () {
  return Auth.currentUserInfo()
    .then((user) => {
      return user.attributes.email
    })
    .catch(() => '')
}

async function getIdToken () {
  await checkLoggedIn()
  const session = await Auth.currentSession()
  localStorage.setItem('Role', session.idToken.payload['custom:role'])
  return session.getIdToken().getJwtToken()
}

function logOut () {
  Auth.signOut()
}

const amplify = { Amplify, Auth, checkLoggedIn, getUserName, getIdToken, logOut }
export default amplify
