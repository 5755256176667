import { Button, Card } from 'antd'
import { ADMINISTRATION_PAGE_UI_STATES } from '../../../statics/constants'
import { CheckCircleFilled, CloseCircleFilled, ArrowLeftOutlined } from '@ant-design/icons'

const ActionResult = ({
  action,
  type,
  resetAdministrationPage,
  setCurrentUIState,
  sourceProjectName,
  destinationProjectName,
  entity,
  customSuccessActionMessage,
  customFailActionMessage
}) => {
  const failureActionVerb = action === 'Clone' ? 'cloning' : action

  return (
    <>
      <Card
        className='shadow-sm col-span-1'
        title={
          <div className='flex items-center'>
            {type === 'success' ? (
              <>
                Success <CheckCircleFilled className='ml-2' style={{ color: '#52c41a' }} />
              </>
            ) : (
              <>
                Fail <CloseCircleFilled className='ml-2' style={{ color: '#ff4d4f' }} />
              </>
            )}
          </div>
        }
        headStyle={{ backgroundColor: 'var(--transparent-color)', fontWeight: 'bold' }}
      >
        {type === 'success' ? (
          <>{customSuccessActionMessage}</>
        ) : (
          customFailActionMessage ?? (
            <p>
              An unexpected error occurred while {failureActionVerb} the {entity}(s) from{' '}
              <span className='font-bold italic mr-1'>{sourceProjectName}</span> to{' '}
              <span className='font-bold italic'>{destinationProjectName}</span>.
            </p>
          )
        )}
      </Card>
      <div className='flex items-center gap-x-6 w-full justify-end'>
        {type === 'fail' && (
          <Button
            className='w-min my-6'
            type='primary'
            onClick={() => setCurrentUIState(ADMINISTRATION_PAGE_UI_STATES.SUMMARY)}
          >
            <ArrowLeftOutlined /> Back
          </Button>
        )}
        <Button className='w-min my-6' type='primary' onClick={() => resetAdministrationPage()}>
          Finish
        </Button>
      </div>
    </>
  )
}

export default ActionResult
