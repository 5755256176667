import React, { useEffect, useState } from 'react'
import ProjectFilters from './ProjectFilters'
import { ADMINISTRATION_PAGE_UI_STATES } from '../../../statics/constants'
import EntityAdministration from './EntityAdministration'
import { httpGet, httpPost } from '../../../store/services/testServiceClient'
import { setProjectProperties } from '../../../store/actions'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch } from 'react-redux'

export const ProjectAdministration = ({
  action,
  entity,
  setEntity,
  setCurrentUIState,
  updateUIOnError,
  currentUIState
}) => {
  const [projects, setProjects] = useState([])
  const [filteredProjects, setFilteredProjects] = useState([])
  const [isActionOnEntityInitiated, setIsActionOnEntityInitiated] = useState(false)
  const [filtersUpdated, setFiltersUpdated] = useState(false)
  const [selectedTableEntities, setSelectedTableEntities] = useState([])
  const [projectFilters, setProjectFilters] = useState({
    projectName: ''
  })
  const [showTableLoader, setShowTableLoader] = useState(false)
  const [clonedProjectData, setClonedProjectData] = useState({ Id: '', Name: '' })

  const history = useHistory()
  const dispatch = useDispatch()

  const projectTableColumns = [
    {
      title: (
        <ProjectFilters
          projectFilters={projectFilters}
          setProjectFilters={setProjectFilters}
          setFiltersUpdated={setFiltersUpdated}
        />
      ),
      dataIndex: 'Name',
      render: (Name) => <>{Name}</>
    }
  ]

  const resetFilters = () => {
    setProjectFilters({
      projectName: ''
    })
  }

  const resetEntityData = () => {
    setFilteredProjects([])
    setSelectedTableEntities([])
  }

  const loadProjects = async () => {
    try {
      setShowTableLoader(true)
      const response = await httpGet('projects')
      setProjects(response.data)
      setFilteredProjects(response.data)
    } catch (e) {
      updateUIOnError('Error retrieving projects.', e)
    } finally {
      setShowTableLoader(false)
    }
  }

  const cloneProject = async () => {
    try {
      setIsActionOnEntityInitiated(true)
      const response = await httpPost('accountadministration/cloneproject', {
        ProjectId: selectedTableEntities[0].Id
      })
      setClonedProjectData({ Id: response.data.Id, Name: response.data.Name })
      setCurrentUIState(ADMINISTRATION_PAGE_UI_STATES.SUCCESS)
    } catch (error) {
      setCurrentUIState(ADMINISTRATION_PAGE_UI_STATES.FAIL)
    } finally {
      setIsActionOnEntityInitiated(false)
    }
  }

  const resetAdministrationPage = () => {
    resetEntityData()
    resetFilters()
    setCurrentUIState(ADMINISTRATION_PAGE_UI_STATES.INITIAL)
    setEntity(null)
    setProjects([])
    setClonedProjectData({ Id: '', Name: '' })
  }

  const applyProjectFilters = () => {
    setFilteredProjects(() =>
      projectFilters.projectName
        ? projects.filter((project) => project.Name.includes(projectFilters.projectName))
        : projects
    )
  }

  useEffect(() => {
    if (filtersUpdated) {
      resetEntityData()
      applyProjectFilters()
      setFiltersUpdated(false)
    }
  }, [filtersUpdated])

  useEffect(() => {
    loadProjects()
  }, [])

  const summaryMessage = <p className='italic text-gray-500'>You are cloning the following project.</p>

  const cloningFailMessage = (
    <p className='italic text-gray-500'> An unexpected error occurred while cloning the project.</p>
  )

  const cloningSuccessMessage = (
    <>
    <p>
      Project cloning request is queued successfully. This can take a few minutes to complete. <br/>
      Once it is completed, a new project '{clonedProjectData.Name}' will be created. <br/>
      You'll also receive a notification by email. <br/>
      You can change this project's name from the Project Settings Page.
    </p>
    <div class="text-orange-700" role="alert">
      <svg class="fill-current h-3 w-3 text-orange-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"></path>
      </svg>
        It is recommended that all source project modifications should be postponed until the cloning is complete.
    </div>
    </>
  )

  return (
    <EntityAdministration
      action={action}
      entity={entity}
      entities={filteredProjects}
      tableColumns={projectTableColumns}
      showTableLoader={showTableLoader}
      selectedTableEntities={selectedTableEntities}
      setSelectedTableEntities={setSelectedTableEntities}
      actionOnSelectedEntity={cloneProject}
      resetAdministrationPage={resetAdministrationPage}
      isActionOnEntityInitiated={isActionOnEntityInitiated}
      currentUIState={currentUIState}
      setCurrentUIState={setCurrentUIState}
      customSummaryMessage={summaryMessage}
      customFailActionMessage={cloningFailMessage}
      customSuccessActionMessage={cloningSuccessMessage}
    />
  )
}
