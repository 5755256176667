import React, { useState, useEffect, useRef } from 'react'
import { formatDate, getTodaysDate, toApproximateDuration } from '../../utils/dateTime'
import { getReport } from '../../store/services/reportServiceClient.js'
import { Alert, Spin, notification } from 'antd'
import footerLogo from '../../assets/footerLogo.png'
import html2pdf from 'html2pdf.js'
import './SummaryReport.css'
import GetExecutionStatusIcon from '../../components/Report/GetExecutionStatusIcons'
import { getTestResultColor, scrollToSection, transformEnvVariables } from '../../utils/util'
import VariableDisplay from './components/VariableDisplay'

const SummaryReport = (props) => {
  const [ErrorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [run, setRun] = useState('')
  const [transformedEnvVariables, setTransformedEnvVariables] = useState([])
  const envSectionRef = useRef()
  const datasetSectionRef = useRef()

  const loadReport = async () => {
    try {
      const response = await getReport(props.match.params.runId)
      console.log(response)
      if (response.status === 200) {
        const envVariables = response.data?.TestEnvironment?.Variables || []
        setRun(response.data)
        const transformedTestEnvVars = await Promise.all(
          envVariables.map((variable) => transformEnvVariables(variable, response.data.RunId, true))
        )
        setTransformedEnvVariables(transformedTestEnvVars)
      } else {
        setErrorMessage('Error loading report')
      }
    } catch (e) {
      if (e.response) {
        switch (e.response.status) {
          case 404:
            setErrorMessage(e.response.data.detail)
            break
          case 500:
            setErrorMessage(e.response.data)
            break
          default:
            break
        }
      }
      setErrorMessage(e.message)
    } finally {
      setIsLoading(false)
    }
  }
  const millisToMinutesAndSeconds = (millis) => {
    const seconds = ((millis % 60000) / 1000).toFixed(2)
    return seconds
  }

  useEffect(() => {
    loadReport()
  }, [])

  const printDocument = () => {
    const element = document.getElementById('divToPrint')
    const opt = {
      margin: 12,
      filename: 'Summary Report_' + getTodaysDate() + '.pdf',
      image: { type: 'jpeg', quality: 1 },
      jsPDF: { unit: 'mm', orientation: 'p', format: 'a4' },
      pagebreak: { avoid: ['.avoidPageBreak'] },
      html2canvas: {
        dpi: 144,
        scale: 2
      }
    }
    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(
        notification.success({
          message: 'Downloaded Successfully',
          placement: 'topRight'
        })
      )
  }

  const scrollToEnvSection = () => scrollToSection(envSectionRef)

  const scrollToDatasetSection = () => scrollToSection(datasetSectionRef)

  useEffect(() => {
    if (!isLoading) printDocument()
  }, [isLoading])

  return (
    <div className='mt-3 '>
      {ErrorMessage && (
        <Alert
          showIcon
          closable
          type='error'
          description={ErrorMessage}
          message='Error'
          afterClose={() => setErrorMessage('')}
        />
      )}
      {isLoading && (
        <div className='flex justify-center items-start'>
          <Spin>
            <Alert
              className='sm:w-96'
              message='Please wait'
              description='Your Summary report is loading...'
              type='info'
            />
          </Spin>
        </div>
      )}

      {!isLoading && ErrorMessage.length === 0 && (
        <div className='rounded-sm mx-auto' id='divToPrint'>
          <div className='grid grid-cols-1 sm:flex items-baseline'>
            <span className=' text-lg font-bold mr-2'>Test Name:</span>
            <h3 className='text-lg text-blue-500'>
              <strong>{run.TestName}</strong>
            </h3>
          </div>
          <span className='text-lg text-left font-bold '>Result:</span>
          <strong className='ml-2 text-lg' style={{ color: getTestResultColor(run.Result) }}>
            {run.Result}
          </strong>
          <div className='grid grid-cols-1 sm:flex sm:justify-between ml-0 mt-3'>
            <ul className='sm:w-5/12 px-0'>
              <div className='flex justify-between items-baseline'>
                <span className='text-md font-bold'>Started At:</span>
                <span className='text-md'>{formatDate(run.StartTime)}</span>
              </div>
              <div className='flex justify-between items-baseline'>
                <span className='text-md font-bold'>Duration:</span>
                <span className='text-md'>{toApproximateDuration(run.Duration)}</span>
              </div>
              <div className='flex justify-between items-baseline mt-3'>
                <span className='text-md font-bold mr-2'>Dataset:</span>
                <span
                  className='cursor-pointer'
                  title={run.Scenario && 'Click to scroll to the Dataset Parameters section'}
                  onClick={run.Scenario && scrollToDatasetSection}
                >
                  {run.Scenario} {run.Scenario && <i className='fa fa-link fa-sm text-blue-500 ml-1' />}
                </span>
              </div>
              <div className='flex justify-between align-items-baseline'>
                <span className='text-md font-bold'>Environment</span>
                <span
                  className={`text-md ${!run?.TestEnvironment?.Name ? 'italic text-gray-400' : 'cursor-pointer'}`}
                  title={run?.TestEnvironment?.Name && 'Click to scroll to the Environment Variables section'}
                  onClick={run?.TestEnvironment?.Name && scrollToEnvSection}
                >
                  {run?.TestEnvironment?.Name ? (
                    <>
                      {run.TestEnvironment.Name}
                      <i className='fa fa-link fa-sm text-blue-500 ml-1' />{' '}
                    </>
                  ) : (
                    'Not Specified'
                  )}
                </span>
              </div>
            </ul>
            <ul className='sm:w-5/12 px-0'>
              <li className='flex justify-between items-baseline'>
                <span className='text-md font-bold mr-2'>Run Id:</span>
                <span className='text-md'>{run.RunId}</span>
              </li>
              <li className='flex justify-between items-baseline'>
                <span className='text-md font-bold'>User:</span>
                <span className='text-md'>{run.User.Name}</span>
              </li>
              <li className='flex justify-between items-baseline'>
                <span className='text-md font-bold'>Machine:</span>
                <span className='text-md'>{run.Environment.Machine}</span>
              </li>
              <li className='flex justify-between items-baseline'>
                <span className='text-md font-bold'>Operating System:</span>
                <span className='text-md text-right'>{run.Environment.OSVersion}</span>
              </li>
              <li className='flex justify-between items-baseline'>
                <span className='text-md font-bold'>useMango Version:</span>
                <span className='text-md'>{run.Environment.UMVersion}</span>
              </li>
            </ul>
          </div>
          <div>
            <span className='text-xl font-bold'>Summary Report</span>
            <div id='table' className='mt-4'>
              <div className='tr'>
                <div className='td ' style={{ width: 70, fontSize: 15, fontWeight: 'bold' }}>
                  Step
                </div>
                <div className='td ' style={{ width: 570, fontSize: 15, fontWeight: 'bold' }}>
                  Step Name
                </div>
                <div className='td ' style={{ width: 470, fontSize: 15, fontWeight: 'bold' }}>
                  Component
                </div>
                <div className='td ' style={{ width: 100, fontSize: 15, fontWeight: 'bold' }}>
                  Duration
                </div>
                <div className='td ' style={{ width: 70, fontSize: 15, fontWeight: 'bold' }}>
                  Result
                </div>
              </div>
              {run.Root.Nodes.map((item) => (
                <div className='tr avoidPageBreak'>
                  <div className='td'>
                    <p className='font-mono text-base'>
                      {item.StepNum}
                      {item.Type === 'Branch' ? (
                        <i className='fas fa-code-branch w-5 inline-block cursor-pointer pl-2' />
                      ) : item.Type === 'Loop' ? (
                        <i className='fas fa-undo w-5 inline-block cursor-pointer pl-2' />
                      ) : null}
                    </p>
                  </div>
                  <div className='td'>{item.StepName}</div>
                  <div className='td'>
                    {item.Result === 'Skipped' ? (
                      <p class='ml-auto my-auto text-gray-400 italic'>Step Skipped</p>
                    ) : (
                      item.ComponentName
                    )}
                  </div>
                  <div className='td'>{millisToMinutesAndSeconds(item.Duration ? item.Duration : 0)}s</div>
                  <div className='td text-center'>
                    {(() => {
                      return GetExecutionStatusIcon(item.Result)
                    })()}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='mt-8 w-full' ref={datasetSectionRef}>
            <VariableDisplay title='Dataset Parameters' loading={false} data={run.Parameters} />
          </div>
          <div className='mt-8 w-full' ref={envSectionRef}>
            <VariableDisplay title='Environment Variables' loading={isLoading} data={transformedEnvVariables} />
          </div>
          <p className='text-right mt-10'>
            <img src={footerLogo} alt='' />
            <i>Report generated by useMango.</i>
          </p>
        </div>
      )}
    </div>
  )
}

export default SummaryReport
