import { Alert, Card, Empty, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { httpGet, httpPut } from '../../../store/services/testServiceClient'

const Environment = ({ selectedProject }) => {
  const [projectEnvironments, setProjectEnvironments] = useState([])
  const [nextCursor, setNextCursor] = useState('')
  const [loadingEnvironments, setLoadingEnvironments] = useState(false)
  const [defaultEnvironment, setDefaultEnvironment] = useState('')
  const [environmentAlertInfo, setEnvironmentAlertInfo] = useState({ message: '', isSuccess: true })

  const loadEnvironments = async (loadMore = false) => {
    try {
      const response = await httpGet(`projects/${selectedProject.Id}/environments?orderBy=Name&cursor=${nextCursor}`)
      const environments = response.data.Items
      if (loadMore) setProjectEnvironments((prev) => [...prev, ...environments])
      else setProjectEnvironments(environments)
      setNextCursor(response.data.Info.Next)
    } catch (e) {
      console.log(e)
      throw new Error(`Failed to fetch environments for project '${selectedProject.Name}'.`)
    }
  }

  const getDefaultEnvironment = async () => {
    try {
      const response = await httpGet(`projects/${selectedProject.Id}/environments/default`)
      setDefaultEnvironment(response.data.Id)
    } catch (e) {
      console.log(e)
      throw new Error(`Failed to fetch default environment for project '${selectedProject.Name}'.`)
    }
  }

  const fetchProjectEnvironments = async () => {
    try {
      setLoadingEnvironments(true)
      await loadEnvironments()
      await getDefaultEnvironment()
    } catch (error) {
      setEnvironmentAlertInfo({
        message: `${error.message}`,
        isSuccess: false
      })
    } finally {
      setLoadingEnvironments(false)
    }
  }

  const getEnvironmentNameFromId = (envId) => projectEnvironments.find((env) => env.Id === envId).Name

  const updateDefaultProjectEnvironment = async (newDefaultEnvId) => {
    try {
      setLoadingEnvironments(true)
      await httpPut(`projects/${selectedProject.Id}/environments/${newDefaultEnvId}/default`)
      setDefaultEnvironment(newDefaultEnvId)
      setEnvironmentAlertInfo({
        message: `Default environment successfully set to '${getEnvironmentNameFromId(newDefaultEnvId)}'.`,
        isSuccess: true
      })
    } catch (e) {
      console.log(e)
      setEnvironmentAlertInfo({
        message: `Failed to set the default environment to '${getEnvironmentNameFromId(newDefaultEnvId)}'.`,
        isSuccess: false
      })
    } finally {
      setLoadingEnvironments(false)
    }
  }

  const clearEnvironmentState = () => {
    setDefaultEnvironment('')
    setProjectEnvironments([])
    setNextCursor('')
    setEnvironmentAlertInfo({ message: '', isSuccess: true })
  }

  useEffect(() => {
    if (selectedProject.Id) {
      clearEnvironmentState()
      fetchProjectEnvironments()
    }
  }, [selectedProject.Id])

  useEffect(() => {
    if (nextCursor) {
      setLoadingEnvironments(true)
      loadEnvironments(true)
        .catch((error) => {
          console.log(error)
          setEnvironmentAlertInfo({
            message: `Error fetching environments for project '${selectedProject.Name}'.`,
            isSuccess: false
          })
        })
        .finally(() => {
          setLoadingEnvironments(false)
        })
    }
  }, [nextCursor])

  return (
    <Card>
      <h3 className='font-semibold'>Environment</h3>
      <h4 className='mb-3'>
        Manage default environment selection here. Tests containing environment variables will be executed using this
        default environment.
      </h4>
      {environmentAlertInfo.message.length > 0 && !loadingEnvironments && (
        <Alert
          message={environmentAlertInfo.message}
          type={environmentAlertInfo.isSuccess ? 'success' : 'error'}
          closable
          showIcon
          afterClose={() => {
            setEnvironmentAlertInfo({ message: '', isSuccess: true })
          }}
          className='mb-2'
        />
      )}
      <div className='flex items-center justify-start flex-wrap gap-x-8 gap-y-2'>
        <p className='text-sm font-semibold m-0'>Select Environment:</p>
        <Select
          dropdownClassName='z-10'
          value={defaultEnvironment !== '' ? defaultEnvironment : undefined}
          placeholder='Select Environment'
          style={{ width: 300 }}
          onChange={(value) => updateDefaultProjectEnvironment(value)}
          showSearch
          filterOption={(searchText, environment) => environment.label.includes(searchText)}
          options={projectEnvironments.map((env) => ({
            value: env.Id,
            label: env.Name
          }))}
          disabled={loadingEnvironments}
          notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<b>No Environments</b>} />}
          loading={loadingEnvironments}
        />
      </div>
    </Card>
  )
}

export default Environment
