import React from 'react'
import { Checkbox, Tooltip } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'

const CheckboxWithTooltip = ({ isChecked, isDisabled, onChange, label, tooltipTitle }) => {
  const handleChange = (e) => {
    onChange(e.target.checked)
  }

  return (
    <>
      <Checkbox className='mt-1' checked={isChecked} onChange={handleChange} disabled={isDisabled}>
        {label}
      </Checkbox>
      <Tooltip
        title={tooltipTitle}
        color='#e6f7ff'
        overlayInnerStyle={{ color: 'black', minWidth: '350px' }}
        placement='right'
      >
        <InfoCircleFilled style={{ color: 'blue' }} />
      </Tooltip>
    </>
  )
}

export default CheckboxWithTooltip
