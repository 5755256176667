import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateProjectProperties } from '../../../store/actions'
import { getDayDifference, getTimezoneOffset } from '../../../utils/dateTime'

import { Select } from 'antd'
const { Option } = Select

function TimeBucketSelector ({ buckets }) {
  const dispatch = useDispatch()
  const { toDate, fromDate, timeSegment } = useSelector((state) => state.selectedProject)

  const bucketInfo =
    [{
      value: 'month',
      name: 'Monthly'
    }, {

      value: 'week',
      name: 'Weekly'
    },
    {
      value: 'day',
      name: 'Daily'
    },
    {
      value: 'hour',
      name: `Hourly (${getTimezoneOffset()})`,
      disabled: getDayDifference(fromDate, toDate) > 6
    }]

  return (
    <Select
      defaultValue={timeSegment}
      style={{ width: 120 }}
      value={timeSegment}
      className='monthFilter'
      onChange={(value) => dispatch(updateProjectProperties({ timeSegment: value }))}
    >
      {bucketInfo.map(({ value, name, disabled = false }) => (
        buckets.includes(value) &&
          <Option value={value} disabled={disabled}>
            {name}
          </Option>
      ))}

    </Select>
  )
}

export default TimeBucketSelector
