// The code needs to be refactored, a ticket UMDEV-5145 has been already created for the same.
import React, { useCallback, useEffect, useState } from 'react'
import { httpGet, httpPost, httpDelete, httpPut } from '../../store/services/testServiceClient.js'
import SettingsNavigation from '../../components/Navigation/SettingsNavigation'
import Breadcrumb from '../../components/Navigation/Breadcrumb'
import { Button, Input, Alert, List, Card, Modal, Table, Spin, Form, Typography, Tag } from 'antd'
import { EditOutlined, InfoCircleFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { setProjectsRequireRefresh } from '../../store/actions/index.js'
import Environment from './components/Environment.jsx'
const { Text } = Typography

const ProjectSettings = () => {
  const [alertText, setAlertText] = useState('')
  const [userSelectedToAdd, setUserSelectedToAdd] = useState('')
  const [availableUsersForProject, setAvailableUsersForProject] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [projectName, setProjectName] = useState('')
  const [updateProjectName, setUpdateProjectName] = useState('')
  const [projectKey, setProjectKey] = useState('')
  const [editing, setEditing] = useState(false)
  const [selectedProject, setSelectedProject] = useState({ Id: '', Name: '', users: [] })
  const [showProgress, setShowProgress] = useState('')
  const [usersAlertText, setUsersAlertText] = useState('')
  const [showUsersProgress, setShowUsersProgress] = useState('')
  const [infoMessage, setInfoMessage] = useState('')
  const [projects, setProjects] = useState('')
  const [isSuccess, setIsSuccess] = useState('')
  const [isUsersAlertSuccess, setIsUsersAlertSuccess] = useState('')
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const dispatch = useDispatch()

  const [hasNameError, setHasNameError] = useState(false)
  const [hasRenameError, setHasRenameError] = useState(false)
  const [hasKeyError, setHasKeyError] = useState(false)

  const [form] = Form.useForm()

  const projectNameChanged = (e) => {
    setProjectName(e.target.value)
    setHasKeyError(false)
    const nameExists = projects.some((project) => project.Name === e.target.value)
    setHasNameError(nameExists)
  }

  const handleProjectRename = (e) => {
    setUpdateProjectName(e.target.value)
    const nameExists = projects.some(
      (project) => project.Name !== selectedProject.Name && project.Name === e.target.value
    )
    setHasRenameError(nameExists)
  }

  const projKeyChanged = (e) => {
    const projectNameInUpperCase = e.target.value.toUpperCase()
    setProjectKey(projectNameInUpperCase)
    const keyExists = projects.some((project) => project.Id.toUpperCase() === projectNameInUpperCase)
    setHasKeyError(keyExists)
  }

  const autoGenerateProjectKey = (e) => {
    let projKeyEntered = e.target.value
    let count = 1
    while (projects.filter(({ Id }) => Id.toUpperCase() === projKeyEntered.toUpperCase()).length) {
      projKeyEntered = `${e.target.value}-${count++}`
    }
    setProjectKey(projKeyEntered.toUpperCase())
  }

  const renameProject = async (e) => {
    e.preventDefault()
    showProgressUI(`Renaming project ${selectedProject.Name} to ${updateProjectName}`)
    try {
      const projectInfo = { Id: selectedProject.Id, Name: updateProjectName }
      await httpPut(`projects/${selectedProject.Id}`, projectInfo)
      updateUIOnSuccess(`Project '${selectedProject.Name}' renamed successfully to '${updateProjectName}'`)
      setSelectedProject({ Id: selectedProject.Id, Name: updateProjectName, users: [] })
      setProjects((projects) => projects.map((project) => (project.Id === projectInfo.Id ? projectInfo : project)))
      setEditing(false)
      dispatch(setProjectsRequireRefresh(true))
    } catch (e) {
      updateUIOnErrors([{ message: `Error renaming project '${selectedProject.Name}' `, response: e.response }])
    }
  }

  const initializeProjectsPage = useCallback(async () => {
    showProgressUI('Loading')
    const errors = []
    const promises = [loadProjects(), loadAllUsers()]
    await Promise.all(promises.map((p) => p.catch((e) => errors.push(e))))
    if (errors.length) {
      updateUIOnErrors(errors)
    } else {
      updateUIOnSuccess()
    }
  }, [])

  const showProgressUI = (message) => {
    setAlertText('')
    setShowProgress(true)
    setInfoMessage(`${message}...`)
  }

  const loadProjects = async () => {
    try {
      const response = await httpGet('projects')
      setProjects(response.data)
    } catch (e) {
      return Promise.reject({ message: 'Error retrieving projects.', response: e.response })
    }
  }

  const loadAllUsers = async () => {
    try {
      const response = await httpGet('users')
      for (let i = 0; i < response.data.length; i++) {
        setAllUsers((prev) => {
          return [...prev, response.data[i].Email]
        })
      }
    } catch (e) {
      return Promise.reject({ message: 'Error retrieving users.', response: e.response })
    }
  }

  const show = (id, projectName) => {
    if (selectedProject.Name !== projectName) {
      setAvailableUsersForProject(allUsers)
      setSelectedProject({ Id: id, Name: projectName, users: [] })
      setUserSelectedToAdd('')
    }
  }

  const createProject = async (e) => {
    e.preventDefault()
    showProgressUI(`Creating project ${projectName}`)
    try {
      const projectInfo = { Id: projectKey, Name: projectName }
      await httpPost('projects', projectInfo)
      setProjects((prev) => {
        return [...prev, projectInfo]
      })
      setSelectedProject({ Id: projectKey, Name: projectName, users: [] })
      updateUIOnSuccess(`Project '${projectName}' added successfully`)
      setProjectName('')
      setProjectKey('')
      dispatch(setProjectsRequireRefresh(true))
    } catch (e) {
      updateUIOnErrors([{ message: `Error creating project '${projectName}'`, response: e.response }])
    }
  }

  const deleteSelectedProject = async () => {
    setIsDeleteModalVisible(false)
    showProgressUI(`Deleting project ${projectName}`)
    try {
      await httpDelete(`projects/${selectedProject.Id}`)
      removeListedProject(selectedProject.Name)
      updateUIOnSuccess(`Project '${selectedProject.Name}' deleted successfully`)
      setSelectedProject((prev) => {
        return { ...prev, Name: '', Id: '' }
      })
      dispatch(setProjectsRequireRefresh(true))
    } catch (e) {
      updateUIOnErrors([{ message: `Error deleting project '${selectedProject.Name}'`, response: e.response }])
    }
  }

  const loadProjectUsers = useCallback(async () => {
    showProgressUIForProjectUsers('Loading')
    try {
      const response = await httpGet(`projects/${selectedProject.Id}/users`)
      if (response.data.length) {
        const users = []
        for (let i = 0; i < response.data.length; i++) {
          const projectUser = response.data[i].Email
          users.push(projectUser)
        }
        setAvailableUsersForProject(allUsers.filter((user, index) => !users.includes(user)))
        setSelectedProject((prev) => {
          return { ...prev, users }
        })
        updateProjectUsersUIOnSuccess()
      } else {
        setSelectedProject((prev) => {
          return { ...prev, users: [] }
        })
        setAvailableUsersForProject(allUsers)
        setInfoMessage('No users configured for this project.')
      }
    } catch (e) {
      updateProjectUsersUIOnError(`Error loading project users for ${selectedProject.Name}`, e)
    }
  }, [allUsers, selectedProject.Name])

  const deleteProjectUser = async (user) => {
    showProgressUIForProjectUsers(`Removing user ${user}`)
    try {
      await httpDelete(`projects/${selectedProject.Id}/users/${user}`)
      await loadProjectUsers()
      updateProjectUsersUIOnSuccess(`User ${user} removed successfully`)
    } catch (e) {
      updateProjectUsersUIOnError(`Error removing user '${user}'`, e)
    }
  }

  const addUserToProject = async () => {
    if (userSelectedToAdd === null) {
      return
    }
    showProgressUIForProjectUsers(`Adding user ${userSelectedToAdd}`)
    try {
      await httpPost(`projects/${selectedProject.Id}/users/${userSelectedToAdd}`)
      await loadProjectUsers()
      updateProjectUsersUIOnSuccess(`User ${userSelectedToAdd} added successfully`)
      setUserSelectedToAdd('')
    } catch (e) {
      updateProjectUsersUIOnError(`Error adding user '${userSelectedToAdd}'`, e)
    }
  }

  const removeListedProject = (projectName) => {
    setProjects((prevProjects) => prevProjects.filter((p) => p.Name !== projectName))
  }

  const updateUIOnSuccess = (message = '') => {
    setShowProgress(false)
    setIsSuccess(true)
    setAlertText(message)
  }

  const updateUIOnErrors = (errors) => {
    setShowProgress(false)
    setIsSuccess(false)
    const errorMessages = []
    errors.forEach((e) => {
      const hasErrorMessage = e.response != null && e.response.data != null && e.response.data.trim().length > 0
      const responseString = hasErrorMessage ? `: ${e.response.data}` : ''
      errorMessages.push(`${e.message} ${responseString}`)
      console.log(e)
    })
    setAlertText(errorMessages.join('\n'))
    setInfoMessage('')
  }

  const showProgressUIForProjectUsers = (message) => {
    setUsersAlertText('')
    setShowUsersProgress(true)
    setInfoMessage(`${message}...`)
  }

  const updateProjectUsersUIOnSuccess = (message = '') => {
    setShowUsersProgress(false)
    setIsUsersAlertSuccess(true)
    setUsersAlertText(message)
  }

  const updateProjectUsersUIOnError = (message, e) => {
    setShowUsersProgress(false)
    setIsUsersAlertSuccess(false)
    const hasErrorMessage = e.response != null && e.response.data != null && e.response.data.trim().length > 0
    const responseString = hasErrorMessage ? `: ${e.response.data}` : ''
    setUsersAlertText(`${message} ${responseString}`)
    setInfoMessage('')
    console.log(e)
  }
  useEffect(() => {
    initializeProjectsPage()
  }, [initializeProjectsPage])

  const showModalDialogForDelete = () => {
    setAlertText('')
    setIsDeleteModalVisible(true)
  }
  const columns = [
    {
      dataIndex: 'users',
      render: (text, item) => (
        <>
          <span>{item}</span>
        </>
      )
    },
    {
      dataIndex: 'selectedProject.users',
      render: (text, item) => (
        <>
          <Button type='link' onClick={() => deleteProjectUser(item)}>
            <span className='fa fa-trash' />
          </Button>
        </>
      )
    }
  ]

  const editProjectName = (currentProjectName) => {
    setUpdateProjectName(currentProjectName)
    setEditing(true)
  }

  useEffect(() => {
    if (selectedProject.Id) {
      loadProjectUsers()
      setEditing(false)
      setUpdateProjectName('')
    }
  }, [selectedProject.Id, loadProjectUsers])

  return localStorage.getItem('Role') === 'Administrator' ? (
    <div>
      <Breadcrumb />
      <SettingsNavigation />
      <Modal
        title='Delete Project'
        visible={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        width={600}
        footer={[
          <Button type='primary' onClick={() => deleteSelectedProject()}>
            Delete
          </Button>,
          <Button type='ghost' onClick={() => setIsDeleteModalVisible(false)}>
            Cancel
          </Button>
        ]}
      >
        <p>Confirm that you want to delete.</p>
      </Modal>
      <div className='mt-3'>
        {alertText.length > 0 && (
          <Alert
            message={alertText}
            type={isSuccess ? 'success' : 'error'}
            closable
            showIcon
            afterClose={() => {
              setAlertText('')
            }}
            className='mb-2'
          />
        )}
        {!showProgress ? (
          <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
            <div className='grid grid-cols-1 gap-y-6'>
              {projects.length > 0 && !showProgress && (
                <List
                  bordered='false'
                  dataSource={projects}
                  className='shadow-sm col-span-1'
                  style={{ overflow: 'auto', maxHeight: '70vh' }}
                  renderItem={(projects) => (
                    <List.Item
                      onClick={() => show(projects.Id, projects.Name)}
                      className={
                        projects.Name === selectedProject.Name
                          ? 'bg-blue-600 text-white'
                          : 'hover:bg-transparentColor cursor-pointer hover:text-black hover:transition duration-200 '
                      }
                      name={projects.Name}
                      key={projects.Name}
                    >
                      {projects.Name}
                    </List.Item>
                  )}
                />
              )}
              <Card
                className='shadow-sm col-span-1'
                title='Create a new project'
                headStyle={{ backgroundColor: 'var(--transparent-color)', fontWeight: 'bold' }}
              >
                <Form form={form} layout='vertical'>
                  <Form.Item label='Project Name' required>
                    <Input
                      placeholder='Enter project name'
                      onChange={projectNameChanged}
                      onInput={autoGenerateProjectKey}
                      value={projectName}
                      className={hasNameError ? 'error' : ''}
                    />
                    {hasNameError && <div className='error-message'>A project with that name already exists.</div>}
                  </Form.Item>
                  <Form.Item
                    label='Project Key'
                    tooltip={{
                      color: '#e6f7ff',
                      overlayInnerStyle: {
                        color: 'black',
                        minWidth: '310px'
                      },
                      title: 'Project key once entered cannot be changed',
                      icon: <InfoCircleFilled style={{ color: 'blue' }} />
                    }}
                    required
                  >
                    <Input
                      placeholder='Enter project key'
                      onChange={projKeyChanged}
                      value={projectKey}
                      className={hasKeyError ? 'error' : ''}
                    />
                    {hasKeyError && <div className='error-message'>Another project uses this project key.</div>}
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type='primary'
                      className='w-full'
                      disabled={projectName === '' || projectKey === '' || hasNameError || hasKeyError}
                      onClick={createProject}
                    >
                      Add
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </div>
            <div className='col-span-1 md:col-span-2 h-96'>
              <Card
                className='shadow-sm'
                title={
                  projects.length > 0 && !showProgress ? (
                    selectedProject.Name.length > 0 ? (
                      <div style={{ height: '60px' }} className='flex items-center'>
                        {!editing && (
                          <div className='flex flex-col gap-y-2'>
                            <div>
                              <Text>{selectedProject.Name}</Text>
                              <Button
                                onClick={() => editProjectName(selectedProject.Name)}
                                type='link'
                                shape='circle'
                                icon={<EditOutlined />}
                              />
                            </div>
                            <Tag className='border-none text-gray-500 bg-gray-200' style={{ width: 'fit-content' }}>
                              <i>{selectedProject.Id}</i>
                            </Tag>
                          </div>
                        )}
                        {editing && (
                          <div className='flex w-full'>
                            <div className='flex flex-col sm:w-1/2'>
                              <Input
                                className='sm:inline-block mr-4 mt-3'
                                value={updateProjectName}
                                onChange={handleProjectRename}
                                placeholder='Enter new name'
                              />
                              <div className='error-message mt-1' style={{ height: '15px' }}>
                                {hasRenameError && 'A project with that name already exists.'}
                              </div>
                            </div>
                            <Button
                              type='default'
                              className='sm:block mr-1 ml-10 mt-3'
                              onClick={() => {
                                setEditing(false)
                                setHasRenameError(false)
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              disabled={
                                updateProjectName === '' || selectedProject.Name === updateProjectName || hasRenameError
                              }
                              id='changeAccountName'
                              type='primary'
                              onClick={renameProject}
                              className='mt-3'
                            >
                              Submit
                            </Button>
                          </div>
                        )}
                        {showProgress && (
                          <div id='infoMessage' className='mt-3'>
                            <Spin>
                              <Alert
                                className='sm:w-96 mx-auto'
                                message='Please wait'
                                description={infoMessage}
                                type='info'
                              />
                            </Spin>
                          </div>
                        )}
                      </div>
                    ) : (
                      'No project selected'
                    )
                  ) : (
                    'No projects exist'
                  )
                }
                extra={
                  selectedProject.Name.length > 0 && (
                    <Button
                      type='primary'
                      danger
                      id='deleteProjectBttn'
                      className='h-10 mr-2'
                      onClick={() => showModalDialogForDelete()}
                    >
                      <span className='fa fa-trash' />
                    </Button>
                  )
                }
                headStyle={{ backgroundColor: 'var(--transparent-color)', fontWeight: 'bold', padding: '0px 16px' }}
              >
                {selectedProject.Name.length > 0 ? (
                  <div className='grid grid-cols-1 gap-y-6'>
                    <Environment selectedProject={selectedProject} />
                    <Card>
                      <h3 className='font-semibold'>Users</h3>
                      <h4 className='mb-3'>
                        Manage project users here. Project users can run tests, create or update tests, components and
                        scripts.
                      </h4>
                      {usersAlertText.length > 0 && !showUsersProgress && (
                        <Alert
                          message={usersAlertText}
                          type={isUsersAlertSuccess ? 'success' : 'error'}
                          closable
                          showIcon
                          afterClose={() => {
                            setUsersAlertText('')
                          }}
                          className='mb-2'
                        />
                      )}
                      <Input
                        id='addProjectUserInput'
                        name='Project user'
                        className='mt-1 mb-1'
                        list='availableUsers'
                        value={userSelectedToAdd}
                        required
                        onChange={(e) => setUserSelectedToAdd(e.target.value)}
                        placeholder='Select an user to add'
                        style={{ maxWidth: 300 }}
                      />
                      <datalist id='availableUsers'>
                        {availableUsersForProject.map((availableUser) => (
                          <option key={availableUser}>{availableUser}</option>
                        ))}
                      </datalist>
                      <Button
                        id='addProjectUserBttn'
                        onClick={() => addUserToProject()}
                        type='primary'
                        className='ml-2 mb-1'
                        disabled={!userSelectedToAdd}
                      >
                        Add user
                      </Button>
                      {selectedProject.users.length > 0 && !showUsersProgress ? (
                        <Table
                          showHeader={false}
                          columns={columns}
                          dataSource={selectedProject.users}
                          pagination={false}
                          className='mt-3'
                          size='small'
                        />
                      ) : (
                        <div id='infoMessage' className='mt-3'>
                          {infoMessage &&
                            (infoMessage === 'Loading...' ? (
                              <>
                                <Spin>
                                  <Alert
                                    className='sm:w-96 mx-auto'
                                    message='Please wait'
                                    description={infoMessage}
                                    type='info'
                                  />
                                </Spin>
                              </>
                            ) : (
                              <Alert className='sm:w-96' description={infoMessage} showIcon type='info' />
                            ))}
                        </div>
                      )}
                    </Card>
                  </div>
                ) : (
                  <>
                    {projects.length > 0 && !showProgress ? (
                      <p>Select a project from the list.</p>
                    ) : (
                      <p>Create a new project and select it from the list.</p>
                    )}
                  </>
                )}
              </Card>
            </div>
          </div>
        ) : (
          <div id='infoMessage' className='mt-3'>
            <Spin>
              <Alert className='sm:w-96 mx-auto' message='Please wait' description={infoMessage} type='info' />
            </Spin>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Alert message='Error' description='Unauthorized Access' showIcon type='warning' className='mt-3' />
  )
}

export default ProjectSettings
