import { publicEmailDomains } from '../statics/publicemaildomains'
import { signupTestEmailAddress } from '../statics/constants'
import { httpGet } from '../store/services/zeroBounceClient'

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

const validate = async (email) => {
  if (!email) {
    return false
  }
  return email.toLowerCase() === signupTestEmailAddress ? true : await validateEmailWithZB(email)
}

const validateEmailWithZB = async (email) => {
  try {
    const response = await httpGet(`validate?email=${email}`)
    return response.data.status !== 'invalid'
  } catch (err) {
    return false
  }
}

const checkIfBuisnessEmail = (email) =>
  email.toLowerCase() === signupTestEmailAddress ? true : !publicEmailDomains.includes(email.split('@')[1])

const isEmail = (email) => {
  return email.match(emailRegex)
}

const validateEmail = {
  isValid: validate,
  isBuisnessEmail: checkIfBuisnessEmail,
  isEmail
}

export default validateEmail
