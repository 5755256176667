import React, { useEffect, useState } from 'react'
import { Button, Input, Select } from 'antd'
import { TEST_STATUSES } from '../../../statics/constants'
import TagsIcon from '../../../assets/tagsIcon.svg'
import AssigneeIcon from '../../../assets/assigneeIcon.svg'
import SortIcon from '../../../assets/sortIcon.svg'
import FilterIcon from '../../../assets/filterIcon.svg'

import { httpGet } from '../../../store/services/testServiceClient'

const TestFilters = ({ testFilters, setTestFilters, sourceProject, setFiltersUpdated, updateUIOnError }) => {
  const { status, assignee, sortBy, tags, testName } = testFilters
  const [searchText, setSearchText] = useState(testName)
  const [users, setUsers] = useState(new Map())
  const [projectTags, setProjectTags] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadProjectUsers = async () => {
    try {
      const response = await httpGet(`projects/${sourceProject}/users`)
      const newUsers = new Map()
      response.data.forEach((user) => {
        newUsers.set(user.Id, user.Email)
      })
      setUsers(newUsers)
    } catch (e) {
      updateUIOnError('Error loading project users.', e)
    }
  }

  const loadProjectTags = async () => {
    try {
      const response = await httpGet(`projects/${sourceProject}/testtags`)
      setProjectTags(response.data)
    } catch (error) {
      updateUIOnError('Error retrieving project tags.', error)
    }
  }

  useEffect(() => {
    const loadFiltersData = async () => {
      setIsLoading(true)
      await Promise.all([loadProjectUsers(), loadProjectTags()])
      setIsLoading(false)
    }
    if (sourceProject) loadFiltersData()
  }, [sourceProject])

  useEffect(() => {
    setSearchText(testName)
  }, [testName])

  return (
    <div className='flex items-center justify-start gap-x-4'>
      <div className='flex items-center gap-x-2'>
        <div className='flex items-center text-xs gap-x-2'>
          <img src={SortIcon} alt='Sort by Icon' />
          <span>Sort By:</span>
        </div>
        <Select
          value={sortBy}
          onChange={(value) => {
            setTestFilters({ ...testFilters, sortBy: value })
            setFiltersUpdated(true)
          }}
          options={[
            { value: 'Name', label: 'Name' },
            { value: '', label: 'Date' }
          ]}
          className='text-xs w-full'
          size='small'
          style={{ width: 130 }}
          disabled={!sourceProject}
        />
      </div>
      <div className='flex items-center gap-x-2'>
        <div className='flex items-center text-xs gap-x-1'>
          <img src={FilterIcon} alt='Filter Icon' />
          <span>Status:</span>
        </div>
        <Select
          value={status}
          onChange={(value) => {
            setTestFilters({ ...testFilters, status: value })
            setFiltersUpdated(true)
          }}
          options={[
            { value: '', label: 'All Status' },
            ...TEST_STATUSES.map((status) => ({
              value: status,
              label: status
            }))
          ]}
          className='text-xs w-full'
          size='small'
          style={{ width: 130 }}
          disabled={!sourceProject}
        />
      </div>
      <div className='flex items-center gap-x-2'>
        <div className='flex items-center text-xs gap-x-1'>
          <img src={AssigneeIcon} alt='Assignee Icon' />
          <span>Assignee:</span>
        </div>
        <Select
          value={assignee}
          onChange={(value) => {
            setTestFilters({ ...testFilters, assignee: value })
            setFiltersUpdated(true)
          }}
          options={[
            { value: 'Anybody', label: 'Anybody' },
            { value: '', label: 'Nobody' },
            ...Array.from(users).map(([Id, email]) => ({
              value: Id,
              label: email
            }))
          ]}
          className='text-xs w-full'
          size='small'
          style={{ width: 230 }}
          disabled={!sourceProject}
          loading={isLoading}
        />
      </div>
      <div className='flex items-center gap-x-2'>
        <div className='flex items-center text-xs gap-x-2'>
          <img src={TagsIcon} alt='Tags Icon' />
          <span>Tags:</span>
        </div>
        <Select
          mode='multiple'
          maxTagCount='responsive'
          placeholder='Select Tags'
          value={tags}
          onChange={(value) => {
            setTestFilters({ ...testFilters, tags: value })
            setFiltersUpdated(true)
          }}
          options={projectTags.map((tag) => ({
            value: tag,
            label: tag
          }))}
          className='text-xs w-full'
          size='small'
          style={{ width: 230 }}
          disabled={!sourceProject}
          loading={isLoading}
        />
      </div>
      <div className='flex items-center gap-x-2'>
        <Input
          placeholder='Filter by test name'
          className='rounded-sm testNameFilter w-full'
          suffix={
            <Button
              size='small'
              type='primary'
              icon={<i className='fa fa-search align-middle' style={{ fontSize: '10px' }} />}
              onClick={() => {
                setTestFilters({ ...testFilters, testName: searchText })
                setFiltersUpdated(true)
              }}
            />
          }
          style={{ fontSize: '12px', width: 270, paddingRight: 0 }}
          size='small'
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'Tab') {
              setTestFilters({ ...testFilters, testName: e.target.value })
              setFiltersUpdated(true)
            }
          }}
          disabled={!sourceProject}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
    </div>
  )
}

export default TestFilters
