import React from 'react'
import { Divider } from 'antd'
const Footer = () => {
  return (
    <div className='py-1'>
      <Divider className='mt-2 mb-3' />
      <footer>
        <p className='mb-0 mx-5 '>&copy; {new Date().getFullYear()} - useMango Ltd.</p>
      </footer>
    </div>
  )
}
export default Footer
