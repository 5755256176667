import React, { useCallback, useEffect, useState } from 'react'

import { classNames } from '../../../utils/styles'
import { formatDate, toApproximateDuration } from '../../../utils/dateTime'
import { Button, Divider, Spin } from 'antd'
import { getAttachmentUrl } from '../../../utils/reports'
import GetExecutionStatusIcon from '../../../components/Report/GetExecutionStatusIcons'
import { allowedSequences } from '../../../utils/util'

const AtomicReportNode = ({ node, runId, root, showAttachment }) => {
  const [expandDetails, setExpandDetails] = useState(false)
  const [inputs, setInputs] = useState([])
  const [outputs, setOutputs] = useState([])
  const [result, setResult] = useState('Passed')
  const [started, setStarted] = useState()
  const [isBranch, setIsBranch] = useState()
  const [isLeftBranchSelected, setIsLeftBranchSelected] = useState()
  const [isRightBranchSelected, setIisRightBranchSelected] = useState()
  const [canDisplayLogMessages, setCanDisplayLogMessages] = useState()
  const [isSeq, setIsSeq] = useState()
  const [message, setMessage] = useState('')
  const [duration, setDuration] = useState(0)
  const [imgIdentifiers, setImgIdentifiers] = useState({})
  const [isLoop, setIsLoop] = useState()
  const [isIteration, setIsIteration] = useState()

  const summaryResult = useCallback(() => {
    return node.Result
  }, [node])

  useEffect(() => {
    if (node) {
      setStarted(formatDate(node.StartTime))
      setIsBranch(node.Type === 'Branch')
      setIsLeftBranchSelected(node.Type === 'Branch' && inputs[0]?.Argument?.value === inputs[1]?.Argument?.value)
      setIisRightBranchSelected(
        node.Type === 'Branch' && !(node.Type === 'Branch' && inputs[0]?.Argument?.value === inputs[1]?.Argument?.value) && result === 'Passed'
      )
      setCanDisplayLogMessages(node.Type === 'Step' && node.Lines.length > 0)
      setIsSeq(allowedSequences.includes(node.Type))
      setMessage(node.Message || '')
      setDuration(toApproximateDuration(node.Duration ? node.Duration : 0))
      setIsLoop(node.Type === 'Loop')
      setIsIteration(node.Type === 'Iteration')
    }
  }, [node, inputs, result])

  useEffect(() => {
    async function getImages () {
      const imgUrlsLoaded = Object.values(imgIdentifiers).some((url) => Boolean(url))
      if (expandDetails && !imgUrlsLoaded) {
        await setUrl()
      }
    }
    getImages()
  }, [expandDetails])

  const onShowImage = (event) => {
    const ref = event.currentTarget.dataset.ref
    showAttachment(ref)
  }

  async function setUrl () {
    for (const imageId in imgIdentifiers) {
      const url = await getAttachmentUrl(runId, imageId)
      setImgIdentifiers((prevImgIdentifiers) => ({
        ...prevImgIdentifiers,
        [imageId]: url
      }))
    }
  }

  const paramArgToViewModel = (paramArg) => {
    // older report contains keyvalue pair for parameters {{key,value}}
    // new report contains list of parameters {{Key,name,value }}
    let arg = {}
    if (paramArg.Value && typeof paramArg.Value === 'object') {
      const type = paramArg.Value.ArgType ? paramArg.Value.ArgType : paramArg.Value.$type
      switch (type) {
        case 'PlainText':
        case 'ArgPlainText':
          const regex = /^<datatypes.ImageIdentifier object at (0x[\dA-Fa-f]+)>$/
          const isImageIdentifer = regex.test(paramArg.Value.Text)
          arg = {
            value: paramArg.Value.Text,
            type,
            isImageIdentifer
          }
          break
        case 'ArgIdentifier':
        case 'Identifier':
        case 'SimpleIdentifier':
        case 'ArgSimpleIdentifier':
          arg = {
            value: paramArg.Value.Method + '=' + paramArg.Value.Specification,
            type
          }
          break
        case 'ImageIdentifier':
        case 'ArgImageIdentifier':
          setImgIdentifiers((prevImgIdentifiers) => ({
            ...prevImgIdentifiers,
            [paramArg.Value.Id]: ''
          }))
          arg = {
            id: paramArg.Value.Id,
            type
          }
          break
        case 'Secret':
        case 'ArgObscureSecret':
          // Reports generated with V1 don't have the Text field
          arg = {
            value: paramArg.Value?.Text || '******',
            type: 'Secret'
          }
          break
        default:
          arg = {
            value: paramArg?.Value?.Text || 'No value found',
            type
          }
          break
      }
    } else if (typeof paramArg.Value === 'string') {
      arg = {
        value: paramArg.Value,
        type: typeof paramArg.Value
      }
    }
    return { Id: paramArg.Key, Name: paramArg.Name, Argument: arg }
  }

  const getArgumentValue = (input, imgIdentifiers) => {
    if (input.Argument?.type === 'ImageIdentifier' || input.Argument?.isImageIdentifer || input.type) {
      return (
        <div className='flex items-center ml-3'>
          <Spin spinning={!imgIdentifiers[input.Argument.id] && !input.Argument?.isImageIdentifer} size='small'>
            <img
              className='text-xs w-20 mr-1'
              src={imgIdentifiers[input.Argument.id]}
              alt={`${
                input.Argument?.isImageIdentifer
                  ? 'Image cannot be displayed'
                  : `${imgIdentifiers[input.Argument.id] ? input.Argument?.type : ''}`
              }`}
            />
          </Spin>
          {input.Argument?.value}
        </div>
      )
    } else {
      return input.Argument?.value
    }
  }

  useEffect(() => {
    if (node) {
      setResult(summaryResult())
    }
  }, [node])

  useEffect(() => {
    if (node.Parameters) {
      setInputs(node.Parameters.map(paramArgToViewModel))
    }
    if (node.Outputs) {
      setOutputs(node.Outputs.map(paramArgToViewModel))
    }
    if (node.Type !== 'Step' || node.Type !== 'Branch') {
      setExpandDetails(root)
    }
  }, [node.Outputs, node.Parameters, node.Type, root])

  return (
    <>
      <li className='ml-1 sm:ml-2 list-none'>
        {root ? (
          <div>
            <h2>Completed steps</h2>
          </div>
        ) : (
          <div>
            {/* Displays the primary information for an executed step in a single row. */}
            <div className='flex'>
              {/* This section displays expander buttons for sub-steps and for the step's details, and also the step number. */}
              <div className='mr-2'>
                {isBranch ? (
                  <i
                    className={classNames(
                      isBranch ? 'text-violet-600' : '',
                      'fas fa-code-branch w-5 inline-block cursor-pointer'
                    )}
                  />
                ) : isLoop ? (
                  <i className='fas fa-undo w-5 inline-block cursor-pointer' />
                ) : (
                  <span className='w-5 inline-block cursor-pointer'>&nbsp;</span>
                )}
              </div>
              <div>
                <span className='font-mono text-base'>
                  {isIteration ? node.CurrentIteration : node.StepNum}
                </span>
                <i
                  className={classNames(
                    isBranch ? 'text-violet-600' : result === 'Skipped' ? 'text-gray-400' : '',
                    `fa fa-fw fa-lg cursor-pointer fa-caret-${expandDetails ? 'down' : 'right'}`
                  )}
                  onClick={() => setExpandDetails(!expandDetails)}
                  aria-hidden='true'
                />
              </div>
              <div
                className={
                  classNames(isBranch && 'text-violet-600', 'w-1/4') &&
                  classNames(result === 'Skipped' && 'text-gray-400', 'w-1/4')
                }
              >
                {isIteration
                  ? `${node.Type}  ${node.CurrentIteration.replaceAll('I', '-').split('-').pop()}`
                  : node.StepName}
              </div>
              {message.length > 0 && !expandDetails && (
                <div className={(isBranch && 'text-violet-600') || (result === 'Skipped' && 'text-gray-400')}>
                  {result === 'Failed' && <i className='fas fa-exclamation-circle mr-2 text-red-500' />}
                  {message.length > 80 ? message.substring(0, 80) + ' ...' : message}
                </div>
              )}

              {/* This section displays icons for each file attached to the step and also the pass / fail icon */}
              <div className={result != 'Failed' ? 'ml-auto' : 'ml-auto flex items-center'}>
                {node.Attachments?.map((attachment) => (
                  <Button key={attachment} type='text' className='p-1 mr-1' data-ref={attachment} onClick={onShowImage}>
                    <i className='fa fa-image' />
                  </Button>
                ))}

                {(() => {
                  return (
                    <div className=' flex items-center justify-center'>
                      {result === 'Failed' ? (
                        <span className='text-red-500 italic pl-1 align-baseline pr-2'>Failed</span>
                      ) : (
                        ''
                      )}
                      {GetExecutionStatusIcon(result)}
                    </div>
                  )
                })()}
              </div>
            </div>
            {/* Displays complete remaining details related to the executed step. This is only shown when the step details are expanded. */}
            {expandDetails ? (
              <div className='text-sm sm:pl-8 mx-4 my-2'>
                <div
                  className={`mb-2 break-words ${
                    (isBranch && 'text-violet-600') || (result === 'Skipped' && 'text-gray-400')
                  }`}
                >
                  {result === 'Failed' && message.length > 0 && (
                    <i className='fas fa-exclamation-circle mr-2 text-red-500' />
                  )}
                  {result === 'Cancelled' ? (
                    <span className='ml-1 italic py-1 ' style={{ color: 'rgb(253 115 197 / 62%)', fontWeight: 600 }}>
                      {message}
                    </span>
                  ) : message}
                </div>
                {isBranch === true ? (
                  <div>
                    <div className='grid grid-cols-5'>
                      <div className='col-span-1'>Started at </div>
                      <div className='col-span-4'>{started} </div>
                    </div>
                    <div className='grid grid-cols-5'>
                      <div className='col-span-1'>Duration </div>
                      <div className='col-span-4'>{duration} </div>
                    </div>
                    <div className='flex flex-col'>
                      <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-sm'>
                            <table className='min-w-full divide-y divide-gray-200'>
                              <thead className='bg-gray-50'>
                                <th
                                  colSpan='2'
                                  className='px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider'
                                >
                                  <div className='text-center flex justify-center items-center'>
                                    <span className='uppercase px-1'>Decision variable value is </span>
                                    {getArgumentValue(inputs[0], imgIdentifiers)}
                                  </div>
                                </th>
                              </thead>
                              <tbody className='bg-white divide-y divide-gray-200'>
                                <tr>
                                  <td className='px-6 py-4 whitespace-nowrap'>
                                    <div
                                      className={classNames(
                                        isBranch && isLeftBranchSelected && 'text-green-500',
                                        'text-center flex items-center'
                                      )}
                                    >
                                      Branch left when value is {getArgumentValue(inputs[1], imgIdentifiers)}
                                    </div>
                                  </td>
                                  <td className='px-6 py-4 whitespace-nowrap'>
                                    <div
                                      className={classNames(
                                        isBranch && isRightBranchSelected && 'text-green-500',
                                        'text-center flex items-center'
                                      )}
                                    >
                                      Branch right when
                                      {inputs[2]?.Argument?.value ? (
                                        <span className='px-1'>
                                          {' '}
                                          value is {getArgumentValue(inputs[2], imgIdentifiers)}
                                        </span>
                                      ) : (
                                        <span className='px-1'> any other value</span>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className='grid grid-cols-5'>
                      <div className='col-span-1'>Started at </div>
                      <div className='col-span-4'>{started} </div>
                    </div>
                    <div className='grid grid-cols-5'>
                      <div className='col-span-1'>Duration </div>
                      <div className='col-span-4'>{duration} </div>
                    </div>
                    {node.ComponentName && (
                      <div className='grid grid-cols-5'>
                        <div className='col-span-1'>Component </div>
                        <div className='col-span-4'>{node.ComponentName} </div>
                      </div>
                    )}
                    {isLoop && (
                      <div className='grid grid-cols-5'>
                        <div className='col-span-1'>Iterations</div>
                        <div className='col-span-4'>{Number(node.Iterations) && Number(node.Iterations) >= 0 ? node.Iterations : <>
                          <i className='fas fa-exclamation-circle mr-2 text-red-500' />Invalid
                        </>}
                        </div>
                      </div>
                    )}
                    {inputs.length > 0 && (
                      <div>
                        <div className='grid grid-cols-5'>
                          <div className='col-span-1'>Inputs</div>
                          <div className='col-span-4 grid grid-cols-3'>
                            <div>
                              <p className='text-xs mb-1 text-left'> Id </p>
                              <Divider className='my-1' />
                            </div>
                            <div>
                              <p className='text-xs mb-1 text-left'> Name </p>
                              <Divider className='my-1' />
                            </div>
                            <div>
                              <p className='text-xs mb-1 text-left'> Value </p>
                              <Divider className='my-1' />
                            </div>
                          </div>
                        </div>
                        {inputs.map((param) => (
                          <div key={param.Key}>
                            <div className='grid grid-cols-5'>
                              <div className='col-span-1' />
                              <div className='col-span-4 grid grid-cols-3 pb-1'>
                                <p className='text-xs mb-1 text-left'>{param.Id}</p>
                                <p className='text-xs mb-1 text-left'>{param.Name}</p>
                                <p
                                  className={`${
                                    param.Argument?.type === 'Secret' && 'text-gray-400 select-none'
                                  } text-xs mb-1 text-left break-all`}
                                >
                                  {getArgumentValue(param, imgIdentifiers)}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {outputs.length > 0 && (
                      <>
                        <div className='grid grid-cols-5'>
                          <div className='col-span-1'>Outputs</div>
                          <div className='col-span-4 grid grid-cols-3'>
                            <div>
                              <p className='text-xs mb-1 text-left'> Id </p>
                              <Divider className='my-1' />
                            </div>
                            <div>
                              <p className='text-xs mb-1 text-left'> Name </p>
                              <Divider className='my-1' />
                            </div>
                            <div>
                              <p className='text-xs mb-1 text-left'> Value </p>
                              <Divider className='my-1' />
                            </div>
                          </div>
                        </div>
                        {outputs.map((param) => (
                          <div key={param.Key}>
                            <div className='grid grid-cols-5'>
                              <div className='col-span-1' />
                              <div className='col-span-4 grid grid-cols-3'>
                                <p className='text-xs mb-1 text-left'>{param.Id}</p>
                                <p className='text-xs mb-1 text-left'>{param.Name}</p>
                                <p className='text-xs mb-1 text-left break-all'>{param.Argument.value}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                    {canDisplayLogMessages && (
                      <div className='flex flex-col pt-3'>
                        <span className='mt-1 mr-3'>Log messages</span>
                        <ul className='p-0'>
                          {node.Lines.map((line, index) => (
                            <li className='flex items-baseline p-0' key={index}>
                              <small className='text-gray-500'>
                                <em>{line.Type}</em>
                              </small>
                              <span className='ml-2'>{line.Message}</span>
                            </li>
                          ))}
                        </ul>
                        {result === 'Failed' && (
                          <div className='my-2'>
                            <p className='break-words'>
                              <i className='fas fa-exclamation-circle text-red-600 mr-2' />
                              <span className='mr-1 text-red-600'>Error: </span> {message}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        )}
        {/* Displays the list of child steps for the current step, if any. */}
        {isSeq && expandDetails && (
          <ul className='px-0 py-2'>
            {node.Nodes?.map((item) => (
              <AtomicReportNode node={item} runId={runId} key={item.NodeId} showAttachment={showAttachment} />
            ))}
          </ul>
        )}
      </li>
    </>
  )
}

export default AtomicReportNode
