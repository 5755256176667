import React, { useState, useRef, useEffect } from 'react'
import { ADMINISTRATION_PAGE_UI_STATES } from '../../../statics/constants'
import EntityAdministration from './EntityAdministration'
import { httpGet, httpPost } from '../../../store/services/testServiceClient'
import ComponentFilters from './ComponentFilters'

const ComponentAdministration = ({
  action,
  entity,
  componentType,
  setEntity,
  setComponentType,
  setCurrentUIState,
  sourceProjectId,
  setSourceProjectId,
  destinationProjectId,
  setDestinationProjectId,
  updateUIOnError,
  setDisableSourceProjectSelect,
  currentUIState,
  sourceProjectName,
  destinationProjectName,
  reuseUnchangedArtefactsWhileCloning,
  setReuseUnchangedArtefactsWhileCloning
}) => {
  const [components, setComponents] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const pageInfoRef = useRef({})
  const [showInfiniteScrollLoader, setShowInfiniteScrollLoader] = useState(false)
  const [showTableLoader, setShowTableLoader] = useState(false)
  const [isActionOnEntityInitiated, setIsActionOnEntityInitiated] = useState(false)
  const [filtersUpdated, setFiltersUpdated] = useState(false)
  const [selectedTableEntities, setSelectedTableEntities] = useState([])
  const [componentFilters, setComponentFilters] = useState({
    status: '',
    sortBy: 'Name',
    tags: [],
    componentName: '',
    searchText: ''
  })

  const componentsTableColumns = [
    {
      title: (
        <ComponentFilters
          componentFilters={componentFilters}
          setComponentFilters={setComponentFilters}
          sourceProject={sourceProjectId}
          setFiltersUpdated={setFiltersUpdated}
          updateUIOnError={updateUIOnError}
          componentType={componentType}
        />
      ),
      dataIndex: 'Name',
      render: (componentName) => <>{componentName}</>
    }
  ]

  const resetFilters = () => {
    setComponentFilters({
      status: '',
      sortBy: 'Name',
      tags: [],
      componentName: '',
      searchText: ''
    })
  }
  const resetEntityData = () => {
    setComponents([])
    setSelectedTableEntities([])
    setHasMore(false)

    pageInfoRef.current = null
  }

  const createFilters = () => {
    const baseFilters = {
      orderBy: componentFilters.sortBy,
      cursor: !pageInfoRef.current ? '' : pageInfoRef.current.Next
    }

    if (componentType === 'Composite') {
      return {
        filter: componentFilters.componentName.trim(),
        status: componentFilters.status,
        tags: componentFilters.tags.length > 0 ? componentFilters.tags.join(',') : '',
        ...baseFilters
      }
    } else {
      return {
        type: componentType === 'Scanned' ? 'ScannedComponent' : 'ScriptedComponent',
        searchText: componentFilters.componentName.trim(),
        ...baseFilters
      }
    }
  }

  const loadComponents = async (loadMore = false) => {
    try {
      loadMore ? setShowInfiniteScrollLoader(true) : setShowTableLoader(true)
      setDisableSourceProjectSelect(true)

      const queryStringParameters = new URLSearchParams(createFilters()).toString()
      const URL = `projects/${sourceProjectId}/${componentType === 'Composite' ? 'testpartindex' : 'simplecomponents'}`
      const response = await httpGet(`${URL}?${queryStringParameters}`)

      if (loadMore) {
        setComponents((prev) => [...prev, ...response.data.Items])
      } else {
        setComponents(response.data.Items)
      }

      pageInfoRef.current = response.data.Info
      setHasMore(pageInfoRef.current?.HasNext)
    } catch (e) {
      updateUIOnError('Error retrieving tests.', e)
    } finally {
      loadMore ? setShowInfiniteScrollLoader(false) : setShowTableLoader(false)
      setDisableSourceProjectSelect(false)
    }
  }

  const loadMoreComponents = async () => {
    if (pageInfoRef.current != null && pageInfoRef.current?.HasNext) {
      try {
        await loadComponents(true)
      } catch (e) {
        updateUIOnError('Error retrieving tests.', e)
      }
    }
  }

  const cloneComponents = async () => {
    try {
      setIsActionOnEntityInitiated(true)
      await httpPost('accountadministration/clonecomponents', {
        ComponentIds: selectedTableEntities.map((entity) => entity.Id),
        SourceProjectId: sourceProjectId,
        DestinationProjectId: destinationProjectId,
        ReuseUnchangedArtefacts: reuseUnchangedArtefactsWhileCloning,
        sourceId: null,
        correlationId: null
      })
      setCurrentUIState(ADMINISTRATION_PAGE_UI_STATES.SUCCESS)
    } catch (error) {
      setCurrentUIState(ADMINISTRATION_PAGE_UI_STATES.FAIL)
    } finally {
      setIsActionOnEntityInitiated(false)
    }
  }

  const resetAdministrationPage = () => {
    resetEntityData()
    setSourceProjectId('')
    resetFilters()
    setDestinationProjectId('')
    setCurrentUIState(ADMINISTRATION_PAGE_UI_STATES.INITIAL)
    setReuseUnchangedArtefactsWhileCloning(false)
    setComponentType('')
    setEntity('')
  }

  useEffect(() => {
    resetFilters()
    setFiltersUpdated(true)
  }, [sourceProjectId, componentType])

  useEffect(() => {
    if (filtersUpdated) {
      resetEntityData()
      if (sourceProjectId) loadComponents()
      setFiltersUpdated(false)
    }
  }, [filtersUpdated])

  const cloningSuccessMessage = (
    <p>
      Cloning complete! View your cloned components now in useMango's '<span className='font-semibold'>Components</span>
      ' section.
    </p>
  )

  return (
    sourceProjectId !== '' && (
      <EntityAdministration
        action={action}
        entity={entity}
        entities={components}
        tableColumns={componentsTableColumns}
        showTableLoader={showTableLoader}
        showInfiniteScrollLoader={showInfiniteScrollLoader}
        loadMore={loadMoreComponents}
        hasMore={hasMore}
        selectedTableEntities={selectedTableEntities}
        setSelectedTableEntities={setSelectedTableEntities}
        sourceProjectId={sourceProjectId}
        destinationProjectId={destinationProjectId}
        sourceProjectName={sourceProjectName}
        destinationProjectName={destinationProjectName}
        reuseUnchangedArtefactsWhileCloning={reuseUnchangedArtefactsWhileCloning}
        setReuseUnchangedArtefactsWhileCloning={setReuseUnchangedArtefactsWhileCloning}
        actionOnSelectedEntity={cloneComponents}
        resetAdministrationPage={resetAdministrationPage}
        isActionOnEntityInitiated={isActionOnEntityInitiated}
        currentUIState={currentUIState}
        setCurrentUIState={setCurrentUIState}
        customSuccessActionMessage={cloningSuccessMessage}
      />
    )
  )
}

export default ComponentAdministration
