import React, { useState } from 'react'
import { Button, Popover } from 'antd'
import { withRouter } from 'react-router-dom'

const PdfDownloadDropdown = ({ executionType, runId, match, downloadIcon, downloadSummaryReport }) => {
  const [showPopover, setShowPopover] = useState(false)
  const isMultiDatasetExecution = executionType === 'MultiDataset' || executionType === 'Batch'
  const projectId = match.params.project

  const handleDownloadSummaryReport = () => {
    if (downloadSummaryReport) {
      downloadSummaryReport()
    } else {
      const downloadUrl = isMultiDatasetExecution
        ? `/p/${projectId}/executions/${runId}?download=true`
        : `/p/${projectId}/executions/summaryreport/${runId}`

      window.open(downloadUrl, '_blank')
    }
    setShowPopover(false)
  }

  const handleDownloadDetailedReport = () => {
    window.open(`/p/${projectId}/executions/detailedreport/${runId}`, '_blank')
    setShowPopover(false)
  }

  return (
    <Popover
      visible={showPopover}
      onVisibleChange={(value) => setShowPopover(value)}
      content={
        <div className='flex flex-col'>
          <Button className='my-1 filterDropdownItem' onClick={handleDownloadSummaryReport}>
            Summary Report
          </Button>
          {!isMultiDatasetExecution && (
            <Button className='my-1 filterDropdownItem' onClick={handleDownloadDetailedReport}>
              Detailed Report
            </Button>
          )}
        </div>
      }
      title={<span className='filterDropdownItem'>Download PDF</span>}
      placement='bottom'
      trigger='click'
      zIndex='1'
    >
      {downloadIcon || <i className='fas fa-download text-gray-500 ml-3 cursor-pointer' />}
    </Popover>
  )
}

export default withRouter(PdfDownloadDropdown)
