import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import { classNames } from '../../../utils/styles'
import './UMDatePicker.css'
import { useSelector } from 'react-redux'

export default function UMDatePicker (props) {
  const { fromDate, toDate, periodLabel } = useSelector((state) => state.selectedProject)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [inputStartDate, setInputStartDate] = useState('')
  const [inputEndDate, setInputEndDate] = useState('')
  const [dateFilterVisible, setDateFilterVisible] = useState(false)
  const [instantDateFilter, setInstantDateFilter] = useState('')
  const [invalidStartDate, setInvalidStartDate] = useState(false)
  const [invalidEndDate, setInvalidEndDate] = useState(false)

  const formatDateForInputDisplay = (date) => {
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
  }

  const formatDateForDropdownDisplay = (date) => {
    return `${date.toLocaleString('default', { month: 'long' }).substr(0, 3)} ${('0' + date.getDate()).slice(
      -2
    )}, ${date.getFullYear()}`
  }

  useEffect(() => {
    selectStartDate(fromDate)
    selectEndDate(toDate)
  }, [])

  useEffect(() => {
    if (startDate) setInputStartDate(formatDateForInputDisplay(startDate))
  }, [startDate])

  useEffect(() => {
    if (endDate) setInputEndDate(formatDateForInputDisplay(endDate))
  }, [endDate])

  useEffect(() => {
    const selectDateRange = async (range) => {
      const newDate = new Date()
      const year = newDate.getFullYear()
      const month = newDate.getMonth()
      const date = newDate.getDate()
      const day = newDate.getDay()
      switch (range) {
        case 'Today':
          setStartDate(new Date(year, month, date))
          setEndDate(new Date(year, month, date))
          break
        case 'Yesterday':
          setStartDate(new Date(year, month, date - 1))
          setEndDate(new Date(year, month, date - 1))
          break
        case 'This Week':
          setStartDate(new Date(year, month, date - day + 1))
          setEndDate(new Date(year, month, date))
          break
        case 'This Month':
          setStartDate(new Date(year, month, 1))
          setEndDate(new Date(year, month, date))
          break
        case 'Last 7 Days':
          setStartDate(new Date(year, month, date - 6))
          setEndDate(new Date(year, month, date))
          break
        case '1 Month':
          setStartDate(new Date(year, month - 1, date))
          setEndDate(new Date(year, month, date))
          break
        case '6 Months':
          setStartDate(new Date(year, month - 6, date))
          setEndDate(new Date(year, month, date))
          break
        case '3 Months':
          setStartDate(new Date(year, month - 3, date))
          setEndDate(new Date(year, month, date))
          break
        default:
          break
      }
    }

    if (instantDateFilter) {
      selectDateRange(instantDateFilter)
    }
  }, [instantDateFilter])

  const selectStartDate = (date) => {
    setStartDate(date)
    date ? setInputStartDate(formatDateForInputDisplay(date)) : setInputStartDate('')
    setInstantDateFilter('')
    setInvalidStartDate(false)
  }

  const selectEndDate = (date) => {
    setEndDate(date)
    date ? setInputEndDate(formatDateForInputDisplay(date)) : setInputEndDate('')
    setInstantDateFilter('')
    setInvalidEndDate(false)
  }

  const applyDateFilter = () => {
    startDate !== '' && props.setFromDate(startDate)
    endDate !== '' && props.setToDate(endDate)
    if (instantDateFilter) props.setDropdownDateRangeValue(instantDateFilter)
    else {
      props.setDropdownDateRangeValue(
        `${formatDateForDropdownDisplay(startDate)} - ${formatDateForDropdownDisplay(endDate)}`
      )
    }
    setDateFilterVisible(false)
  }

  const clearDateFilter = () => {
    setInstantDateFilter('')
    setStartDate('')
    setEndDate('')
    setInputEndDate('')
    setInputStartDate('')
    props.setFromDate('')
    props.setToDate('')
    props.setDropdownDateRangeValue('')
  }

  const checkValidDate = (dateString) => {
    if (!/^\d{4}\/\d{1,2}\/\d{1,2}$/.test(dateString)) return false
    const parts = dateString.split('/')
    const day = parseInt(parts[2], 10)
    const month = parseInt(parts[1], 10)
    const year = parseInt(parts[0], 10)
    if (year < 1000 || year > 3000 || month === 0 || month > 12) return false
    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29
    if (!(day > 0 && day <= monthLength[month - 1])) return false
    if (new Date(dateString) > new Date()) return false
    else return true
  }

  return (
    <Dropdown
      overlay={
      (
        <Menu>
          <div className='py-1.5 px-3'>
            <div className='flex items-center justify-start mt-1  text-sm'>
              <span className='mr-2 font-bold text-xs'>From: </span>
              <input
                type='text'
                className={classNames(
                  'p-1 rounded-sm focus:outline-none focus:ring-1  focus:border-blue-300',
                  invalidStartDate && inputStartDate
                    ? 'bg-red-100 border border-solid  border-red-400 '
                    : 'border border-solid border-gray-300'
                )}
                style={{ width: '100px' }}
                placeholder='YYYY/MM/DD'
                value={inputStartDate}
                onChange={(e) => {
                  setInputStartDate(e.target.value)
                  setInstantDateFilter('')
                  if (checkValidDate(e.target.value)) {
                    if (new Date(e.target.value) > endDate) {
                      selectEndDate(new Date(e.target.value))
                    }
                    setStartDate(new Date(e.target.value))
                    setInvalidStartDate(false)
                  } else {
                    setInvalidStartDate(true)
                  }
                }}
              />
              <span className='font-bold text-xs' style={{ marginLeft: '10px', marginRight: '6px' }}>
                To:
              </span>
              <input
                type='text'
                className={classNames(
                  'p-1 rounded-sm focus:outline-none focus:ring-1  focus:border-blue-300',
                  invalidEndDate && inputEndDate
                    ? 'bg-red-100 border border-solid  border-red-400 '
                    : 'border border-solid border-gray-300'
                )}
                style={{ width: '100px' }}
                placeholder='YYYY/MM/DD'
                value={inputEndDate}
                onChange={(e) => {
                  setInputEndDate(e.target.value)
                  setInstantDateFilter('')
                  if (checkValidDate(e.target.value)) {
                    if (!inputStartDate || new Date(e.target.value) < startDate) {
                      selectStartDate(new Date(e.target.value))
                    }
                    setEndDate(new Date(e.target.value))
                    setInvalidEndDate(false)
                  } else {
                    setInvalidEndDate(true)
                  }
                }}
              />
            </div>
            <div className=' m-0 p-0 mt-2 umDatePicker'>
              <DatePicker
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates
                  selectStartDate(start)
                  selectEndDate(end)
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                monthsShown={2}
                maxDate={new Date()}
                shouldCloseOnSelect={false}
                calendarStartDay={1}
              />
            </div>
            <div className='mt-2'>
              <div className='flex  flex-wrap items-center m-0 gap-x-0.5'>
                <span className='text-xs font-semibold'>Auto Select:</span>
                <button
                  className={classNames(
                    'p-1  text-xs rounded-md cursor-pointer',
                    instantDateFilter === 'Today'
                      ? 'border border-solid border-gray-300 bg-blue-500 text-white'
                      : 'border border-solid border-white bg-white '
                  )}
                  onClick={() => {
                    setInstantDateFilter('Today')
                  }}
                >
                  Today
                </button>
                <button
                  className={classNames(
                    'p-1 text-xs rounded-md cursor-pointer',
                    instantDateFilter === 'Yesterday'
                      ? 'border border-solid border-gray-300 bg-blue-500 text-white'
                      : 'border border-solid border-white bg-white '
                  )}
                  onClick={() => {
                    setInstantDateFilter('Yesterday')
                    setInvalidEndDate(false)
                    setInvalidStartDate(false)
                  }}
                >
                  Yesterday
                </button>
                <button
                  className={classNames(
                    'p-1 text-xs rounded-md cursor-pointer',
                    instantDateFilter === 'This Week'
                      ? 'border border-solid border-gray-300 bg-blue-500 text-white'
                      : 'border border-solid border-white bg-white '
                  )}
                  onClick={() => {
                    setInstantDateFilter('This Week')
                    setInvalidEndDate(false)
                    setInvalidStartDate(false)
                  }}
                >
                  This Week
                </button>
                <button
                  className={classNames(
                    'p-1 text-xs rounded-md cursor-pointer',
                    instantDateFilter === 'This Month'
                      ? 'border border-solid border-gray-300 bg-blue-500 text-white'
                      : 'border border-solid border-white bg-white '
                  )}
                  onClick={() => {
                    setInstantDateFilter('This Month')
                    setInvalidEndDate(false)
                    setInvalidStartDate(false)
                  }}
                >
                  This Month
                </button>

                <button
                  className={classNames(
                    'p-1 text-xs rounded-md cursor-pointer',
                    instantDateFilter === 'Last 7 Days'
                      ? 'border border-solid border-gray-300 bg-blue-500 text-white'
                      : 'border border-solid border-white bg-white '
                  )}
                  onClick={() => {
                    setInstantDateFilter('Last 7 Days')
                    setInvalidEndDate(false)
                    setInvalidStartDate(false)
                  }}
                >
                  7D
                </button>
                <button
                  className={classNames(
                    'p-1 text-xs rounded-md cursor-pointer',
                    instantDateFilter === '1 Month'
                      ? 'border border-solid border-gray-300 bg-blue-500 text-white'
                      : 'border border-solid border-white bg-white '
                  )}
                  onClick={() => {
                    setInstantDateFilter('1 Month')
                    setInvalidEndDate(false)
                    setInvalidStartDate(false)
                  }}
                >
                  1M
                </button>
                <button
                  className={classNames(
                    'p-1 text-xs rounded-md cursor-pointer',
                    instantDateFilter === '3 Months'
                      ? 'border border-solid border-gray-300 bg-blue-500 text-white'
                      : 'border border-solid border-white bg-white '
                  )}
                  onClick={() => {
                    setInstantDateFilter('3 Months')
                    setInvalidEndDate(false)
                    setInvalidStartDate(false)
                  }}
                >
                  3M
                </button>
                <button
                  className={classNames(
                    'p-1 text-xs rounded-md cursor-pointer',
                    instantDateFilter === '6 Months'
                      ? 'border border-solid border-gray-300 bg-blue-500 text-white'
                      : 'border border-solid border-white bg-white '
                  )}
                  onClick={() => {
                    setInstantDateFilter('6 Months')
                    setInvalidEndDate(false)
                    setInvalidStartDate(false)
                  }}
                >
                  6M
                </button>
              </div>
            </div>
            <div className='flex justify-end'>
              <Button className='mt-2 mr-3 text-xs' type='default' size='small' onClick={() => clearDateFilter()}>
                Clear
              </Button>
              <Button
                className='mt-2 text-xs'
                type='primary'
                size='small'
                onClick={() => applyDateFilter()}
                disabled={(!startDate || !endDate) && !instantDateFilter}
              >
                Apply
              </Button>
            </div>
          </div>

        </Menu>)
}
      trigger={['click']}
      className='mr-3'
      visible={dateFilterVisible}
      onVisibleChange={(visible) => setDateFilterVisible(visible)}
      overlayClassName='z-0'
    >
      <span
        className={classNames(
          'px-3 py-1 cursor-pointer',
          dateFilterVisible ? 'focus:ring-2 focus:ring-purple-600' : ''
        )}
        style={{
          border: '1px solid lightgrey',
          width: 224
        }}
      >
        {periodLabel || 'Select Date'}
        <DownOutlined
          style={{ color: '#bfbfbf', float: 'right', fontSize: 12 }}
          className='mt-1.5'
        />
      </span>
    </Dropdown>
  )
}
