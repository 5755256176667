import { takeLatest, all } from 'redux-saga/effects'
import actions from '../../statics/actions'
import { getAccountHandle, getProjectsHandle, loginHandle, logoutHandle } from './handlers'

export function * watcherSaga () {
  yield all([
    yield takeLatest(actions.LOGIN, loginHandle),
    yield takeLatest(actions.GET_PROJECTS, getProjectsHandle),
    yield takeLatest(actions.LOGOUT, logoutHandle),
    yield takeLatest(actions.GET_ACCOUNT, getAccountHandle)
  ])
}
