import React, { useState, useEffect } from 'react'
import amplify from '../../store/services/amplify'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import appRoutes from '../../statics/appRoutes'
import md5 from 'blueimp-md5'
import { logout } from '../../store/actions'
import { Menu, Dropdown, notification } from 'antd'

const UserPanel = () => {
  const dispatch = useDispatch()
  const [username, setUserName] = useState()
  const [userImgLink, setUserImgLink] = useState('')
  const getUsername = async () => {
    const username = await amplify.getUserName()
    if (username) {
      setUserImgLink(`https://www.gravatar.com/avatar/${md5(username)}?d=mp&s=30`)
      setUserName(username)
    }
  }

  const settingsMenu = (
    <Menu>
      <Menu.Item>
        <Link to={appRoutes.accountPage}>Account</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={appRoutes.profilePage}>Your profile</Link>
      </Menu.Item>
      {localStorage.getItem('Role') === 'Administrator' && (
        <>
          <Menu.Item>
            <Link to={appRoutes.projectSettingsPage}>Project Settings</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to={appRoutes.userSettingsPage}>User Settings</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to={appRoutes.administrationPage}>Administration</Link>
          </Menu.Item>
        </>
      )}
      <Menu.Item>
        <Link
          onClick={() => {
            dispatch(logout())
            notification.success({
              message: 'Logged out successfully',
              placement: 'topRight'
            })
            window.location.href = '/login'
          }}
        >
          Log out
        </Link>
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    getUsername()
  }, [])

  return (
    <>
      <Dropdown overlay={settingsMenu} placement='bottomRight'>
        <div>
          <img width={40} height={40} src={userImgLink} alt={username} className='rounded-full' />
          <i className='fa fa-caret-down text-lg p-2 text-gray-500' />
        </div>
      </Dropdown>
    </>
  )
}

export default UserPanel
