import Auth from '@aws-amplify/auth'
import { Button, Input, Alert, notification, Skeleton, Tag, Tooltip, Spin } from 'antd'
import React, { useState, useEffect } from 'react'
import amplify from '../../store/services/amplify'
import { httpGet } from '../../store/services/testServiceClient'
import { getFormattedDate } from '../../utils/dateTime'
import md5 from 'blueimp-md5'

const Profile = () => {
  const [code, setCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [forgotPasswordView, setForgotPasswordView] = useState(false)
  const [changePasswordView, setChangePasswordView] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userDetails, setUserDetails] = useState(null)
  const [showLoader, setShowLoader] = useState(false)
  const [loadingChangePassword, setLoadingChangePassword] = useState(false)
  const [loadingForgotPassowrd, setLoadingForgotPassword] = useState(false)
  const [loadingSubmitPassword, setLoadingSubmitPassword] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [userImgLink, setUserImgLink] = useState('')
  const [username, setUserName] = useState()

  const initiateForgotPassword = async () => {
    clearInputs()
    setLoadingForgotPassword(true)
    Auth.forgotPassword(await amplify.getUserName())
      .then(() => {
        notification.success({
          message: 'Check registered email for the verification code',
          placement: 'topRight',
          duration: 3
        })
        setForgotPasswordView(true)
      })
      .catch((error) => {
        notification.error({
          message: 'Failed to send verification code.',
          description: error.message || 'Error resetting password.',
          placement: 'topRight'
        })
        console.log(error)
      })
      .finally(() => setLoadingForgotPassword(false))
  }

  const initiateChangePassword = async (e) => {
    e.preventDefault()
    setLoadingChangePassword(true)
    Auth.changePassword(await Auth.currentAuthenticatedUser(), currentPassword, newPassword)
      .then(() => {
        notification.success({
          message: 'Password changed successfully.',
          placement: 'topRight',
          duration: 3
        })
        setChangePasswordView(false)
        resetPasswordUI()
      })
      .catch((error) => {
        notification.error({
          message: 'Failed to change password.',
          description: error.message || 'Error changing password.',
          placement: 'topRight'
        })
        console.log(error)
      })
      .finally(() => setLoadingChangePassword(false))
  }

  const confirmForgotPassword = async (e) => {
    e.preventDefault()
    setLoadingSubmitPassword(true)
    Auth.forgotPasswordSubmit(await amplify.getUserName(), code, newPassword)
      .then(() => {
        notification.success({
          message: 'Password changed successfully.',
          placement: 'topRight',
          duration: 3
        })
        resetPasswordUI()
        setChangePasswordView(false)
      })
      .catch((error) => {
        notification.error({
          message: 'Failed to change password.',
          description: error.message || 'Error changing password.',
          placement: 'topRight'
        })
        console.log(error)
        setLoadingSubmitPassword(false)
      })
  }

  const resetPasswordUI = () => {
    setCode('')
    clearInputs()
    setForgotPasswordView(false)
    setChangePasswordView(false)
    setLoadingSubmitPassword(false)
  }

  const clearInputs = () => {
    setCurrentPassword('')
    setNewPassword('')
  }

  const getUserDetails = async () => {
    setLoading(true)
    try {
      const respose = await httpGet('user')
      setUserDetails(respose.data)
    } catch (error) {
      notification.error({
        message: 'Failed to load user details.',
        description: error.message,
        placement: 'topRight'
      })
    }
    setLoading(false)
  }

  const sendVerificationEmail = async () => {
    try {
      setShowLoader(true)
      const user = await Auth.currentAuthenticatedUser()
      await Auth.verifyUserAttribute(user, 'email')
      notification.success({
        message: 'Verification email sent to registered email.',
        placement: 'topRight'
      })
    } catch (error) {
      notification.error({
        message: 'Failed to send verification email.',
        description: error.message,
        placement: 'topRight'
      })
    } finally {
      setShowLoader(false)
    }
  }

  const getGravatar = async () => {
    const username = await amplify.getUserName()
    if (username) {
      setUserImgLink(`https://www.gravatar.com/avatar/${md5(username)}?d=mp`)
      setUserName(username)
    }
  }

  useEffect(() => {
    getUserDetails()
    getGravatar()
  }, [])

  return (
    <div className='mt-4 w-full flex justify-center'>
      <div className='my-4 w-full'>
        {loading
          ? (
            <div className='w-full mx-auto shadow-sm px-5 py-5 '>
              <Skeleton active />
            </div>
            )
          : (
            <>
              <div className='w-full mx-auto pb-2 shadow-sm rounded'>
                <div className='p-4 bg-transparentColor font-bold text-md w-full flex items-center justify-between mb-2'>
                  User Details
                  {userDetails?.IsSysAdmin && (
                    <Tooltip placement='bottom' title='System Administrator' className='cursor-default'>
                      <Tag icon={<i className='fas fa-user-shield mr-2' />} color='geekblue' className='px-3 py-1'>
                        SysAdmin
                      </Tag>
                    </Tooltip>
                  )}
                </div>
                <div className='flex flex-col sm:flex-row items-start justify-start w-full'>
                  <div className='flex items-center mx-auto sm:mx-12 justify-center my-2 w-full sm:w-auto'>
                    <Tooltip
                      placement='bottom'
                      color='white'
                      overlayClassName='text-black'
                      title={
                        <>
                          <span className='text-sm text-black'>
                            Fetched from{' '}
                            <a href='https://en.gravatar.com/' target='_blank' rel='noreferrer'>
                              Gravatar
                            </a>
                          </span>
                        </>
                      }
                    >
                      <img width={120} height={120} src={userImgLink} alt={username} className='rounded-full' />
                    </Tooltip>
                  </div>
                  <div className='px-4 w-full' style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.06)' }}>
                    <div className='grid grid-cols-2 gap-x-16 w-full'>
                      <div className='col-span-1'>
                        <p className='text-gray-500 text-xs mb-0'> Name</p>
                        <p className='font-semibold text-md mb-0'>{userDetails?.Name}</p>
                        <p className='text-gray-500 text-xs mt-4 mb-0'>Email</p>
                        <p className='font-semibold text-md mb-0'>{userDetails?.Email}</p>
                        <p className='text-gray-500 text-xs mt-4 mb-0'>Title</p>
                        <p className='font-semibold text-md mb-0'>{userDetails?.Title}</p>
                      </div>
                      <div className='col-span-1'>
                        <p className='text-gray-500 text-xs mb-0'>Account Status</p>
                        <p className='font-semibold text-md mb-0'>{userDetails?.AccountStatus}</p>
                        <p className='text-gray-500 text-xs mt-4 mb-0'>Last Modified Date</p>
                        <p className='font-semibold text-md mb-0'>{getFormattedDate(userDetails?.LastModifiedDate)}</p>
                        <p className='text-gray-500 text-xs mt-4 mb-0'>Administrator</p>
                        <p className='font-semibold text-md mb-0'>
                          {userDetails?.IsAdministrator ? (
                            <>
                              <i className='fas fa-check-circle text-green-600' /> Yes
                            </>
                          ) : (
                            <>
                              <i className='fas fa-times-circle text-red-600' /> No
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    {!userDetails?.EmailVerified && (
                      <div className='my-4 w-full'>
                        <Alert
                          message='Email Not Verified'
                          description={
                            <Button type='primary' onClick={sendVerificationEmail} loading={showLoader} className='my-2'>
                              Verify Email
                            </Button>
                          }
                          type='warning'
                          showIcon
                        />
                      </div>
                    )}
                    <div className='w-full mx-auto pb-2 my-5'>
                      <div className='flex flex-col w-full xl:w-3/4'>
                        {!changePasswordView
                          ? (
                            <>
                              <Button className='w-min my-2' onClick={() => setChangePasswordView(true)} type='primary'>
                                Change Password
                              </Button>
                            </>
                            )
                          : !forgotPasswordView && changePasswordView
                              ? (
                                <>
                                  <form onSubmit={initiateChangePassword} className='grid grid-cols-4 gap-4 items-center'>
                                    <label className='col-span-1'>Current Password: </label>
                                    <Input.Password
                                      className='col-span-3'
                                      placeholder='Enter current password'
                                      value={currentPassword}
                                      onChange={(e) => setCurrentPassword(e.target.value)}
                                    />
                                    <div
                                      className='col-span-4 text-right'
                                      style={{ marginTop: '-10px', height: '20px' }}
                                    >
                                      <Spin size='small' spinning={loadingForgotPassowrd} className='mx-2' />
                                      <span
                                        className=' text-xs text-blue-600 text-right cursor-pointer hover:text-blue-400' onClick={initiateForgotPassword}
                                      >
                                        Forgot Password?
                                      </span>
                                    </div>
                                    <label className='col-span-1'>New Password: </label>
                                    <Input.Password
                                      className='col-span-3'
                                      placeholder='Enter new password'
                                      value={newPassword}
                                      onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <div className='col-span-4 flex justify-end items-center gap-2'>
                                      <Button className='w-min col-span-1' onClick={resetPasswordUI} type='default'>
                                        Cancel
                                      </Button>
                                      <Button
                                        className='w-min col-span-1'
                                        disabled={!currentPassword || newPassword.length < 16}
                                        onClick={initiateChangePassword}
                                        type='primary'
                                        htmlType='submit'
                                        loading={loadingChangePassword}
                                      >
                                        Change Password
                                      </Button>
                                    </div>
                                  </form>
                                </>
                                )
                              : (
                                <form onSubmit={confirmForgotPassword} className='grid grid-cols-4 gap-4 items-center'>
                                  <label className='col-span-1'>Verification Code: </label>
                                  <Input
                                    className='col-span-3'
                                    placeholder='Enter confirmation code'
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                  />
                                  <label className='col-span-1'>New Password: </label>
                                  <Input.Password
                                    className='col-span-3'
                                    placeholder='Enter new password'
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                  />
                                  <div className='col-span-4 flex justify-end items-center gap-2'>
                                    <Button onClick={resetPasswordUI} type='default'>
                                      Cancel
                                    </Button>
                                    <Button
                                      disabled={!code || newPassword.length < 16}
                                      onClick={confirmForgotPassword}
                                      type='primary'
                                      htmlType='submit'
                                      loading={loadingSubmitPassword}
                                    >
                                      Change Password
                                    </Button>
                                  </div>
                                  <Alert
                                    message='A verification code has been sent on your registered email'
                                    className='col-span-4'
                                    showIcon
                                    type='info'
                                  />
                                </form>
                                )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            )}
      </div>
    </div>
  )
}

export default Profile
