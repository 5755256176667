import React, { useCallback, useState, useEffect } from 'react'

import { withRouter } from 'react-router-dom'
import { getReport } from '../../store/services/reportServiceClient'

import Breadcrumb from '../../components/Navigation/Breadcrumb'
import { Alert, Spin } from 'antd'
import AtomicReport from './AtomicReport'
import MultiDatasetReport from './MultiDatasetReport'

const Report = (props) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [report, setReport] = useState({})

  const loadReport = useCallback(async () => {
    try {
      const response = await getReport(props.match.params.runId)
      if (response.status === 200) {
        setReport(response.data)
      } else {
        setErrorMessage('Error loading report')
      }
    } catch (e) {
      if (e.response) {
        switch (e.response.status) {
          case 404:
            setErrorMessage(e.response.data.detail)
            break
          case 500:
            setErrorMessage(e.response?.data)
            break
          default:
            setErrorMessage(e.message)
            break
        }
      }
    } finally {
      setIsLoading(false)
    }
  }, [props.match.params.runId])

  useEffect(() => {
    loadReport()
  }, [loadReport])

  return (
    <div className='mt-3'>
      <Breadcrumb testName={report.TestName} />
      {errorMessage && (
        <Alert
          showIcon
          closable
          type='error'
          description={errorMessage}
          message='Error'
          afterClose={() => setErrorMessage('')}
        />
      )}
      {isLoading && (
        <div className='flex justify-center items-start'>
          <Spin>
            <Alert className='sm:w-96' message='Please wait' description='Your report is loading...' type='info' />
          </Spin>
        </div>
      )}
      {!isLoading && errorMessage.length === 0 && (
        <>
          {report.Root.Type === 'TestSequence' ? (
            <AtomicReport run={report} setErrorMessage={setErrorMessage} />
          ) : (
            <MultiDatasetReport report={report} />
          )}
        </>
      )}
    </div>
  )
}

export default withRouter(Report)
